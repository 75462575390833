import Step1 from "./steps/Step1";
import Step10 from "./steps/Step10";
import Step11 from "./steps/Step11";
import Step5 from "./steps/Step5";
import Step1Title from "./titles/Step1Title";

export const InteractiveGuideSteps = { Step1, Step5, Step10, Step11 };

export const InteractiveGuideTitles = {
  Step1Title,
};
