import { useQuery } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import { useCustomCompareMemo } from "use-custom-compare";
import useAppSearch from "../../hooks/router/useAppSearch";
import { useAssetsWithBalance } from "../../hooks/useAssetsWithBalance";
import {
  getAssetPriceToday,
  getAssetsPool,
  transformGQLAdaAsset,
} from "../../utils/assets.utils";
import { getBrambleSdk } from "../client/bramble.sdk";
import { getStats2Sdk } from "../client/statsSdk";
import { BrambleTransformer } from "../utils/BrambleTransformer.class";

export const useAssetMetadata = (additionalIds?: string[]) => {
  const { given, taken } = useAppSearch();
  const { assetsWithBalance } = useAssetsWithBalance();

  const ids = useCustomCompareMemo(
    () => {
      const map = new Map();
      const fullList = [
        ...[given, taken].filter(Boolean),
        ...(assetsWithBalance?.map(({ metadata: { assetId } }) => assetId) ??
          []),
        ...(additionalIds ?? []),
      ];

      fullList.forEach((id) => id && map.set(id, id));
      return [...map.values()].sort();
    },
    [given, taken, assetsWithBalance, additionalIds],
    isEqual,
  );

  return useQuery({
    queryKey: ["assets", ...ids],
    queryFn: async () => {
      const { fetchAssetsById, fetchPoolByAssets } = await getBrambleSdk();
      const { getAdaPrice } = await getStats2Sdk();
      const {
        assets: { byIds },
      } = await fetchAssetsById({ ids });
      const {
        pools: { byAssets },
      } = await fetchPoolByAssets({ assets: ids });
      const { adaPrice } = await getAdaPrice();
      const { bramble, stats } =
        BrambleTransformer.transformBrambleAssetsFragment(byIds);

      const assetsPool = getAssetsPool(byIds, byAssets);

      return {
        bramble,
        stats: stats.map((asset) => {
          const pool = assetsPool[asset.assetId];
          const priceToday = getAssetPriceToday(adaPrice, pool);
          return { ...transformGQLAdaAsset(asset), priceToday };
        }),
      };
    },
    enabled: Boolean(ids?.length && ids.filter(Boolean)),
    staleTime: 1000 * (additionalIds ? 5 : 10 * 60), // 10 minutes if no additionalIds are passed in.
    gcTime: 1000 * 60 * 25, // 25 minutes
    refetchOnWindowFocus: false,
  });
};

export const useAssetById = (assetId: string) => {
  return useQuery({
    queryKey: ["assetDescription", assetId],
    queryFn: async () => {
      if (!assetId) {
        return {
          bramble: undefined,
          stats: undefined,
        };
      }

      const { fetchAssetById } = await getBrambleSdk();
      const response = await fetchAssetById({ id: assetId });
      return BrambleTransformer.transformBrambleAssetFragment(
        response.assets.byId,
      );
    },
    enabled: Boolean(assetId),
    staleTime: 1000 * 60 * 25, // 25 minutes
    gcTime: 1000 * 60 * 25, // 25 minutes
    refetchOnWindowFocus: false,
  });
};
