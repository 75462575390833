import { DateField } from "@prismicio/client/*";
import { toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { motion } from "framer-motion";
import { FC, useCallback, useEffect, useState } from "react";
import {
  slideInAndScaleFromBottomMotion,
  staggeredChildrenMotion,
} from "../../../motions/default.motions";
import { NewsActions, useNewsContext } from "../../../stores/NewsContext";
import { ControlCenterNewsListItem } from "../../ControlCenterNewsListItem";
import NoNewsPlaceholder from "../../NewsPopover/NoNewsPlaceholder";
import ControlCenterContentFooter from "../ControlCenterContentFooter";
import ControlCenterContentNode from "../ControlCenterContentNode";
import ControlCenterContentTitle from "../ControlCenterContentTitle";

const ControlCenterInbox: FC = () => {
  const [mounted, setMounted] = useState(false);
  const {
    dispatch,
    state: {
      isFetchingNews,
      isRefetchingNews,
      isNewsFetched,
      numberOfUnreadNewsItems,
      popoverNewsItems: newsItems,
    },
  } = useNewsContext();

  const renderMemoizedNewsItems = useCallback(() => {
    if (isFetchingNews || isRefetchingNews) {
      return (
        <motion.ul
          key="ControlCenterNewsList"
          variants={staggeredChildrenMotion}
          className={cx("grid gap-4", toolkitCx.layout.spacing.both.md)}
        >
          {Array.from({ length: 3 }).map((item, index) => (
            <ControlCenterNewsListItem.Skeleton key={`${item}-${index}`} />
          ))}
        </motion.ul>
      );
    }

    if (isNewsFetched && !isRefetchingNews && newsItems.length === 0) {
      return (
        <motion.div
          key="NoNewsPlaceholder"
          className="my-auto"
          variants={slideInAndScaleFromBottomMotion}
        >
          <NoNewsPlaceholder />
        </motion.div>
      );
    }

    if (isNewsFetched && !isRefetchingNews) {
      return (
        <motion.ul
          variants={staggeredChildrenMotion}
          key="ControlCenterNewsList"
          className={cx("grid gap-4", toolkitCx.layout.spacing.both.md)}
        >
          {newsItems.map(({ data, first_publication_date, id }) => {
            return (
              <ControlCenterNewsListItem.Node
                key={id}
                first_publication_date={first_publication_date as DateField}
                id={id}
                newsItem={data}
              />
            );
          })}
        </motion.ul>
      );
    }
  }, [isFetchingNews, isRefetchingNews, isNewsFetched, newsItems]);

  useEffect(() => {
    setMounted(true);

    return () => {
      if (mounted) {
        NewsActions.setReadAllNewsItems(
          newsItems?.map(({ id }) => id),
          dispatch,
        );
      }
    };
    // No need to listen for `newsItems` changes here, because this effect should only run once on unmount.
  }, [dispatch, mounted]);

  return (
    <>
      <ControlCenterContentTitle
        title={`Inbox${
          numberOfUnreadNewsItems && numberOfUnreadNewsItems > 0
            ? ` (${numberOfUnreadNewsItems})`
            : ""
        }`}
        description="Stay up to date with the latest news and updates from SundaeSwap."
      />
      <ControlCenterContentNode
        tag="div"
        className={cx("grid gap-4", toolkitCx.layout.spacing.bottom.md, {
          "h-full": newsItems.length === 0,
        })}
      >
        {renderMemoizedNewsItems()}
      </ControlCenterContentNode>

      <ControlCenterContentFooter title="News provided by Sundae Labs." />
    </>
  );
};

export default ControlCenterInbox;
