import { Text } from "@sundaeswap/ui-toolkit";
import { FC, memo } from "react";

const TasteTestExplanationGuideStep1Title: FC = () => {
  return (
    <>
      <div
        className="absolute left-0 top-0 z-[-1] h-full w-full bg-contain bg-no-repeat opacity-10"
        style={{
          backgroundImage: `url("/static/images/discover/taste_test_jumbotron.png")`,
        }}
      />
      <div className="relative grid gap-4">
        <Text className="text-center" tag="h1" size="3xl" weight="semibold">
          Welcome to the{" "}
          <Text
            tag="span"
            className="inline"
            size="3xl"
            weight="semibold"
            gradient
          >
            Taste Test
          </Text>{" "}
          – A New Frontier in Price Discovery!
        </Text>
      </div>
    </>
  );
};

export default memo(TasteTestExplanationGuideStep1Title);
