import { useI18N } from "@sundaeswap/react-hooks";
import { Image, Text } from "@sundaeswap/ui-toolkit";
import { FC } from "react";

const V3ComparisonStep0: FC = () => {
  const { t } = useI18N("joyride");

  return (
    <>
      <div className="pointer-events-none absolute inset-0 overflow-hidden">
        <Image
          className="absolute -bottom-1 -right-14 w-44 opacity-30"
          src="/static/images/v3/froyo.png"
        />
        <Image
          className="absolute -bottom-1 -left-14 w-44 opacity-30"
          src="/static/images/v3/shimmy.png"
        />
      </div>
      <div className="flex h-48 items-center justify-center text-center">
        <Text tag="h1" size="4xl" className="max-w-screen-xs" weight="bold">
          {t("v3.guides.comparison.steps.step0.description")}
        </Text>
      </div>
    </>
  );
};

export default V3ComparisonStep0;
