import { AssetAmount } from "@sundaeswap/asset";
import { ADA_METADATA } from "@sundaeswap/core";
import { TFractionLike } from "@sundaeswap/fraction";

import { DEFAULT_DECIMALS } from "../constants/cardano.constants";
import { stringifyBigInt } from "./string-format";

/**
 * Formats a number or bigint to a localized string.
 *
 * This function takes a value (either a number or bigint), optional formatting options,
 * and an optional locale code. If the value is not a number or bigint, it returns an
 * empty string. If the value is a bigint, it uses the builtin `toLocaleString` method
 * of the bigint to format it. If the value is a number, it uses the `Intl.NumberFormat`
 * constructor to create a new number format object and formats the value.
 *
 * If no locale is specified, the function defaults to "en" (English).
 *
 * @param {number | bigint} value - The value to format. If this is not a number or bigint,
 * the function returns an empty string.
 * @param {Intl.NumberFormatOptions | BigInt["toLocaleString"]} formatOpts - Optional formatting
 * options. If the value is a bigint, this should be options accepted by `BigInt.toLocaleString`,
 * otherwise it should be options accepted by `Intl.NumberFormat`.
 * @param {string} currentLocale - Optional locale code. If not provided, defaults to "en".
 *
 * @returns {string} The value formatted as a localized string, or an empty string if the
 * value is not a number or bigint.
 */
export const numberI18n = (
  value?: number | bigint,
  formatOpts?: Intl.NumberFormatOptions | bigint["toLocaleString"],
  currentLocale = "en",
) => {
  if (value === undefined) return "";

  if (!/^number|bigint$/.test(typeof value)) return "";

  if (/^bigint$/.test(typeof value))
    return value.toLocaleString(currentLocale, {
      ...(formatOpts as bigint["toLocaleString"]),
    });

  return new Intl.NumberFormat(currentLocale, {
    ...(formatOpts as Intl.NumberFormatOptions),
  }).format(value);
};

export const stringToBigint = (
  input: TFractionLike,
  decimals = DEFAULT_DECIMALS,
) => AssetAmount.fromValue(input, decimals).amount;

export const divideBy = (input: TFractionLike, divider = 100) =>
  Number(input) / divider;
export const multiplyBy = (input: TFractionLike, multiplier = 10) =>
  Number(input) * multiplier;

/**
 * Formats a number as a percentage for a specific locale.
 *
 * This function takes a number as input and a locale, it returns the
 * localized percentage string representation of the number. The fraction
 * digits are capped to a maximum of 2.
 *
 * @param {number} value - The number to format as a percentage.
 * @param {string} [currentLocale="en"] - The locale to use for formatting. Default is "en".
 * @param {Intl.NumberFormatOptions | BigInt["toLocaleString"]} [formatOpts] - Optional formatting options.
 *
 * @returns {string} - Localized percentage string.
 */
export const percentageI18n = (
  value: number,
  currentLocale = "en",
  formatOpts?: Intl.NumberFormatOptions | bigint["toLocaleString"],
) =>
  numberI18n(
    value,
    { ...formatOpts, maximumFractionDigits: 2, style: "percent" },
    currentLocale,
  );

/**
 * Parses a string representation of a number, potentially in scientific notation, and
 * returns both a string and numerical representation.
 *
 * This function takes a string as input, which can be a number or a number in scientific
 * notation. It converts the string to a number and returns both a string and numerical
 * representation. The string representation is fixed to 100 decimal places.
 *
 * Note: This function may not handle very large values correctly.
 *
 * @param {string} input - The string representation of a number to parse.
 *
 * @returns {object} An object with the string and numerical representation of the number.
 * If the input is not provided, it returns an object with both values set to 0.
 */
export const parseScientific = (input?: string | null) => {
  if (!input) {
    return { str: "0", num: 0 };
  }
  // TODO: add tests for large numbers;
  // this may not handle very large values, but the parseScientific
  // code we had before was slightly buggy.
  // Previously: https://gist.github.com/jiggzson/b5f489af9ad931e3d186?permalink_comment_id=3723670#gistcomment-3723670
  return { str: Number(input).toFixed(100), num: Number(input) };
};

/**
 * Determines the maximum number of fraction digits to use when displaying a percentage
 * based on the provided value. This can be useful for formatting percentages with varying
 * precision based on their magnitude.
 *
 * @param {number} value - The numerical value for which the maximum fraction digits are determined.
 * @returns {number} - Returns 6 if the value is less than 0.000001, 4 if the value is less than 0.0001,
 *                     otherwise returns 2.
 */
export const getPercentMaximumFractionDigits = (value: number) => {
  if (value < 0.000001) return 6;
  if (value < 0.0001) return 4;
  return 2;
};

/**
 * Formats a number as a localized string using the ADA Symbol (₳).
 * @param value The value to format.
 * @param currentLocale The locale to use for formatting. Default is "en".
 * @returns The value formatted as a localized string using the ADA Symbol (₳).
 */
export const formatCurrencyToAda = (
  value: number,
  currentLocale = "en",
  formatOpts?: Intl.NumberFormatOptions,
) => {
  return numberI18n(
    value,
    {
      ...formatOpts,
      minimumFractionDigits: 0,
      maximumFractionDigits: ADA_METADATA.decimals,
      style: "currency",
      currency: "USD",
    },
    currentLocale,
  )
    .replace("$", "₳")
    .replace("US", "");
};

/**
 * Serializes elements of a query key array, handling BigInt and other complex objects.
 * Uses generics to allow any type of input while maintaining type checks.
 *
 * @param queryKey - The array of values to be used as a query key.
 * @returns An array of serialized strings.
 */
export function serializeQueryKey<T>(queryKey: T[]): (string | T)[] {
  try {
    const result = queryKey.map((element) => {
      if (typeof element === "object" && element !== null) {
        return JSON.stringify(element, stringifyBigInt);
      }
      return typeof element === "bigint" ? element.toString() : element;
    });

    return result;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`Could not serialize queryKey for:`, queryKey);
    throw e;
  }
}
