import { Dispatch } from "react";
import {
  ECardanoExplorer,
  EFarmsFilter,
  EFarmsViewMode,
  EProfileAppActions,
  IProfileAppAction,
  IProfileAppState,
} from "./types";

type TActionFunc<T> = (value: T, dispatch: Dispatch<IProfileAppAction>) => void;

/* -------------------------------------------------------------------------------------------------
 * App
 * -----------------------------------------------------------------------------------------------*/
const setActiveLanguage: TActionFunc<string> = (activeLanguage, dispatch) =>
  dispatch({
    type: EProfileAppActions.SET_ACTIVE_LANGUAGE,
    payload: { activeLanguage },
  });

const setCardanoExplorer: TActionFunc<ECardanoExplorer> = (
  cardanoExplorer,
  dispatch,
) =>
  dispatch({
    type: EProfileAppActions.SET_CARDANO_EXPLORER,
    payload: { cardanoExplorer },
  });

const setErrorReporting: TActionFunc<boolean> = (
  errorReportingEnabled,
  dispatch,
) =>
  dispatch({
    type: EProfileAppActions.SET_ERROR_REPORTING,
    payload: { errorReportingEnabled },
  });

const setHotjarEnabled: TActionFunc<boolean> = (hotjarEnabled, dispatch) =>
  dispatch({
    type: EProfileAppActions.SET_HOTJAR_ENABLED,
    payload: { hotjarEnabled },
  });

const setProfileEnabled: TActionFunc<boolean> = (profileEnabled, dispatch) =>
  dispatch({
    type: EProfileAppActions.SET_PROFILE_ENABLED,
    payload: { profileEnabled },
  });

const setPrivacyEnabled: TActionFunc<boolean> = (privacyEnabled, dispatch) =>
  dispatch({
    type: EProfileAppActions.SET_PRIVACY_MODE,
    payload: { privacyEnabled },
  });

const syncDefaultState: TActionFunc<Partial<IProfileAppState>> = (
  state,
  dispatch,
) =>
  dispatch({ type: EProfileAppActions.SYNC_DEFAULT_STATE, payload: { state } });

const setPreferredHandle: TActionFunc<string | null> = (handle, dispatch) =>
  dispatch({
    type: EProfileAppActions.SET_PREFERRED_HANDLE,
    payload: { handle },
  });

const setPreferredAvatar: TActionFunc<string> = (avatar, dispatch) =>
  dispatch({
    type: EProfileAppActions.SET_PREFERRED_AVATAR,
    payload: { avatar },
  });

const setPrefersReducedMotion: TActionFunc<boolean> = (
  prefersReducedMotion,
  dispatch,
) =>
  dispatch({
    type: EProfileAppActions.SET_PREFERS_REDUCED_MOTION,
    payload: { prefersReducedMotion },
  });

/* -------------------------------------------------------------------------------------------------
 * Limit Orders
 * -----------------------------------------------------------------------------------------------*/
const addLimitOrder: TActionFunc<string> = (txHash, dispatch) =>
  dispatch({ type: EProfileAppActions.ADD_LIMIT_ORDER, payload: { txHash } });

/* -------------------------------------------------------------------------------------------------
 * TX Settings
 * -----------------------------------------------------------------------------------------------*/
const setSlippageTolerance: TActionFunc<number> = (
  slippageTolerance,
  dispatch,
) =>
  dispatch({
    type: EProfileAppActions.SET_SLIPPAGE_TOLERANCE,
    payload: { slippageTolerance },
  });
const setIsSlippageToleranceSetToAuto: TActionFunc<boolean> = (
  isSlippageToleranceSetToAuto,
  dispatch,
) =>
  dispatch({
    type: EProfileAppActions.SET_IS_SLIPPAGE_TOLERANCE_SET_TO_AUTO,
    payload: { isSlippageToleranceSetToAuto },
  });

/* -------------------------------------------------------------------------------------------------
 * Farms
 * -----------------------------------------------------------------------------------------------*/
const setFarmsFilter: TActionFunc<EFarmsFilter> = (farmsFilter, dispatch) =>
  dispatch({
    type: EProfileAppActions.SET_FARMS_FILTER,
    payload: { farmsFilter },
  });
const setFarmsViewMode: TActionFunc<EFarmsViewMode> = (
  farmsViewMode,
  dispatch,
) =>
  dispatch({
    type: EProfileAppActions.SET_FARMS_VIEW_MODE,
    payload: { farmsViewMode },
  });

/* -------------------------------------------------------------------------------------------------
 * Pool Filters
 * -----------------------------------------------------------------------------------------------*/
const setPoolFilters: TActionFunc<Partial<IProfileAppState["filters"]>> = (
  poolFilters,
  dispatch,
) =>
  dispatch({
    type: EProfileAppActions.SET_POOL_FILTERS,
    payload: { poolFilters },
  });
const addFavoritePool: TActionFunc<string> = (poolId, dispatch) =>
  dispatch({ type: EProfileAppActions.SET_FAVORITE_POOL, payload: { poolId } });
const removeFavoritePool: TActionFunc<string> = (poolId, dispatch) =>
  dispatch({
    type: EProfileAppActions.UNSET_FAVORITE_POOL,
    payload: { poolId },
  });

/* -------------------------------------------------------------------------------------------------
 * Toggles
 * -----------------------------------------------------------------------------------------------*/
const setToggles: TActionFunc<IProfileAppState["toggles"]> = (
  toggles,
  dispatch,
) => dispatch({ type: EProfileAppActions.SET_TOGGLES, payload: { toggles } });

/* -------------------------------------------------------------------------------------------------
 * Export
 * -----------------------------------------------------------------------------------------------*/
export const PersistentActions = {
  addLimitOrder,
  setActiveLanguage,
  setCardanoExplorer,
  setErrorReporting,
  setFarmsFilter,
  setFarmsViewMode,
  setHotjarEnabled,
  setProfileEnabled,
  setPrivacyEnabled,
  syncDefaultState,
  setIsSlippageToleranceSetToAuto,
  setSlippageTolerance,
  setPreferredAvatar,
  setPreferredHandle,
  setPrefersReducedMotion,
  toggles: {
    setToggles,
  },
  filters: {
    pools: {
      addFavoritePool,
      removeFavoritePool,
      setPoolFilters,
    },
  },
};
