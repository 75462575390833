import { AssetAmount } from "@sundaeswap/asset";
import { SundaeUtils } from "@sundaeswap/core/utilities";

import { useWalletObserver } from "@sundaeswap/wallet-lite";
import { useEffect } from "react";
import {
  ADA_TX_FEE,
  SCOOPER_FEE,
} from "../../../constants/SundaeSwap.constants";
import { ADA_METADATA } from "../../../constants/cardano.constants";
import { IAssetMetaData } from "../../../types/Asset.types";
import { OrderActions } from "../actions";
import { useOrderContext } from "../context";

export const useAdaAfterSwap = () => {
  const {
    state: {
      derived,
      assets: { given, taken },
    },
    dispatch,
  } = useOrderContext();
  const { balance } = useWalletObserver<IAssetMetaData>();

  /**
   * Updates the estimated ADA left after a swap, **NOT** including
   * the minimum ADA threshold. This is so we can compare the leftover
   * ADA against the minimum constraint if needed.
   */
  useEffect(() => {
    if (!balance || (!given && !taken)) {
      return;
    }

    const baseAda = new AssetAmount<IAssetMetaData>(
      balance.get(ADA_METADATA.assetId)?.amount ?? 0n,
      ADA_METADATA,
    );
    let addedAda = new AssetAmount<IAssetMetaData>(0, ADA_METADATA);
    let subtractedAda = new AssetAmount<IAssetMetaData>(0, ADA_METADATA);

    if (given && SundaeUtils.isAdaAsset(given.metadata)) {
      subtractedAda = subtractedAda.add(given);
    }

    if (taken && SundaeUtils.isAdaAsset(taken.metadata)) {
      addedAda = addedAda.add(taken);
    }

    if (derived?.transaction?.builtTx?.fees) {
      subtractedAda = subtractedAda.add(
        derived.transaction.builtTx.fees.scooperFee,
      );
      if (derived?.transaction?.builtTx?.fees?.cardanoTxFee) {
        subtractedAda.add(derived.transaction.builtTx.fees.cardanoTxFee);
      }
    } else {
      subtractedAda = subtractedAda.add(SCOOPER_FEE).add(ADA_TX_FEE);
    }

    OrderActions.setAdaAfterSwap(
      baseAda.add(addedAda).subtract(subtractedAda),
      dispatch,
      "OrderContext.useAdaAfterSwap",
    );
  }, [balance, taken, given, derived?.transaction?.builtTx?.fees]);
};
