export const testIds = {
  addressCopy: { container: "address-copy" },
  availableAdaBalanceTooltip: {
    trigger: "available-ada-balance-tooltip-trigger",
    content: "available-ada-balance-tooltip-content",
  },
  assetPieChart: {
    container: "asset-pie-chart-container",
    canvas: "asset-pie-chart-canvas",
    loader: "asset-pie-chart-loader",
  },
  assetAmountBox: {
    container: "asset-amount-box-container",
    input: "asset-amount-box-input",
    usdValue: "asset-amount-box-usd-value",
    balance: "asset-amount-box-balance",
    maxButton: "asset-amount-box-max-button",
  },
  assetSelectDialog: { content: "asset-select-dialog-content" },
  migrateLiquidityDialog: { content: "migrate-liquidity-dialog-content" },
  assetSelectFlow: {
    initial: { container: "initial-asset-select-container" },
    input: "asset-select-input",
  },
  exchangeFlow: {
    swap: {
      container: "swap-order-container",
      orderExecuted: {
        infoContainer: "order-executed-info-container",
      },
    },
    ratioDisplay: {
      container: "ratio-display-container",
    },
    ratioInput: {
      loadingIndicator: "ratio-input-loading-container",
      container: "ratio-input-container",
      input: "ratio-input-input",
    },
    orderControls: {
      container: "swap-parameters-container",
      limitOrderInput: {
        container: "limit-order-input-container",
        loadingIndicator: "limit-order-input-loading-indicator",
        input: "limit-order-input-input-field",
        assetA: "limit-order-input-asset-a",
        perAsset: "limit-order-input-per-asset",
      },
      liquidityPoolDropdown: {
        loader: "liquidity-pool-loader-container",
        container: "liquidity-pool-dropdown-container",
        select: "liquidity-pool-dropdown-select",
        item: "liquidity-pool-dropdown-select-item",
      },
      orderTypeSelect: {
        loader: "order-type-loader-container",
        container: "order-type-select-container",
        select: "order-type-select-select",
        item: "order-type-select-select-item",
      },
    },
    transactionOverview: {
      container: "transaction-overview-container",
    },
    transactionSubmitting: {
      container: "transaction-submitting-container",
    },
    notEnoughAssets: "not-enough-assets",
  },
  walletButton: {
    connectWalletButton: "connect-wallet-button",
    walletDetailsPopover: "wallet-details-popover",
  },
  pairActionsDropdown: {
    toggle: "pair-actions-toggle",
    container: "pair-actions-container",
    addLiquidity: "pair-actions-add-liquidity",
    removeLiquidity: "pair-actions-remove-liquidity",
    trade: "pair-actions-trade",
    lock: "pair-actions-lock-v1",
    unlock: "pair-actions-unlock",
  },
  newsBanner: "news-banner",
  balanceWithAssetName: {
    value: "balance-value",
    assetName: "balance-asset-name",
  },
  chartSkeleton: { wrap: "chart-skeleton-wrap", error: "chart-skeleton-error" },
  activeNewsDialog: { content: "active-news-dialog-content" },
  chooseHandleDialog: { content: "choose-handle-dialog-content" },
  setPriceTarget: { content: "set-price-target-dialog-content" },
  createPoolDialog: { content: "create-pool-dialog-content" },
  exchangeSettingsDialog: { content: "exchange-settings-dialog-content" },
  poolSelectDialog: { content: "pool-select-dialog-content" },
  provideLiquidityDialog: { content: "provide-liquidity-dialog-content" },
  swapDialog: { content: "swap-dialog-content" },
  unlockLiquidityDialog: { content: "unlock-liquidity-dialog-content" },
  withdrawLiquidityDialog: { content: "withdraw-liquidity-dialog-content" },
  wrongNetworkDialog: { content: "wrong-network-dialog-content" },
  zapDialog: {
    content: "zap-dialog-content",
    assetAmountBox: "zap-asset-amount-box",
    poolSelect: {
      content: "zap-pool-select-content",
      input: "zap-pool-select-input",
    },
  },
  lightweightChart: { chart: "lightweight-chart-ref" },
  tasteTest: {
    tasteTestTables: "taste-test-tables",
    inactiveTasteTestsTable: "inactive-taste-tests-table",
    helpCenter: "taste-test-help-center",
    priceChart: "taste-test-price-chart",
  },
  walletConnectDialog: { content: "wallet-connect-dialog-content" },
  yieldFarming: {
    landingPage: {
      topFarms: "yield-farming-top-farms",
    },
    badge: {
      trigger: "yield-farming-trigger",
      container: "yield-farming-container",
      popover: {
        v2Badge: "yield-farming-v2-badge",
        programLabel: "yield-farming-v2-program",
        maxHRA: "yield-farming-max-hra",
        estimatedLockedValue: "yield-farming-locked-value",
        estimatedStart: "yield-farming-start",
        emittedAsset: "yield-farming-emitted-asset",
        estimatedEnd: "yield-farming-end",
        delegatedPercent: "yield-farming-delegated-percent",
      },
    },
    positions: {
      ctas: {
        create: "positions-create-cta",
        unlockAll: "positions-unlock-all-cta",
        update: "positions-update-cta",
      },
      table: {
        noContent: "positions-table-nocontent",
      },
      dialogs: {
        edit: "positions-dialog",
        unlock: "positions-unlock-dialog",
      },
    },
    delegations: {
      ctas: {
        update: "delegations-update-cta",
      },
      table: {
        header: {
          column1: "delegation-program",
          column2: "delegation-amount",
          column3: "delegation-percentage",
        },
        noContent: "delegations-table-nocontent",
      },
      dialog: {
        container: "delegations-dialog",
        undelegatedPercentage: "delegations-dialog-undelegated-percentage",
      },
    },
    earnings: {
      balance: (index: number) => `earnings-balance-${index}`,
      claimButton: "claim-button",
      noDataIndicator: "no-data-indicator",
      program: (index: number) => `earnings-program-${index}`,
      dialog: {
        button: "button",
        cancelButton: "earnings-dialog-cancel-button",
        checkbox: (index: number) => `earnings-table-checkbox-${index}`,
        container: "earnings-dialog",
        headerCheckbox: "earnings-dialog-header-checkbox",
        loader: "earnings-dialog-loader",
      },
      error: {
        message: "earnings-error-message",
      },
    },
  },
};
