import { FC, useCallback, useMemo } from "react";
import { createPortal } from "react-dom";
import { CONTROL_CENTER_GUIDES_NODE } from "../../../constants/page.constants";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import ControlCenterGuideChapters from "../ControlCenterGuideChapters";
import ControlCenterGuideList from "../ControlCenterGuideList";
import ControlCenterGuidesSuccessMessage from "../ControlCenterGuidesSuccessMessage";

const ControlCenterGuides: FC = () => {
  const node = document.getElementById(CONTROL_CENTER_GUIDES_NODE);
  const {
    state: {
      localState: {
        guides: { activeChapter, showSuccessAnimation },
      },
    },
  } = useControlCenterContext();
  const memoizedGuideNode = useMemo(() => {
    if (activeChapter) {
      return <ControlCenterGuideList />;
    }

    return <ControlCenterGuideChapters />;
  }, [activeChapter]);

  const renderControlCenterGuidesNode = useCallback(() => {
    return showSuccessAnimation ? (
      <ControlCenterGuidesSuccessMessage />
    ) : (
      memoizedGuideNode
    );
  }, [memoizedGuideNode, showSuccessAnimation]);

  return node && createPortal(renderControlCenterGuidesNode(), node);
};

export default ControlCenterGuides;
