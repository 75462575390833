import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import {
  joyrideIds,
  joyrideSelectors,
} from "../../../../constants/joyride.constants";
import { paths } from "../../../../pages/routes";
import { EGuideChapter, IGuide } from "../../../../stores/ControlCenterContext";

export const useLiquidityGuideJoyride = (): IGuide => {
  const { t } = useI18N("joyride");

  return useMemo(
    () => ({
      rank: 1,
      description: t("liquidity.description"),
      title: t("liquidity.title"),
      chapter: EGuideChapter.LIQUIDITY,
      joyride: {
        id: joyrideIds.liquidity,
        steps: [
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step1.title"),
            content: t("liquidity.guides.steps.step1.description"),
            placement: "right",
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.filters}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step2.title"),
            content: t("liquidity.guides.steps.step2.description"),
            placement: "left",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.createPoolButton}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step3.title"),
            content: t("liquidity.guides.steps.step3.description"),
            placement: "left",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.manageButton}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step4.title"),
            content: t("liquidity.guides.steps.step4.description"),
            placement: "right",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.pool}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step5.title"),
            content: t("liquidity.guides.steps.step5.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.hra}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step6.title"),
            content: t("liquidity.guides.steps.step6.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.price}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step7.title"),
            content: t("liquidity.guides.steps.step7.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.price24h}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step8.title"),
            content: t("liquidity.guides.steps.step8.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.tvl}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step9.title"),
            content: t("liquidity.guides.steps.step9.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.volume24h}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step10.title"),
            content: t("liquidity.guides.steps.step10.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.fees24h}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step11.title"),
            content: t("liquidity.guides.steps.step11.description"),
            placement: "left",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.zapButton}"]`,
          },
          {
            entryUrl: paths.liquidity.root,
            title: t("liquidity.guides.steps.step12.title"),
            content: t("liquidity.guides.steps.step12.description"),
            placement: "left",
            target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.actionsMenu}"]`,
          },
        ],
      },
    }),
    [t],
  );
};
