import { EContractVersion, ETxBuilderType, SundaeSDK } from "@sundaeswap/core";
import { useWalletObserver } from "@sundaeswap/wallet-lite";
import { Blockfrost, Lucid } from "lucid-cardano";
import {
  Context,
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import { useProtocols } from "../../gql/hooks/protocol.query";
import { getEnv } from "../../utils/network.utils";
import { Actions } from "./actions";
import { appReducer } from "./reducer";
import { IAppAction, IAppContext, IAppState } from "./types";

export const defaultAppState: IAppState = {
  sdk: null,
  displayedOrderRouteIndex: 0,
  V3MaxScooperFee: undefined,
  collapsibles: {
    orderFeeBreakdown: { isOpen: false },
  },
  dialogs: {
    assetSelect: {
      isOpen: false,
      context: undefined,
    },
    pickAvatar: { isOpen: false },
    pickHandle: { isOpen: false },
    liquidity: {
      deposit: { isOpen: false },
      createPool: { isOpen: false },
      migrate: { isOpen: false },
      unlock: { isOpen: false },
      withdraw: { isOpen: false },
      zap: { isOpen: false },
      zapPoolSelect: { isOpen: false },
    },
    swap: { isOpen: false },
    walletConnect: { isOpen: false },
    wrongNetwork: { isOpen: false },
    cancellation: { isOpen: false },
  },
  offCanvasOpen: false,
  sortPoolsSortingDirection: undefined,
  sortPoolsBy: undefined,
  latestTxHash: undefined,
  protocols: [],
  tasteTestActiveScrollIndex: 0,
};

const AppContext: Context<IAppContext> = createContext({
  state: defaultAppState,
  dispatch: (_value: IAppAction) => {},
});

export const AppContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appReducer, defaultAppState);
  const { activeWallet } = useWalletObserver();
  const { data: protocolsData } = useProtocols();

  const protocols = protocolsData?.protocols ?? [];

  const memoizedDispatch = useMemo(() => dispatch, []);

  useEffect(() => {
    (async () => {
      if (activeWallet) {
        const network = getEnv();
        const lucid = await Lucid.new(
          new Blockfrost(
            window.__APP_CONFIG.blockfrostUrl,
            window.__APP_CONFIG.blockfrostKey,
          ),
          network === "mainnet" ? "Mainnet" : "Preview",
        );
        lucid.selectWallet(await window.cardano[activeWallet].enable());

        const sdk = new SundaeSDK({
          wallet: {
            builder: {
              type: ETxBuilderType.LUCID,
              lucid,
            },
            name: activeWallet,
            network,
          },
        });

        Actions.setSDK(sdk, dispatch);

        const txBuilderInstance = sdk.builder(EContractVersion.V3);
        const V3ScooperFee = await txBuilderInstance.getMaxScooperFeeAmount();
        Actions.setV3MaxScooperFeeAmount(V3ScooperFee, dispatch);
      }
    })();
  }, [activeWallet]);

  return (
    <AppContext.Provider
      value={{ state: { ...state, protocols }, dispatch: memoizedDispatch }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Utility hook.
export const useAppContext = (): IAppContext => useContext(AppContext);
