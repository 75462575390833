import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import {
  EGuideChapter,
  IChapter,
} from "../../../../stores/ControlCenterContext";
import { useTasteTestExplanationGuideJoyride } from "../guides/explanation-guide/useTasteTestExplanationGuideJoyride";
import { useTasteTestInteractiveGuideJoyride } from "../guides/interactive-guide/useTasteTestInteractiveGuideJoyride";

export const useTasteTestJoyrideChapter = (): IChapter => {
  const tasteTestExplanationGuideJoyride =
    useTasteTestExplanationGuideJoyride();
  const tasteTestInteractiveGuide = useTasteTestInteractiveGuideJoyride();

  return useMemo(() => {
    return {
      id: EGuideChapter.TASTE_TEST,
      title: "Taste Test",
      description:
        "The Taste Test is a unique flavor of token launch. Learn everything you need to know about it with our comprehensive guides.",
      guides: sortBy(
        [tasteTestExplanationGuideJoyride, tasteTestInteractiveGuide],
        "rank",
        "asc",
      ),
    };
  }, [tasteTestExplanationGuideJoyride, tasteTestInteractiveGuide]);
};
