import { AssetAmount } from "@sundaeswap/asset";
import { IAssetMetaData } from "../types/Asset.types";
import { readableAdaAssetId } from "./cardano.constants";
import { ADA_METADATA } from "@sundaeswap/core";

/**
 * The minimum ADA amount that _must_ remain in the wallet after any transaction which equals 10 ADA (for now).
 * This value consists of:
 * 2 ADA Deposit
 * 2.5 ADA Scooper Fee
 * 0.5 ADA Transaction Fee
 * 5 ADA used as minADA
 */
export const MIN_ADA_WALLET_BALANCE = new AssetAmount<IAssetMetaData>(
  10000000,
  ADA_METADATA,
);

export const SCOOPER_FEE = new AssetAmount<IAssetMetaData>(
  2500000,
  ADA_METADATA,
); // 2.5 ADA
export const SCOOPER_FEE_V3 = new AssetAmount<IAssetMetaData>(
  1000000,
  ADA_METADATA,
); // 1 ADA
export const SCOOPER_FEE_V3_WITH_DISCOUNT = new AssetAmount<IAssetMetaData>(
  500000,
  ADA_METADATA,
); // 0.5 ADA
export const DEPOSIT = new AssetAmount<IAssetMetaData>(2000000, ADA_METADATA); // 2 ADA
export const ADA_TX_FEE = new AssetAmount<IAssetMetaData>(200000, ADA_METADATA); // 0.2 ADA

export const HOTJAR_ID = 3358118;
export const HANDLE_SUNDAE_BG_ASSET_PREFIX =
  "c705d66dd826fa750a3fcc0a74c27fdb20db2ed18ad569232d7123ad";
export const BASE_BUY_ASSET_IDS = [
  readableAdaAssetId,
  "c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad", // USDM
  "8db269c3ec630e06ae29f74bc39edd1f87c819f1056206e879a1cd61", // DJED
  "f66d78b4a3cb3d37afa0ec36461e51ecbde00f26c8f0a68f94b69880", // iUSD
];
