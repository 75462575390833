export const paths = {
  root: "/",
  exchange: `/exchange`,
  liquidity: {
    root: "/liquidity",
    children: {
      provide: "/provide",
      withdraw: "/withdraw",
      zap: "/zap",
      createPool: "/create-pool",
    },
  },
  tasteTest: {
    root: "/taste-test",
  },
  orders: "/orders",
  yieldFarming: "/yield-farming",
  magicLinks: {
    guides: {
      tasteTest: {
        explanationGuide: "/tt",
        interactiveGuide: "/tt-clrty",
      },
      v3: {
        comparisonGuide: "/v3",
      },
    },
  },
};

export const externalRoutes = {
  about: "https://sundae.fi/",
  contact: "https://sundae.fi/contact",
  disclaimer: "https://sundae.fi/terms",
  discord: "https://discord.gg/Sundae",
  explorer: {
    mainnet: {
      cardanoscan: "https://cardanoscan.io",
      cexplorer: "https://cexplorer.io",
      cardanoExplorer: "https://beta.explorer.cardano.org",
    },
    preview: {
      cardanoscan: "https://preview.cardanoscan.io",
      cexplorer: "https://preview.cexplorer.io",
      cardanoExplorer: "https://preview.beta.explorer.cardano.org",
    },
  },
  faq: "https://help.sundaeswap.finance/en/",
  faqFarms:
    "https://help.sundaeswap.finance/en/collections/3299695-yield-farming",
  feesHelpArticle:
    "https://help.sundaeswap.finance/en/articles/7338192-minimum-ada-available-balance",
  github: "https://github.com/SundaeSwap-finance",
  governance: "https://governance.sundaeswap.finance/",
  handlePersonalization:
    "https://medium.com/ada-handle/how-to-personalization-e8368e7566d7",
  hotjar: "https://www.hotjar.com/",
  learnMoreAboutWallets:
    "https://help.sundaeswap.finance/en/collections/3303291-wallets",
  medium: "https://sundaeswap-finance.medium.com/",
  privacy: "https://sundae.fi/privacy",
  tasteTestArticle:
    "https://sundae.fi/posts/sundaeswap-presents-the-taste-test",
  terms: "https://sundae.fi/terms",
  reddit: "https://www.reddit.com/r/SundaeSwap/",
  risksAndRewards:
    "https://help.sundaeswap.finance/en/articles/5887307-what-are-the-risks-and-rewards",
  twitter: "https://twitter.com/SundaeSwap",
  liquidity: "https://help.sundaeswap.finance/en/collections/3281728-liquidity",
  cardanoTokenRegistryHelp:
    "https://help.sundaeswap.finance/en/articles/7336428-the-cardano-token-registry",
  cryptoTaxCalculatorManual:
    "https://app.cryptotaxcalculator.io/imports/custom/manual",
  cryptoTaxCalculatorAssistance:
    "https://cryptotaxcalculator.io/guides/advanced-manual-csv-import",
  cip45VideoDemonstration: "https://youtu.be/mRpXIh-DyYM?si=bcPCfNhGMQryTpCX",
  cip45LearnMore:
    "https://github.com/fabianbormann/CIPs/blob/master/CIP-%3F%3F%3F%3F/README.md",
  v3Whitepaper: "https://cdn.sundaeswap.finance/SundaeV3.pdf",
};

export enum EGuideSearchParamEntries {
  tasteTestExplanationGuide = "ttexpl",
  tasteTestInteractiveGuide = "ttclrty",
  v3 = "v3",
}

export enum PortfolioTab {
  assets = "assets",
  liquidity = "liquidity",
  orders = "orders",
  walletOverview = "wallet-overview",
  yieldFarming = "yield-farming",
  settings = "settings",
}

export type TSearchParams = {
  activeAssetA?: string;
  activeAssetB?: string;
  counter?: string;
  enableBramble?: string;
  given?: string;
  guide?: EGuideSearchParamEntries;
  routeIdent?: string;
  tab?: PortfolioTab;
  taken?: string;
  zapAssetId?: string;
  zapPoolId?: string;
};
