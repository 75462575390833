import { EventType } from "@sundaeswap/react-hooks";
import { useEffect, useMemo, useState } from "react";

import { getEnv } from "../utils/network.utils";
import useAppLocation from "./router/useAppLocation";
import { useEventTracking } from "./useEventTracking";
import useIsLocalhost from "./useIsLocalhost";
import { useIsWalletConnected } from "./useIsWalletConnected";

/**
 * Custom hook to handle page view tracking using the counters API.
 * This hook is primarily used to track page views in the application.
 * The tracking data consists of the app, company, event type, and the value
 * (a combination of normalized path and search string).
 * This hook also includes a feature flag and a hostname check to conditionally
 * allow or disallow tracking. It further checks if the hook has been initialized
 * to avoid double renders for the actual tracking.
 *
 * @returns {void} - This hook does not return a value. It performs the tracking action
 *                   as a side-effect when the conditions are met.
 */
export const usePageviewTracking = () => {
  const [initialized, setInitialized] = useState(false);
  const location = useAppLocation();
  const { isLocalhost } = useIsLocalhost();
  const isWalletConnected = useIsWalletConnected();

  const normalizedCountersPath = useMemo(() => {
    if (location.pathname === "/") {
      return isWalletConnected ? "portfolio" : "home";
    }
    return location.pathname.replace("/", "");
  }, [location.pathname, isWalletConnected]);

  const normalizedCountersSearchStr = useMemo(() => {
    if (location.searchStr) {
      return location.searchStr;
    }

    return "";
  }, [location.searchStr]);

  const handleEventTracking = useEventTracking();

  /**
   * This effect sets the `initialized` value once the app is mounted.
   * This is used to avoid double renders for the actual tracking.
   */
  useEffect(() => {
    setInitialized(true);
  }, []);

  /**
   * This effect only runs when `initialized` is true and we are not running localhost.
   */
  useEffect(() => {
    if (initialized && !isLocalhost) {
      handleEventTracking({
        eventType: EventType.Pageview,
        value: `${normalizedCountersPath}${normalizedCountersSearchStr}`,
        network: getEnv(),
      });
    }
  }, [
    isLocalhost,
    initialized,
    handleEventTracking,
    normalizedCountersPath,
    normalizedCountersSearchStr,
  ]);
};
