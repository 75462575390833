import { PrismicDocument } from "@prismicio/client";
import { THooks } from "@sundaeswap/react-hooks";
import deepmerge from "deepmerge";
import hasIn from "lodash/hasIn";
import omitBy from "lodash/omitBy";
import {
  Context,
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import {
  LOCAL_STORAGE_FIRST_VISIT_KEY,
  LOCAL_STORAGE_NEWS_READ_KEY,
} from "../../constants/localStorage.constants";
import { usePrismicNews } from "../../gql/hooks/prismic.query";
import { useIsInitialPageLoad } from "../../hooks/useIsInitialPageLoad";
import { NewsReducer } from "./reducer";
import {
  INewsAction,
  INewsContext,
  INewsField,
  INewsState,
  IPersistedNewsState,
} from "./types";

export const defaultPersistedNewsState: IPersistedNewsState = {
  forceOpened: [],
  newsRead: [],
};

export const defaultNewsState: INewsState = {
  activeNewsItem: undefined,
  error: undefined,
  isFetchingNews: false,
  isNewsFetched: false,
  isPopoverOpen: false,
  isRefetchingNews: false,
  latestBannerNewsItem: undefined,
  popoverNewsItems: [],
  setActiveNewsItem: () => {},
  setIsPopoverOpen: () => {},
  ...defaultPersistedNewsState,
};

const NewsContext: Context<INewsContext> = createContext({
  state: defaultNewsState,
  dispatch: (_action: INewsAction) => {},
});

export const NewsContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isFirstVisit] = THooks.useLocalStorage(
    LOCAL_STORAGE_FIRST_VISIT_KEY,
    true,
  );
  const [activeNewsItem, setActiveNewsItem] = useState<
    PrismicDocument<INewsField> | undefined
  >(undefined);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const {
    data: newsItems,
    error,
    isFetching,
    isRefetching,
    isFetched,
  } = usePrismicNews({ tags: ["dex"] });
  const { isInitialPageLoad } = useIsInitialPageLoad();
  const [savedState, saveState] = THooks.useLocalStorage<IPersistedNewsState>(
    LOCAL_STORAGE_NEWS_READ_KEY,
    defaultPersistedNewsState,
  );

  const newsReducer = useCallback(
    (state: IPersistedNewsState, action: INewsAction): IPersistedNewsState => {
      const newState = NewsReducer(state, action);
      saveState(newState);
      return newState;
    },
    [saveState],
  );

  const hydratedState = useMemo(() => {
    const hydrated = deepmerge(
      defaultPersistedNewsState,
      omitBy(savedState, (_value, key) => {
        if (!hasIn(defaultPersistedNewsState, key)) {
          return true;
        }

        return false;
      }),
    );

    return {
      ...defaultPersistedNewsState,
      ...hydrated,
    };
  }, [savedState, defaultPersistedNewsState]);

  const [state, dispatch] = useReducer(newsReducer, hydratedState);

  /**
   * This constant is responsible for finding the latest banner news item.
   * It listens for changes to the 'news' array and 'state.newsRead'.
   * When 'state.newsRead' does not include the id of the banner news item, it returns the banner news item.
   * If 'state.newsRead' includes the id of the banner news item, it returns undefined.
   */
  const latestBannerNewsItem = useMemo(() => {
    const bannerNewsItem = newsItems?.find(
      (newsItem) => newsItem.data.type === "banner",
    );

    if (!state.newsRead.includes(bannerNewsItem?.id || "")) {
      return bannerNewsItem;
    }

    return undefined;
  }, [state.newsRead, newsItems]);

  /**
   * This constant is responsible for finding the number of unread news.
   * It listens for changes to the 'news' array and 'state.newsRead'.
   */
  const numberOfUnreadNewsItems = useMemo(() => {
    return newsItems?.filter(
      (newsItem) =>
        !state.newsRead.includes(newsItem.id) &&
        newsItem.data.type !== "banner",
    )?.length;
  }, [newsItems, state.newsRead]);

  /**
   * This useEffect hook is responsible for managing the 'force_open' news.
   * When there's a news with 'force_open' set to true, it checks if this news has been read and if it has been force opened.
   * If this news hasn't been read or force opened, it's not the very first visit for a user, and it's not the initial page load (after page transition), it dispatches an action to set the id of this news in the state and sets it as the active news.
   * It also dispatches the action to set the id of this news in the state as force opened. This will make sure the Popover is closed and reopened properly.
   * The reason why we're also checking for `isFirstVisit` here is because on the very first visit, we will show a different dialog unrelated to the news.
   */
  useEffect(() => {
    // Find the news that has 'force_open' set to true and whether it's a dialog.
    const forcedNews = newsItems?.find(
      (newsItem) => newsItem.data.force_open && newsItem.data.type === "dialog",
    );
    // Check if this news has been read
    const isRead = state.newsRead.includes(forcedNews?.id ?? "");
    // Check if this news has been force opened
    const hasBeenForceOpened = state.forceOpened.includes(forcedNews?.id ?? "");

    if (
      !isFirstVisit &&
      forcedNews?.id &&
      !isRead &&
      !hasBeenForceOpened &&
      !isInitialPageLoad
    ) {
      dispatch({
        type: "SET_FORCE_OPENED_NEWS_ID",
        payload: { forceOpened: forcedNews.id },
      });
      setActiveNewsItem(forcedNews);
      return;
    }
  }, [
    dispatch,
    isFirstVisit,
    isInitialPageLoad,
    newsItems,
    state.newsRead,
    state.forceOpened,
  ]);

  return (
    <NewsContext.Provider
      value={{
        state: {
          ...state,
          activeNewsItem,
          error,
          isNewsFetched: isFetched,
          isFetchingNews: isFetching,
          isPopoverOpen,
          isRefetchingNews: isRefetching,
          latestBannerNewsItem,
          numberOfUnreadNewsItems,
          setIsPopoverOpen,
          popoverNewsItems:
            newsItems?.filter(({ data }) => data.type !== "banner") || [],
          setActiveNewsItem,
        },
        dispatch,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};

// Utility hook.
export const useNewsContext = (): INewsContext => useContext(NewsContext);
