import { configResponsive as cr, usePrevious } from "@sundaeswap/react-hooks";
import cx from "classnames";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import ControlCenterFAB from "../../components/ControlCenterComponents/ControlCenterFAB";
import Header from "../../components/Header";
import InitialPageTransition from "../../components/InitialPageTransition";
import JoyrideWrapper from "../../components/JoyrideComponents/JoyrideWrapper";
import NewsBanner from "../../components/NewsBanner";
import { configResponsive } from "../../config/responsive";
import useAppLocation from "../../hooks/router/useAppLocation";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";
import { useIsInitialPageLoad } from "../../hooks/useIsInitialPageLoad";
import { useMagicLinks } from "../../hooks/useMagicLinks";
import { useNewsBannerHeight } from "../../hooks/useNewsBannerHeight";
import { usePageviewTracking } from "../../hooks/usePageviewTracking";
import { paths } from "../../pages/routes";
import { useProfileAppContext } from "../../stores";
import { OrderActions, useOrderContext } from "../../stores/OrderContext";
import { featureIsEnabled } from "../../utils/features.utils";
import { useCounter } from "./hooks/counter";
import { useHotjar } from "./hooks/hotjar";

export const MainLayout: React.FC = () => {
  cr(configResponsive);
  const { isInitialPageLoad } = useIsInitialPageLoad();
  const {
    state: { prefersReducedMotion },
  } = useProfileAppContext();
  const { dispatch } = useOrderContext();
  const { headerHeight } = useHeaderHeight();
  const { newsBannerHeight } = useNewsBannerHeight();
  const { pathname } = useAppLocation();
  const prevPathname = usePrevious(pathname);

  usePageviewTracking();

  /**
   * This hook is used to handle magic links.
   * It will redirect the user to the correct entry url for a certain guide if the "magic url" matches a pattern in `paths.magicLinks.*`
   * If there is no match, we will render the regular application.
   */
  useMagicLinks();

  /**
   * Initiates counter event tracking.
   */
  useCounter();

  // Reset the order flow when navigating away from the exchange page
  useEffect(() => {
    if (pathname !== prevPathname && prevPathname === paths.exchange) {
      OrderActions.resetOrderFlow(dispatch, "MainLayout.useEffect.cleanup");
    }
  }, [pathname, prevPathname, dispatch]);

  // Setup hotjar.
  useHotjar();

  return (
    <>
      <NewsBanner />
      <main className="min-h-screen overflow-hidden dark:bg-gray-1000 lg:overflow-visible">
        <Header />
        <article
          className={cx("mx-auto p-4 lg:px-8 lg:pb-8 xl:p-6 xl:pb-6 xl:pt-10", {
            "mt-[5rem]": !newsBannerHeight,
          })}
          style={{
            marginTop: `calc(${headerHeight}px + ${newsBannerHeight}px)`,
          }} // using `style` here as tailwind does not compute dynamic values while using `calc` in className
        >
          {isInitialPageLoad &&
          !prefersReducedMotion &&
          // disable page transition on dev env
          process.env.NODE_ENV !== "development" ? (
            <InitialPageTransition />
          ) : (
            <Outlet />
          )}
        </article>
      </main>

      {featureIsEnabled("controlCenter") && (
        <>
          <ControlCenterFAB />
          <JoyrideWrapper />
        </>
      )}
    </>
  );
};
