import {
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
  MinusIcon,
  PlusIcon,
  TvIcon,
} from "@heroicons/react/24/outline";
import { DateField } from "@prismicio/client/*";
import { AHooks, EventType, useI18N } from "@sundaeswap/react-hooks";
import { Button, DateI18N, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { motion, useInView } from "framer-motion";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useEventTracking } from "../../hooks/useEventTracking";
import { slideInAndScaleFromBottomMotion } from "../../motions/default.motions";
import {
  LocalControlCenterActions,
  useControlCenterContext,
} from "../../stores/ControlCenterContext";
import {
  INewsField,
  NewsActions,
  useNewsContext,
} from "../../stores/NewsContext";
import { getEnv } from "../../utils/network.utils";
import { prismicLinkResolver } from "../../utils/prismic.utils";

interface IControlCenterNewsListItemProps {
  first_publication_date: DateField;
  id: string;
  newsItem: INewsField;
}

const CLAMP_LENGTH = 115;

const ControlCenterNewsListItem: FC<IControlCenterNewsListItemProps> = ({
  first_publication_date,
  id,
  newsItem,
}) => {
  const handleEventTracking = useEventTracking();
  const { t } = useI18N("news");
  const {
    dispatch,
    state: { newsRead, popoverNewsItems: newsItems, setActiveNewsItem },
  } = useNewsContext();
  const {
    actionHandler,
    dispatchers: { localDispatch: localControlCenterDispatch },
    state: {
      localState: {
        news: { lastClosedNewsId },
      },
    },
  } = useControlCenterContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const scrollTimer = useRef<number>();
  const flashTimer = useRef<number>();
  const newsItemRef = useRef<HTMLLIElement>(null);
  const isRefInView = useInView(newsItemRef, { once: true });
  const isRefHovered = AHooks.useHover(newsItemRef);

  const {
    backlink,
    desc,
    popover_backlink,
    read_more_label,
    title,
    type = "inside_popover",
  } = newsItem;
  const isRead = newsRead.includes(id);
  const shouldClamp = desc && desc.length > CLAMP_LENGTH;
  const link = backlink ?? popover_backlink;
  const withCta =
    (type === "inside_popover" && link?.link_type !== "Any") ||
    type === "dialog";

  /**
   * Reset border animation when the news item is hovered after it's been closed.
   */
  const handleHoverRef = useCallback(() => {
    if (isRefHovered && lastClosedNewsId === id) {
      LocalControlCenterActions.news.setLastClosedNewsId(
        undefined,
        localControlCenterDispatch,
      );
    }
  }, [id, isRefHovered, lastClosedNewsId, localControlCenterDispatch]);

  /**
   * Handles the click event on the show more/less button.
   */
  const handleToggleLineClamp = useCallback(
    (e: MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
      e.stopPropagation();
      handleEventTracking({
        eventType: EventType.Click,
        source: "ControlCenterNewsListItem",
        value: `line_clamp`,
        network: getEnv(),
      });
      setIsExpanded((prev) => !prev);
    },
    [setIsExpanded, id, handleEventTracking],
  );

  /**
   * Handles the click event on the news item.
   */
  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
      if (type === "dialog") {
        const activeNewsItem = newsItems?.find(
          (newsItem) => newsItem.id === id,
        );
        handleEventTracking({
          eventType: EventType.Click,
          source: "ControlCenterNewsListItem",
          value: `open_dialog`,
          network: getEnv(),
        });
        setActiveNewsItem(activeNewsItem);
        actionHandler.controlCenter.close();
      } else if (
        type === "inside_popover" &&
        link &&
        link?.link_type !== "Any"
      ) {
        handleEventTracking({
          eventType: EventType.Click,
          source: "ControlCenterNewsListItem",
          value: `open_link`,
          network: getEnv(),
        });
        window.open(prismicLinkResolver(link), "_blank");
      } else {
        handleToggleLineClamp(e);
      }

      NewsActions.setReadNewsItem(id, dispatch);
    },
    [dispatch, handleToggleLineClamp, id, link, type],
  );

  /**
   * Renders the button node.
   */
  const renderMemoizedButtonNode = useCallback(() => {
    if (type === "inside_popover" && link?.link_type !== "Any") {
      return (
        <>
          <ArrowTopRightOnSquareIcon
            className={cx(
              "h-4 w-4 group-hover/btn:scale-110",
              toolkitCx.layout.transition.transform,
            )}
          />
          {read_more_label ?? t("link", { context: "inside_popover" })}
        </>
      );
    }

    if (type === "dialog") {
      return (
        <>
          <TvIcon
            className={cx(
              "h-4 w-4 group-hover/btn:scale-110",
              toolkitCx.layout.transition.transform,
            )}
          />{" "}
          {t("link", { context: "dialog" })}
        </>
      );
    }

    return null;
  }, [
    backlink,
    dispatch,
    id,
    link,
    popover_backlink,
    read_more_label,
    t,
    type,
  ]);

  /**
   * Scrolls to the guide if it's the last closed guide and it's not in view.
   * Also shows a flash animation on the guide.
   */
  useEffect(() => {
    if (lastClosedNewsId && lastClosedNewsId === id && !isRefInView) {
      scrollTimer.current = setTimeout(() => {
        if (newsItemRef.current) {
          newsItemRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
        LocalControlCenterActions.news.setLastClosedNewsId(
          undefined,
          localControlCenterDispatch,
        );
      }, 1000) as unknown as number;
    }
  }, [
    flashTimer.current,
    id,
    newsItemRef.current,
    isRefInView,
    lastClosedNewsId,
    localControlCenterDispatch,
    scrollTimer.current,
  ]);

  /**
   * Reset border animation when the news item is hovered after it's been closed.
   */
  useEffect(() => {
    handleHoverRef();
  }, [handleHoverRef]);

  /**
   * Clears the timers.
   */
  useEffect(() => {
    return () => {
      if (scrollTimer.current) clearTimeout(scrollTimer.current);
      if (flashTimer.current) clearTimeout(flashTimer.current);
    };
  }, []);

  return (
    <motion.li
      key={id}
      onClick={handleOnClick}
      role="button"
      ref={newsItemRef}
      variants={slideInAndScaleFromBottomMotion}
      className="group relative w-full"
    >
      <div
        className={cx(
          "flex flex-col space-y-4 overflow-hidden",
          "relative z-10 rounded-lg",
          toolkitCx.layout.background.default,
          toolkitCx.layout.background.hoverable,
          toolkitCx.layout.transition.default,
          toolkitCx.layout.shadow.default,
          toolkitCx.layout.border.default,
          toolkitCx.layout.border.weight.default,
          toolkitCx.layout.spacing.both.md,
          {
            [`${toolkitCx.recipes.blurryCorner.primary}`]: !isRead,
            "!border-primary": lastClosedNewsId === id,
            "opacity-75 group-hover:opacity-100": isRead,
          },
        )}
      >
        <div className="relative z-20 grid gap-1">
          <Text tag="span" weight="medium">
            {title}
          </Text>

          <Text
            size="xs"
            variant="silent"
            tag="p"
            className="flex items-center gap-1"
          >
            <CalendarDaysIcon className="h-3 w-3" />
            <DateI18N
              size="xs"
              value={new Date(first_publication_date as string)}
              variant="silent"
            />
          </Text>

          <Text
            className={cx({
              "line-clamp-3": !isExpanded && shouldClamp,
            })}
            tag="p"
            variant="muted"
            size="xs"
          >
            {desc}
          </Text>

          {shouldClamp && (
            <Text
              className="flex w-fit items-center gap-1"
              onClick={handleToggleLineClamp}
              tag="small"
              variant="primary"
              size="xs"
            >
              {isExpanded ? "Hide" : "Show More"}
              {isExpanded ? (
                <MinusIcon className="h-3 w-3" />
              ) : (
                <PlusIcon className="h-3 w-3" />
              )}
            </Text>
          )}
        </div>

        {withCta && (
          <Button className="group/btn" variant="neutral" size="sm">
            {renderMemoizedButtonNode()}
          </Button>
        )}
      </div>
    </motion.li>
  );
};

export default ControlCenterNewsListItem;
