import Step1 from "./steps/Step1";
import Step1Title from "./titles/Step1Title";

export const WithdrawLiquidityGuideSteps = {
  Step1,
};

export const WithdrawLiquidityGuideTitles = {
  Step1Title,
};
