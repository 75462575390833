import { Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import { FC, useCallback } from "react";

import { useWalletPositions } from "../../../../../../gql/hooks/positions.query";
import { useDeepCompareMemoize } from "../../../../../../hooks/useDeepCompareMemoize";

const PortfolioLiquidityGuideStep2: FC = () => {
  const { positions, isLoading } = useWalletPositions({
    page: 0,
    pageSize: 50,
  });
  const memoizedPositions = useDeepCompareMemoize(positions);

  const renderMemoizedTextNode = useCallback(() => {
    if (isLoading) {
      return (
        <div className="grid gap-2">
          {Array.from({ length: 3 }).map((item, index) => (
            <div
              key={`${item}-${index}`}
              className={cx(
                "h-4 rounded-lg motion-safe:animate-pulse",
                toolkitCx.layout.background.loading,
                { "w-full": index !== 2, "w-1/2": index === 2 },
              )}
            />
          ))}
        </div>
      );
    }

    if (!isLoading && isEmpty(positions)) {
      return (
        <Text tag="p" size="sm">
          We have a dedicated guide for providing liquidity, make sure to check
          that out!
        </Text>
      );
    }

    return (
      <Text tag="p" size="sm">
        Here, you can see the liquidity you are providing to pools.
      </Text>
    );
  }, [memoizedPositions, isLoading]);

  return <>{renderMemoizedTextNode()}</>;
};

export default PortfolioLiquidityGuideStep2;
