import { Text } from "@sundaeswap/ui-toolkit";
import { FC, memo } from "react";

const TasteTestWhatIsItStep1: FC = () => {
  return (
    <div className="grid gap-2 text-center">
      <Text tag="p" size="sm">
        The Taste Test is a unique flavor of token launch. It creates a
        decentralized negotiation between a project and the community to decide
        a fair opening price for a new token, bootstrap liquidity for a trading
        market, and reduce initial market volatility.
      </Text>
      <Text tag="p" size="sm">
        With this interactive Guide we will explain you everything you need to
        know about a Taste Test and how to participate.
      </Text>
      <Text tag="p" size="sm">
        To start the Guide, simply click on the "Next" button below.
      </Text>
    </div>
  );
};

export default memo(TasteTestWhatIsItStep1);
