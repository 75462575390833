import { RenderWalletState } from "@sundaeswap/wallet-lite";
import { FC, lazy } from "react";

import ConnectWalletButton from "../ConnectWalletButton";
import LoadingComponent from "../LoadingComponent";

const LazyWalletDetailsPopover = lazy(() => import("../WalletDetailsPopover"));

const WalletButton: FC = () => {
  return (
    <RenderWalletState
      loader={<LoadingComponent />}
      fallback={<p>Could not load.</p>}
      render={({ willAutoConnect, ready }) => {
        if (willAutoConnect || ready) {
          return <LazyWalletDetailsPopover />;
        }

        return (
          <ConnectWalletButton className="ml-auto mr-auto lg:ml-0 lg:mr-0" />
        );
      }}
    />
  );
};

export default WalletButton;
