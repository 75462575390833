import { useI18N } from "@sundaeswap/react-hooks";
import { useWalletObserver } from "@sundaeswap/wallet-lite";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import isEqual from "lodash/isEqual";
import { useCustomCompareMemo } from "use-custom-compare";
import {
  EErrorType,
  useYieldFarmingEarningsContext,
} from "../../components/YieldFarmingComponents/EarningsTable/context";
import { ETransactionState } from "../../types/Transaction.types";
import { serializeQueryKey } from "../../utils/number-format";
import { unixToSlot } from "../../utils/slots.utils";
import { getStats2Sdk } from "../client/statsSdk";
import { getYieldFarmingV2Sdk } from "../client/yieldFarmingSdk";
import { FreezerItems, InputMaybe } from "../generated/stats2.sdk";
import {
  Earning,
  EarningInput,
  Position,
  Program,
} from "../generated/yieldFarmingV2.sdk";
import { useServerTime } from "./time.query";

const DEFAULT_PAGE_SIZE = 100;

/* -------------------------------------------------------------------------------------------------
 * Types
 * -----------------------------------------------------------------------------------------------*/
export type TFetchFreezerOpen = {
  address?: string;
  pageSize?: InputMaybe<number>;
  token?: InputMaybe<string>;
};

export type TFetchFreezerHistory = {
  address?: string;
  dateFrom?: InputMaybe<string>;
  dateTo?: InputMaybe<string>;
  pageSize?: InputMaybe<number>;
  token?: InputMaybe<string>;
};

/* -------------------------------------------------------------------------------------------------
 * Fetchers
 * -----------------------------------------------------------------------------------------------*/
export async function fetchFreezerOpen({
  address,
  pageSize,
  token,
}: TFetchFreezerOpen) {
  if (!address) {
    return null;
  }

  const { freezerOpen } = await getStats2Sdk();
  const { freezerOpen: positions, now } = await freezerOpen({
    address,
    pageSize,
    token,
  });
  return {
    positions: positions as FreezerItems, // TODO: `positions` should type `pool` as `Pool`
    now,
  };
}

export async function fetchFreezerHistory({
  address,
  dateFrom,
  dateTo,
  pageSize,
  token,
}: TFetchFreezerHistory) {
  if (!address) {
    return;
  }

  const { freezerHistory } = await getStats2Sdk();
  const { freezerHistory: farmsHistory } = await freezerHistory({
    address,
    dateFrom,
    dateTo,
    pageSize,
    token,
  });

  return farmsHistory;
}

/* -------------------------------------------------------------------------------------------------
 * Hooks
 * -----------------------------------------------------------------------------------------------*/
export const useFarmPositions = ({
  address,
  pageSize = DEFAULT_PAGE_SIZE,
  token,
}: TFetchFreezerOpen) =>
  useQuery({
    queryKey: serializeQueryKey(["frozenPositions", address, pageSize, token]),
    queryFn: () => fetchFreezerOpen({ address, pageSize, token }),
    enabled: Boolean(address),
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
  });

export const useFarmsHistory = ({
  address,
  dateFrom,
  dateTo,
  pageSize = DEFAULT_PAGE_SIZE,
  token,
}: TFetchFreezerHistory) =>
  useQuery({
    queryKey: serializeQueryKey([
      "frozenPositionsHistory",
      address,
      pageSize,
      token,
    ]),
    queryFn: () =>
      fetchFreezerHistory({ address, dateFrom, dateTo, pageSize, token }),
    enabled: Boolean(address),
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
  });

/* -------------------------------------------------------------------------------------------------
 * Yield Farming v2
 * -----------------------------------------------------------------------------------------------*/
export const useYieldFarmingProgramsQuery = () => {
  const queryInfo = useQuery({
    queryKey: ["yieldFarmingPrograms"],
    queryFn: async () => {
      const { getPrograms } = await getYieldFarmingV2Sdk();
      return getPrograms();
    },
    retry: 2,
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
    refetchOnWindowFocus: false,
  });

  return {
    ...queryInfo,
    data: (queryInfo?.data?.programs as Program[]) ?? [],
  };
};

export const useYieldFarmingPositionsQuery = ({
  beneficiary,
  enableRefetchInterval,
}: {
  enableRefetchInterval?: boolean;
  beneficiary: (string | undefined)[];
}) => {
  const queryInfo = useQuery({
    queryKey: ["yieldFarmingPositions", ...beneficiary],
    queryFn: async () => {
      const { getPositions } = await getYieldFarmingV2Sdk();

      return getPositions({
        beneficiary: beneficiary.filter((val) => val !== undefined) as string[],
      });
    },
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
    refetchInterval: enableRefetchInterval ? 1000 * 5 : false,
  });

  return {
    ...queryInfo,
    data: (queryInfo?.data?.positions as Position[]) ?? [],
  };
};

export const useYieldFarmingEarningsQuery = (
  beneficiary: (string | undefined)[],
  enableRefetchInterval?: boolean,
) => {
  const queryBeneficiary = [
    ...(beneficiary.filter((val) => val !== undefined) as string[]),
  ].sort();
  const queryInfo = useQuery({
    queryKey: ["yieldFarmingEarnings", queryBeneficiary],
    queryFn: async () => {
      const { getEarnings } = await getYieldFarmingV2Sdk();
      return getEarnings({ beneficiary: queryBeneficiary });
    },
    enabled: queryBeneficiary.length > 0,
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
    refetchOnWindowFocus: false,
    refetchInterval: enableRefetchInterval ? 1000 * 5 : false,
  });

  return {
    ...queryInfo,
    data: (queryInfo?.data?.earnings as Earning[]) ?? [],
  };
};

export const useYieldFarmingEstimatedEarningsQuery = (
  beneficiary: (string | undefined)[],
) => {
  const queryBeneficiary = [
    ...(beneficiary.filter((val) => val !== undefined) as string[]),
  ].sort();
  const queryInfo = useQuery({
    queryKey: ["yieldFarmingEstimatedEarnings", queryBeneficiary.sort()],
    queryFn: async () => {
      const { getEstimatedEarnings } = await getYieldFarmingV2Sdk();
      return getEstimatedEarnings({ beneficiary: queryBeneficiary });
    },
    enabled: queryBeneficiary.length > 0,
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
    refetchOnWindowFocus: false,
  });

  return {
    ...queryInfo,
    data: (queryInfo?.data?.estimatedEarnings as Earning[]) ?? [],
  };
};

export const useYieldFarmingProgramsCalculation = (
  programs: string[] = [],
  date: string,
) => {
  const queriesInfo = useQueries({
    queries: programs.map((program) => ({
      queryKey: ["yieldFarmingCalculation", program],
      queryFn: async () => {
        const { getCalculation } = await getYieldFarmingV2Sdk();
        return getCalculation({ program, date });
      },
      staleTime: 1000 * 60 * 60 * 4, // 4 hours
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      enabled: Boolean(program),
    })),
  });

  return {
    someAreRefetching: queriesInfo.some(({ isRefetching }) => isRefetching),
    someAreLoading: queriesInfo.some(({ isLoading }) => isLoading),
    allAreLoaded: queriesInfo.every(
      ({ isLoading, isSuccess }) => !isLoading && isSuccess,
    ),
    queries: queriesInfo.map((result) => ({
      ...result,
      data: result.data?.calculation,
    })),
  };
};

export const useYieldFarmingProgramsEstimation = (
  timestamp: InputMaybe<string> = null,
) => {
  const { data: programs } = useYieldFarmingProgramsQuery();
  const programIds = useCustomCompareMemo(
    () => programs?.map(({ id }) => id)?.sort(),
    [programs],
    isEqual,
  );

  const queriesInfo = useQueries({
    queries:
      programIds?.map((program) => ({
        queryKey: ["yieldFarmingEstimation", program, timestamp],
        queryFn: async () => {
          if (!program) return null;

          const { getEstimation } = await getYieldFarmingV2Sdk();
          return getEstimation({ program, timestamp });
        },
        staleTime: 1000 * 60 * 60 * 4, // 4 hours
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        enabled: Boolean(program),
      })) ?? [],
  });

  return useCustomCompareMemo(
    () => ({
      someAreRefetching: queriesInfo.some(({ isRefetching }) => isRefetching),
      someAreLoading: queriesInfo.some(({ isLoading }) => isLoading),
      allAreLoaded: queriesInfo.every(
        ({ isLoading, isSuccess }) => !isLoading && isSuccess,
      ),
      queries: queriesInfo.map((result) => ({
        ...result,
        data: result.data?.estimation,
      })),
    }),
    [queriesInfo],
    isEqual,
  );
};

export const useClaimRewards = () => {
  const { t } = useI18N("farms");
  const { mainAddress, observer, network, utxos } = useWalletObserver();
  const { errorData, handleSetErrorData, setDialogState, refetchEarnings } =
    useYieldFarmingEarningsContext();
  const timeQuery = useServerTime();
  const time = timeQuery?.data?.now ?? new Date().toISOString();
  const claimRewardMutation = useMutation({
    mutationKey: serializeQueryKey([
      "claimRewardMutation",
      mainAddress,
      Boolean(observer.api),
      utxos,
    ]),
    mutationFn: async (earnings: EarningInput[]) => {
      if (!observer.api || !mainAddress) {
        throw new Error("Could not find an API instance or wallet address.");
      }

      if (!utxos) {
        throw new Error("Could not retrieve UTXOs.");
      }

      const userInputs = utxos.map(({ input }) => ({
        index: Number(input().index().toString()),
        txHash: input().transactionId().toString(),
      }));
      const { claimRewards } = await getYieldFarmingV2Sdk();
      const { claim: data } = await claimRewards({
        destinationAddress: mainAddress ?? "",
        earnings,
        userInputs,
      });

      // Declare these upfront, so we can use them within the catch if we have this data
      let signResponse;
      let txWithWitnesses;
      let txId;

      try {
        const cbor = await import("cbor-web");
        const rawTx = cbor.decode(data.transactionHex);

        const ttl = (rawTx[0] as Map<number, number>)?.get(3) ?? -Infinity;
        const currentSlot = unixToSlot(
          Math.floor(new Date(time).valueOf() / 1000),
          network,
        );
        if (ttl < currentSlot) {
          handleSetErrorData({
            type: EErrorType.info,
            message: t("portfolio.earnings.errors.cooldown"),
          });
          claimRewardMutation.reset();
          return null;
        }

        signResponse = await observer.api.signTx(data.transactionHex, true);
        const rawWitnesses = cbor.decode(signResponse);
        for (const witness of rawWitnesses.get(0)) {
          rawTx[1].get(0).push(witness);
        }

        txWithWitnesses = Buffer.from(cbor.encodeCanonical(rawTx)).toString(
          "hex",
        );
        txId = await observer.api.submitTx(txWithWitnesses);
        return txId;
      } catch (e) {
        const declinedToSign = /user rejected|user declined (to sign )?tx/i;
        const errorMessage = e?.message ?? e?.info ?? "";
        if (declinedToSign.test(errorMessage)) {
          handleSetErrorData({
            type: EErrorType.info,
            message: t("portfolio.earnings.errors.declined"),
          });
          claimRewardMutation.reset();
          return null;
        }

        handleSetErrorData({
          type: EErrorType.error,
          message: t("portfolio.earnings.errors.generic"),
          originalError: e,
          details: {
            data,
            signResponse,
            txWithWitnesses,
          },
        });
        return null;
      }
    },
    onMutate: () => {
      setDialogState(ETransactionState.submitting);
    },
    onSuccess: (txId) => {
      refetchEarnings();
      if (txId) {
        setDialogState(ETransactionState.success);
      }
    },
    onError: (error: AxiosError | Error) => {
      const regex = new RegExp(/no\savailable\sUTXOs$/);

      // If the error is an issue with available UTXOs, then we notify and keep trying for 1 minute.
      if (
        error instanceof AxiosError &&
        error?.code === "500" &&
        (regex.test(error?.response?.data as string) ||
          regex.test(error?.message))
      ) {
        handleSetErrorData({
          type: EErrorType.warning,
          message: t("portfolio.earnings.errors.throttle"),
        });
      } else {
        const axiosError = (error as AxiosError)?.response?.data as string;
        const originalError = !!axiosError ? new Error(axiosError) : error;
        handleSetErrorData({
          type: EErrorType.error,
          message: t("portfolio.earnings.errors.generic"),
          originalError,
        });
      }
    },
    retry: 6,
    retryDelay: (retryAttempt, error) => {
      if (retryAttempt === 0 && !errorData) {
        const axiosError = (error as AxiosError)?.response?.data as string;
        const originalError = !!axiosError ? new Error(axiosError) : error;
        handleSetErrorData({
          type: EErrorType.error,
          message: t("portfolio.earnings.errors.generic"),
          originalError,
        });
        claimRewardMutation.reset();
      }

      return 5000;
    },
  });

  return claimRewardMutation;
};

export const useRollupQuery = (key = "all") => {
  const queryInfo = useQuery({
    queryKey: ["rollup", key],
    queryFn: async () => {
      const { getRollup } = await getYieldFarmingV2Sdk();
      return getRollup({ key });
    },
    staleTime: 1000 * 60 * 60 * 4, // 4 hours
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnWindowFocus: false,
  });

  return {
    ...queryInfo,
    data: queryInfo?.data?.rollup,
  };
};
