import { KeyboardIcon, Notice, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, useEffect, useState } from "react";
import {
  LocalControlCenterActions,
  useControlCenterContext,
} from "../../../stores/ControlCenterContext";
interface IJoyrideAutofillStepProps {
  description?: string;
  guideSelectorId: string;
  maxValue?: number;
  title?: string;
}

const JoyrideInputStep: FC<IJoyrideAutofillStepProps> = ({
  description,
  guideSelectorId,
  maxValue,
  title,
}) => {
  const inputElement = document.querySelector(
    `[data-guide-selector="${guideSelectorId}"]`,
  ) as HTMLInputElement;
  const [value, setValue] = useState<string>();
  const isValidValue = maxValue
    ? Number(value) <= maxValue ||
      (Number(inputElement?.value) > 0 &&
        Number(inputElement?.value) <= maxValue)
    : !!value;
  const {
    dispatchers: { localDispatch },
  } = useControlCenterContext();

  useEffect(() => {
    const inputChangeListener = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.dataset.guideSelector === guideSelectorId) {
        setValue(target.value);
      }
    };

    inputElement?.focus();
    document.addEventListener("input", inputChangeListener);

    return () => {
      document.removeEventListener("input", inputChangeListener);
    };
  }, []);

  useEffect(() => {
    LocalControlCenterActions.guides.setIsNextButtonDisabled(
      !isValidValue,
      localDispatch,
    );
  }, [isValidValue]);

  return (
    <div className="grid gap-4">
      {title && (
        <div
          className="mx-auto flex max-w-[90%] items-start gap-2 motion-safe:animate-fadeIn"
          key="JoyrideTooltipComponent--Title"
        >
          <div
            className={cx(
              "flex h-8 w-8 items-center justify-center rounded-full ",
              toolkitCx.layout.background.separator,
            )}
          >
            <KeyboardIcon className="h-4 w-4" />
          </div>

          <Text tag="h1" size="2xl" weight="semibold">
            {title}
          </Text>
        </div>
      )}

      {description && (
        <div
          key="JoyrideTooltipComponent--Content"
          className="motion-safe:animate-fadeIn"
        >
          <Text tag="p" size="sm">
            {description}
          </Text>
        </div>
      )}

      <Notice className="my-2 text-left" type="info" size="xs">
        For this text input field, please type in{" "}
        {!!maxValue ? `a value between 0 and ${maxValue}` : "any value"} and
        click on "Next".
      </Notice>
    </div>
  );
};

export default JoyrideInputStep;
