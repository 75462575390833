import { useLocalStorageWithState } from "@sundaeswap/react-hooks";
import { useEffect, useState } from "react";

import { LOCAL_STORAGE_SHOULD_SEND_COUNTER_EVENT } from "../../../constants/localStorage.constants";
import useAppSearch from "../../../hooks/router/useAppSearch";
import { useEventTracking } from "../../../hooks/useEventTracking";
import { getEnv } from "../../../utils/network.utils";

export const useCounter = () => {
  const { counter } = useAppSearch();
  const [shouldSendCounterEvent, setShouldSendCounterEvent] =
    useLocalStorageWithState(LOCAL_STORAGE_SHOULD_SEND_COUNTER_EVENT, false);
  const [counterEventSend, setCounterEventSend] = useState(
    shouldSendCounterEvent,
  );
  const handleEventTracking = useEventTracking();

  useEffect(() => {
    if (counter) {
      setShouldSendCounterEvent(true);
    }
  }, [counter]);

  useEffect(() => {
    if (counter && shouldSendCounterEvent && !counterEventSend) {
      const env = getEnv();
      handleEventTracking({
        source: "url",
        value: counter,
        network: env === "mainnet" ? "mainnet" : "preview",
      });
      setCounterEventSend(true);
    }
  }, [shouldSendCounterEvent, counterEventSend, counter]);
};
