import {
  BooleanField,
  ColorField,
  DateField,
  ImageField,
  LinkField,
  PrismicDocument,
  SelectField,
  SliceZone,
} from "@prismicio/client";
import { Dispatch, SetStateAction } from "react";

import { KeyTextField } from "@prismicio/types";
import { ICustomPrismicDocument } from "../../types/Prismic.types";

export interface INewsDocumentData {
  /**
   * Title field in *News*
   *
   * - **Field Type**: Text
   * - **API ID Path**: news.title
   * - **Documentation**: https://prismic.io/docs/core-concepts/key-text
   *
   */
  title: KeyTextField;
  /**
   * Description field in *News*
   *
   * - **Field Type**: Text
   * - **API ID Path**: news.desc
   * - **Documentation**: https://prismic.io/docs/core-concepts/key-text
   *
   */
  desc: KeyTextField;
  /**
   * Type field in *News*
   *
   * - **Field Type**: Select
   * - **API ID Path**: news.type
   * - **Documentation**: https://prismic.io/docs/core-concepts/select
   *
   */
  type: SelectField<"dialog" | "inside_popover" | "banner">;
  /**
   * Dialog Image field in *News*
   *
   * - **Field Type**: Image
   * - **Placeholder**: *None*
   * - **API ID Path**: news.dialog_image
   * - **Tab**: Dialog Content
   * - **Documentation**: https://prismic.io/docs/core-concepts/image
   *
   */
  dialog_image?: ImageField<never>;
  /**
   * Force Open on Mount field in *News*
   *
   * - **Field Type**: Boolean
   * - **Default Value**: false
   * - **API ID Path**: news.force_open
   * - **Documentation**: https://prismic.io/docs/core-concepts/boolean
   *
   */
  force_open?: BooleanField;
  /**
   * Dismiss Button CTA field in *News*
   *
   * - **Field Type**: Text
   * - **API ID Path**: news.dismiss_button_cta
   * - **Documentation**: https://prismic.io/docs/core-concepts/key-text
   *
   */
  dismiss_button_cta?: KeyTextField;
  /**
   * Read More Button Label field in *News*
   *
   * - **Field Type**: Text
   * - **API ID Path**: news.read_more_label
   * - **Documentation**: https://prismic.io/docs/core-concepts/key-text
   *
   */
  read_more_label?: KeyTextField;
  /**
   * Popover Backlink field in *News*
   *
   * - **Field Type**: Link
   * - **API ID Path**: news.popover_backlink
   * - **Documentation**: https://prismic.io/docs/core-concepts/link-content-relationship
   *
   */
  popover_backlink?: LinkField;
  /**
   * @deprecated replace with `popover_backlink`
   */
  backlink?: LinkField;
  /**
   * Banner Backlink field in *News*
   *
   * - **Field Type**: Link
   * - **API ID Path**: news.banner_backlink
   * - **Documentation**: https://prismic.io/docs/core-concepts/link-content-relationship
   *
   */
  banner_backlink?: LinkField;
  /**
   * Banner Background Color field in *News*
   *
   * - **Field Type**: Color
   * - **API ID Path**: news.banner_bg_color
   * - **Documentation**: https://prismic.io/docs/core-concepts/color
   *
   */
  banner_bg_color?: ColorField;
  /**
   * Banner Text Color field in *News*
   *
   * - **Field Type**: Color
   * - **API ID Path**: news.banner_text_color
   * - **Documentation**: https://prismic.io/docs/core-concepts/color
   *
   */
  banner_text_color?: ColorField;
  /**
   * Valid From field in *News*
   *
   * - **Field Type**: Date
   * - **API ID Path**: news.valid_from
   * - **Documentation**: https://prismic.io/docs/core-concepts/date
   *
   */
  banner_valid_from?: DateField;
  /**
   * Valid Until field in *News*
   *
   * - **Field Type**: Date
   * - **API ID Path**: news.valid_until
   * - **Documentation**: https://prismic.io/docs/core-concepts/date
   *
   */
  banner_valid_until?: DateField;
}

// @ts-ignore
export interface INewsField extends ICustomPrismicDocument, INewsDocumentData {
  // @ts-ignore
  slices1?: SliceZone; // Slices for the `Dialog Content` Tab in the `News` Custom Type in Prismic.
}

export enum ENewsActions {
  /* -------------------------------------------------------------------------------------------------
   * Array of news ids that are retrieved from the CMS. Used to track which news items have been read.
   * -----------------------------------------------------------------------------------------------*/
  SET_READ_NEWS_ITEM = "SET_READ_NEWS_ITEM",
  SET_UNREAD_NEWS_ITEM = "SET_UNREAD_NEWS_ITEM",
  SET_READ_ALL_NEWS_ITEMS = "SET_READ_ALL_NEWS_ITEMS",
  SET_OPEN_NEWS_ITEM_ID = "SET_OPEN_NEWS_ITEM_ID",
  SET_FORCE_OPENED_NEWS_ID = "SET_FORCE_OPENED_NEWS_ID",
}

export interface INewsAction {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export interface IPersistedNewsState {
  forceOpened: string[];
  newsRead: string[];
}

export interface INewsState extends IPersistedNewsState {
  activeNewsItem?: PrismicDocument<INewsField>;
  error: unknown;
  isFetchingNews: boolean;
  isNewsFetched: boolean;
  isPopoverOpen: boolean;
  isRefetchingNews: boolean;
  latestBannerNewsItem?: PrismicDocument<INewsField>;
  numberOfUnreadNewsItems?: number;
  popoverNewsItems: PrismicDocument<INewsField>[];
  setActiveNewsItem: Dispatch<
    SetStateAction<PrismicDocument<INewsField> | undefined>
  >;
  setIsPopoverOpen: Dispatch<SetStateAction<boolean | undefined>>;
}

export interface INewsContext {
  state: INewsState;
  dispatch: Dispatch<INewsAction>;
}
