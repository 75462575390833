import { Image, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import { FC, useCallback } from "react";

import { useWalletPositions } from "../../../../../../gql/hooks/positions.query";

const PortfolioLiquidityGuideStep1Title: FC = () => {
  const { positions, isLoading } = useWalletPositions({
    page: 0,
    pageSize: 50,
  });

  const renderMemoizedTextNode = useCallback(() => {
    if (isLoading) {
      return (
        <div
          className={cx(
            "h-14 w-full rounded-lg motion-safe:animate-pulse",
            toolkitCx.layout.background.loading,
          )}
        />
      );
    }

    if (!isLoading && isEmpty(positions)) {
      return (
        <Text className="text-center" tag="h1" size="2xl" weight="medium">
          No Positions found
        </Text>
      );
    }

    return (
      <Text className="text-center" tag="h1" size="2xl" weight="medium">
        A detailed Breakdown of your provided Liquidity
      </Text>
    );
  }, [positions, isLoading]);

  const renderMemoizedNode = useCallback(() => {
    return (
      <div className="grid gap-4">
        <div className="overflow-hidden rounded-lg">
          <Image
            className="mx-auto"
            src="https://media.giphy.com/media/67ThRZlYBvibtdF9JH/giphy.gif"
            width={500}
            height={375}
          />
        </div>
        {renderMemoizedTextNode()}
      </div>
    );
  }, [renderMemoizedTextNode]);

  return renderMemoizedNode();
};

export default PortfolioLiquidityGuideStep1Title;
