import { Text } from "@sundaeswap/ui-toolkit";
import { FC, useEffect } from "react";
import { joyrideSelectors } from "../../../../../../constants/joyride.constants";
import {
  LocalControlCenterActions,
  useControlCenterContext,
} from "../../../../../../stores/ControlCenterContext";

const TasteTestInteractiveGuideStep11: FC = () => {
  const {
    dispatchers: { localDispatch },
  } = useControlCenterContext();

  const inputElement = document.querySelector(
    `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.contributedAmount}"]`,
  ) as HTMLInputElement;
  const isValidValue = !!inputElement?.value;

  useEffect(() => {
    LocalControlCenterActions.guides.setIsNextButtonDisabled(
      !isValidValue,
      localDispatch,
    );
  }, [isValidValue]);

  return (
    <div className="grid gap-4">
      <Text tag="p" size="xs" variant="silent">
        Disclaimer - this will not sign and submit, but render a summary
        instead. Currently Order Context not available in scope - stop guide
        here or worth debugging?
      </Text>
      <Text tag="p" size="sm">
        {isValidValue
          ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          : "You haven't entered a valid amount you want to contribute yet. Please click on the BACK Button and enter an amount."}
      </Text>
    </div>
  );
};

export default TasteTestInteractiveGuideStep11;
