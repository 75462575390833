import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import { IChapter } from "../../stores/ControlCenterContext";
import { useIsWalletConnected } from "../useIsWalletConnected";
import { useTradeJoyrideChapter } from "./exchange/chapter/useTradeJoyrideChapter";
import { useLiquidityJoyrideChapter } from "./liquidity/chapter/useLiquidityJoyrideChapter";
import { usePortfolioJoyrideChapter } from "./portfolio/chapter/usePortfolioJoyrideChapter";
import { useTasteTestJoyrideChapter } from "./taste-test/chapter/useTasteTestJoyrideChapter";
import { useYieldFarmingJoyrideChapter } from "./yield-farming/chapter/useYieldFarmingJoyrideChapter";

export const useSortedJoyridesByChapter = (): IChapter[] => {
  const isWalletConnected = useIsWalletConnected();
  const liquidityChapter = useLiquidityJoyrideChapter();
  const portfolioChapter = usePortfolioJoyrideChapter();
  const tradeChapter = useTradeJoyrideChapter();
  const tasteTestChapter = useTasteTestJoyrideChapter();
  const yieldFarmingChapter = useYieldFarmingJoyrideChapter();

  // portfolio chapter should only be available when wallet is connected

  return useMemo(() => {
    const chapters = [
      liquidityChapter,
      tradeChapter,
      tasteTestChapter,
      yieldFarmingChapter,
    ];

    if (isWalletConnected) {
      chapters.push(portfolioChapter);
    }

    return sortBy(chapters, "id", "asc");
  }, [
    isWalletConnected,
    liquidityChapter,
    portfolioChapter,
    tradeChapter,
    tasteTestChapter,
    yieldFarmingChapter,
  ]);
};
