import { useCallback } from "react";

import { AssetAmount } from "@sundaeswap/asset";
import { OrderActions, useOrderContext } from "../stores/OrderContext";
import { IAssetMetaData } from "../types/Asset.types";
import { getAssetId } from "../utils/assets.utils";
import useAppNavigate from "./router/useAppNavigate";

export type TAssetSelectContext = "given" | "taken";

export interface IUseAssetSelectCallbackProps {
  assetMetadata?: IAssetMetaData;
  context?: TAssetSelectContext;
}

export const useAssetSelect = () => {
  const {
    dispatch,
    state: {
      assets: { given, taken },
      flowData: { syncSearch },
    },
  } = useOrderContext();
  const navigate = useAppNavigate();

  const handleAssetSelect = useCallback<
    (args: IUseAssetSelectCallbackProps, key?: string) => void
  >(
    ({ assetMetadata, context }, key) => {
      if (!context) {
        return;
      }

      switch (context) {
        default:
        case "given": {
          if (!assetMetadata) {
            return resetAsset({ context }, key);
          }

          syncSearch
            ? navigate({
                search: ({ routeIdent: _routeIdent, ...prev }) => ({
                  ...prev,
                  given: getAssetId(assetMetadata),
                  ...(taken && { taken: taken.metadata.assetId }),
                }),
              })
            : OrderActions.setGivenAmount(
                new AssetAmount(0n, assetMetadata),
                dispatch,
                `useAssetSelect.${key || context}`,
              );
          break;
        }
        case "taken": {
          if (!assetMetadata) {
            return resetAsset({ context }, key);
          }

          syncSearch
            ? navigate({
                search: ({ routeIdent: _routeIdent, ...prev }) => ({
                  ...prev,
                  taken: getAssetId(assetMetadata),
                  ...(given && { given: given.metadata.assetId }),
                }),
              })
            : OrderActions.setTakenAmount(
                new AssetAmount(0n, assetMetadata),
                dispatch,
                `useAssetSelect.${key || context}`,
              );
          break;
        }
      }
    },
    [dispatch, navigate, given, taken, syncSearch],
  );

  const resetAsset = useCallback<
    (
      args: Omit<IUseAssetSelectCallbackProps, "assetMetadata">,
      key?: string,
    ) => void
  >(
    ({ context }, key) => {
      switch (context) {
        default:
        case "given":
          syncSearch
            ? navigate({
                search: (prev) => ({
                  ...prev,
                  given: undefined,
                }),
              })
            : OrderActions.setGivenAmount(
                undefined,
                dispatch,
                `useAssetSelect.reset.${key || context}`,
              );
          break;
        case "taken":
          syncSearch
            ? navigate({
                search: (prev) => ({
                  ...prev,
                  taken: undefined,
                }),
              })
            : OrderActions.setTakenAmount(
                undefined,
                dispatch,
                `useAssetSelect.reset.${key || context}`,
              );
      }
    },
    [dispatch, navigate, syncSearch],
  );

  return { handleAssetSelect, resetAsset };
};
