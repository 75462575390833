import { XMarkIcon } from "@heroicons/react/24/outline";
import { EventType } from "@sundaeswap/react-hooks";
import {
  Button,
  ScrollArea,
  Text,
  Tooltip,
  toolkitCx,
} from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import type { TooltipRenderProps } from "react-joyride";

import { useEventTracking } from "../../../hooks/useEventTracking";
import {
  LocalControlCenterActions,
  useControlCenterContext,
} from "../../../stores/ControlCenterContext";
import { getEnv } from "../../../utils/network.utils";
import JoyrideTooltipCloseNode from "../JoyrideTooltipCloseNode";

const JoyrideTooltipComponent: FC<TooltipRenderProps> = ({
  continuous,
  index,
  isLastStep,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => {
  const handleEventTracking = useEventTracking();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>();
  const {
    dispatchers: { localDispatch },
    state: {
      localState: {
        guides: { isNextButtonDisabled, showGuideCloseUI },
      },
      persistentState: {
        guides: { currentGuideId },
      },
    },
  } = useControlCenterContext();

  const handleCloseClick = useCallback(() => {
    handleEventTracking({
      eventType: EventType.Click,
      source: "JoyrideTooltipComponent",
      value: `guide_${currentGuideId}__close`,
      network: getEnv(),
    });
    LocalControlCenterActions.guides.showGuideCloseUI(true, localDispatch);
  }, [handleEventTracking, currentGuideId, localDispatch]);

  const handleBackClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      handleEventTracking({
        eventType: EventType.Click,
        source: "JoyrideTooltipComponent",
        value: `guide_${currentGuideId}__back`,
        network: getEnv(),
      });
      backProps.onClick(e);
    },
    [handleEventTracking, currentGuideId, backProps.onClick],
  );

  const handlePrimaryClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      handleEventTracking({
        eventType: EventType.Click,
        source: "JoyrideTooltipComponent",
        value: `guide_${currentGuideId}__primary`,
        network: getEnv(),
      });
      primaryProps.onClick(e);
    },
    [handleEventTracking, currentGuideId, primaryProps.onClick],
  );

  const renderMemoizedNode = useCallback(() => {
    /* -------------------------------------------------------------------------------------------------
     * If the user wants to close the tutorial, we render a confirmation dialog.
     * -----------------------------------------------------------------------------------------------*/
    if (showGuideCloseUI) {
      return <JoyrideTooltipCloseNode currentIndex={index} guideSize={size} />;
    }

    return (
      <div
        className={cx(
          "relative",
          toolkitCx.layout.spacing.horizontal.lg,
          toolkitCx.layout.spacing.vertical.lg,
        )}
      >
        <div
          {...closeProps}
          onClick={handleCloseClick}
          className="absolute right-4 top-4 z-50 cursor-pointer"
        >
          <Tooltip
            primitiveProps={{ content: { className: "z-[1111]" } }}
            trigger={
              <div>
                <XMarkIcon
                  className={cx("h-5 w-5", toolkitCx.layout.text.silent)}
                />
              </div>
            }
            tooltipLabel="Leave this Guide"
          />
        </div>

        <div
          key="JoyrideTooltipComponent--Container"
          className="z-10 my-auto grid h-fit gap-4 text-center"
        >
          {step.title && (
            <div
              className="mx-auto max-w-[90%]"
              key="JoyrideTooltipComponent--Title"
            >
              <>
                {typeof step.title === "string" ? (
                  <Text tag="h1" size="2xl" weight="medium">
                    {step.title}
                  </Text>
                ) : (
                  step.title
                )}
              </>
            </div>
          )}
          {step.content && (
            <div key="JoyrideTooltipComponent--Content">
              <>
                {typeof step.content === "string" ? (
                  <Text tag="p" size="sm">
                    {step.content}
                  </Text>
                ) : (
                  step.content
                )}
              </>
            </div>
          )}

          {!step.hideFooter && (
            <div
              key="JoyrideTooltipComponent--Buttons"
              className="flex items-center justify-center gap-4"
            >
              {continuous ? (
                <>
                  {index > 0 && (
                    <div
                      key="JoyrideTooltipComponent--back"
                      className="flex items-center justify-center gap-4"
                    >
                      <Button
                        {...backProps}
                        onClick={handleBackClick}
                        className="flex-1"
                        size="sm"
                        variant="neutral"
                      >
                        {step.locale.back ?? "Back"}
                      </Button>
                    </div>
                  )}

                  <div
                    key="JoyrideTooltipComponent--next"
                    className="flex items-center justify-center gap-4"
                  >
                    <Button
                      {...primaryProps}
                      onClick={handlePrimaryClick}
                      className={cx({ "flex-1": index > 0 })}
                      disabled={isNextButtonDisabled}
                      size="sm"
                    >
                      {isLastStep
                        ? step.locale.last ?? "Finish Guide"
                        : step.locale.next ?? "Next"}
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  {...closeProps}
                  onClick={handleCloseClick}
                  size="sm"
                  variant="neutral"
                >
                  {step.locale.close ?? "Close"}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }, [showGuideCloseUI, isNextButtonDisabled, handleCloseClick]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current?.clientHeight);
    }
  }, [containerRef.current]);

  return (
    <div
      {...tooltipProps}
      key="JoyrideTooltipComponent--Tooltip"
      className={cx(
        "relative grid w-[75vw] gap-4 overflow-hidden rounded-lg motion-safe:animate-fadeIn sm:w-[60vw]",
        "min-h-[200px]",
        "focus:outline-none",
        "z-50",
        toolkitCx.layout.background.default,
        toolkitCx.layout.border.default,
        toolkitCx.layout.border.weight.default,
        toolkitCx.layout.text.default,
        toolkitCx.recipes.blurryCorner.primary,
        // We're setting the `isLargeStep` property in the guide itself. Not an official props from `react-joyride`.
        {
          // @ts-expect-error
          "md:w-[450px] md:max-w-none": !step.isLargeStep,
          // @ts-expect-error
          "md:w-full md:max-w-[50vw]": step.isLargeStep && !showGuideCloseUI,
          // @ts-expect-error
          "md:w-[450px] md:max-w-[50vw]": step.isLargeStep && showGuideCloseUI,
        },
      )}
      ref={containerRef}
      style={{ height: containerHeight }}
    >
      <ScrollArea
        primitiveProps={{
          viewport: {
            className: "w-full max-h-[82vh]",
          },
        }}
      >
        {renderMemoizedNode()}
      </ScrollArea>
    </div>
  );
};

export default JoyrideTooltipComponent;
