import { AssetAmount, IAssetAmountMetadata } from "@sundaeswap/asset";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { TRatioDirection } from "@sundaeswap/cpp";
import { Fraction, TFractionLike } from "@sundaeswap/fraction";
import isNil from "lodash/isNil";
import { Trans } from "react-i18next";
import { TEMPORARY_Q_ASSETS } from "../constants/assets.constants";
import {
  ADA_METADATA,
  DEFAULT_DECIMALS,
  SUNDAE_CDN,
  readableAdaAssetId,
} from "../constants/cardano.constants";
import {
  AssetBrambleFragmentFragment,
  PoolBrambleFragmentFragment,
} from "../gql/generated/bramble.sdk";
import { Asset, TokenMetadataSource } from "../gql/generated/stats2.sdk";
import {
  transformBrambleAsset,
  transformBramblePool,
} from "../gql/utils/transformers";
import TransWithCardanoRegistryLink from "../i18n/components/warnings/WarningWithCardanoRegistryLink/TransWithCardanoRegistryLink.trans";
import { IAssetMetaData } from "../types/Asset.types";
import { TPool } from "../types/Pool.types";
import { getIpfsImagePath } from "./images.utils";
import { calculatePoolRatio } from "./pool.utils";
import { bigintToString, truncateString } from "./string-format";

/**
 * Defines a type for the ratio values associated with different exchange modes.
 * Each key corresponds to a value related to the exchange: the direction of the exchange,
 * and the quantities of asset A and asset B.
 * Each value is a Record object with keys being the different exchange modes and values being
 * either the ratio direction or the quantity in bigint.
 */
export type TRatioValues = Record<
  "direction" | "assetAQuantity" | "assetBQuantity",
  TRatioDirection | bigint
>;

export const getAssetLogo = (
  asset: IAssetMetaData,
  privacyEnabled?: boolean,
) => {
  const { logo } = asset || {};
  if (privacyEnabled) return "/static/images/pfp/default_avatar.png";
  if (SundaeUtils.isAdaAsset(asset)) return "/static/images/cardano.png";
  const isIpfs = logo?.startsWith("ipfs://");
  if (logo && isIpfs) return getIpfsImagePath(logo);
  if (logo) return `${SUNDAE_CDN}${logo}`;
  return null;
};

export const getAssetName = (
  metadata: IAssetMetaData | IAssetAmountMetadata,
  truncateFrom = 6,
  truncateUntil = 2,
) => {
  if (SundaeUtils.isAdaAsset(metadata)) {
    return "ADA";
  }

  return truncateString(
    metadata?.ticker || metadata?.assetName || "",
    truncateFrom,
    truncateUntil,
  );
};

/**
 * Like getAssetname, but returns the (₳) symbol for ADA
 */
export const getAdaSymbolOrAssetName = (
  metadata?: IAssetMetaData,
  truncateFrom = 6,
  truncateUntil = 2,
) => {
  if (!metadata) return "";

  if (SundaeUtils.isAdaAsset(metadata)) {
    return "₳";
  }
  return getAssetName(metadata, truncateFrom, truncateUntil);
};

export const getAssetId = (assetMetadata?: IAssetMetaData | null) => {
  if (!assetMetadata) return "";

  return SundaeUtils.isAdaAsset(assetMetadata)
    ? readableAdaAssetId
    : assetMetadata.assetId || "";
};

export const getAssetPolicyIdFromMetadata = ({ policyId }: IAssetMetaData) => {
  return policyId || "";
};

export const getTruncatedAssetId = (
  { assetId }: IAssetMetaData,
  truncateFrom = 6,
  truncateUntil = 2,
) => truncateString(assetId, truncateFrom, truncateUntil);

export const getAssetDescription = ({ description }: IAssetMetaData) => {
  return description || "";
};

/**
 * Calculates the ratio between two values.
 *
 * @function
 * @param {TFractionLike} firstValue - The first value for the ratio calculation.
 * @param {TFractionLike} secondValue - The second value for the ratio calculation.
 * @returns {string|null} The ratio of the first value to the second value, expressed as a string in base 10. If either of the inputs is zero or negative, or if either input is missing, the function returns null.
 * @throws {Error} Will throw an error if the inputs are not valid for the Fraction class's `asFraction` method.
 */
export const getAssetsRatio = (
  firstValue: TFractionLike,
  secondValue: TFractionLike,
) => {
  const first = Fraction.asFraction(firstValue ?? 0);
  const second = Fraction.asFraction(secondValue ?? 0);

  if (!first.greaterThan(0) || !second.greaterThan(0)) {
    return null;
  }

  return first.divide(second).toString(10);
};

/**
 * Returns the ratio of an asset pair as an AssetAmount.
 */
export const calculateAssetsRatio = (
  firstValue?: TFractionLike | null,
  secondValue?: TFractionLike | null,
  decimals = DEFAULT_DECIMALS,
) => {
  if (!firstValue || !secondValue) return null;
  const ratio = getAssetsRatio(firstValue, secondValue);
  return ratio ? AssetAmount.fromValue(ratio, decimals) : null;
};

export const calculatePerRatio = ({
  poolQuantityA,
  poolQuantityB,
  fromDecimal,
  toDecimal,
}: {
  poolQuantityA: TFractionLike;
  poolQuantityB: TFractionLike;
  fromDecimal: number;
  toDecimal: number;
}) => {
  const aPerB = getAssetsRatio(poolQuantityA, poolQuantityB);
  const amount = AssetAmount.fromValue(
    Number(aPerB) * 10 ** (fromDecimal - toDecimal),
    toDecimal,
  );
  return amount;
};

export const isSameAsset = (
  assetA?: IAssetMetaData,
  assetB?: IAssetMetaData,
) => {
  if (!assetA || !assetB) return false;

  return assetA.assetId === assetB.assetId;
};

/**
 * Takes a list of assets and removes the asset which is passed as second argument.
 * Used e.g. for the asset selection to remove duplicates and prevent pairs like i.e. `ADA <-> ADA`
 */
export const stripActiveAsset = (
  assets?: AssetAmount<IAssetMetaData>[],
  activeAsset?: IAssetMetaData,
) =>
  assets?.filter((asset) => {
    if (
      SundaeUtils.isAdaAsset(asset.metadata) &&
      [readableAdaAssetId, ADA_METADATA.assetId].includes(
        activeAsset?.assetId ?? "",
      )
    ) {
      return false;
    }
    return asset.metadata.assetId !== activeAsset?.assetId;
  }) ?? [];

/**
 * Checks whether an asset has the `CardanoTokenRegistry` inside its `sources` field.
 */
export const isAssetRegistered = (asset?: Asset | IAssetMetaData | null) =>
  Boolean(
    (asset as Asset)?.sources?.includes(
      TokenMetadataSource.CardanoTokenRegistry,
    ),
  ) ||
  (!!asset && SundaeUtils.isAdaAsset(asset));

/**
 * Checks whether an asset as `0` as decimals.
 */
export const isAssetIndivisible = (
  asset: IAssetMetaData | null | undefined,
): boolean => asset?.decimals === 0;

/**
 * Returns a warning string based on the asset status (unregistered / indivisible / both).
 */
export const getAssetWarning = (asset: IAssetMetaData) => {
  if (isAssetIndivisible(asset) && !isAssetRegistered(asset)) {
    return (
      <TransWithCardanoRegistryLink
        i18nKey="warning.unregisteredAndIndivisibleAsset"
        ns="warnings"
      />
    );
  }

  if (!isAssetRegistered(asset)) {
    return (
      <TransWithCardanoRegistryLink
        i18nKey="warning.unregisteredAsset"
        ns="warnings"
      />
    );
  }

  if (isAssetIndivisible(asset)) {
    return (
      <Trans i18nKey="warning.indivisible" ns="warnings">
        This token is not registered, interact at your own risk.
      </Trans>
    );
  }

  return null;
};

/**
 * Since we use `cardano.ada` or `ada.lovelace` as identifier in our `search` for the ADA token, it should be transformed back to `""` for our GQL query.
 */
export const transformAssetIdToGqlAssetId = (assetId: string) => {
  return SundaeUtils.ADA_ASSET_IDS.includes(assetId)
    ? ADA_METADATA.assetId
    : assetId;
};

/**
 * Our GQL returns a different "default" ADA asset than our `ADA_METADATA`. So in order for our frontend to properly digest the response, we transform the backend's default ADA to the frontend version.
 */
export const transformGQLAdaAsset = (gqlAsset: Asset) => {
  if (gqlAsset.assetId === readableAdaAssetId) {
    return ADA_METADATA;
  }

  return gqlAsset;
};

/**
 * Maps over a list of `Asset` and returns the asset with the matching assetId.
 */
export const getSingleAssetById = (
  activeAssetId?: string,
  assetsByIds?: Asset[],
) => {
  if (isNil(activeAssetId) || !assetsByIds) return;

  return assetsByIds.find((asset) => {
    const assetId = transformAssetIdToGqlAssetId(activeAssetId);
    return asset.assetId === assetId;
  });
};

/**
 * This function returns the active asset from a list.
 */
export const getActiveAssetFromList = (
  asset?: Asset,
  list?: AssetAmount<IAssetMetaData>[],
): AssetAmount<IAssetMetaData> | undefined => {
  if (!asset || !list) return;

  const assetFromList = list.find(
    (assetWithBalance) => assetWithBalance.metadata.assetId === asset.assetId,
  );

  return (
    assetFromList ||
    AssetAmount.fromValue(0n, { ...asset, warning: getAssetWarning(asset) })
  );
};

/**
 * Checks whether an asset has a truthy quantity value.
 */
export const assetHasQuantity = (
  asset?: AssetAmount<IAssetMetaData> | null,
) => {
  if (!asset?.amount) return false;

  return asset.amount > 0n;
};

/**
 * Calculates the price of an asset in ADA based on its USD price, the USD price of ADA, and the number of decimal places.
 *
 * @param {number} assetInUSD - The price of the asset in USD.
 * @param {number} adaInUSD - The price of ADA in USD.
 * @param {number} [decimals=6] - The number of decimal places for the result (default is 6).
 * @returns {number} The price of the asset in ADA with the specified number of decimal places.
 * @throws {Error} Throws an error if the price of ADA in USD is less than or equal to zero.
 *
 * @example
 * const assetInUSD = 2;
 * const adaInUSD = 1;
 * const assetPriceInADA = calculateAssetPriceInADA(assetInUSD, adaInUSD); // 2
 */
export const calculateAssetPriceInADA = (
  assetInUSD: number | null,
  adaInUSD?: number | null,
  decimals: number = 6,
): number | null => {
  // null or undefined, but allow `0`
  if (isNil(assetInUSD) || isNil(adaInUSD)) {
    return null;
  }

  if (adaInUSD <= 0) {
    return 0;
  }

  const result = assetInUSD * (1 / adaInUSD);

  // Use parseFloat to convert the result back to a number after using toFixed() for formatting.
  return parseFloat(result.toFixed(decimals));
};

/**
 * Adjusts the price based on the difference in decimals between two assets.
 *
 * @param {number} rawPrice - The raw price returned from the API in the diminutive units of the pool's asset A.
 * @param {number} decimalsA - The number of decimals for asset A (e.g. ADA).
 * @param {number} decimalsB - The number of decimals for asset B (e.g. SNEK).
 * @param {number} [displayDecimals] - The number of decimals to display in the result (optional).
 * @returns {number} - The adjusted price to be displayed in the frontend.
 *
 * @example
 * // For a raw price of 107.18678832804468 (in Lovelaces), 6 decimals for ADA, and 0 decimals for SNEK:
 * // adjustPrice(107.18678832804468, 6, 0) => 0.000107
 * // adjustPrice(107.18678832804468, 6, 0, 8) => 0.00010700
 */
export const getDisplayedAssetPrice = (
  rawPrice: number,
  decimalsA: number,
  decimalsB: number,
  displayDecimals?: number,
): number => {
  // Calculate the difference in decimals between the two assets
  const decimalDifference = decimalsB - decimalsA;

  // Calculate the adjustment factor based on the decimal difference
  const adjustmentFactor = Math.pow(10, decimalDifference);

  // Multiply the raw price by the adjustment factor to get the adjusted price
  const adjustedPrice = rawPrice * adjustmentFactor;

  // If displayDecimals is not provided, use the decimals of assetA as the default
  if (displayDecimals === undefined) {
    displayDecimals = decimalsA;
  }

  // Round the adjusted price to the desired number of decimal places
  const roundedPrice = adjustedPrice.toFixed(displayDecimals);

  return Number(roundedPrice);
};

/**
 * Determines the direction of the ratio ("A_PER_B" or "B_PER_A") based on
 * whether the asset ID of `activeAssetA` matches the asset ID of `assetA` in `activePool`.
 * @param {AssetAmount<IAssetMetaData>} activeAssetA - Active asset A in the current state.
 * @param {TPool} activePool - The active pool in the current state.
 * @return {TRatioDirection | null} - Returns the direction of the ratio if the assets match, otherwise null.
 */
export const getNaturalRatioDirection = (
  activeAssetA: AssetAmount<IAssetMetaData> | null,
  activePool: TPool | null,
): TRatioDirection | null => {
  if (!activeAssetA || !activePool) return null;
  return "A_PER_B";
};

/**
 * Creates a map of assetId to asset.
 *
 * @param assetsWithBalance - Array of assets with their balance.
 * @returns A map of assetId to asset.
 *
 * @example
 * // Suppose the input (assetsWithBalance) is:
 * // [
 * //   {metadata: {assetId: "1", ...otherProperties}, quantity: "100"},
 * //   {metadata: {assetId: "2", ...otherProperties}, quantity: "200"}
 * // ]
 * // The output would be:
 * // Map {
 * //   "1" => {metadata: {assetId: "1", ...otherProperties}, quantity: "100"},
 * //   "2" => {metadata: {assetId: "2", ...otherProperties}, quantity: "200"}
 * // }
 *
 * This allows easier access of certain values when comparing it to different assets:
 *
 * @example
 * // const matchingAsset = assets.map(({assetId}) => assetMap.get(assetId));
 */
export const createAssetMap = (
  assetsWithBalance: AssetAmount<IAssetMetaData>[],
) => {
  return assetsWithBalance?.reduce((map, asset) => {
    map.set(asset.metadata.assetId, asset);
    return map;
  }, new Map<string, AssetAmount<IAssetMetaData>>());
};

/**
 * Function that calculates the value of a single asset in USD based on its quantity,
 * the price of ADA in USD, and the price of the asset in USD (if not ADA).
 * @param {Object} asset - The asset to calculate the value for.
 * @param {number} adaInUSD - The price of ADA in USD.
 * @returns {number} Value of the asset in USD.
 */
export const calculateAssetValueInUSD = (
  asset: AssetAmount<IAssetMetaData>,
  adaInUSD: number,
): number => {
  const { priceToday } = asset.metadata;
  const quantity = Number(
    bigintToString(asset.amount, asset.metadata.decimals),
  );

  if (SundaeUtils.isAdaAsset(asset.metadata)) {
    return quantity * adaInUSD;
  }

  if (priceToday === null) {
    return 0;
  }

  const assetPriceInADA = adaInUSD
    ? calculateAssetPriceInADA(Number(priceToday), adaInUSD)
    : 0;
  return quantity * (assetPriceInADA ?? 0) * adaInUSD;
};

/**
 * Calculates the value of an array of assets in USD based on their quantities, the price of ADA in USD,
 * and the price of the assets in USD (if not ADA).
 *
 * @param { AssetAmount<IAssetMetaData>} assets - The array of assets to calculate values for.
 * @param {number} adaInUSD - The price of ADA in USD.
 * @returns {Object} An object with each asset's value in USD and the total value.
 */
export const calculateAssetValuesInUSD = (
  assets?: AssetAmount<IAssetMetaData>[],
  adaInUSD?: number,
): { [assetId: string]: number; total: number } | null => {
  if (!assets || !assets.length || isNil(adaInUSD)) return null;

  return assets.reduce(
    (acc, asset) => {
      const { assetId } = asset.metadata;
      const assetValue = calculateAssetValueInUSD(asset, adaInUSD);

      acc[assetId] = assetValue;
      acc.total = (acc.total || 0) + assetValue;
      return acc;
    },
    {} as { [assetId: string]: number; total: number },
  );
};

/**
 * We currently receive `ada.lovelace` as assetId from the SDK. We need to normalize it to `cardano.ada` for the API in order to receive the correct asset.
 * @param assetIds - Array of assetIds.
 * @returns Array of assetIds with normalized ADA assetId.
 */
export const normalizeAdaAssetIdsForGql = (assetIds: string[]) => {
  return assetIds.map((assetId) => {
    if (SundaeUtils.ADA_ASSET_IDS.includes(assetId)) {
      return readableAdaAssetId;
    }

    return assetId;
  });
};

export const getQAsset = (assetId: string) => {
  return TEMPORARY_Q_ASSETS.find(
    (asset) => asset.assetId.split(".")[0] === assetId,
  ) as IAssetMetaData;
};

/**
 * Split an assetId into its parts:
 *   [policyId, assetName]
 * @param assetId
 * @returns {[string, string]}
 */
export const assetIdToParts = (assetId: string): [string, string] => {
  return [
    assetId.replace(".", "").slice(0, 56),
    assetId.replace(".", "").slice(56),
  ];
};

/**
 * Retrieves the liquidity pool information for each asset from a list of assets.
 * It iterates over each asset, finds all pools where the asset is present (either as assetA or assetB),
 * and then selects the pool with the highest TVL (Total Value Locked) as the primary pool for that asset.
 * Assets that are identified as ADA (using SundaeUtils.isAdaAsset) or do not have an associated pool are mapped to `undefined`.
 *
 * @param {AssetBrambleFragmentFragment[]} assets - An array of assets to find pools for.
 * @param {PoolBrambleFragmentFragment[]} pools - An array of pools to search through.
 * @returns {Object} An object mapping asset IDs to their primary liquidity pool or undefined. The primary pool is determined based on the highest TVL.
 */
export const getAssetsPool = (
  assets: AssetBrambleFragmentFragment[],
  pools: PoolBrambleFragmentFragment[],
) => {
  return assets.reduce((acc: { [key: string]: TPool | undefined }, asset) => {
    const items = pools.filter(
      (pool) => pool.assetA.id === asset.id || pool.assetB.id === asset.id,
    );

    const pool = items.reduce(
      (max, item) =>
        Number(item.current.tvl.quantity) > Number(max.current.tvl.quantity)
          ? item
          : max,
      items[0],
    );

    acc[asset.id] =
      SundaeUtils.isAdaAsset(transformBrambleAsset(asset)) || !pool
        ? undefined
        : transformBramblePool(pool);
    return acc;
  }, {});
};

/**
 * Calculates the current price of an asset in a given pool, based on the ADA price.
 * The asset price is derived by multiplying the ADA price with the pool's asset ratio.
 * If the ADA price or pool ratio is not available, the function returns "0".
 *
 * @param {TPool} pool - The pool from which to calculate the asset's price.
 * @param {string} adaPrice - The current price of ADA in a string format.
 * @returns {string} The calculated price of the asset today as a string.
 */
export const getAssetPriceToday = (adaPrice: string, pool?: TPool) => {
  const poolRatio = pool && calculatePoolRatio(pool);
  return String(
    (adaPrice ? Number(adaPrice) : 0) * (poolRatio?.toNumber() ?? 0),
  );
};
