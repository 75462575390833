import { Image, Text } from "@sundaeswap/ui-toolkit";
import { FC, memo } from "react";

const TasteTestExplanationGuideStep4: FC = () => {
  return (
    <div className="grid gap-4">
      <Text tag="p" size="sm">
        If the taste test price is lower than the true market value, then after
        equilibrium is reached, users will have gotten a discount.
      </Text>
      <Image
        width={394}
        height={221}
        src="/static/images/guides/taste-test/slide_1.png"
      />
      <Text tag="p" size="sm">
        This attracts more participants if belief in a higher price is
        widespread.
      </Text>
    </div>
  );
};

export default memo(TasteTestExplanationGuideStep4);
