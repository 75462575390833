import { Text } from "@sundaeswap/ui-toolkit";
import { FC, useCallback } from "react";

const TasteTestInteractiveGuideStep1: FC = () => {
  const renderMemoizedNode = useCallback(() => {
    return (
      <div className="grid gap-2 text-center">
        <Text tag="p" size="sm">
          Token Launches are all about timing. The earlier you get in, the more
          you stand to gain. But a Taste Test is not a Pre Sale like any other.
          It's a unique flavor of token sale that creates a decentralized
          negotiation between a project and the community.
        </Text>
        <Text tag="p" size="sm">
          In this guide, we show you how can participate in a Taste Test and
          earn LP tokens.
        </Text>
      </div>
    );
  }, []);

  return renderMemoizedNode();
};

export default TasteTestInteractiveGuideStep1;
