import { toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { motion } from "framer-motion";
import { FC } from "react";
import { slideInAndScaleFromBottomMotion } from "../../motions/default.motions";

const ControlCenterNewsListItemSkeleton: FC = () => {
  return (
    <motion.li
      variants={slideInAndScaleFromBottomMotion}
      className={cx(
        "w-full",
        "flex flex-col space-y-4 overflow-hidden",
        "relative z-10 rounded-lg",
        "motion-safe:animate-pulse",
        toolkitCx.layout.background.default,
        toolkitCx.layout.shadow.default,
        toolkitCx.layout.border.default,
        toolkitCx.layout.border.weight.default,
        toolkitCx.layout.spacing.both.md,
      )}
    >
      <div className="relative z-20 grid gap-1">
        <div
          className={cx(
            "h-6 w-full rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
        <div
          className={cx(
            "h-4 w-2/5 rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
        <div
          className={cx(
            "h-4 w-full rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
        <div
          className={cx(
            "h-4 w-full rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
        <div
          className={cx(
            "h-4 w-1/2 rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
      </div>

      <div
        className={cx(
          "h-8 w-full rounded-lg",
          toolkitCx.layout.background.loading,
        )}
      />
    </motion.li>
  );
};

export default ControlCenterNewsListItemSkeleton;
