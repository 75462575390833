import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import {
  joyrideIds,
  joyrideSelectors,
} from "../../../../../constants/joyride.constants";
import { paths, PortfolioTab } from "../../../../../pages/routes";
import {
  EGuideChapter,
  IGuide,
} from "../../../../../stores/ControlCenterContext";

export const usePortfolioOrdersGuideJoyride = (): IGuide => {
  const { t } = useI18N("joyride");

  return useMemo(
    () => ({
      rank: 5,
      description: t("orders.description"),
      title: t("orders.title"),
      chapter: EGuideChapter.PORTFOLIO,
      joyride: {
        id: joyrideIds.portfolio.orders,
        steps: [
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step1.title"),
            content: t("orders.guides.steps.step1.description"),
            placement: "top",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.searchInput}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step2.title"),
            content: t("orders.guides.steps.step2.description"),
            placement: "left",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.exportTransactionReportButton}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step3.title"),
            content: t("orders.guides.steps.step3.description"),
            placement: "top",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.orderTypeSelect}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step4.title"),
            content: t("orders.guides.steps.step4.description"),
            placement: "right",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.orderType}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step5.title"),
            content: t("orders.guides.steps.step5.description"),
            placement: "right",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.date}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step6.title"),
            content: t("orders.guides.steps.step6.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.input}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step7.title"),
            content: t("orders.guides.steps.step7.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.output}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step8.title"),
            content: t("orders.guides.steps.step8.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.orderStatus}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.orders,
            },
            title: t("orders.guides.steps.step9.title"),
            content: t("orders.guides.steps.step9.description"),
            placement: "bottom",
            target: `[data-guide-selector="${joyrideSelectors.guides.orders.manage}"]`,
          },
        ],
      },
    }),
    [t],
  );
};
