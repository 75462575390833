import { useQuery } from "@tanstack/react-query";
import { fetchPrismicNewsByEveryTags } from "../../prismic/prismic.config";

interface IUsePrismicNewsArgs {
  tags: string[];
}

export const usePrismicNews = ({ tags }: IUsePrismicNewsArgs) =>
  useQuery({
    queryKey: ["news", ...tags],
    queryFn: () => fetchPrismicNewsByEveryTags(tags),
    refetchOnWindowFocus: false,
    gcTime: 1000 * 60 * 10, // 10 Minutes
    refetchInterval: 1000 * 60 * 10, // 10 Minutes
    staleTime: 1000 * 60 * 5, // 5 Minutes
  });
