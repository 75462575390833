import { AssetAmount } from "@sundaeswap/asset";
import {
  useWalletLoadingState,
  useWalletObserver,
} from "@sundaeswap/wallet-lite";
import { useMemo } from "react";

import { MIN_ADA_WALLET_BALANCE } from "../constants/SundaeSwap.constants";
import { ADA_METADATA } from "../constants/cardano.constants";
import { IAssetMetaData } from "../types/Asset.types";

export const useAdaBalance = () => {
  const { connectingWallet } = useWalletLoadingState();
  const { adaBalance } = useWalletObserver<IAssetMetaData>();

  const isAdaBalanceLessThanMinimum = useMemo(() => {
    if (!adaBalance) return false;

    return MIN_ADA_WALLET_BALANCE.value.greaterThan(adaBalance.value);
  }, [adaBalance]);

  const availableAdaBalance = useMemo(() => {
    if (isAdaBalanceLessThanMinimum) {
      return new AssetAmount<IAssetMetaData>(0n, ADA_METADATA);
    }

    return adaBalance.sub(MIN_ADA_WALLET_BALANCE);
  }, [adaBalance, isAdaBalanceLessThanMinimum]);

  return {
    availableAdaBalance,
    adaBalanceFromWallet: adaBalance,
    isAdaBalanceLessThanMinimum,
    loaded: !connectingWallet,
  };
};
