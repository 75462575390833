import {
  ArrowsLeftRight,
  Avatar,
  Text,
  toolkitCx,
} from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, memo } from "react";
import {
  ADA_METADATA,
  SUNDAE_METADATA,
} from "../../../../../../constants/cardano.constants";

const TasteTestExplanationGuideStep2: FC = () => {
  return (
    <div className="my-4 grid items-center">
      <div className="grid place-items-center gap-4">
        <div className="mx-auto flex w-3/4 items-center justify-between gap-4">
          <div className="grid place-items-center gap-2">
            <Avatar size="xl" src={SUNDAE_METADATA.logo ?? ""} />
            <Text tag="p" size="2xs" variant="silent">
              5,000,000 CARAMEL
            </Text>
          </div>

          <ArrowsLeftRight
            className={cx("h-6 w-6", toolkitCx.layout.text.silent)}
          />

          <div className="relative grid flex-1 place-items-center gap-4">
            <Avatar size="lg" src={ADA_METADATA.logo ?? ""} />
            <Avatar
              className="-right-2"
              size="lg"
              src={ADA_METADATA.logo ?? ""}
            />
            <Avatar size="lg" src={ADA_METADATA.logo ?? ""} />
            <Text tag="p" size="2xs" variant="silent">
              ??? ADA
            </Text>
          </div>
        </div>
        <Text tag="p" size="sm">
          Imagine a new “CARAMEL” token is launching
        </Text>
        <Text tag="p" size="sm">
          The founders want to sell a portion of their Token. Their community
          wants to buy a portion of it. But there is{" "}
          <Text tag="span" className="inline" weight="semibold" size="sm">
            no established price
          </Text>{" "}
          yet.
        </Text>
      </div>
    </div>
  );
};

export default memo(TasteTestExplanationGuideStep2);
