import { FC, lazy, Suspense } from "react";
import { externalRoutes } from "../../../../../../pages/routes";
import LoadingComponent from "../../../../../LoadingComponent";
const LazyReactPlayer = lazy(() => import("react-player/youtube"));

const V3CreatePoolStep2: FC = () => {
  return (
    <div className="relative h-0 w-full overflow-hidden pb-[56.25%]">
      <Suspense fallback={<LoadingComponent />}>
        <LazyReactPlayer
          controls
          className="absolute left-0 top-0 !h-full !w-full"
          url={externalRoutes.cip45VideoDemonstration} // TODO: Replace with actual URL
        />
      </Suspense>
    </div>
  );
};

export default V3CreatePoolStep2;
