import { Dispatch } from "react";
import {
  ELocalControlCenterStateActions,
  EPersistentControlCenterStateActions,
  ILocalControlCenterState,
  ILocalControlCenterStateAction,
  IPersistentControlCenterState,
  IPersistentControlCenterStateAction,
} from "./types";

type TLocalDispatch = Dispatch<ILocalControlCenterStateAction>;
type TPersistentDispatch = Dispatch<IPersistentControlCenterStateAction>;

/* -------------------------------------------------------------------------------------------------
 * Persistent Control Center Actions
 * -----------------------------------------------------------------------------------------------*/
const startGuide = (
  currentGuideId: IPersistentControlCenterState["guides"]["currentGuideId"],
  dispatch: TPersistentDispatch,
) => {
  dispatch({
    type: EPersistentControlCenterStateActions.START_GUIDE,
    payload: { currentGuideId },
  });
};
const openGuidesOnMount = (
  openGuidesOnMount: boolean,
  dispatch: TPersistentDispatch,
) => {
  dispatch({
    type: EPersistentControlCenterStateActions.OPEN_GUIDES_ON_MOUNT,
    payload: { openGuidesOnMount },
  });
};
const completeGuide = (
  currentGuideId: IPersistentControlCenterState["guides"]["currentGuideId"],
  completed: boolean,
  dispatch: TPersistentDispatch,
) => {
  dispatch({
    type: EPersistentControlCenterStateActions.COMPLETE_GUIDE,
    payload: { currentGuideId, completed },
  });
};
const closeGuide = (
  currentGuideId: IPersistentControlCenterState["guides"]["currentGuideId"],
  completed: boolean,
  stepIndex: number,
  dispatch: TPersistentDispatch,
) => {
  dispatch({
    type: EPersistentControlCenterStateActions.CLOSE_GUIDE,
    payload: { currentGuideId, completed, stepIndex },
  });
};
const goToStep = (
  currentGuideId: IPersistentControlCenterState["guides"]["currentGuideId"],
  stepIndex: number,
  dispatch: TPersistentDispatch,
) => {
  dispatch({
    type: EPersistentControlCenterStateActions.HANDLE_STEP,
    payload: { currentGuideId, stepIndex },
  });
};
const setIsEveryGuideCompleted = (
  isEveryChapterCompleted: IPersistentControlCenterState["guides"]["isEveryChapterCompleted"],
  dispatch: TPersistentDispatch,
) => {
  dispatch({
    type: EPersistentControlCenterStateActions.SET_IS_EVERY_CHAPTER_COMPLETED,
    payload: { isEveryChapterCompleted },
  });
};

/* -------------------------------------------------------------------------------------------------
 * Local Control Center Actions
 * -----------------------------------------------------------------------------------------------*/
const loadChapters = (
  chapters: ILocalControlCenterState["guides"]["chapters"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.LOAD_CHAPTERS,
    payload: { chapters },
  });
};
const runGuide = (
  runGuide: ILocalControlCenterState["guides"]["runGuide"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.RUN_GUIDE,
    payload: { runGuide },
  });
};
const setActiveChapter = (
  activeChapter: ILocalControlCenterState["guides"]["activeChapter"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SET_ACTIVE_CHAPTER,
    payload: { activeChapter },
  });
};
const setActiveGuideId = (
  activeGuideId: ILocalControlCenterState["guides"]["activeGuideId"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SET_ACTIVE_GUIDE,
    payload: { activeGuideId },
  });
};
const setWithAnimationDelay = (
  withAnimationDelay: ILocalControlCenterState["withAnimationDelay"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SET_WITH_ANIMATION_DELAY,
    payload: { withAnimationDelay },
  });
};
const setIsNextButtonDisabled = (
  isNextButtonDisabled: ILocalControlCenterState["guides"]["isNextButtonDisabled"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SET_IS_NEXT_BUTTON_DISABLED,
    payload: { isNextButtonDisabled },
  });
};
const setLastClosedGuideItemId = (
  lastClosedGuideItemId: ILocalControlCenterState["guides"]["lastClosedGuideItemId"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SET_LAST_CLOSED_GUIDE_ID,
    payload: { lastClosedGuideItemId },
  });
};
const showGuides = (
  showGuides: ILocalControlCenterState["showGuides"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SHOW_GUIDES,
    payload: { showGuides },
  });
};
const showGuideCloseUI = (
  showGuideCloseUI: ILocalControlCenterState["guides"]["showGuideCloseUI"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SHOW_GUIDE_CLOSE_UI,
    payload: { showGuideCloseUI },
  });
};
const showGuidesLoadingAnimation = (
  showLoadingAnimation: ILocalControlCenterState["guides"]["showLoadingAnimation"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SHOW_LOADING_ANIMATION_GUIDES,
    payload: { showLoadingAnimation },
  });
};
const showHelpCenter = (
  showHelpCenter: ILocalControlCenterState["showHelpCenter"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SHOW_HELP_CENTER,
    payload: { showHelpCenter },
  });
};
const showInbox = (
  showInbox: ILocalControlCenterState["showInbox"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SHOW_INBOX,
    payload: { showInbox },
  });
};
const showSuccessAnimation = (
  showSuccessAnimation: boolean,
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SHOW_SUCCESS_ANIMATION,
    payload: { showSuccessAnimation },
  });
};
const showToolbar = (
  showToolbar: ILocalControlCenterState["showToolbar"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SHOW_TOOLBAR,
    payload: { showToolbar },
  });
};
const setLastClosedNewsId = (
  lastClosedNewsId: ILocalControlCenterState["news"]["lastClosedNewsId"],
  dispatch: TLocalDispatch,
) => {
  dispatch({
    type: ELocalControlCenterStateActions.SET_LAST_CLOSED_NEWS_ID,
    payload: { lastClosedNewsId },
  });
};

const LocalControlCenterActions = {
  guides: {
    loadChapters,
    runGuide,
    setActiveChapter,
    setActiveGuideId,
    setIsNextButtonDisabled,
    setLastClosedGuideItemId,
    showGuideCloseUI,
    showGuidesLoadingAnimation,
    showSuccessAnimation,
  },
  news: {
    setLastClosedNewsId,
  },
  setWithAnimationDelay,
  showGuides,
  showHelpCenter,
  showInbox,
  showToolbar,
};

const PersistentControlCenterActions = {
  guides: {
    closeGuide,
    completeGuide,
    goToStep,
    openGuidesOnMount,
    setIsEveryChapterCompleted: setIsEveryGuideCompleted,
    startGuide,
  },
};

export { LocalControlCenterActions, PersistentControlCenterActions };
