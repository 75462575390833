import { Variants } from "framer-motion";
import {
  DEFAULT_DURATION,
  EXIT_DURATION,
  slideInAndScaleFromBottomMotion,
} from "./default.motions";

export const CONTROL_CENTER_AVATAR_ANIMATION_DELAY = 2;

export const controlCenterAvatarMotion: Variants = {
  initial: {
    zIndex: -1,
    opacity: 0,
    y: 5,
    scale: 0.98,
  },
  animate: {
    zIndex: 40,
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      duration: DEFAULT_DURATION,
      delay: CONTROL_CENTER_AVATAR_ANIMATION_DELAY,
    },
  },
  exit: {
    opacity: 0,
    y: 5,
    scale: 0.98,
    transition: {
      duration: EXIT_DURATION,
    },
  },
};

export const controlCenterToolbarMotion: Variants = {
  initial: {
    opacity: 0,
    scale: 0.98,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      delayChildren: 0.125,
      staggerChildren: 0.125,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    transition: {
      when: "afterChildren",
      duration: 0.5,
      staggerChildren: 0.125,
    },
  },
};

export const controlCenterToolbarElementMotion: Variants = {
  ...slideInAndScaleFromBottomMotion,
  animate: {
    ...slideInAndScaleFromBottomMotion.animate,
    transition: {
      ...(
        slideInAndScaleFromBottomMotion.animate as typeof slideInAndScaleFromBottomMotion
      ).transition,
      duration: 0.5,
    },
  },
  exit: {
    ...slideInAndScaleFromBottomMotion.exit,
    transition: {
      ...(
        slideInAndScaleFromBottomMotion.exit as typeof slideInAndScaleFromBottomMotion
      ).transition,
      duration: 0.25,
    },
  },
};

export const getControlCenterContentContainerMotion = (
  withAnimationDelay: boolean,
): Variants => ({
  initial: {
    opacity: 0,
    y: 5,
    scale: 0.98,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      duration: DEFAULT_DURATION,
      delay: withAnimationDelay ? 0.25 : 0,
    },
  },
  exit: {
    opacity: 0,
    y: 5,
    scale: 0.98,
    transition: {
      duration: EXIT_DURATION,
      when: "beforeChildren",
    },
  },
});

export const getControlCenterContentHeaderMotion = (
  withAnimationDelay: boolean,
): Variants => ({
  ...slideInAndScaleFromBottomMotion,
  animate: {
    ...slideInAndScaleFromBottomMotion.animate,
    transition: {
      ...(
        slideInAndScaleFromBottomMotion.animate as typeof slideInAndScaleFromBottomMotion
      ).transition,
      delay: withAnimationDelay ? 0.375 : 0,
      staggerChildren: 0.125,
    },
  },
});

export const getControlCenterContentElementsMotion = (
  withAnimationDelay: boolean,
): Variants => ({
  ...slideInAndScaleFromBottomMotion,
  animate: {
    ...slideInAndScaleFromBottomMotion.animate,
    transition: {
      ...(
        slideInAndScaleFromBottomMotion.animate as typeof slideInAndScaleFromBottomMotion
      ).transition,
      delayChildren: withAnimationDelay ? 0.5 : 0.125,
      staggerChildren: 0.125,
    },
  },
});

export const getControlCenterHelpCenterMotion = (
  withAnimationDelay: boolean,
): Variants => ({
  ...slideInAndScaleFromBottomMotion,
  animate: {
    ...slideInAndScaleFromBottomMotion.animate,
    transition: {
      ...(
        slideInAndScaleFromBottomMotion.animate as typeof slideInAndScaleFromBottomMotion
      ).transition,
      delay: withAnimationDelay ? 1 : 0.125,
    },
  },
});

export const getControlCenterContentFooterMotion = (
  withAnimationDelay: boolean,
): Variants => ({
  ...slideInAndScaleFromBottomMotion,
  animate: {
    ...slideInAndScaleFromBottomMotion.animate,
    transition: {
      ...(
        slideInAndScaleFromBottomMotion.animate as typeof slideInAndScaleFromBottomMotion
      ).transition,
      delay: withAnimationDelay ? 0.75 : 0.25,
    },
  },
});
