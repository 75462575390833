import { useI18N } from "@sundaeswap/react-hooks";
import { Image, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC } from "react";

const V3ComparisonStep7: FC = () => {
  const { t } = useI18N("joyride");
  return (
    <div className="flex flex-col gap-4">
      <Image
        className={cx(
          "mx-auto h-12 w-fit !rounded-lg",
          toolkitCx.layout.shadow.default,
        )}
        src="/static/images/guides/v3/comparison/step_4.png"
      />
      <Text tag="p" size="sm">
        {t("v3.guides.comparison.steps.step8.description")}
      </Text>
    </div>
  );
};

export default V3ComparisonStep7;
