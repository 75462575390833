import { AssetAmount } from "@sundaeswap/asset";
import { Dispatch } from "react";
import { TAssetSelectContext } from "../../../hooks/useAssetSelect";
import { IOrderAction, OrderActions } from "../../../stores/OrderContext";
import { IAssetMetaData } from "../../../types/Asset.types";

export const handleInputChange = (
  value: number | undefined,
  context: TAssetSelectContext,
  asset: AssetAmount<IAssetMetaData>,
  dispatch: Dispatch<IOrderAction>,
) => {
  if (value === asset?.value?.toNumber()) {
    return;
  }

  if (context === "given") {
    OrderActions.setGivenAmount(
      AssetAmount.fromValue(value ?? 0n, asset?.metadata),
      dispatch,
      `AssetInputs.handleInputChange.${context}`,
    );
  } else {
    OrderActions.setTakenAmount(
      AssetAmount.fromValue(value ?? 0n, asset?.metadata),
      dispatch,
      `AssetInputs.handleInputChange.${context}`,
    );
  }
};

export const normalizeInputValue = (asset: AssetAmount<IAssetMetaData>) =>
  asset?.amount === 0n ? "" : asset?.value?.toString();
