import { useEffect } from "react";
import { EGuideSearchParamEntries, paths } from "../pages/routes";
import useAppLocation from "./router/useAppLocation";
import useAppNavigate from "./router/useAppNavigate";
import { useIsInitialPageLoad } from "./useIsInitialPageLoad";

export const useMagicLinks = () => {
  const { isInitialPageLoad } = useIsInitialPageLoad();
  const location = useAppLocation();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (isInitialPageLoad) {
      switch (location.pathname) {
        case paths.magicLinks.guides.tasteTest.explanationGuide: {
          navigate({
            to: paths.tasteTest.root,
            search: {
              guide: EGuideSearchParamEntries.tasteTestExplanationGuide,
            },
          });
          break;
        }
        case paths.magicLinks.guides.tasteTest.interactiveGuide: {
          navigate({
            to: `${paths.tasteTest.root}/clarity`,
            search: {
              guide: EGuideSearchParamEntries.tasteTestInteractiveGuide,
            },
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [isInitialPageLoad]);
};
