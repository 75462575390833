import Step1 from "./steps/Step1";
import Step1Title from "./titles/Step1Title";

export const OrderTypeSteps = {
  Step1,
};

export const OrderTypeTitles = {
  Step1Title,
};
