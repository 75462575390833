import { SundaeSwapLogo, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC } from "react";
import { getControlCenterContentFooterMotion } from "../../../motions/control-center.motions";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import Motion from "../../Motion";

interface IControlCenterContentFooterProps {
  title: string;
}

const ControlCenterContentFooter: FC<IControlCenterContentFooterProps> = ({
  title,
}) => {
  const {
    state: {
      localState: { withAnimationDelay },
    },
  } = useControlCenterContext();

  return (
    <Motion
      animationKey="ControlCenterGuideList--footer"
      className={cx(
        "border-t border-t-gray-300 dark:border-t-gray-600",
        "!mt-auto flex items-center justify-between pt-4",
        toolkitCx.layout.spacing.horizontal.md,
      )}
      variants={getControlCenterContentFooterMotion(withAnimationDelay)}
    >
      <SundaeSwapLogo className="h-6 w-6" />
      <Text tag="p" size="xs" variant="muted">
        {title}
      </Text>
    </Motion>
  );
};

export default ControlCenterContentFooter;
