import { IRouterNextOptions } from "../hooks/router/useAppNavigate";
import { TSearchParams, paths } from "../pages/routes";

export const getChildRoutePath = (
  route: { root: string; children: { [key: string]: string } },
  key: string,
) => {
  return `${route.root}${route.children[key]}`;
};

/**
 * This is a collection of useful and reusable routing recipes.
 */
export const locationFactories = {
  tasteTest: {
    detail: (slug: string): IRouterNextOptions => ({
      to: `${paths.tasteTest.root}/${slug}`,
    }),
  },
  exchange: {
    limit: ({
      given: activeAssetA,
      taken: activeAssetB,
    }: TSearchParams): IRouterNextOptions => ({
      to: paths.exchange,
      search: (old) => ({
        ...old,
        ...(activeAssetA && { given: activeAssetA }),
        ...(activeAssetB && { taken: activeAssetB }),
      }),
    }),
    swap: ({
      given: activeAssetA,
      taken: activeAssetB,
    }: TSearchParams): IRouterNextOptions => ({
      to: paths.exchange,
      search: (old) => ({
        ...old,
        ...(activeAssetA && { given: activeAssetA }),
        ...(activeAssetB && { taken: activeAssetB }),
      }),
    }),
    flipInputOutput: ({
      given: activeAssetA,
      taken: activeAssetB,
    }: TSearchParams): IRouterNextOptions => ({
      to: paths.exchange,
      search: (old) => ({
        ...old,
        given: activeAssetA,
        taken: activeAssetB,
      }),
    }),
  },
  liquidity: {
    zap: ({ zapAssetId }: TSearchParams): IRouterNextOptions => ({
      to: `${paths.liquidity.root}/${paths.liquidity.children.zap}`,
      search: (old) => ({
        ...old,
        given: zapAssetId,
      }),
    }),
  },
};

/**
 * Parses a query string into an object, converting numeric values to numbers.
 * This function uses a regular expression to extract the query string from a full URL
 * or directly parses it if the input is already a query string.
 *
 * @param {string} url - The full URL or query string to parse.
 * @returns {Record<string, unknown>} An object representation of the query string.
 */
export const parseQueryString = (url: string): Record<string, unknown> => {
  // Regular expression to extract the query string part from a URL
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const obj: Record<string, unknown> = {};
  let match;

  while ((match = regex.exec(url)) !== null) {
    // Convert numeric values to numbers
    obj[match[1]] = isNaN(Number(match[2])) ? match[2] : Number(match[2]);
  }

  return obj;
};

/**
 * Compares a query string with a search object to check if they represent the same parameters.
 *
 * @param {string} queryString - The query string to compare.
 * @param {TSearchParams} searchObject - The search object to compare against.
 * @returns {boolean} True if the query string and the search object match, false otherwise.
 */
export const compareSearchObjects = (
  customSearchQuery: TSearchParams,
  searchObject: TSearchParams,
) => {
  for (const key in searchObject) {
    if (searchObject.hasOwnProperty(key)) {
      const typedKey = key as keyof typeof searchObject;
      if (
        !customSearchQuery[typedKey] ||
        customSearchQuery[typedKey] !== searchObject[typedKey]
      ) {
        return false;
      }
    }
  }
  return true;
};

export const decodeSearchParams = (searchParams: URLSearchParams) => {
  return [...searchParams.entries()].reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: val,
    };
  }, {});
};

export const parseSearchString = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const result: { [key: string]: string } = {};
  for (const [key, value] of searchParams) {
    result[key] = decodeURIComponent(value);
  }
  return result;
};
