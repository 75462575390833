import { useWalletObserver } from "@sundaeswap/wallet-lite";
import { useEffect } from "react";

import { HOTJAR_ID } from "../../../constants/SundaeSwap.constants";
import { useProfileAppContext } from "../../../stores";

export const useHotjar = () => {
  const {
    state: { hotjarEnabled },
  } = useProfileAppContext();
  const { mainAddress } = useWalletObserver();

  useEffect(() => {
    if (window.location.protocol === "http:") {
      return;
    }

    if (hotjarEnabled) {
      import("react-hotjar").then(({ hotjar }) => {
        hotjar.initialize(HOTJAR_ID, 6);
      });
    }
  }, [hotjarEnabled]);

  useEffect(() => {
    if (window.location.protocol === "http:") {
      return;
    }

    import("react-hotjar").then(({ hotjar }) => {
      if (hotjar.initialized() && mainAddress) {
        hotjar.identify(mainAddress, {});
      }
    });
  }, [mainAddress]);
};
