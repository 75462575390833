import { SundaeUtils } from "@sundaeswap/core/utilities";
import { useAssetsWithBalance } from "../../../hooks/useAssetsWithBalance";
import { useOrderContext } from "../context";

export const useGivenBalance = () => {
  const {
    state: {
      assets: { given },
    },
  } = useOrderContext();
  const { assetsWithBalance } = useAssetsWithBalance();
  return (
    given?.metadata &&
    assetsWithBalance.find(({ metadata }) =>
      SundaeUtils.isAssetIdsEqual(metadata.assetId, given.metadata.assetId),
    )
  );
};
