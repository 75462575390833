import { InfoCircledIcon, Text, Tooltip } from "@sundaeswap/ui-toolkit";
import { memo } from "react";
import { Trans } from "react-i18next";
import ErrorReportingTooltip from "./ErrorReportingTooltip.trans";

const ErrorFallbackMessageAutoSend = memo(() => {
  return (
    <Text tag="div" className="grid gap-2">
      <Trans i18nKey="message" context="autoSend" ns="error-fallback">
        <Text tag="div">A log of this error has been created.</Text>
        <Tooltip
          trigger={
            <div>
              <Text tag="span">
                We have created an error report that has <b>automatically</b>{" "}
                been sent to us to improve the platform.
                <InfoCircledIcon className="ml-1 inline" />
              </Text>
            </div>
          }
          tooltipLabel={<ErrorReportingTooltip />}
        />
      </Trans>
    </Text>
  );
});

export default ErrorFallbackMessageAutoSend;
