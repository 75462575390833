import {
  ProvideLiquidityGuideSteps,
  ProvideLiquidityGuideTitles,
} from "./provide";
import {
  WithdrawLiquidityGuideSteps,
  WithdrawLiquidityGuideTitles,
} from "./withdraw";

export const Liquidity = {
  ProvideLiquidity: {
    Steps: ProvideLiquidityGuideSteps,
    Titles: ProvideLiquidityGuideTitles,
  },
  WithdrawLiquidity: {
    Steps: WithdrawLiquidityGuideSteps,
    Titles: WithdrawLiquidityGuideTitles,
  },
};
