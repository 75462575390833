import { Text } from "@sundaeswap/ui-toolkit";
import { FC } from "react";
import { Trans } from "react-i18next";

const V3ComparisonStep1: FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <Trans
        i18nKey="v3.guides.comparison.steps.step1.description"
        ns="joyride"
      >
        <Text tag="p" size="sm">
          Last year, we overhauled the whole SundaeSwap interface to bring you
          Sundae v2. Now, we present Sundae v3, a ground-up rewrite of the smart
          contracts.
        </Text>
        <Text tag="p" size="sm">
          These new contracts represent 3 years worth of innovation and
          optimization from the Sundae Labs team. Lets give you a tour of what
          that means for you!
        </Text>
      </Trans>
    </div>
  );
};

export default V3ComparisonStep1;
