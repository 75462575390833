import { ExternalLink, Text } from "@sundaeswap/ui-toolkit";
import { FC } from "react";
import { Trans } from "react-i18next";
import { externalRoutes } from "../../../../../../pages/routes";

const V3ComparisonStep9: FC = () => {
  return (
    <Text tag="p" size="sm">
      <Trans
        i18nKey="v3.guides.comparison.steps.step9.description"
        ns="joyride"
      >
        There are lots more features, particularly for protocol builders, which
        you can find described in our{" "}
        <ExternalLink
          href={externalRoutes.v3Whitepaper}
          size="sm"
          variant="info"
        >
          whitepaper
        </ExternalLink>
        !
      </Trans>
    </Text>
  );
};

export default V3ComparisonStep9;
