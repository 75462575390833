import cx from "classnames";
import { MotionProps, motion } from "framer-motion";
import { ElementType, PropsWithChildren, forwardRef, useMemo } from "react";
import { getControlCenterContentElementsMotion } from "../../../motions/control-center.motions";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";

interface IControlCenterContentNodeProps
  extends PropsWithChildren<MotionProps> {
  className?: string;
  tag: ElementType;
}

export const ControlCenterContentNode = forwardRef<
  HTMLElement,
  IControlCenterContentNodeProps
>(({ children, className, tag, ...rest }, forwardedRef) => {
  const {
    state: {
      localState: { withAnimationDelay },
    },
  } = useControlCenterContext();

  const MemoizedMotionNode = useMemo(() => motion(tag), [tag]);

  return (
    <MemoizedMotionNode
      {...rest}
      ref={forwardedRef}
      key="ControlCenterContentNode"
      className={cx(
        "max-h-[350px] overflow-y-scroll xl:max-h-[400px]",
        className,
      )}
      variants={getControlCenterContentElementsMotion(withAnimationDelay)}
    >
      {children}
    </MemoizedMotionNode>
  );
});
