import { useI18N } from "@sundaeswap/react-hooks";
import {
  DiscordLogoIcon,
  DotsHorizontalIcon,
  ExternalLink,
  HeaderButton,
  InfoCircledIcon,
  LockClosedIcon,
  MediumLogo,
  Pencil2Icon,
  Popover,
  QuestionMarkCircledIcon,
  ScrollArea,
  TwitterLogoIcon,
} from "@sundaeswap/ui-toolkit";
import React from "react";
import { externalRoutes } from "../../pages/routes";

export interface IQuickMenuPopoverProps {
  className?: string;
  openFrom?: "top" | "left";
}

const QuickMenuPopover: React.FC<IQuickMenuPopoverProps> = ({
  className,
  openFrom = "left",
}) => {
  const { t } = useI18N("header");

  return (
    <Popover
      className={className}
      primitiveProps={{
        content: { side: openFrom },
      }}
      trigger={
        <HeaderButton>
          <DotsHorizontalIcon />
        </HeaderButton>
      }
      withCloseIcon={false}
    >
      <ScrollArea primitiveProps={{ viewport: { className: "max-h-screen" } }}>
        <div className="grid gap-4 p-4 xl:gap-2">
          {[
            {
              label: t("header.buttons.quickMenu.about"),
              icon: <InfoCircledIcon />,
              link: externalRoutes.about,
            },
            {
              label: t("header.buttons.quickMenu.faq"),
              icon: <QuestionMarkCircledIcon />,
              link: externalRoutes.faq,
            },
            {
              label: t("header.buttons.quickMenu.governance"),
              icon: <Pencil2Icon />,
              link: externalRoutes.governance,
            },
            {
              label: t("header.buttons.quickMenu.discord"),
              icon: <DiscordLogoIcon />,
              link: externalRoutes.discord,
            },
            {
              label: t("header.buttons.quickMenu.twitter"),
              icon: <TwitterLogoIcon />,
              link: externalRoutes.twitter,
            },
            {
              label: t("header.buttons.quickMenu.medium"),
              icon: <MediumLogo />,
              link: externalRoutes.medium,
            },
            {
              label: t("header.buttons.quickMenu.legal"),
              icon: <LockClosedIcon />,
              link: externalRoutes.privacy,
            },
          ].map(({ label, link, icon }) => (
            <ExternalLink
              key={label}
              className="flex items-center justify-between"
              href={link}
              size="sm"
            >
              {label}
              {icon}
            </ExternalLink>
          ))}
        </div>
      </ScrollArea>
    </Popover>
  );
};

export default QuickMenuPopover;
