import { ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { EventType, useI18N } from "@sundaeswap/react-hooks";
import {
  ConditionalWrapper,
  Percentage,
  Text,
  toolkitCx,
} from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { motion, useInView } from "framer-motion";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useEventTracking } from "../../../hooks/useEventTracking";
import { slideInAndScaleFromBottomMotion } from "../../../motions/default.motions";
import { useProfileAppContext } from "../../../stores";
import {
  LocalControlCenterActions,
  useControlCenterContext,
} from "../../../stores/ControlCenterContext";
import { getEnv } from "../../../utils/network.utils";

type TChapterProps = {
  chapterId?: string;
  guideId?: never;
  type: "chapter";
};

type TGuideProps = {
  chapterId?: never;
  guideId?: string;
  type: "guide";
};

type TControlCenterGuideItemProps = {
  description?: string;
  inViewRoot?: RefObject<Element>;
  isCompleted: boolean;
  onClick: (id?: string) => void;
  percentage: number;
  progressBarTranslateX: number;
  rank: number;
  title: string;
} & (TChapterProps | TGuideProps);

const ControlCenterGuideItem: FC<TControlCenterGuideItemProps> = ({
  chapterId,
  description,
  guideId,
  inViewRoot,
  isCompleted,
  onClick,
  percentage,
  progressBarTranslateX,
  rank,
  title,
  type,
}) => {
  const handleEventTracking = useEventTracking();
  const {
    dispatchers: { localDispatch },
    state: {
      localState: {
        guides: { lastClosedGuideItemId },
      },
    },
  } = useControlCenterContext();
  const {
    state: { prefersReducedMotion },
  } = useProfileAppContext();
  const { currentLocale } = useI18N();
  const scrollTimer = useRef<number>();
  const flashTimer = useRef<number>();
  const guideItemRef = useRef<HTMLLIElement>(null);
  const isRefInView = useInView(guideItemRef, {
    once: true,
    ...(inViewRoot && {
      margin: "-65px 0px -65px 0px",
      root: inViewRoot,
    }),
  });
  const [showFlashAnimation, setShowFlashAnimation] = useState(false);
  const isChapterType = type === "chapter";

  /**
   * Handles the click event on the guide item.
   */
  const handleOnClick = useCallback(() => {
    handleEventTracking({
      eventType: EventType.Click,
      source: "ControlCenterGuideItem",
      value: `${isChapterType ? "chapter" : "guide"}_${isChapterType ? chapterId : guideId}`,
      network: getEnv(),
    });
    onClick(guideId);
  }, [chapterId, guideId, isChapterType, onClick, handleEventTracking]);

  /**
   * Renders the arrow node.
   */
  const renderMemoizedArrowNode = useCallback(
    () => (
      <ArrowRightIcon
        className={cx(
          "h-5 w-5 -translate-x-2 opacity-0",
          "group-hover:translate-x-0 group-hover:opacity-100",
          toolkitCx.layout.transition.default,
          {
            [`${toolkitCx.layout.text.info}`]: !isCompleted,
            [`${toolkitCx.layout.text.success}`]: isCompleted,
          },
        )}
      />
    ),
    [isCompleted],
  );

  /**
   * Scrolls to the guide (chapter; if single chapter) if it's the last closed guide and it's not in view.
   * Also shows a flash animation on the guide.
   */
  useEffect(() => {
    if (
      lastClosedGuideItemId &&
      (guideId === lastClosedGuideItemId ||
        chapterId === lastClosedGuideItemId) &&
      !isRefInView
    ) {
      scrollTimer.current = setTimeout(() => {
        if (guideItemRef.current) {
          guideItemRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 1000) as unknown as number;
    } else if (
      lastClosedGuideItemId &&
      (guideId === lastClosedGuideItemId ||
        chapterId === lastClosedGuideItemId) &&
      isRefInView
    ) {
      flashTimer.current = setTimeout(() => {
        if (!showFlashAnimation) {
          setShowFlashAnimation(true);
        }
      }, 1000) as unknown as number;

      LocalControlCenterActions.guides.setLastClosedGuideItemId(
        undefined,
        localDispatch,
      );
    }
  }, [
    chapterId,
    guideId,
    isRefInView,
    lastClosedGuideItemId,
    localDispatch,
    showFlashAnimation,
  ]);

  /**
   * Clears the timers.
   */
  useEffect(() => {
    return () => {
      if (scrollTimer.current) clearTimeout(scrollTimer.current);
      if (flashTimer.current) clearTimeout(flashTimer.current);
    };
  }, []);

  return (
    <motion.li
      variants={slideInAndScaleFromBottomMotion}
      role="button"
      ref={guideItemRef}
      onClick={handleOnClick}
      className="group relative w-full cursor-pointer"
    >
      <div
        className={cx(
          "flex flex-col space-y-4",
          "relative z-10 overflow-hidden rounded-lg",
          toolkitCx.layout.background.default,
          toolkitCx.layout.background.hoverable,
          toolkitCx.layout.transition.default,
          toolkitCx.layout.shadow.default,
          toolkitCx.layout.border.default,
          toolkitCx.layout.border.weight.default,
          toolkitCx.layout.spacing.both.md,
          toolkitCx.recipes.scaleWhenActive,
          {
            [`${toolkitCx.recipes.blurryCorner.primary}`]:
              isChapterType && !isCompleted,
            [`${toolkitCx.recipes.blurryCorner.success}`]:
              isChapterType && isCompleted,
            "motion-safe:animate-shadow-pulse": showFlashAnimation,
          },
        )}
      >
        <div className="grid w-full gap-2">
          {!isChapterType && (
            <div className="flex items-center justify-between">
              <div
                className={cx(
                  "flex h-6 w-6 items-center justify-center rounded-full",
                  "border border-transparent",
                  {
                    "bg-success/75 group-hover:border-success group-hover:bg-success":
                      isCompleted,
                    "bg-primary/20 group-hover:border-primary group-hover:bg-primary/40":
                      !isCompleted,
                  },
                  toolkitCx.layout.transition.colors,
                )}
              >
                <Text
                  tag="span"
                  size="xs"
                  variant={isCompleted ? "forceLight" : "normal"}
                >
                  {rank}
                </Text>
              </div>

              {renderMemoizedArrowNode()}
            </div>
          )}

          <div className="grid gap-1">
            <ConditionalWrapper
              condition={isChapterType}
              wrapper={(children) => (
                <div className="flex items-center justify-between">
                  {children}
                  {renderMemoizedArrowNode()}
                </div>
              )}
            >
              <Text
                className={cx({
                  "max-w-[90%]": isChapterType,
                })}
                tag="h2"
                size={isChapterType ? "lg" : "base"}
                weight="medium"
              >
                {title}
              </Text>
            </ConditionalWrapper>
            {description && (
              <Text tag="p" variant="muted" size="xs">
                {description}
              </Text>
            )}
          </div>
        </div>

        <div className="flex w-full items-center justify-between gap-4">
          <div className="relative h-1 w-full overflow-hidden rounded-full bg-primary/20">
            <div
              className={cx(
                "left:0 absolut top-0 z-10 h-full w-full rounded-full",
                {
                  "bg-primary": !isCompleted,
                  "bg-success": isCompleted,
                },
                toolkitCx.layout.transition.transform,
              )}
              style={{
                transform: `translateX(${progressBarTranslateX}%)`,
              }}
            />
          </div>

          <Text tag="span" size="xs" variant="muted">
            {percentage === 1 ? (
              <CheckCircleIcon className="h-5 w-5 text-success" />
            ) : (
              <Percentage
                className="inline"
                formatOpts={{ maximumFractionDigits: 0 }}
                locale={currentLocale}
                size="xs"
                value={percentage}
                variant="current"
              />
            )}
          </Text>
        </div>
        {!prefersReducedMotion && isCompleted && (
          <Text
            tag="span"
            size="7xl"
            className={cx(
              "absolute -bottom-6 -left-4 z-10 opacity-20 group-hover:scale-110 dark:opacity-10",
              toolkitCx.layout.transition.transform,
            )}
          >
            🎉
          </Text>
        )}
      </div>
    </motion.li>
  );
};

export default ControlCenterGuideItem;
