import { ArrowPathIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { useI18N, useTheme } from "@sundaeswap/react-hooks";
import {
  Balance,
  Header as SundaeHeader,
  Text,
  Tooltip,
} from "@sundaeswap/ui-toolkit";
import { RenderWalletState } from "@sundaeswap/wallet-lite";
import isEqual from "lodash/isEqual";
import React, { useCallback } from "react";
import { useCustomCompareMemo } from "use-custom-compare";

import { getMenuItems } from "../../config/headerMenu";
import { HOMEPAGE_LINK_TITLE } from "../../constants/href.constants";
import { MIN_ADA_WALLET_BALANCE } from "../../constants/SundaeSwap.constants";
import useAppLocation from "../../hooks/router/useAppLocation";
import useAppNavigate from "../../hooks/router/useAppNavigate";
import useAppSearch from "../../hooks/router/useAppSearch";
import { useIsWalletConnected } from "../../hooks/useIsWalletConnected";
import { useNewsBannerHeight } from "../../hooks/useNewsBannerHeight";
import { PortfolioTab, paths } from "../../pages/routes";
import { useProfileAppContext } from "../../stores";
import { featureIsEnabled } from "../../utils/features.utils";
import AdaPrice from "../AdaPrice";
import AvailableADABalanceTooltip from "../AvailableADABalanceTooltip";
import Link from "../Link";
import NewsPopover from "../NewsPopover";
import QuickMenuPopover from "../QuickMenuPopover";
import WalletButton from "../WalletButton";

export const Header: React.FC = () => {
  const { currentLocale, t } = useI18N("header");
  const navigate = useAppNavigate();
  const { pathname } = useAppLocation();
  const isWalletConnected = useIsWalletConnected();
  const { theme, toggleTheme } = useTheme();
  const { newsBannerHeight } = useNewsBannerHeight();
  const search = useAppSearch();
  const {
    state: { privacyEnabled },
  } = useProfileAppContext();

  const menuItems = useCustomCompareMemo(
    () => getMenuItems({ isWalletConnected, pathname, search, t }),
    [isWalletConnected, t, navigate, pathname],
    isEqual,
  );

  const allowTracking = window.location.hostname !== "localhost";

  const handleNavigateToOrders = useCallback(
    () => navigate({ to: paths.root, search: { tab: PortfolioTab.orders } }),
    [navigate],
  );

  return (
    <SundaeHeader
      allowTracking={allowTracking}
      countersApiOptions={{ app: "sundaeswap", company: "sundaelabs" }}
      isConnected={isWalletConnected}
      openOffCanvasLabel={t("header.menu.openOffCanvasLabel")}
      closeOffCanvasLabel={t("header.menu.closeOffCanvasLabel")}
      navigationItems={menuItems}
      renderLogo={({ SundaeSwapLogo }) => (
        <Link
          className="flex items-center"
          title={HOMEPAGE_LINK_TITLE}
          hoverable={false}
          to={paths.root}
          variant="info"
        >
          <SundaeSwapLogo className="h-8 w-8" />
        </Link>
      )}
      renderTertiaryNav={({ HeaderButton }) => (
        <>
          {!featureIsEnabled("controlCenter") && <NewsPopover />}
          {isWalletConnected && (
            <Tooltip
              trigger={
                <HeaderButton onClick={handleNavigateToOrders}>
                  <div>
                    <QueueListIcon className="h-4 w-4" />
                  </div>
                </HeaderButton>
              }
              tooltipLabel={t("header.buttons.orders")}
            />
          )}
          <QuickMenuPopover />
        </>
      )}
      renderWalletButton={() => (
        <div className="flex items-center space-x-4">
          <AdaPrice />
          <RenderWalletState
            loader={
              <>
                <ArrowPathIcon className="h-4 w-4 animate-spin" />
                {t("balance", { ns: "glossary", context: "loading" })}
              </>
            }
            render={({
              adaBalance,
              switching,
              connectingWallet,
              ready,
              willAutoConnect,
            }) => {
              return ready || willAutoConnect ? (
                <Text
                  className="flex items-center gap-2"
                  tag="span"
                  size="sm"
                  variant="muted"
                >
                  {switching || connectingWallet ? (
                    <>
                      <ArrowPathIcon className="h-4 w-4 animate-spin" />
                      {t("balance", { ns: "glossary", context: "loading" })}
                    </>
                  ) : (
                    <>
                      {t("balance", { ns: "glossary", context: "available" })}:
                      <AvailableADABalanceTooltip>
                        <Balance
                          locale={currentLocale}
                          privacy={privacyEnabled}
                          size="sm"
                          weight="bold"
                          value={
                            adaBalance.amount > MIN_ADA_WALLET_BALANCE.amount
                              ? adaBalance.sub(MIN_ADA_WALLET_BALANCE)
                              : adaBalance
                          }
                        />
                      </AvailableADABalanceTooltip>
                    </>
                  )}
                </Text>
              ) : null;
            }}
          />
          <WalletButton />
        </div>
      )}
      showThemeToggle
      style={{
        marginTop: `${newsBannerHeight}px`,
      }}
      theme={theme}
      toggleTheme={toggleTheme}
    />
  );
};
