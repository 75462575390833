import { useI18N } from "@sundaeswap/react-hooks";
import { Text } from "@sundaeswap/ui-toolkit";
import { FC } from "react";

const V3ComparisonStep8: FC = () => {
  const { t } = useI18N("joyride");
  return (
    <>
      <div
        className="absolute left-0 top-0 z-[-1] h-full w-full bg-cover bg-no-repeat opacity-10"
        style={{
          backgroundImage: `url("/static/images/v3/improved_table_filtering.png")`,
        }}
      />
      {
        <Text tag="p" size="sm">
          {t("v3.guides.comparison.steps.step7.description")}
        </Text>
      }
    </>
  );
};

export default V3ComparisonStep8;
