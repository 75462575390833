import { CandlestickData } from "lightweight-charts";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { EAppActions, IAppAction, IAppState, TRootDialogKeys } from "./types";

export const appReducer = (
  prevState: IAppState,
  action: IAppAction,
): IAppState => {
  switch (action.type) {
    /* -------------------------------------------------------------------------------------------------
     * App
     * -----------------------------------------------------------------------------------------------*/
    case EAppActions.SET_OFFCANVAS_OPEN:
      return {
        ...prevState,
        offCanvasOpen: action.payload.offCanvasOpen,
      };
    case EAppActions.SET_POOLS_SORT_BY:
      return {
        ...prevState,
        sortPoolsBy: action.payload.sortPoolsBy,
      };
    case EAppActions.SET_POOLS_SORTING_DIRECTION:
      return {
        ...prevState,
        sortPoolsSortingDirection: action.payload.sortPoolsSortingDirection,
      };
    case EAppActions.SET_ACTIVE_ZAP_ASSET:
      return {
        ...prevState,
        activeZapAsset: action.payload.activeZapAsset,
      };
    case EAppActions.SET_ACTIVE_ZAP_POOL:
      return {
        ...prevState,
        activeZapPool: action.payload.activeZapPool,
      };
    case EAppActions.SET_SDK:
      return {
        ...prevState,
        sdk: action.payload.sdk,
      };
    case EAppActions.SET_V3_SCOOPER_FEE:
      return {
        ...prevState,
        V3MaxScooperFee: action.payload.V3MaxScooperFee,
      };
    case EAppActions.SET_LATEST_TX_HASH:
      return {
        ...prevState,
        latestTxHash: action.payload.latestTxHash,
      };
    case EAppActions.SET_TRADE_ON_CHART_HOVER_PARAMS: {
      const params = action.payload.tradeOnChartParams;
      const entries = [...(params.seriesData.entries() ?? [])];
      const marketEntry = entries?.[0]?.[1];
      const closeValue = (marketEntry as CandlestickData)?.close;
      return {
        ...prevState,
        tradeOnChartHoverCardParams: {
          params,
          price: closeValue,
        },
      };
    }
    case EAppActions.SET_TRADE_ON_CHART_DIALOG_PARAMS: {
      const params = action.payload.tradeOnChartParams;
      const entries = [...(params.seriesData.entries() ?? [])];
      const marketEntry = entries?.[0]?.[1];
      const closeValue = (marketEntry as CandlestickData)?.close;
      return {
        ...prevState,
        tradeOnChartDialogParams: {
          params,
          price: closeValue,
        },
      };
    }
    case EAppActions.SET_DIALOG_OPEN: {
      const { dialog, isOpen, context } = action.payload;
      const newState = cloneDeep(prevState); // Clone the state to avoid direct mutation

      if (dialog.includes(".")) {
        // Handle nested dialog updates
        set(newState.dialogs, dialog, { isOpen, context });
      } else {
        // Handle top-level dialog updates
        newState.dialogs[dialog as TRootDialogKeys] = { isOpen, context };
      }

      return newState;
    }
    case EAppActions.SET_COLLAPSIBLE_OPEN: {
      const { collapsible, isOpen } = action.payload;
      const newState = cloneDeep(prevState); // Clone the state to avoid direct mutation

      newState.collapsibles[collapsible as keyof IAppState["collapsibles"]] = {
        isOpen,
      };

      return newState;
    }
    case EAppActions.SET_TASTE_TEST_ACTIVE_SCROLL_INDEX:
      return {
        ...prevState,
        tasteTestActiveScrollIndex: action.payload.tasteTestActiveScrollIndex,
      };
    case EAppActions.SET_DISPLAYED_ORDER_ROUTE_INDEX:
      return {
        ...prevState,
        displayedOrderRouteIndex: action.payload.displayedOrderRouteIndex,
      };
    default:
      throw new Error("No action was found!");
  }
};
