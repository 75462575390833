// TODO: TEMPORARY ASSETS UNTIL BACKEND HAS FIXED THE POSITION ENDPOINT AND RETURNS EQUAL DATA FOR ALL QUERIES
export const TEMPORARY_Q_ASSETS = [
  {
    assetId: "6df63e2fdde8b2c3b3396265b0cc824aa4fb999396b1c154280f6b0c.",
    policyId: "6df63e2fdde8b2c3b3396265b0cc824aa4fb999396b1c154280f6b0c",
    assetName: null,
    decimals: 6,
    logo: "https://cdn.sundaeswap.finance/logos/606/6066495161619780364.png",
    ticker: "qDJED",
    dateListed: "2024-01-09 09:54:23.336217743 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
  {
    assetId: "7a4d45e6b4e6835c4cea3968f291fab3704949cfd2f2dc1997c4eeec.",
    policyId: "7a4d45e6b4e6835c4cea3968f291fab3704949cfd2f2dc1997c4eeec",
    assetName: null,
    decimals: 8,
    logo: "https://cdn.sundaeswap.finance/logos/513/5132182024856679928.png",
    ticker: "qUSDT",
    dateListed: "2024-01-09 11:04:08.585506373 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
  {
    assetId: "8996bb07509defe0be6f0c39845a736b266c85a70d87ebfb66454a78.",
    policyId: "8996bb07509defe0be6f0c39845a736b266c85a70d87ebfb66454a78",
    assetName: null,
    decimals: 6,
    logo: "https://cdn.sundaeswap.finance/logos/110/11083218441237171962.png",
    ticker: "qDAI",
    dateListed: "2024-01-09 09:54:29.796506508 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
  {
    assetId: "a04ce7a52545e5e33c2867e148898d9e667a69602285f6a1298f9d68.",
    policyId: "a04ce7a52545e5e33c2867e148898d9e667a69602285f6a1298f9d68",
    assetName: null,
    decimals: 6,
    logo: "https://cdn.sundaeswap.finance/logos/118/11861503433742785805.png",
    ticker: "qAda",
    dateListed: "2024-01-12 09:56:20.192849667 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
  {
    assetId: "a4430a085f45bca6399bec6bd7514eb8c2fce1ed75c7554739cfc32b.",
    policyId: "a4430a085f45bca6399bec6bd7514eb8c2fce1ed75c7554739cfc32b",
    assetName: null,
    decimals: 6,
    logo: "https://cdn.sundaeswap.finance/logos/134/13442712731188571057.png",
    ticker: "qMIN",
    dateListed: "2024-01-09 09:54:32.668035031 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
  {
    assetId: "aebcb6eaba17dea962008a9d693e39a3160b02b5b89b1c83e537c599.",
    policyId: "aebcb6eaba17dea962008a9d693e39a3160b02b5b89b1c83e537c599",
    assetName: null,
    decimals: 8,
    logo: "https://cdn.sundaeswap.finance/logos/970/9706141504635062805.png",
    ticker: "qUSDC",
    dateListed: "2024-01-09 09:54:33.995063882 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
  {
    assetId: "d15c36d6dec655677acb3318294f116ce01d8d9def3cc54cdd78909b.",
    policyId: "d15c36d6dec655677acb3318294f116ce01d8d9def3cc54cdd78909b",
    assetName: null,
    decimals: 6,
    logo: "https://cdn.sundaeswap.finance/logos/164/16481387629691259750.png",
    ticker: "qiUSD",
    dateListed: "2024-01-09 09:54:42.063116513 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
  {
    assetId: "e1ff3557106fe13042ba0f772af6a2e43903ccfaaf03295048882c93.",
    policyId: "e1ff3557106fe13042ba0f772af6a2e43903ccfaaf03295048882c93",
    assetName: null,
    decimals: 6,
    logo: "https://cdn.sundaeswap.finance/logos/104/10454413768858396949.png",
    ticker: "qSHEN",
    dateListed: "2024-01-09 10:56:02.133543636 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
  {
    assetId: "f2636c8280e49e7ed7a7b1151341130989631b45a08d1b320f016981.",
    policyId: "f2636c8280e49e7ed7a7b1151341130989631b45a08d1b320f016981",
    assetName: null,
    decimals: 6,
    logo: "https://cdn.sundaeswap.finance/logos/773/7736600365043610642.png",
    ticker: "qWMT",
    dateListed: "2024-01-27 05:54:53.921377522 +0000 UTC",
    marketCap: null,
    sources: ["CardanoTokenRegistry"],
    priceToday: null,
    priceYesterday: null,
    priceDiff24H: null,
    poolId: "",
    slotAdded: 0,
    totalSupply: null,
    tvl: null,
    tvlDiff24H: null,
    tvlToday: null,
    tvlYesterday: null,
    volume24H: null,
    warning: null,
  },
];
