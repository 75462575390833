import merge from "lodash/merge";
import { FC, PropsWithChildren, useMemo, useReducer } from "react";

import { defaultPortfolioState, PortfolioContext } from "./context";
import { usePortfolioContextEffects } from "./hooks/usePortfolioContextEffects";
import { portfolioReducer } from "./reducer";

const PortfolioContextContextEffects: FC<PropsWithChildren> = ({
  children,
}) => {
  // Effects that apply only to the nearest PortfolioContext.
  usePortfolioContextEffects();

  return <>{children}</>;
};

export const PortfolioContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    portfolioReducer,
    merge({}, defaultPortfolioState),
  );
  const memoizedDispatch = useMemo(() => dispatch, []);

  return (
    <PortfolioContext.Provider value={{ state, dispatch: memoizedDispatch }}>
      <PortfolioContextContextEffects>
        {children}
      </PortfolioContextContextEffects>
    </PortfolioContext.Provider>
  );
};
