import type {
  FilledContentRelationshipField,
  FilledLinkToWebField,
  LinkField,
} from "@prismicio/client";

/**
 * Resolves the type of Prismic link and returns a URL based on the link type.
 *
 * @param {LinkField} link - The Prismic link to be resolved.
 *
 * @returns {string | undefined} The resolved URL if the link can be resolved, undefined otherwise.
 */
export const prismicLinkResolver = (link: LinkField) => {
  let typedLink;
  if ((link as FilledContentRelationshipField).uid) {
    typedLink = link as FilledContentRelationshipField;
    if (typedLink.type === "blog_post") {
      return `https://sundae.fi/posts/${typedLink.uid}`;
    }
  }

  if ((link as FilledLinkToWebField).url) {
    typedLink = link as FilledLinkToWebField;
    return typedLink.url;
  }
};
