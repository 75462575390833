import { lazyInit } from "../../utils/lazyInit";
import * as yieldFarmingV2 from "../generated/yieldFarmingV2.sdk";

export const getYieldFarmingV2GraphqlClient = lazyInit(() =>
  import("graphql-request").then(
    ({ GraphQLClient }) =>
      new GraphQLClient(window.__APP_CONFIG.apiUrls.farmingV2),
  ),
);

export const getYieldFarmingV2Sdk = lazyInit(() =>
  getYieldFarmingV2GraphqlClient().then(yieldFarmingV2.getSdk),
);
