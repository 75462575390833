import React, { Suspense, lazy } from "react";

import LoadingComponent from "../../components/LoadingComponent";
import { useIsWalletConnected } from "../../hooks/useIsWalletConnected";
import { PortfolioContextProvider } from "../../stores/PortfolioContext/components";

const LazyPortfolio = lazy(
  () => import(/* webpackChunkName: "Portfolio" */ "./Portfolio"),
);
const LazyV3Home = lazy(
  () => import(/* webpackChunkName: "V3Home" */ "./HomeV3"),
);

const Home: React.FC = () => {
  const isWalletConnected = useIsWalletConnected();

  if (isWalletConnected)
    return (
      <Suspense fallback={<LoadingComponent className="min-h-[50vh]" />}>
        <PortfolioContextProvider>
          <LazyPortfolio />
        </PortfolioContextProvider>
      </Suspense>
    );

  return (
    <Suspense fallback={<LoadingComponent />}>
      <LazyV3Home />
    </Suspense>
  );
};

export default Home;
