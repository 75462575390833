import { PrismicProvider } from "@prismicio/react";
import {
  ConditionalWrapper,
  ExternalLink,
  Image,
  Text,
  Toast,
  TooltipProvider,
} from "@sundaeswap/ui-toolkit";
import { FC, ReactNode, lazy } from "react";

import { MigrateLiquidityContextProvider } from "./components/Dialogs/MigrateLiquidityDialog/context";
import { SUNDAE_GENERAL_TOAST_ID } from "./constants/toast.constants";
import { prismicClient } from "./prismic/prismic.config";
import { AppContextProvider } from "./stores/AppContext/context";
import { OrderContextProvider } from "./stores/OrderContext";
import { ProfileAppContextProvider } from "./stores/ProfileAppContext/components";
import { featureIsEnabled } from "./utils/features.utils";
import { prismicLinkResolver } from "./utils/prismic.utils";

const LazyControlCenterContextProvider = lazy(() =>
  import("./stores/ControlCenterContext").then(
    ({ ControlCenterContextProvider }) => ({
      default: ControlCenterContextProvider,
    }),
  ),
);
const LazyNewsContextProvider = lazy(() =>
  import("./stores/NewsContext").then(({ NewsContextProvider }) => ({
    default: NewsContextProvider,
  })),
);

export type TProvidersProps = {
  children: ReactNode;
};

const LazyReactQueryDevTools =
  process.env.NODE_ENV === "development"
    ? lazy(() =>
        import("@tanstack/react-query-devtools").then((m) => ({
          default: m.ReactQueryDevtools,
        })),
      )
    : () => null;

export const Providers: FC<TProvidersProps> = ({ children }) => {
  return (
    <TooltipProvider delayDuration={150}>
      <ProfileAppContextProvider>
        <AppContextProvider>
          <OrderContextProvider>
            <LazyNewsContextProvider>
              <MigrateLiquidityContextProvider>
                <PrismicProvider
                  client={prismicClient}
                  richTextComponents={{
                    heading1: ({ children }) => (
                      <Text tag="h1" size="xl">
                        {children}
                      </Text>
                    ),

                    heading2: ({ children }) => (
                      <Text tag="h2" size="lg">
                        {children}
                      </Text>
                    ),
                    heading3: ({ children }) => (
                      <Text tag="h3">{children}</Text>
                    ),
                    heading4: ({ children }) => (
                      <Text tag="h4">{children}</Text>
                    ),
                    paragraph: ({ children }) => (
                      <Text tag="p" size="sm">
                        {children}
                      </Text>
                    ),
                    hyperlink: ({ node, text }) => {
                      return (
                        <ExternalLink
                          arrow="top-right"
                          size="sm"
                          variant="primary"
                          href={prismicLinkResolver(node.data)}
                        >
                          {text}
                        </ExternalLink>
                      );
                    },
                    image: ({ node }) => (
                      <Image
                        className="my-2"
                        src={node.url}
                        alt={node.alt ?? "Sundae Labs"}
                        width={node.dimensions.width}
                        height={node.dimensions.height}
                      />
                    ),
                  }}
                >
                  <ConditionalWrapper
                    condition={featureIsEnabled("controlCenter")}
                    wrapper={(children) => (
                      <LazyControlCenterContextProvider>
                        {children}
                      </LazyControlCenterContextProvider>
                    )}
                  >
                    {children}
                    <Toast id={SUNDAE_GENERAL_TOAST_ID} />
                    <LazyReactQueryDevTools />
                  </ConditionalWrapper>
                </PrismicProvider>
              </MigrateLiquidityContextProvider>
            </LazyNewsContextProvider>
          </OrderContextProvider>
        </AppContextProvider>
      </ProfileAppContextProvider>
    </TooltipProvider>
  );
};

export default Providers;
