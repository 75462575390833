import { AssetAmount, AssetRatio } from "@sundaeswap/asset";
import {
  EContractVersion,
  IComposedTx,
  ITxBuilderSign,
  TUTXO,
} from "@sundaeswap/core";
import { TSwapOutcome } from "@sundaeswap/cpp";
import type { Tx, TxComplete } from "lucid-cardano";
import { Dispatch, ReactNode } from "react";

import { IAssetInputProps } from "../../components/OrderComponents/AssetInputs/AssetInput";
import { DeepPartial } from "../../types/Advanced";
import { IAssetMetaData } from "../../types/Asset.types";
import { TPool } from "../../types/Pool.types";
import { TransactionErrorHandler } from "../../utils/transactions/TransactionErrorHandler.class";

export enum EOrderActions {
  RESET_ORDER_FLOW = "RESET_ORDER_FLOW",
  SET_ADA_AFTER_SWAP = "SET_ADA_AFTER_SWAP",
  SET_GIVEN_AMOUNT = "SET_GIVEN_AMOUNT",
  SET_GIVEN_UTXO_AMOUNT = "SET_GIVEN_UTXO_AMOUNT",
  SET_GIVEN_EXCEEDS_BALANCE = "SET_GIVEN_EXCEEDS_BALANCE",
  SET_ORDER_CONSENT = "SET_ORDER_CONSENT",
  SET_ORDER_FLOW_STATE = "SET_ORDER_FLOW_STATE",
  SET_ORDER_TYPE = "SET_ORDER_TYPE",
  SET_RATIO = "SET_RATIO",
  SET_TAKEN_AMOUNT = "SET_TAKEN_AMOUNT",
  SET_TAKEN_EXCEEDS_RESERVES = "SET_TAKEN_EXCEEDS_RESERVES",
  SET_TRANSACTION = "SET_TRANSACTION",
  SET_TRANSACTION_STATE = "SET_TRANSACTION_STATE",
  SWAP_ORDER_DIRECTION = "SWAP_ORDER_DIRECTION",
  SET_FETCHING_POOL = "SET_FETCHING_POOL",
  SET_PROTOCOL_VERSION = "SET_PROTOCOL_VERSION",
  SET_ORDER_ROUTE = "SET_ORDER_ROUTE",
  UNSET_ORDER_ROUTE = "UNSET_ORDER_ROUTE",
}

export enum EOrderType {
  market = "market",
  limit = "limit",
}

export enum EOrderTransactionBuilderState {
  BUILD = "BUILD",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum EOrderFlowState {
  initial = "initial",
  preview = "preview",
  previewCancelled = "previewCancelled",
  submitting = "submitting",
  submittingCancelled = "submittingCancelled",
  success = "success",
  error = "error",
  reset = "reset",
}

export interface ITransactionErrorActions {
  resolutionAction: () => void;
  resolutionButtonText: ReactNode;
}

export interface IOrderTransactionErrorState {
  actions?: ITransactionErrorActions;
  message?: ReactNode;
  handler?: TransactionErrorHandler;
}

export interface IOrderStateTransaction {
  builtTx?: IComposedTx<Tx, TxComplete>;
  completeTx?: ITxBuilderSign<TxComplete>;
  state?: EOrderTransactionBuilderState;
  error?: IOrderTransactionErrorState;
}

export interface IOrderAction {
  type: string;
  // Payload types are enforced in ./actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  // Optional dispatch key to make tracing easier.
  key?: string;
}

export interface IOrderFlowConsent {
  checked: boolean;
  i18nKey?: string;
}

export interface IOrderState {
  assets: {
    given?: AssetAmount<IAssetMetaData>;
    taken?: AssetAmount<IAssetMetaData>;
  };
  derived: {
    adaAfterSwap?: AssetAmount<IAssetMetaData>;
    givenExceedsBalance: boolean;
    swapOutcome?: TSwapOutcome[];
    takenExceedsReserves: boolean;
    transaction?: IOrderStateTransaction;
  };
  flowData: {
    flowState: EOrderFlowState;
    isUpdate: boolean;
    orderConsent: IOrderFlowConsent;
    orderType: EOrderType;
    syncSearch: boolean;
    syncPool: boolean;
    fetchingPool: boolean;
    features?: {
      allowSwapDirection?: boolean;
      assetInputsProps?: {
        given?: Omit<IAssetInputProps, "assetList">;
        taken?: Omit<IAssetInputProps, "assetList">;
      };
      allowOrderType?: boolean;
      allowAssetRatio?: boolean;
      allowLiquidityDropdown?: boolean;
    };
    contractVersion: EContractVersion; // TODO: remove
  };
  orderRoute?: TPool[];
  ratio?: AssetRatio<IAssetMetaData>;
  givenUtxoAmount?: {
    amount: AssetAmount<IAssetMetaData>;
    utxo: TUTXO;
  };
}

export interface IOrderContext {
  state: IOrderState;
  dispatch: Dispatch<IOrderAction>;
}

export interface IOrderContextProviderProps {
  seed?: DeepPartial<IOrderState>;
}
