import type { GraphQLClient } from "graphql-request";
import type * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Multisig: any;
  PoolEmissions: any;
  Rollup: any;
  StatusMap: any;
}

export interface CalculationStats {
  __typename?: "CalculationStats";
  cumulativeDelegationsByPool: Array<PerPoolMetric>;
  delegationsByPool: Array<PerPoolMetric>;
  emissionsByPool: Array<PerPoolMetric>;
  emissionsLovelaceByPool: Array<PerPoolMetric>;
  estimatedLockedLovelaceByPool: Array<PerPoolMetric>;
  lockedLPByPool: Array<PerPoolMetric>;
  numRecipients: Scalars["Int"];
  perPoolHRA: Array<PerPoolMetric>;
  program: Program;
  timestamp: Scalars["String"];
  totalDelegations: Scalars["String"];
  totalEmissions: Scalars["String"];
  totalEmissionsLovelace: Scalars["String"];
  totalEstimatedLockedLovelace: Scalars["String"];
  totalHRA: Scalars["String"];
  totalLPByPool: Array<PerPoolMetric>;
}

export interface ClaimOutput {
  __typename?: "ClaimOutput";
  transactionHex: Scalars["String"];
  witnesses: Array<Witness>;
}

export interface DateTime {
  __typename?: "DateTime";
  /** canonical date is 2006-01-02 15:04:05 MST */
  format: Scalars["String"];
  slot: Scalars["Int"];
}

export interface DateTimeFormatArgs {
  layout?: InputMaybe<Scalars["String"]>;
}

export interface Delegation {
  __typename?: "Delegation";
  pool: Maybe<Pool>;
  program: Maybe<Program>;
  weight: Scalars["Int"];
}

export interface Earning {
  __typename?: "Earning";
  byPool: Array<PerPoolValue>;
  date: DateTime;
  expiration: Maybe<DateTime>;
  owner: Scalars["Multisig"];
  ownerID: Scalars["String"];
  program: Program;
  status: EarningStatus;
  txExpiration: Maybe<DateTime>;
  txId: Maybe<Scalars["String"]>;
  txRollbackHorizon: Maybe<DateTime>;
  value: Array<Value>;
  verified: Scalars["Boolean"];
}

export interface EarningInput {
  date: Scalars["String"];
  ownerID: Scalars["String"];
  program: Scalars["String"];
}

export type EarningStatus =
  | "Claimable"
  | "Claimed"
  | "Cooldown"
  | "Expired"
  | "Pending"
  | "Unverified";

export interface Mutation {
  __typename?: "Mutation";
  claim: ClaimOutput;
}

export interface MutationClaimArgs {
  destinationAddress: Scalars["String"];
  earnings: Array<EarningInput>;
  userInputs: Array<TxIn>;
}

export interface PerPoolMetric {
  __typename?: "PerPoolMetric";
  percent: Scalars["Float"];
  pool: Pool;
  quantity: Scalars["String"];
}

export interface PerPoolValue {
  __typename?: "PerPoolValue";
  pool: Pool;
  value: Array<Value>;
}

export interface Pool {
  __typename?: "Pool";
  assetA: Scalars["String"];
  assetB: Scalars["String"];
  lpAsset: Scalars["String"];
  poolIdent: Scalars["String"];
}

export interface Position {
  __typename?: "Position";
  createdAt: DateTime;
  delegation: Array<Delegation>;
  index: Scalars["Int"];
  owner: Scalars["Multisig"];
  ownerID: Scalars["String"];
  spentAt: Maybe<DateTime>;
  spentTxHash: Maybe<Scalars["String"]>;
  txHash: Scalars["String"];
  value: Array<Value>;
}

export interface Program {
  __typename?: "Program";
  dailyEmission: Array<Value>;
  disqualifiedPools: Maybe<Array<Pool>>;
  eligiblePools: Maybe<Array<Pool>>;
  emittedAsset: Scalars["String"];
  firstDailyRewards: DateTime;
  id: Scalars["String"];
  label: Scalars["String"];
  lastDailyRewards: Maybe<DateTime>;
  maxPoolCount: Maybe<Scalars["Int"]>;
  maxPoolIntegerPercent: Maybe<Scalars["Int"]>;
  minLPIntegerPercent: Maybe<Scalars["Int"]>;
  /** Most recent emission amounts */
  poolEmissions: Scalars["PoolEmissions"];
  serviceFee: Scalars["Int"];
  stakedAsset: Maybe<Scalars["String"]>;
  status: Scalars["StatusMap"];
}

export interface Query {
  __typename?: "Query";
  calculation: CalculationStats;
  earnings: Array<Earning>;
  estimatedEarnings: Array<Earning>;
  estimation: CalculationStats;
  now: DateTime;
  ok: Scalars["String"];
  positions: Array<Position>;
  programs: Array<Program>;
  rollup: Scalars["Rollup"];
  rollupKeys: Array<Scalars["String"]>;
}

export interface QueryCalculationArgs {
  date: Scalars["String"];
  program: Scalars["String"];
}

export interface QueryEarningsArgs {
  beneficiary: Array<Scalars["String"]>;
}

export interface QueryEstimatedEarningsArgs {
  beneficiary: Array<Scalars["String"]>;
}

export interface QueryEstimationArgs {
  program: Scalars["String"];
  timestamp: InputMaybe<Scalars["String"]>;
}

export interface QueryPositionsArgs {
  beneficiary: Array<Scalars["String"]>;
}

export interface QueryRollupArgs {
  key: Scalars["String"];
}

export interface TxIn {
  index: Scalars["Int"];
  txHash: Scalars["String"];
}

export interface Value {
  __typename?: "Value";
  amount: Scalars["String"];
  assetID: Scalars["String"];
}

export interface Witness {
  __typename?: "Witness";
  pubKey: Scalars["String"];
  signature: Scalars["String"];
}

export interface _Service {
  __typename?: "_Service";
  sdl: Scalars["String"];
}

export type EarningFragmentFragment = {
  __typename?: "Earning";
  owner: any;
  ownerID: string;
  status: EarningStatus;
  txId: string | null;
  verified: boolean;
  byPool: Array<{
    __typename?: "PerPoolValue";
    pool: {
      __typename?: "Pool";
      poolIdent: string;
      assetA: string;
      assetB: string;
      lpAsset: string;
    };
    value: Array<{ __typename?: "Value"; assetID: string; amount: string }>;
  }>;
  date: { __typename?: "DateTime"; format: string };
  expiration: { __typename?: "DateTime"; format: string } | null;
  program: { __typename?: "Program"; label: string; id: string };
  txExpiration: { __typename?: "DateTime"; format: string } | null;
  txRollbackHorizon: { __typename?: "DateTime"; format: string } | null;
  value: Array<{ __typename?: "Value"; assetID: string; amount: string }>;
};

export type GetProgramsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProgramsQuery = {
  __typename?: "Query";
  programs: Array<{
    __typename?: "Program";
    id: string;
    label: string;
    emittedAsset: string;
    stakedAsset: string | null;
    minLPIntegerPercent: number | null;
    maxPoolCount: number | null;
    maxPoolIntegerPercent: number | null;
    serviceFee: number;
    dailyEmission: Array<{
      __typename?: "Value";
      assetID: string;
      amount: string;
    }>;
    firstDailyRewards: {
      __typename?: "DateTime";
      format: string;
      slot: number;
    };
    lastDailyRewards: {
      __typename?: "DateTime";
      format: string;
      slot: number;
    } | null;
    eligiblePools: Array<{
      __typename?: "Pool";
      poolIdent: string;
      lpAsset: string;
      assetA: string;
      assetB: string;
    }> | null;
    disqualifiedPools: Array<{
      __typename?: "Pool";
      poolIdent: string;
      lpAsset: string;
      assetA: string;
      assetB: string;
    }> | null;
  }>;
};

export type GetPositionsQueryVariables = Exact<{
  beneficiary: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetPositionsQuery = {
  __typename?: "Query";
  positions: Array<{
    __typename?: "Position";
    spentTxHash: string | null;
    txHash: string;
    index: number;
    ownerID: string;
    createdAt: { __typename?: "DateTime"; format: string; slot: number };
    spentAt: { __typename?: "DateTime"; format: string; slot: number } | null;
    value: Array<{ __typename?: "Value"; assetID: string; amount: string }>;
    delegation: Array<{
      __typename?: "Delegation";
      weight: number;
      pool: {
        __typename?: "Pool";
        poolIdent: string;
        lpAsset: string;
        assetA: string;
        assetB: string;
      } | null;
      program: {
        __typename?: "Program";
        id: string;
        label: string;
        emittedAsset: string;
        stakedAsset: string | null;
        minLPIntegerPercent: number | null;
        maxPoolCount: number | null;
        maxPoolIntegerPercent: number | null;
        dailyEmission: Array<{
          __typename?: "Value";
          assetID: string;
          amount: string;
        }>;
        firstDailyRewards: {
          __typename?: "DateTime";
          format: string;
          slot: number;
        };
        lastDailyRewards: {
          __typename?: "DateTime";
          format: string;
          slot: number;
        } | null;
        eligiblePools: Array<{
          __typename?: "Pool";
          poolIdent: string;
          lpAsset: string;
          assetA: string;
          assetB: string;
        }> | null;
        disqualifiedPools: Array<{
          __typename?: "Pool";
          poolIdent: string;
          lpAsset: string;
          assetA: string;
          assetB: string;
        }> | null;
      } | null;
    }>;
  }>;
};

export type GetEarningsQueryVariables = Exact<{
  beneficiary: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetEarningsQuery = {
  __typename?: "Query";
  earnings: Array<{
    __typename?: "Earning";
    owner: any;
    ownerID: string;
    status: EarningStatus;
    txId: string | null;
    verified: boolean;
    byPool: Array<{
      __typename?: "PerPoolValue";
      pool: {
        __typename?: "Pool";
        poolIdent: string;
        assetA: string;
        assetB: string;
        lpAsset: string;
      };
      value: Array<{ __typename?: "Value"; assetID: string; amount: string }>;
    }>;
    date: { __typename?: "DateTime"; format: string };
    expiration: { __typename?: "DateTime"; format: string } | null;
    program: { __typename?: "Program"; label: string; id: string };
    txExpiration: { __typename?: "DateTime"; format: string } | null;
    txRollbackHorizon: { __typename?: "DateTime"; format: string } | null;
    value: Array<{ __typename?: "Value"; assetID: string; amount: string }>;
  }>;
};

export type GetEstimatedEarningsQueryVariables = Exact<{
  beneficiary: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetEstimatedEarningsQuery = {
  __typename?: "Query";
  estimatedEarnings: Array<{
    __typename?: "Earning";
    owner: any;
    ownerID: string;
    status: EarningStatus;
    txId: string | null;
    verified: boolean;
    byPool: Array<{
      __typename?: "PerPoolValue";
      pool: {
        __typename?: "Pool";
        poolIdent: string;
        assetA: string;
        assetB: string;
        lpAsset: string;
      };
      value: Array<{ __typename?: "Value"; assetID: string; amount: string }>;
    }>;
    date: { __typename?: "DateTime"; format: string };
    expiration: { __typename?: "DateTime"; format: string } | null;
    program: { __typename?: "Program"; label: string; id: string };
    txExpiration: { __typename?: "DateTime"; format: string } | null;
    txRollbackHorizon: { __typename?: "DateTime"; format: string } | null;
    value: Array<{ __typename?: "Value"; assetID: string; amount: string }>;
  }>;
};

export type GetCalculationQueryVariables = Exact<{
  program: Scalars["String"];
  date: Scalars["String"];
}>;

export type GetCalculationQuery = {
  __typename?: "Query";
  calculation: {
    __typename?: "CalculationStats";
    program: {
      __typename?: "Program";
      id: string;
      label: string;
      emittedAsset: string;
      stakedAsset: string | null;
      firstDailyRewards: { __typename?: "DateTime"; slot: number };
      lastDailyRewards: { __typename?: "DateTime"; slot: number } | null;
      eligiblePools: Array<{ __typename?: "Pool"; poolIdent: string }> | null;
      disqualifiedPools: Array<{
        __typename?: "Pool";
        poolIdent: string;
      }> | null;
    };
    perPoolHRA: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
    emissionsByPool: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
    estimatedLockedLovelaceByPool: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
    delegationsByPool: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
  };
};

export type GetEstimationQueryVariables = Exact<{
  program: Scalars["String"];
  timestamp: InputMaybe<Scalars["String"]>;
}>;

export type GetEstimationQuery = {
  __typename?: "Query";
  estimation: {
    __typename?: "CalculationStats";
    program: {
      __typename?: "Program";
      id: string;
      label: string;
      emittedAsset: string;
      stakedAsset: string | null;
      firstDailyRewards: { __typename?: "DateTime"; slot: number };
      lastDailyRewards: { __typename?: "DateTime"; slot: number } | null;
      eligiblePools: Array<{ __typename?: "Pool"; poolIdent: string }> | null;
      disqualifiedPools: Array<{
        __typename?: "Pool";
        poolIdent: string;
      }> | null;
    };
    cumulativeDelegationsByPool: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
    perPoolHRA: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
    emissionsByPool: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
    estimatedLockedLovelaceByPool: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
    delegationsByPool: Array<{
      __typename?: "PerPoolMetric";
      quantity: string;
      percent: number;
      pool: { __typename?: "Pool"; poolIdent: string };
    }>;
  };
};

export type ClaimRewardsMutationVariables = Exact<{
  userInputs: Array<TxIn> | TxIn;
  destinationAddress: Scalars["String"];
  earnings: Array<EarningInput> | EarningInput;
}>;

export type ClaimRewardsMutation = {
  __typename?: "Mutation";
  claim: {
    __typename?: "ClaimOutput";
    transactionHex: string;
    witnesses: Array<{
      __typename?: "Witness";
      pubKey: string;
      signature: string;
    }>;
  };
};

export type GetRollupQueryVariables = Exact<{
  key: Scalars["String"];
}>;

export type GetRollupQuery = { __typename?: "Query"; rollup: any };

export const EarningFragmentFragmentDoc = gql`
  fragment EarningFragment on Earning {
    byPool {
      pool {
        poolIdent
        assetA
        assetB
        lpAsset
      }
      value {
        assetID
        amount
      }
    }
    date {
      format(layout: "2006-01-02")
    }
    expiration {
      format(layout: "2006-01-02T15:04:05Z")
    }
    owner
    ownerID
    program {
      label
      id
    }
    status
    txExpiration {
      format(layout: "2006-01-02T15:04:05Z")
    }
    txId
    txRollbackHorizon {
      format(layout: "2006-01-02T15:04:05Z")
    }
    value {
      assetID
      amount
    }
    verified
  }
`;
export const GetProgramsDocument = gql`
  query getPrograms {
    programs {
      id
      label
      dailyEmission {
        assetID
        amount
      }
      firstDailyRewards {
        format
        slot
      }
      lastDailyRewards {
        format
        slot
      }
      emittedAsset
      stakedAsset
      eligiblePools {
        poolIdent
        lpAsset
        assetA
        assetB
      }
      disqualifiedPools {
        poolIdent
        lpAsset
        assetA
        assetB
      }
      minLPIntegerPercent
      maxPoolCount
      maxPoolIntegerPercent
      serviceFee
    }
  }
`;
export const GetPositionsDocument = gql`
  query getPositions($beneficiary: [String!]!) {
    positions(beneficiary: $beneficiary) {
      spentTxHash
      txHash
      index
      createdAt {
        format
        slot
      }
      spentTxHash
      spentAt {
        format
        slot
      }
      ownerID
      value {
        assetID
        amount
      }
      delegation {
        pool {
          poolIdent
          lpAsset
          assetA
          assetB
        }
        program {
          id
          label
          dailyEmission {
            assetID
            amount
          }
          firstDailyRewards {
            format
            slot
          }
          lastDailyRewards {
            format
            slot
          }
          emittedAsset
          stakedAsset
          eligiblePools {
            poolIdent
            lpAsset
            assetA
            assetB
          }
          disqualifiedPools {
            poolIdent
            lpAsset
            assetA
            assetB
          }
          minLPIntegerPercent
          maxPoolCount
          maxPoolIntegerPercent
        }
        weight
      }
    }
  }
`;
export const GetEarningsDocument = gql`
  query getEarnings($beneficiary: [String!]!) {
    earnings(beneficiary: $beneficiary) {
      ...EarningFragment
    }
  }
  ${EarningFragmentFragmentDoc}
`;
export const GetEstimatedEarningsDocument = gql`
  query getEstimatedEarnings($beneficiary: [String!]!) {
    estimatedEarnings(beneficiary: $beneficiary) {
      ...EarningFragment
    }
  }
  ${EarningFragmentFragmentDoc}
`;
export const GetCalculationDocument = gql`
  query getCalculation($program: String!, $date: String!) {
    calculation(program: $program, date: $date) {
      program {
        firstDailyRewards {
          slot
        }
        lastDailyRewards {
          slot
        }
        id
        label
        eligiblePools {
          poolIdent
        }
        disqualifiedPools {
          poolIdent
        }
        emittedAsset
        stakedAsset
      }
      perPoolHRA {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      emissionsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      estimatedLockedLovelaceByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      delegationsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
    }
  }
`;
export const GetEstimationDocument = gql`
  query getEstimation($program: String!, $timestamp: String) {
    estimation(program: $program, timestamp: $timestamp) {
      program {
        firstDailyRewards {
          slot
        }
        lastDailyRewards {
          slot
        }
        id
        label
        eligiblePools {
          poolIdent
        }
        disqualifiedPools {
          poolIdent
        }
        emittedAsset
        stakedAsset
      }
      cumulativeDelegationsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      perPoolHRA {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      emissionsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      estimatedLockedLovelaceByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      delegationsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
    }
  }
`;
export const ClaimRewardsDocument = gql`
  mutation claimRewards(
    $userInputs: [TxIn!]!
    $destinationAddress: String!
    $earnings: [EarningInput!]!
  ) {
    claim(
      userInputs: $userInputs
      destinationAddress: $destinationAddress
      earnings: $earnings
    ) {
      transactionHex
      witnesses {
        pubKey
        signature
      }
    }
  }
`;
export const GetRollupDocument = gql`
  query getRollup($key: String!) {
    rollup(key: $key)
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    getPrograms(
      variables?: GetProgramsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetProgramsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProgramsQuery>(GetProgramsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getPrograms",
        "query",
      );
    },
    getPositions(
      variables: GetPositionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetPositionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPositionsQuery>(GetPositionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getPositions",
        "query",
      );
    },
    getEarnings(
      variables: GetEarningsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetEarningsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEarningsQuery>(GetEarningsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getEarnings",
        "query",
      );
    },
    getEstimatedEarnings(
      variables: GetEstimatedEarningsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetEstimatedEarningsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEstimatedEarningsQuery>(
            GetEstimatedEarningsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getEstimatedEarnings",
        "query",
      );
    },
    getCalculation(
      variables: GetCalculationQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetCalculationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCalculationQuery>(
            GetCalculationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getCalculation",
        "query",
      );
    },
    getEstimation(
      variables: GetEstimationQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetEstimationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEstimationQuery>(GetEstimationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getEstimation",
        "query",
      );
    },
    claimRewards(
      variables: ClaimRewardsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<ClaimRewardsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ClaimRewardsMutation>(
            ClaimRewardsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "claimRewards",
        "mutation",
      );
    },
    getRollup(
      variables: GetRollupQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetRollupQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetRollupQuery>(GetRollupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getRollup",
        "query",
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
