import isEmpty from "lodash/isEmpty";
import useAppLocation from "../hooks/router/useAppLocation";
import { TSearchParams } from "../pages/routes";
import { compareSearchObjects } from "./routes.utils";

/**
 * Checks if the current URL matches a given entry URL, considering both the path and search parameters.
 *
 * @param {string} entryUrl - The entry URL to compare with the current location.
 * @param {ReturnType<typeof useAppLocation>} location - The current location object.
 * @returns {boolean} True if the current URL matches the entry URL, false otherwise.
 */
export const isOnJoyrideEntryUrl = (
  entryUrl: string,
  entrySearchParams: TSearchParams,
  location: ReturnType<typeof useAppLocation>,
) => {
  /**
   * If the entry URL has search parameters, and the current URL has search parameters,
   * we need to compare the search parameters and the path.
   * If they match, return true. Otherwise, return false.
   */
  if (!isEmpty(location.search) && !isEmpty(entrySearchParams)) {
    const strippedUrl = entryUrl.split("?")[0];
    return (
      compareSearchObjects(entrySearchParams, location.search) &&
      location.pathname === strippedUrl
    );
  }

  /**
   * If the entry URL has no search parameters, but the current URL has search parameters,
   * and the current URL path matches the entry URL path, return false.
   * This assumes we're on the entry URL, but the search parameters are not yet set or not relevant.
   */
  if (
    ((isEmpty(entrySearchParams) && !isEmpty(location.search)) ||
      (!isEmpty(entrySearchParams) && isEmpty(location.search))) &&
    location.pathname === entryUrl
  ) {
    return false;
  }

  /**
   * If there are no search parameters, we simply compare the paths.
   */
  return location.pathname === entryUrl;
};
