import { useLocation } from "react-router-dom";
import { TSearchParams } from "../../pages/routes";
import { parseSearchString } from "../../utils/routes.utils";

const useAppLocation = () => {
  const routerLocation = useLocation();

  const search: TSearchParams = parseSearchString(routerLocation.search);

  const location = {
    state: routerLocation.state,
    pathname: routerLocation.pathname,
    key: routerLocation.key,
    hash: routerLocation.hash,
    search,
    searchStr: routerLocation.search,
  };

  return location;
};

export default useAppLocation;
