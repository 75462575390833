import {
  AcademicCapIcon,
  InboxIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { EventType, useI18N } from "@sundaeswap/react-hooks";
import { FC, useCallback, useEffect } from "react";
import { CONTROL_CENTER_GUIDES_NODE } from "../../../constants/page.constants";
import { useEventTracking } from "../../../hooks/useEventTracking";
import { controlCenterToolbarMotion } from "../../../motions/control-center.motions";
import {
  LocalControlCenterActions,
  useControlCenterContext,
} from "../../../stores/ControlCenterContext";
import { useNewsContext } from "../../../stores/NewsContext";
import { getEnv } from "../../../utils/network.utils";
import Motion from "../../Motion";
import ControlCenterContentContainer from "../ControlCenterContentContainer";
import ControlCenterGuides from "../ControlCenterGuides";
import ControlCenterHelpCenter from "../ControlCenterHelpCenter";
import ControlCenterInbox from "../ControlCenterInbox";
import ControlCenterToolbarElement from "../ControlCenterToolbarElement";

const ControlCenterToolbar: FC = () => {
  const { t } = useI18N("glossary");
  const handleEventTracking = useEventTracking();
  const {
    actionHandler,
    dispatchers: { localDispatch },
    state: {
      localState: { showGuides, showHelpCenter, showInbox },
    },
  } = useControlCenterContext();
  const {
    state: { numberOfUnreadNewsItems },
  } = useNewsContext();

  const handleGuideClick = useCallback(() => {
    if (!showGuides) {
      handleEventTracking({
        eventType: EventType.Click,
        source: "ControlCenterToolbarElement",
        value: "guides",
        network: getEnv(),
      });
    }
    actionHandler.controlCenter.openGuide();
  }, [actionHandler.controlCenter, handleEventTracking, showGuides]);

  const handleNewsClick = useCallback(() => {
    if (!showInbox) {
      handleEventTracking({
        eventType: EventType.Click,
        source: "ControlCenterToolbarElement",
        value: "news",
        network: getEnv(),
      });
    }
    actionHandler.controlCenter.openInbox();
  }, [actionHandler.controlCenter, handleEventTracking, showInbox]);

  const handleHelpCenterClick = useCallback(() => {
    if (!showHelpCenter) {
      handleEventTracking({
        eventType: EventType.Click,
        source: "ControlCenterToolbarElement",
        value: "help_center",
        network: getEnv(),
      });
    }
    actionHandler.controlCenter.openHelpCenter();
  }, [actionHandler.controlCenter, handleEventTracking, showHelpCenter]);

  useEffect(() => {
    if (!showInbox && !showHelpCenter) {
      LocalControlCenterActions.showGuides(true, localDispatch);
    }
  }, [localDispatch, showGuides, showHelpCenter, showInbox]);

  return (
    <>
      <Motion
        animationKey="ControlCenterToolbar"
        className="relative z-50"
        variants={controlCenterToolbarMotion}
      >
        <ControlCenterToolbarElement
          animationKey="Guides"
          className="right-[68px] xl:right-[75px]" // Avatar is 56px (xl: 63px); Spacing is 12px --> 56 + 12 = 68 (63 + 12 = 75)
          icon={<AcademicCapIcon />}
          isActive={showGuides}
          onClick={handleGuideClick}
          tooltipLabel={t("guides")}
        />
        <ControlCenterToolbarElement
          animationKey="News"
          className="right-[120px] xl:right-[132px]" // Avatar is 56px (xl: 63px); Guide Bubble is 40px (xl: 45px) Spacing is 12px, and we need to apply the spacing twice --> 56 + 40 + 12 + 12 = 120 (63 + 45 + 12 + 12 = 132)
          icon={<InboxIcon />}
          isActive={showInbox}
          notificationCount={numberOfUnreadNewsItems}
          onClick={handleNewsClick}
          tooltipLabel={t("inbox")}
        />
        <ControlCenterToolbarElement
          animationKey="HelpCenter"
          className="right-[172px] xl:right-[189px]" // Avatar is 56px (xl: 63px); Guide Bubble is 40px (xl: 45px) Spacing is 12px, and we need to apply the spacing three times --> 56 + 40 + 40 + 12 + 12 + 12 = 172 (63 + 45 + 45 + 12 + 12 + 12 = 189)
          icon={<QuestionMarkCircleIcon />}
          isActive={showHelpCenter}
          onClick={handleHelpCenterClick}
          tooltipLabel={t("faq")}
        />
      </Motion>
      <ControlCenterContentContainer>
        <div
          className="relative z-10 flex h-full flex-col"
          id={CONTROL_CENTER_GUIDES_NODE}
        >
          {showGuides && <ControlCenterGuides />}
          {showHelpCenter && <ControlCenterHelpCenter />}
          {showInbox && <ControlCenterInbox />}
        </div>
      </ControlCenterContentContainer>
    </>
  );
};

export default ControlCenterToolbar;
