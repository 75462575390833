import { THooks } from "@sundaeswap/react-hooks";
import { AnimatePresence } from "framer-motion";
import { FC, lazy, useCallback, useRef } from "react";

import { controlCenterAvatarMotion } from "../../../motions/control-center.motions";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import Motion from "../../Motion";
import ControlCenterAvatar from "../ControlCenterAvatar";
import ControlCenterToolbar from "../ControlCenterToolbar";

const LazyActiveNewsDialog = lazy(
  () =>
    import(
      /* webpackChunkName: "ActiveNewsDialog" */ "../../Dialogs/ActiveNewsDialog"
    ),
);

const ControlCenterFAB: FC = () => {
  const controlCenterRef = useRef(null);
  const {
    actionHandler,
    state: {
      localState: {
        guides: { runGuide },
        showToolbar,
      },
    },
  } = useControlCenterContext();

  const handleOutsideClick = useCallback(() => {
    if (!runGuide) {
      actionHandler.controlCenter.close();
    }
  }, [actionHandler.controlCenter, runGuide]);

  THooks.useOnClickOutside(controlCenterRef, handleOutsideClick);

  return (
    <>
      <AnimatePresence key="ControlCenter">
        <Motion
          animationKey="ControlCenter--Container"
          variants={controlCenterAvatarMotion}
          className="fixed bottom-4 right-4"
          ref={controlCenterRef}
        >
          <ControlCenterAvatar />

          <AnimatePresence key="ControlCenter--Toolbar">
            {showToolbar && <ControlCenterToolbar />}
          </AnimatePresence>
        </Motion>
      </AnimatePresence>

      <LazyActiveNewsDialog />
    </>
  );
};

export default ControlCenterFAB;
