import { Variants } from "framer-motion";

export const DEFAULT_DURATION = 0.75;
export const EXIT_DURATION = 0.25;

export const DEFAULT_MOTION_PROPS = {
  animate: "animate",
  exit: "exit",
  initial: "initial",
};

// General Dialog Animation
export const slideInAndScaleFromBottomMotion: Variants = {
  initial: {
    opacity: 0,
    scale: 0.98,
    y: 20,
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    y: 20,
    transition: {
      type: "spring",
      duration: 0.5,
    },
  },
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: DEFAULT_DURATION,
    },
  },
};

export const delayedSlideInAndScaleFromBottomMotion = (
  animationDelay: number,
): Variants => {
  return {
    ...slideInAndScaleFromBottomMotion,
    animate: {
      ...slideInAndScaleFromBottomMotion.animate,
      transition: {
        ...(slideInAndScaleFromBottomMotion.animate as typeof slideInAndScaleFromBottomMotion),
        delay: animationDelay,
      },
    },
  };
};

export const staggeredChildrenMotion: Variants = {
  ...slideInAndScaleFromBottomMotion,
  animate: {
    ...slideInAndScaleFromBottomMotion.animate,
    transition: {
      ...(slideInAndScaleFromBottomMotion.animate as Variants).transition,
      staggerChildren: 0.125,
    },
  },
};

export const fadeInMotion: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: DEFAULT_DURATION,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: EXIT_DURATION,
    },
  },
};
