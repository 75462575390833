import { AssetAmount } from "@sundaeswap/asset";
import {
  EContractVersion,
  IComposedTx,
  ITxBuilderSign,
} from "@sundaeswap/core";
import type { Tx, TxComplete } from "lucid-cardano";
import { Dispatch, SetStateAction } from "react";
import { TPool } from "../../../types/Pool.types";
import { ETransactionState } from "../../../types/Transaction.types";

export enum EErrorType {
  error = "error",
  info = "info",
  warning = "warning",
}

// TODO: Move to a shared location since Order Context uses this as well (`EOrderTransactionBuilderState`)
export enum EMigrationTransactionBuilderState {
  BUILD = "BUILD",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface IMigrationError {
  type: EErrorType;
  message: string;
  originalError?: Error;
  details?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type TSelectedPool = Record<EContractVersion, TPool>;

export interface IMigrateLiquidityContext {
  derived: {
    fees?: {
      adaTxFee?: AssetAmount;
      deposit?: AssetAmount;
      scooperFee?: AssetAmount;
      totalFees?: AssetAmount;
    };
    isLoadingV3Pools?: boolean;
    selectedPools: TSelectedPool[];
    v3PoolsByAssetId?: TPool[];
  };
  error?: IMigrationError;
  flowState: {
    dialogState: ETransactionState;
    selectionChanged?: boolean;
  };
  handlers: {
    handleSetError: (data: IMigrationError) => void;
    handleCheckedChange: (
      checked: boolean | string,
      v1Pool: TPool,
      v3Pool: TPool,
    ) => void;
    resetContext: () => void;
    setDialogState: Dispatch<SetStateAction<ETransactionState>>;
    setTransactionBuilderState: Dispatch<
      SetStateAction<EMigrationTransactionBuilderState | undefined>
    >;
    setBuiltTx: Dispatch<
      SetStateAction<IComposedTx<Tx, TxComplete> | undefined>
    >;
    setCompleteTx: Dispatch<
      SetStateAction<ITxBuilderSign<TxComplete> | undefined>
    >;
    setIsLoadingV3Pools: Dispatch<SetStateAction<boolean | undefined>>;
    setSelectedPools: Dispatch<SetStateAction<TSelectedPool[]>>;
  };
  transaction: {
    builderState?: EMigrationTransactionBuilderState;
    builtTx?: IComposedTx<Tx, TxComplete>;
    completeTx?: ITxBuilderSign<TxComplete>;
  };
}
