import { Image, Text } from "@sundaeswap/ui-toolkit";
import { FC, useCallback } from "react";
import { useIsWalletConnected } from "../../../../../../hooks/useIsWalletConnected";
import { DisconnectedWallet } from "../../../../DisconnectedWallet";

const OrderTypeStep1Title: FC = () => {
  const isWalletConnected = useIsWalletConnected();

  const renderMemoizedNode = useCallback(() => {
    if (!isWalletConnected) {
      return <DisconnectedWallet.Title />;
    }

    return (
      <div className="grid gap-4">
        <div className="overflow-hidden rounded-lg">
          <Image
            className="mx-auto"
            src="https://media.giphy.com/media/l4KhWMUew1f0PsBZm/giphy.gif"
            width={500}
            height={236}
          />
        </div>
        <Text className="text-center" tag="h1" size="3xl" weight="semibold">
          Market vs. Limit Orders
        </Text>
      </div>
    );
  }, [isWalletConnected]);

  return renderMemoizedNode();
};

export default OrderTypeStep1Title;
