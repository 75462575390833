import { useI18N, useResponsive } from "@sundaeswap/react-hooks";
import {
  Button,
  ExternalLink,
  Heading,
  Image,
  Text,
  toolkitCx,
} from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MouseParallax } from "react-just-parallax";
import useAppNavigate from "../../hooks/router/useAppNavigate";
import { externalRoutes, paths } from "../routes";

interface INotFound extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  actionButton?: ReactNode;
  context?: string;
  easterEggLabel?: string;
  helpLink?: ReactNode;
  img?: string;
  copy?: string;
  title?: ReactNode;
}

const DEFAULT_NOT_FOUND_DUDE = "/static/images/dudes/wally_404_1024x1024.png";
const ENOUGH_IS_ENOUGH = 10;

const NotFound: FC<INotFound> = ({
  actionButton,
  className,
  context,
  easterEggLabel,
  helpLink,
  img = DEFAULT_NOT_FOUND_DUDE,
  copy,
  title,
}) => {
  const { t } = useI18N("not-found");
  const navigate = useAppNavigate();
  const responsive = useResponsive();
  const isLargeScreen = useMemo(
    () => responsive.lg || responsive.xl || responsive.xxl,
    [responsive.lg, responsive.xl, responsive.xxl],
  );
  const [imageClicked, setImageClicked] = useState(false);
  const [clickCounter, setClickCounter] = useState(0);

  useEffect(() => {
    if (easterEggLabel && clickCounter === ENOUGH_IS_ENOUGH) {
      alert(easterEggLabel ?? t("notFound.context.404.easterEgg"));
    }
  }, [clickCounter, easterEggLabel]);

  return (
    <div
      className={cx(
        "grid gap-4",
        "lg:flex lg:items-center lg:justify-center lg:gap-12",
        className,
      )}
    >
      <div
        className={cx(
          "mx-auto max-w-sm lg:mx-0 lg:max-w-lg",
          toolkitCx.layout.transition.transform,
          {
            "scale-[0.975]": imageClicked,
            "motion-safe:animate-shake": clickCounter === ENOUGH_IS_ENOUGH,
          },
        )}
      >
        <MouseParallax strength={0.01}>
          <Image
            src={img}
            alt={t("notFound", { ns: "alt-tags" })}
            height={1024}
            width={1024}
            {...(Boolean(easterEggLabel) && {
              onClick: () => {
                setImageClicked(true);
                setClickCounter(
                  clickCounter >= ENOUGH_IS_ENOUGH ? 0 : clickCounter + 1,
                );

                setTimeout(() => setImageClicked(false), 150);
              },
            })}
          />
        </MouseParallax>
      </div>

      <div
        className={cx(
          "grid gap-6 lg:max-w-2xl lg:gap-8",
          toolkitCx.recipes.blurryCorner.primary,
        )}
      >
        <div
          className={cx(
            "z-10 flex h-9 w-fit min-w-min items-center self-start rounded-full",
            toolkitCx.layout.spacing.horizontal.md,
            toolkitCx.layout.border.default,
            toolkitCx.layout.border.weight.default,
          )}
        >
          <Text
            className="flex items-center gap-2"
            tag="span"
            gradient
            variant="silent"
          >
            {context ??
              // TODO: wording
              t("notFound.context.404.error")}
          </Text>
        </div>

        <Heading
          tag="h1"
          size={isLargeScreen ? "7xl" : "5xl"}
          weight="semibold"
        >
          {title ??
            // TODO: wording
            t("notFound.context.404.title")}
        </Heading>

        <Text tag="p" variant="muted" size="2xl">
          {copy ??
            // TODO: wording
            t("notFound.context.404.copy")}
        </Text>

        <div className="flex items-center gap-8">
          {actionButton ?? (
            <Button
              onClick={() => navigate({ to: paths.root })}
              variant="gradient"
              size="lg"
              className="w-fit"
            >
              {
                // TODO: wording
                t("notFound.context.404.button")
              }
            </Button>
          )}

          {helpLink ?? (
            <ExternalLink
              href={externalRoutes.faq}
              arrow="top-right"
              variant="primary"
            >
              {
                // TODO: wording
                t("visitHelpCenter", { ns: "common" })
              }
            </ExternalLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
