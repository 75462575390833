export enum EChartInterval {
  "1W" = "1W",
  "2W" = "2W",
  "1M" = "1M",
  "MTD" = "MTD",
  "3M" = "3M",
  "YTD" = "YTD",
  "ALL" = "ALL",
}

export enum EChartScale {
  "linear" = "linear",
  "logarithmic" = "logarithmic",
}
