import { SundaeUtils } from "@sundaeswap/core/utilities";
import { useQuery } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

import { readableAdaAssetId } from "../../constants/cardano.constants";
import { TPool, TPoolMap } from "../../types/Pool.types";
import {
  getAssetId,
  transformAssetIdToGqlAssetId,
} from "../../utils/assets.utils";
import { getPoolPairId } from "../../utils/pool.utils";
import { getBrambleSdk } from "../client/bramble.sdk";
import { getStats2Sdk } from "../client/statsSdk";
import { PoolBrambleFragmentFragment, Stats } from "../generated/bramble.sdk";
import { VolumeInterval } from "../generated/stats2.sdk";
import { BrambleTransformer } from "../utils/BrambleTransformer.class";

export const usePopularPools = (pageSize?: number, staleTime = 30) => {
  const queryInfo = useQuery({
    queryKey: ["popularPools", pageSize],
    queryFn: async () => {
      const { fetchPopularPools } = await getBrambleSdk();
      const response = await fetchPopularPools();
      const { getAdaPrice } = await getStats2Sdk();
      const { adaPrice } = await getAdaPrice();
      return BrambleTransformer.transformBramblePoolsFragment(
        response.pools.popular,
        adaPrice,
      );
    },
    staleTime: 1000 * staleTime,
  });

  const { data, ...rest } = queryInfo;

  return {
    ...rest,
    data: data ?? {
      bramble: [],
      stats: [],
    },
  };
};

export const usePoolsBySearch = (searchQuery?: string) => {
  return useQuery({
    queryKey: ["searchPools", searchQuery],
    queryFn: async () => {
      if (!searchQuery) {
        return {
          bramble: [],
          stats: [],
        };
      }

      const { fetchPoolsBySearch } = await getBrambleSdk();
      const response = await fetchPoolsBySearch({ searchValue: searchQuery });
      const { getAdaPrice } = await getStats2Sdk();
      const { adaPrice } = await getAdaPrice();
      return BrambleTransformer.transformBramblePoolsFragment(
        response?.pools.search ?? [],
        adaPrice,
      );
    },
    enabled: Boolean(searchQuery),
  });
};

export const usePoolsBySearchWithStats = (searchQuery?: string) => {
  const queryInfo = useQuery({
    queryKey: ["searchPoolsWithStats", searchQuery],
    queryFn: async () => {
      const { searchPoolsWithStats } = await getStats2Sdk();
      return searchPoolsWithStats({
        query: searchQuery!,
        currency: "ADA",
        dateFrom: new Date(Date.now() - 86400000).toISOString(),
        dateTo: new Date().toISOString(),
        interval: VolumeInterval.Daily,
      });
    },
    enabled: Boolean(searchQuery),
  });

  const { data, ...rest } = queryInfo;

  return {
    ...rest,
    data: data ?? {
      bramble: [],
      stats: [],
    },
  };
};

export const usePoolsByPair = ({
  coinA,
  coinB,
  enabled = true,
}: {
  coinA?: string;
  coinB?: string;
  enabled?: boolean;
}) => {
  const queryInfo = useQuery({
    queryKey: ["poolsByPair", coinA, coinB],
    queryFn: async () => {
      if (coinA === undefined || coinB === undefined) {
        return {
          bramble: [],
          stats: [],
        };
      }

      const { fetchPoolsByPair } = await getBrambleSdk();
      /**
       * We utilize the new Bramble API here and pass it through
       * a transformer so that we keep backwards compatibility in check.
       */
      const {
        pools: { byPair },
      } = await fetchPoolsByPair({ assetA: coinA, assetB: coinB });
      const { getAdaPrice } = await getStats2Sdk();
      const { adaPrice } = await getAdaPrice();

      const pairs = BrambleTransformer.transformBramblePoolsFragment(
        byPair,
        adaPrice,
      );

      return {
        bramble: pairs.bramble,
        stats: pairs.stats,
      };
    },
    retry: 2,
    staleTime: 1000 * 60 * 3 /* 3 min */,
    enabled,
  });

  return {
    ...queryInfo,
    data: queryInfo.data ?? {
      bramble: [],
      stats: [],
    },
  };
};

export const useRawPoolsByAssetIds = ({
  assetIds,
  enabled = true,
  page,
  pageSize,
}: {
  assetIds: string[];
  enabled?: boolean;
  page?: number;
  pageSize?: number;
}) => {
  const queryInfo = useQuery({
    queryKey: ["getRawPoolsByAssetIds", sortBy(assetIds), pageSize, page],
    queryFn: async () => {
      const { fetchPoolByAssets } = await getBrambleSdk();
      /**
       * We utilize the new Bramble API here and pass it through
       * a transformer so that we keep backwards compatibility in check.
       */
      const {
        pools: { byAssets },
      } = await fetchPoolByAssets({ assets: assetIds });
      const { getAdaPrice } = await getStats2Sdk();
      const { adaPrice } = await getAdaPrice();
      return BrambleTransformer.transformBramblePoolsFragment(
        byAssets,
        adaPrice,
      );
    },
    refetchOnWindowFocus: false,
    enabled,
  });

  const { data, ...rest } = queryInfo;

  return {
    ...rest,
    data: data ?? {
      bramble: [],
      stats: [],
    },
  };
};

/**
 * Formats a combined array of pool data for both input and output assets into a map keyed by pair IDs.
 *
 * The function takes two arrays of TPool objects (or nulls), one for input pools and
 * another for output pools. It then merges these arrays and reduces them into an object where each key
 * is a unique pair ID derived from the asset IDs in the pools, and the value is an array of TPool
 * objects associated with that pair ID. If a pool object in the input arrays is null, it is ignored.
 *
 * @param {Array<TPool | null>} poolsForInput - An array of pool data (or nulls) for the input asset.
 * @param {Array<TPool | null>} poolsForOutput - An array of pool data (or nulls) for the output asset.
 * @returns {Record<string, TPool[]>} An object mapping each unique pair ID to an array of TPool objects associated with that pair.
 */
const formatPoolsByAssetIds = (
  poolsForInput: (TPool | null)[],
  poolsForOutput: (TPool | null)[],
) => {
  const data = [...poolsForInput, ...poolsForOutput];
  const formattedData: Record<string, TPool[]> = data?.reduce((acc, pool) => {
    if (!pool) return acc;

    const pairId = getPoolPairId([
      getAssetId(pool.assetA),
      getAssetId(pool.assetB),
    ]);
    return {
      ...acc,
      [pairId]: acc[pairId] ? [...acc[pairId], pool] : [pool],
    };
  }, {} as TPoolMap);

  return formattedData;
};

export const usePoolsByAssetIds = ({
  assetIds,
  enabled = true,
  pageSize,
  page,
}: {
  assetIds: string[];
  enabled?: boolean;
  pageSize?: number;
  page?: number;
}) => {
  return useQuery({
    queryKey: ["getPoolsByAssetIds", assetIds, pageSize, page],
    queryFn: async () => {
      const { fetchPoolByAssets } = await getBrambleSdk();
      /**
       * We utilize the new Bramble API here and pass it through
       * a transformer so that we keep backwards compatibility in check.
       */

      const assetsForInput = [assetIds[0]].map((id) =>
        transformAssetIdToGqlAssetId(id),
      );
      const responseForInput = assetIds[0]
        ? await fetchPoolByAssets({ assets: assetsForInput })
        : { pools: { byAssets: [] } };
      const { getAdaPrice } = await getStats2Sdk();
      const { adaPrice } = await getAdaPrice();
      const resultsForInput = BrambleTransformer.transformBramblePoolsFragment(
        responseForInput.pools.byAssets,
        adaPrice,
      );

      const assetsForOutput = [assetIds[1]].map((id) =>
        transformAssetIdToGqlAssetId(id),
      );
      const responseForOutput = assetIds[1]
        ? await fetchPoolByAssets({ assets: assetsForOutput })
        : { pools: { byAssets: [] } };
      const resultsForOutput = BrambleTransformer.transformBramblePoolsFragment(
        responseForOutput.pools.byAssets,
        adaPrice,
      );

      const brambleData = [
        ...resultsForInput.bramble,
        ...resultsForOutput.bramble,
      ];

      const brambleFormattedData: Record<
        string,
        PoolBrambleFragmentFragment[]
      > = brambleData?.reduce(
        (acc, pool) => {
          if (!pool) return acc;
          const assetAId = SundaeUtils.isAdaAsset({
            ...pool.assetA,
            assetId: pool.assetA.id,
          })
            ? readableAdaAssetId
            : pool.assetA.id || "";

          const assetBId = SundaeUtils.isAdaAsset({
            ...pool.assetB,
            assetId: pool.assetB.id,
          })
            ? readableAdaAssetId
            : pool.assetB.id || "";

          const pairId = getPoolPairId([assetAId, assetBId]);
          return {
            ...acc,
            [pairId]: acc[pairId] ? [...acc[pairId], pool] : [pool],
          };
        },
        {} as {
          [assetPairId: string]: PoolBrambleFragmentFragment[];
        },
      );

      return {
        bramble: brambleFormattedData,
        stats: formatPoolsByAssetIds(
          resultsForInput.stats,
          resultsForOutput.stats,
        ),
      };
    },
    enabled: enabled && assetIds.length > 0,
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
    refetchOnWindowFocus: false,
  });
};

export const usePoolsByLPTokens = ({
  assetIds,
  pageSize,
  page,
}: {
  assetIds: string[];
  pageSize?: number;
  page?: number;
}) => {
  const queryInfo = useQuery({
    queryKey: ["getPoolsByLPTokens", assetIds, pageSize, page],
    queryFn: async () => {
      const { fetchPoolByLPTokens } = await getBrambleSdk();
      /**
       * We utilize the new Bramble API here and pass it through
       * a transformer so that we keep backwards compatibility in check.
       */
      const {
        pools: { byLPTokens },
      } = await fetchPoolByLPTokens({ assetIds });
      const { getAdaPrice } = await getStats2Sdk();
      const { adaPrice } = await getAdaPrice();
      return BrambleTransformer.transformBramblePoolsFragment(
        byLPTokens,
        adaPrice,
      );
    },
    refetchOnWindowFocus: false,
  });

  const { data, ...rest } = queryInfo;

  return {
    ...rest,
    data: data ?? {
      bramble: [],
      stats: [],
    },
  };
};

export const usePoolByIdent = (ident?: string) => {
  return useQuery({
    queryKey: ["poolByIdent", ident],
    queryFn: async () => {
      if (!ident) return;
      const { fetchPoolById } = await getBrambleSdk();
      /**
       * We utilize the new Bramble API here and pass it through
       * a transformer so that we keep backwards compatibility in check.
       */
      const {
        pools: { byId },
      } = await fetchPoolById({ id: ident });
      return BrambleTransformer.transformBramblePoolFragment(byId);
    },
    enabled: Boolean(ident),
  });
};

// getPoolsByIdent is limited to 20 idents per request, so we need to batch them
export const usePoolsByIdent = (idents?: string[]) => {
  return useQuery({
    queryKey: ["poolsByIdent", idents],
    queryFn: async () => {
      if (!idents || isEmpty(idents)) return { bramble: [], stats: [] };

      const { fetchPoolsById } = await getBrambleSdk();
      const { getAdaPrice } = await getStats2Sdk();
      const { adaPrice } = await getAdaPrice();
      /**
       * We utilize the new Bramble API here and pass it through
       * a transformer so that we keep backwards compatibility in check.
       */
      const {
        pools: { byIds },
      } = await fetchPoolsById({ ids: idents });
      return BrambleTransformer.transformBramblePoolsFragment(byIds, adaPrice);
    },
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 25, // 25 minutes
    staleTime: 1000 * 60 * 25, // 25 minutes
    gcTime: 1000 * 60 * 25, // 25 minutes
    enabled: !!idents?.length,
  });
};

// TODO: transform properly
export const usePoolStats = ({ ident }: { ident?: string }) => {
  return useQuery({
    queryKey: ["poolStats", ident],
    queryFn: async () => {
      if (!ident) return;

      const { fetchPoolStats } = await getBrambleSdk();
      /**
       * We utilize the new Bramble API here and pass it through
       * a transformer so that we keep backwards compatibility in check.
       */
      const {
        pools: { byId },
      } = await fetchPoolStats({ id: ident });
      //return BrambleTransformer.transformBramblePoolsFragment(byId);
      return byId;
    },
    enabled: Boolean(ident),
    staleTime: 1000 * 60 * 25, // 25 minutes
    gcTime: 1000 * 60 * 25, // 25 minutes
    refetchOnWindowFocus: false,
  });
};

/**
 * Fetches Pool Information based on a given LP token asset ID.
 * This is tied to the Taste Test, since the returned dataset is limited and it only returns the quantity.
 * @param assetId - The asset ID of the LP token
 */
export const usePoolByLPAssetId = (assetId: string) => {
  return useQuery({
    queryKey: ["fetchTTPoolByLpToken", assetId],
    queryFn: async () => {
      if (!assetId) return {} as Stats;

      const { fetchTTPoolByLpToken } = await getBrambleSdk();
      const data = await fetchTTPoolByLpToken({ assetId });
      return (data?.pools?.byLPToken?.current ?? {}) as Stats;
    },
    enabled: !SundaeUtils.ADA_ASSET_IDS.includes(assetId),
    staleTime: 1000 * 60 * 60, // 1h
    gcTime: 1000 * 60 * 60, // 1h
    refetchInterval: 1000 * 60 * 60, // 1h
    refetchOnWindowFocus: false,
  });
};

/**
 * Fetches pool information based on given and taken assets using a specific routing algorithm.
 * The function queries pools that match a given asset being exchanged for a taken asset, using a specified route depth.
 * It leverages both the Bramble and Stats2 SDKs to fetch and transform pool data accordingly,
 * incorporating current ADA price into the transformation for valuation purposes.
 *
 * @param {string} given - The asset ID of the asset being given.
 * @param {string} taken - The asset ID of the asset being taken.
 * @returns {object} Returns a query object containing the transformed pool data including bramble and stats, or an empty stats object if no assets are provided.
 */
export const usePoolsByRoute = ({
  given,
  taken,
}: {
  given?: string;
  taken?: string;
}) => {
  return useQuery({
    queryKey: ["fetchPoolsByRoute", given, taken],
    queryFn: async () => {
      if (!given || !taken) return;

      const { fetchPoolByRoute } = await getBrambleSdk();

      const {
        pools: { byRoute },
      } = await fetchPoolByRoute({
        depth: 2,
        givenAsset: given,
        takenAsset: taken,
      });
      const { getAdaPrice } = await getStats2Sdk();
      const { adaPrice } = await getAdaPrice();
      return BrambleTransformer.transformBramblePoolsRoutes(byRoute, adaPrice);
    },
    enabled: !!given && !!taken,
    staleTime: 1000 * 60 * 60, // 1h
    gcTime: 1000 * 60 * 60, // 1h
    refetchInterval: 1000 * 60 * 60, // 1h
    refetchOnWindowFocus: false,
  });
};
