import { useI18N } from "@sundaeswap/react-hooks";
import { Accordion, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { motion } from "framer-motion";
import { FC, memo } from "react";
import { slideInAndScaleFromBottomMotion } from "../../../motions/default.motions";
import ControlCenterContentFooter from "../ControlCenterContentFooter";
import ControlCenterContentNode from "../ControlCenterContentNode";
import ControlCenterContentTitle from "../ControlCenterContentTitle";

const ControlCenterHelpCenter: FC = () => {
  const { t } = useI18N("help-center");

  return (
    <>
      <ControlCenterContentTitle
        title={t("title")}
        description={t("description")}
      />
      <ControlCenterContentNode
        tag="div"
        className={cx("grid gap-4", toolkitCx.layout.spacing.bottom.md)}
      >
        <motion.div
          variants={slideInAndScaleFromBottomMotion}
          className={toolkitCx.layout.text.default}
        >
          <Accordion
            blurryVariant={undefined}
            defaultValue="0"
            contentContainerClassName="!py-0 !px-4 !pb-2"
            triggerContainerClassName="!px-4 !py-2 text-left"
            items={[
              {
                id: "0",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("whatIsSundaeSwap.q")}
                  </Text>
                ),
                content: (
                  <Text size="sm" tag="p" variant="muted">
                    {t("whatIsSundaeSwap.a")}
                  </Text>
                ),
              },
              {
                id: "1",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("howToSwap.q")}
                  </Text>
                ),
                content: (
                  <div className="flex flex-col gap-2">
                    <Text size="sm" tag="p" variant="muted">
                      {t("howToSwap.a")}
                    </Text>
                    {(
                      t("howToSwap.a", {
                        context: "steps",
                        returnObjects: true,
                      }) as string[]
                    ).map((step, index) => (
                      <Text key={step} size="sm" tag="p" variant="muted">
                        {index + 1}. {step}
                      </Text>
                    ))}
                  </div>
                ),
              },
              {
                id: "2",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("whatIsScooperFee.q")}
                  </Text>
                ),
                content: (
                  <Text size="sm" tag="p" variant="muted">
                    {t("whatIsScooperFee.a")}
                  </Text>
                ),
              },
              {
                id: "3",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("whyScooperFee.q")}
                  </Text>
                ),
                content: (
                  <Text size="sm" tag="p" variant="muted">
                    {t("whyScooperFee.a")}
                  </Text>
                ),
              },
              {
                id: "4",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("dynamicScooperFee.q")}
                  </Text>
                ),
                content: (
                  <Text size="sm" tag="p" variant="muted">
                    {t("dynamicScooperFee.a")}
                  </Text>
                ),
              },
              {
                id: "5",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("whatIfTxFails.q")}
                  </Text>
                ),
                content: (
                  <Text size="sm" tag="p" variant="muted">
                    {t("whatIfTxFails.a")}
                  </Text>
                ),
              },
              {
                id: "6",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("whatIsLiquidityProvision.q")}
                  </Text>
                ),
                content: (
                  <div className="flex flex-col gap-2">
                    <Text size="sm" tag="p" variant="muted">
                      {t("whatIsLiquidityProvision.a")}
                    </Text>
                    {(
                      t("whatIsLiquidityProvision.a", {
                        context: "steps",
                        returnObjects: true,
                      }) as string[]
                    ).map((step, index) => (
                      <Text key={step} size="sm" tag="p" variant="muted">
                        {index + 1}. {step}
                      </Text>
                    ))}
                  </div>
                ),
              },
              {
                id: "7",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("howToYieldFarm.q")}
                  </Text>
                ),
                content: (
                  <div className="flex flex-col gap-2">
                    <Text size="sm" tag="p" variant="muted">
                      {t("howToYieldFarm.a")}
                    </Text>
                    {(
                      t("howToYieldFarm.a", {
                        context: "steps",
                        returnObjects: true,
                      }) as string[]
                    ).map((step, index) => (
                      <Text key={step} size="sm" tag="p" variant="muted">
                        {index + 1}. {step}
                      </Text>
                    ))}
                  </div>
                ),
              },
              {
                id: "8",
                title: (
                  <Text tag="h2" weight="medium" variant="current">
                    {t("whatAreRisks.q")}
                  </Text>
                ),
                content: (
                  <div className="flex flex-col gap-2">
                    <Text size="sm" tag="p" variant="muted">
                      {t("whatAreRisks.a")}
                    </Text>
                    {(
                      t("whatAreRisks.a", {
                        context: "steps",
                        returnObjects: true,
                      }) as string[]
                    ).map((step, index) => (
                      <Text key={step} size="sm" tag="p" variant="muted">
                        {index + 1}. {step}
                      </Text>
                    ))}
                    <Text size="sm" tag="p" variant="muted">
                      {t("whatAreRisks.a", { context: "conclusion" })}
                    </Text>
                  </div>
                ),
              },
            ]}
            type="single"
          />
        </motion.div>
      </ControlCenterContentNode>

      <ControlCenterContentFooter title="SundaeSwap FAQs." />
    </>
  );
};

export default memo(ControlCenterHelpCenter);
