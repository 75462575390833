import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TSearchParams } from "../../pages/routes";
import { decodeSearchParams } from "../../utils/routes.utils";
import useAppLocation from "./useAppLocation";

export interface IRouterNextOptions {
  hash?: string;
  to?: string;
  search?:
    | TSearchParams
    | ((currentSearchParams: TSearchParams) => TSearchParams);
}

const useAppNavigate = () => {
  const [searchParams] = useSearchParams();
  const routerNavigate = useNavigate();
  const { pathname } = useAppLocation();

  const currentSearchParams = decodeSearchParams(searchParams);

  const navigate = (args: IRouterNextOptions) => {
    const { hash, to, search } = args;
    routerNavigate({
      pathname: to || pathname,
      ...(hash && { hash }),
      ...(search && {
        search: `${createSearchParams(
          typeof search === "function" ? search(currentSearchParams) : search,
        )}`,
      }),
    });
  };

  return navigate;
};

export default useAppNavigate;
