import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import { Step } from "react-joyride";

import { Guides } from "../../../../../components/JoyrideStepComponents";
import { joyrideIds } from "../../../../../constants/joyride.constants";
import {
  EGuideChapter,
  IGuide,
} from "../../../../../stores/ControlCenterContext";

export const useTasteTestExplanationGuideJoyride = (): IGuide => {
  const { t } = useI18N("joyride");
  const defaultStepOptions: Partial<Step> = {
    placement: "center",
    target: "body",
  };

  return useMemo(
    () =>
      ({
        rank: 1,
        description: t("tasteTest.explanationGuide.description"),
        title: t("tasteTest.explanationGuide.title"),
        chapter: EGuideChapter.TASTE_TEST,
        joyride: {
          id: joyrideIds.tasteTest.explanationGuide,
          steps: [
            {
              ...defaultStepOptions,
              locale: {
                next: "Start Guide",
              },
              title: <Guides.TasteTest.Explanation.Titles.Step1Title />,
              content: <Guides.TasteTest.Explanation.Steps.Step1 />,
            },
            {
              ...defaultStepOptions,
              title: "The issue with token sales",
              content: <Guides.TasteTest.Explanation.Steps.Step2 />,
            },
            {
              ...defaultStepOptions,
              title: "Volatility as the biggest problem",
              content: <Guides.TasteTest.Explanation.Steps.Step3 />,
            },
            {
              ...defaultStepOptions,
              title: "Price Discovery",
              content: <Guides.TasteTest.Explanation.Steps.Step4 />,
            },
            {
              ...defaultStepOptions,
              title: "Price Discovery",
              content: <Guides.TasteTest.Explanation.Steps.Step5 />,
            },
            {
              ...defaultStepOptions,
              title: "Price Discovery",
              content: <Guides.TasteTest.Explanation.Steps.Step6 />,
            },
            {
              ...defaultStepOptions,
              title: "Price Discovery",
              content: <Guides.TasteTest.Explanation.Steps.Step7 />,
            },
          ] as Step[],
        },
      }) as IGuide,
    [defaultStepOptions, t],
  );
};
