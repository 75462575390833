// import { TStatsTick } from "../../hooks/useTicksApi";
import { ILiquidity } from "../../types/Liquidity.types";
import { IOperation } from "../../types/Operation.types";
import { TPool } from "../../types/Pool.types";
import {
  AssetBrambleFragmentFragment,
  LiquidityBrambleFragmentFragment,
  // FetchPoolTicksCompactQuery,
  OrderBrambleFragmentFragment,
  PoolBrambleFragmentFragment,
  TransactionOutputFragmentFragment,
} from "../generated/bramble.sdk";
import {
  AssetFragmentFragment,
  LiquidityFeesFragmentFragment,
} from "../generated/stats2.sdk";
import {
  getOrderOpFromBramble,
  getOutcomeFromBramble,
  getSpentReasonFromBramble,
  transformBrambleAsset,
  transformBramblePool,
} from "./transformers";

export interface IBrambleTransformResponse<
  BrambleSchema = unknown,
  StatsSchema = unknown,
> {
  bramble: BrambleSchema;
  stats: StatsSchema;
}

/**
 * The BrambleTransformer is a simple class that handles
 * API response data transformations. Because Bramble is a new
 * API that merges GraphQL fields, the responses are usually
 * formatted different with sometimes different field names and
 * additions when compared to the old stats API.
 *
 * The intent of this class is to be an interim solution for
 * backwards compatibility until we are done progressively upgrading
 * Stats-centric components to use the new Bramble types.
 */
export class BrambleTransformer {
  /**
   * Transforms a single asset data returned by the Bramble API into a format that is
   * compatible with the older Stats API, ensuring seamless integration and backward compatibility.
   * This transformation involves mapping fields from the Bramble asset data format to the
   * corresponding fields in the Stats API asset data format.
   *
   * @param {AssetBrambleFragmentFragment} data - The asset data from the Bramble API to be transformed.
   * @returns {IBrambleTransformResponse<AssetBrambleFragmentFragment, AssetFragmentFragment>} An object containing both the original Bramble asset data and the transformed Stats API compatible asset data. This allows for easy comparison, testing, or gradual migration to the new API structures.
   */
  static transformBrambleAssetFragment(
    data: AssetBrambleFragmentFragment,
  ): IBrambleTransformResponse<
    AssetBrambleFragmentFragment,
    AssetFragmentFragment
  > {
    const stats = transformBrambleAsset(data);

    return {
      bramble: data,
      stats,
    };
  }

  /**
   * Transforms asset data returned by the Bramble API into a
   * backwards-compatible object structure to match the old Stats API.
   *
   * This method is specifically designed to handle the transformation of
   * asset data. It maps through each item in the provided array, applying
   * a transformation function (`transformBrambleAsset`) to each element.
   * This function is intended to convert the Bramble asset data format into
   * a format that is compatible with the older Stats API, ensuring seamless
   * integration and backward compatibility.
   *
   * The method returns an object containing both the original Bramble data
   * and the transformed data. This dual data structure is useful for
   * comparison, testing, or gradual migration of components from old
   * to new API structures.
   *
   * @param {AssetBrambleFragmentFragment[]} data - The array of asset data from the Bramble API.
   * @returns {IBrambleTransformResponse<AssetBrambleFragmentFragment[], AssetFragmentFragment[]>}
   * An object containing the original Bramble data and the transformed data for Stats API compatibility.
   */
  static transformBrambleAssetsFragment(
    data: AssetBrambleFragmentFragment[],
  ): IBrambleTransformResponse<
    AssetBrambleFragmentFragment[],
    AssetFragmentFragment[]
  > {
    const stats = data.map((bramble) => transformBrambleAsset(bramble));

    return {
      bramble: data,
      stats,
    };
  }

  /**
   * Transforms pool data returned by the Bramble API into a
   * backwards-compatible object structure to match the old Stats API.
   *
   * @param {IBrambleTransformResponse} data The response data from Bramble about a pool.
   * @returns {IBrambleTransformResponse<PoolBrambleFragmentFragment, TPool>} The transformed data.
   */
  static transformBramblePoolFragment(
    data: PoolBrambleFragmentFragment,
    adaUsdPrice?: string,
  ): IBrambleTransformResponse<PoolBrambleFragmentFragment, TPool> {
    return {
      bramble: data,
      stats: transformBramblePool(data, adaUsdPrice),
    };
  }

  /**
   * Transforms pools data returned by the Bramble API into a
   * backwards-compatible object structure to match the old Stats API.
   *
   * @param {IBrambleTransformResponse[]} data The response data from Bramble about a pool.
   * @returns {IBrambleTransformResponse<PoolBrambleFragmentFragment[], TPool[]>} The transformed data.
   */
  static transformBramblePoolsFragment(
    data: PoolBrambleFragmentFragment[],
    adaUsdPrice?: string,
  ): IBrambleTransformResponse<PoolBrambleFragmentFragment[], TPool[]> {
    const stats = data.map((bramble) =>
      transformBramblePool(bramble, adaUsdPrice),
    );

    return {
      bramble: data,
      stats,
    };
  }

  /**
   * Transforms open order data returned by the Bramble API into a
   * backwards-compatible structure that matches the old Stats API.
   *
   * This method is designed to handle the transformation of open order data.
   * It iterates over each open order in the provided array, transforming
   * each order into a format compatible with the older Stats API. This ensures
   * seamless integration and backward compatibility with components that
   * rely on the older API structure.
   *
   * The transformation includes mapping each Bramble order to an object structure
   * expected by the Stats API, including the creation of a transaction object and
   * setting default values for fields not provided by Bramble.
   *
   * @param {OrderBrambleFragmentFragment[]} data - The array of open order data from the Bramble API.
   * @param {string} address - The address associated with the open orders.
   * @returns {IBrambleTransformResponse<OrderBrambleFragmentFragment[], IOperation[]>}
   * An object containing the original Bramble data and the transformed data for Stats API compatibility.
   */
  static transformBrambleOpenOrders(
    data: OrderBrambleFragmentFragment[],
    address?: string,
    adaUsdPrice?: string,
  ): IBrambleTransformResponse<OrderBrambleFragmentFragment[], IOperation[]> {
    const stats = data
      .filter(({ outcome }) => outcome === null)
      .map((item) => ({
        address,
        createdAt: item.createdAt,
        op: getOrderOpFromBramble(item),
        outcome: null,
        pool:
          item.pool &&
          transformBramblePool(
            item.pool as PoolBrambleFragmentFragment,
            adaUsdPrice,
          ),
        spentAt: null,
        spentReason: null,
        spentTxIn: null,
        txIn: {
          index: Number(item.id.split("#")[1]),
          txHash: item.id.split("#")[0],
        },
      })) as IOperation[];

    return {
      bramble: data,
      stats,
    };
  }

  /**
   * Transforms order data returned by the Bramble API into a
   * backwards-compatible structure that matches the old Stats API.
   *
   * This method is designed to handle the transformation of order data,
   * focusing specifically on orders with a non-null outcome. It iterates
   * over each order in the provided array, transforming each one into
   * a format compatible with the older Stats API. This transformation is
   * critical for ensuring that components relying on the old API structure
   * can seamlessly integrate with the new data format provided by Bramble.
   *
   * The transformation includes mapping each Bramble order to an object structure
   * expected by the Stats API, including the creation of a transaction object and
   * setting default values for fields not provided by Bramble.
   *
   * @param {OrderBrambleFragmentFragment[]} data - The array of order data from the Bramble API.
   * @returns {IBrambleTransformResponse<OrderBrambleFragmentFragment[], IOperation[]>}
   * An object containing the original Bramble data and the transformed data for Stats API compatibility.
   */
  static transformBrambleOrders(
    data: OrderBrambleFragmentFragment[],
    address: string,
    adaUsdPrice?: string,
  ): IBrambleTransformResponse<OrderBrambleFragmentFragment[], IOperation[]> {
    const stats = data
      .filter(({ outcome }) => outcome !== null)
      .map((item) => ({
        address,
        createdAt: item.createdAt,
        op: getOrderOpFromBramble(item),
        outcome: getOutcomeFromBramble(item),
        pool:
          item.pool &&
          transformBramblePool(
            item.pool as PoolBrambleFragmentFragment,
            adaUsdPrice,
          ),
        spentAt: item.spentAt?.format,
        spentReason: getSpentReasonFromBramble(item.outcome),
        spentTxIn: {
          index: 0,
          txHash: item.spentTxHash,
        },
        txIn: {
          index: 0,
          txHash: item.id,
        },
      })) as IOperation[];

    return {
      bramble: data,
      stats,
    };
  }

  /**
   * Transforms liquidity data returned by the Bramble API into a
   * backwards-compatible structure that matches the old Stats API.
   *
   * This method is designed to handle the transformation of liquidity data,
   * specifically liquidity operations such as adding or removing liquidity from a pool.
   * It maps through each item in the provided array, applying a transformation function
   * to convert Bramble liquidity data into a format that is compatible with the
   * older Stats API. This ensures seamless integration and backward compatibility
   * with components and logic that rely on the older API structure.
   *
   * The transformation includes mapping each Bramble liquidity item to an object
   * structure expected by the Stats API. This includes converting asset and fee
   * information into a format that can be easily consumed by the old API, ensuring
   * that all relevant data is retained and accurately represented.
   *
   * @param {LiquidityBrambleFragmentFragment[]} data - The array of liquidity data from the Bramble API.
   * @returns {IBrambleTransformResponse<LiquidityBrambleFragmentFragment[], ILiquidity[]>}
   * An object containing the original Bramble data and the transformed data for Stats API compatibility.
   */
  static transformBrambleLiquidity(
    data: LiquidityBrambleFragmentFragment[],
    adaUsdPrice?: string,
  ): IBrambleTransformResponse<
    LiquidityBrambleFragmentFragment[],
    ILiquidity[]
  > {
    const stats: ILiquidity[] = data.map((item) => ({
      feesA: {
        quantity: item.fees.assetA.quantity,
        asset: transformBrambleAsset(item.fees.assetA.asset),
      },
      feesB: {
        quantity: item.fees.assetB.quantity,
        asset: transformBrambleAsset(item.fees.assetB.asset),
      },
      pool: transformBramblePool(item.pool, adaUsdPrice),
      lpTokens: {
        asset: transformBrambleAsset(item.quantity.asset),
        quantity: item.quantity.quantity,
      },
      poolShare:
        Number(item.quantity.quantity) /
        Number(item.pool.current?.quantityLP.quantity),
    }));

    return {
      bramble: data,
      stats,
    };
  }

  /**
   * Transforms transaction output data returned by the Bramble API into a
   * backwards-compatible structure that matches the old Stats API for liquidity fees.
   *
   * This method is specifically designed to handle the transformation of liquidity fee data.
   * It aggregates fees from multiple transactions related to the same pool, creating a consolidated
   * view of fees in assets A and B. This transformation is crucial for ensuring compatibility
   * with components and logic that expect data structured according to the old Stats API.
   *
   * The method iterates over each transaction output, grouping fees by pool ID. For each pool,
   * it sums the fees in assets A and B separately. This aggregated data is then transformed to match
   * the structure expected by the old Stats API, facilitating seamless integration and backward compatibility.
   *
   * @param {TransactionOutputFragmentFragment[]} data - The array of transaction output data from the Bramble API.
   * @returns {IBrambleTransformResponse<TransactionOutputFragmentFragment[], LiquidityFeesFragmentFragment[]>}
   * An object containing the original Bramble data and the transformed data for Stats API compatibility. The transformed
   * data is an array of liquidity fee information for each pool, with fees in assets A and B aggregated across transactions.
   */
  static transformBrambleLiquidityFees(
    data: TransactionOutputFragmentFragment[],
    adaUsdPrice?: string,
  ): IBrambleTransformResponse<
    TransactionOutputFragmentFragment[],
    LiquidityFeesFragmentFragment[]
  > {
    const groupedFees = data.reduce(
      (
        acc: { [key: string]: LiquidityFeesFragmentFragment },
        liquidityFees,
      ) => {
        const { fees } = liquidityFees;
        if (acc[fees[0].pool.id]) {
          acc[fees[0].pool.id] = {
            ...acc[fees[0].pool.id],
            feesA: {
              ...acc[fees[0].pool.id].feesA,
              quantity: String(
                Number(acc[fees[0].pool.id].feesA.quantity) +
                  Number(fees[0].fees.assetA.quantity),
              ),
            },
            feesB: {
              ...acc[fees[0].pool.id].feesB,
              quantity: String(
                Number(acc[fees[0].pool.id].feesB.quantity) +
                  Number(fees[0].fees.assetB.quantity),
              ),
            },
          };
        } else {
          acc[fees[0].pool.id] = {
            feesA: {
              asset: transformBrambleAsset(fees[0].fees.assetA.asset),
              quantity: fees[0].fees.assetA.quantity,
            },
            feesB: {
              asset: transformBrambleAsset(fees[0].fees.assetB.asset),
              quantity: fees[0].fees.assetB.quantity,
            },
            pool: transformBramblePool(fees[0].pool, adaUsdPrice),
          };
        }

        return acc;
      },
      {},
    );

    return {
      bramble: data,
      stats: Object.values(groupedFees),
    };
  }

  /**
   * Transforms an array of pool routes data returned by the Bramble API into a
   * backwards-compatible structure that matches the old Stats API. This method
   * is designed to handle route-based pool data, where each route consists of a series
   * of pools that form a path from one asset to another through various intermediate pools.
   *
   * This transformation involves converting the nested array of pool data received from
   * Bramble into a nested array of pool data structured according to the Stats API format.
   * Each pool in the route is individually transformed to ensure that all necessary data
   * conform to the expected structure of older components or logic that rely on the Stats API.
   *
   * Additionally, the ADA to USD price can be provided to apply value transformations where necessary,
   * ensuring that monetary values are consistent and comparable across different data structures.
   *
   * @param {PoolBrambleFragmentFragment[][]} data - The nested array of pool routes data from the Bramble API.
   * @param {string} [adaUsdPrice] - Optional parameter to provide the current ADA to USD exchange rate, used in transforming monetary values.
   * @returns {IBrambleTransformResponse<PoolBrambleFragmentFragment[][], TPool[][]>}
   * An object containing the original Bramble data and the transformed data structured according to the Stats API.
   * This allows for integration into systems and components that are not yet upgraded to handle the new data format.
   */
  static transformBramblePoolsRoutes(
    data: PoolBrambleFragmentFragment[][],
    adaUsdPrice?: string,
  ): IBrambleTransformResponse<PoolBrambleFragmentFragment[][], TPool[][]> {
    const stats = data.map((route) =>
      route.map((pool) => transformBramblePool(pool, adaUsdPrice)),
    );

    return {
      bramble: data,
      stats,
    };
  }
}
