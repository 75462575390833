import Step0 from "./steps/Step0";
import Step1 from "./steps/Step1";
import Step10 from "./steps/Step10";
import Step5 from "./steps/Step5";
import Step6 from "./steps/Step6";
import Step7 from "./steps/Step7";
import Step8 from "./steps/Step8";
import Step9 from "./steps/Step9";

export const ComparisonSteps = {
  Step0,
  Step1,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  Step10,
};
