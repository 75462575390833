import { AHooks } from "@sundaeswap/react-hooks";

export const getImagePathByTheme = (
  path: string,
  isDarkMode = false,
  separator = "_",
  fileType?: "jpg" | "png" | "gif" | "webp",
) => `${path}${isDarkMode ? `${separator}dark` : ""}.${fileType ?? "png"}`;

/**
 * Replaces the ifps protocol with https
 * @param path - The path to the image
 * @returns - The path to the image with the ifps protocol replaced with https
 */
export const getIpfsImagePath = (path?: string) => {
  if (!path) return undefined;
  return path.replace("ipfs://", "https://ipfs.io/ipfs/");
};

/**
 * Returns the size of the image to use for the no data table image
 * @param responsive - The responsive object returned from our `useResponsive` hook from @sundaeswap/react-hooks
 * @returns - The size of the image to use for the no data table image
 */
export const getNoDataTableImageSize = (
  responsive: ReturnType<typeof AHooks.useResponsive>,
) => {
  if (responsive.lg) {
    return 275; // 275px
  }

  return 125; // 125px
};
