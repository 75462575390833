export enum ETransactionState {
  initial = "initial",
  preview = "preview",
  previewCancelled = "previewCancelled",
  submitting = "submitting",
  submittingCancelled = "submittingCancelled",
  success = "success",
  error = "error",
  reset = "reset",
}
