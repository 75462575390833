import { Text } from "@sundaeswap/ui-toolkit";
import { FC } from "react";
import { Trans } from "react-i18next";

const V3ComparisonStep5: FC = () => {
  return (
    <>
      <div
        className="absolute left-0 top-0 z-[-1] h-full w-full bg-cover bg-no-repeat opacity-10"
        style={{
          backgroundImage: `url("/static/images/v3/lower_fees.png")`,
        }}
      />
      <div className="flex flex-col gap-4">
        <Trans
          i18nKey="v3.guides.comparison.steps.step5.description"
          ns="joyride"
        >
          <Text tag="p" size="sm">
            The biggest advantage for users of the SundaeSwap protocol is the
            lowered protocol fees. The fee that was previously 2.5 ADA has been
            lowered to a maximum of 1 ADA per trade. Additionally, by vote of
            the Sundae DAO, there is a 50% discount for the first 3 months!
          </Text>
          <Text tag="p" size="sm">
            Additionally, if there are many people swapping against the pool,
            the fees get cheaper! You may receive a portion of your fee back
            with the result of your trade.
          </Text>
        </Trans>
      </div>
    </>
  );
};

export default V3ComparisonStep5;
