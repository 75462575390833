import { THooks } from "@sundaeswap/react-hooks";
import { useWalletObserver } from "@sundaeswap/wallet-lite";
import deepmerge from "deepmerge";
import hasIn from "lodash/hasIn";
import omitBy from "lodash/omitBy";
import {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { DEFAULT_SLIPPAGE_TOLERANCE } from "../../constants/exchange.constants";
import { LOCAL_STORAGE_KEY } from "../../constants/localStorage.constants";
import { featureIsEnabled } from "../../utils/features.utils";
import { ProfileAppReducer } from "./reducer";
import {
  ECardanoExplorer,
  EFarmsFilter,
  EFarmsViewMode,
  EProfileAppActions,
  IProfileAppAction,
  IProfileAppContext,
  IProfileAppState,
} from "./types";

const defaultPersistentState: IProfileAppState = {
  activeLanguage: "en",
  avatar: null,
  cardanoExplorer: ECardanoExplorer.cexplorer,
  errorReportingEnabled: true,
  farmsViewMode: EFarmsViewMode.list,
  farmsFilter: EFarmsFilter.all,
  favoritePools: [],
  filters: {
    pools: {
      contractVersion: [],
      lpFee: [],
    },
  },
  isSlippageToleranceSetToAuto: true,
  toggles: {
    orders: {
      canceled: false,
    },
    pools: {
      favorites: false,
    },
  },
  handle: null,
  limitOrders: [],
  prefersReducedMotion:
    typeof window.matchMedia === "function" // jest using jsdom and jsdom has no `matchMedia` object, so we check whether `matchMedia` is available first or fallback to `false`
      ? window.matchMedia("(prefers-reduced-motion: reduce)").matches
      : false,
  privacyEnabled: false,
  slippageTolerance: DEFAULT_SLIPPAGE_TOLERANCE,

  // Do not pollute the users localStorage with features that are not enabled.
  ...(featureIsEnabled("hotjar") && {
    hotjarEnabled: true,
  }),
};

export const ProfileAppContext: Context<IProfileAppContext> = createContext({
  state: defaultPersistentState,
  dispatch: (_value: IProfileAppAction) => {},
});

export const ProfileAppContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { mainAddress } = useWalletObserver();
  const [savedState, saveState, remove] =
    THooks.useLocalStorage<IProfileAppState>(
      `${LOCAL_STORAGE_KEY}-${mainAddress}`,
      defaultPersistentState,
    );

  const persistentReducer = useCallback(
    (state: IProfileAppState, action: IProfileAppAction): IProfileAppState => {
      const newState = ProfileAppReducer(state, action, defaultPersistentState);
      if (action.type === EProfileAppActions.LOGOUT) {
        remove();
      } else {
        saveState(newState);
      }

      return newState;
    },
    [saveState, remove],
  );

  const hydratedState = useMemo(() => {
    const hydrated = deepmerge(
      defaultPersistentState,
      omitBy(savedState, (_value, key) => {
        if (!hasIn(defaultPersistentState, key)) {
          return true;
        }

        return false;
      }),
    );

    return {
      ...defaultPersistentState,
      ...hydrated,
    };
  }, [savedState, defaultPersistentState]);

  const [state, dispatch] = useReducer(persistentReducer, hydratedState);

  const memoizedDispatch = useMemo(() => dispatch, []);

  return (
    <ProfileAppContext.Provider value={{ state, dispatch: memoizedDispatch }}>
      {children}
    </ProfileAppContext.Provider>
  );
};
