import { Text } from "@sundaeswap/ui-toolkit";
import { FC } from "react";

const TasteTestInteractiveGuideStep5: FC = () => {
  return (
    <div className="grid gap-4">
      <Text tag="p" size="sm">
        If you want to contribute to a Taste Test, the "Explore Taste Test"
        button will take you to the Taste Test page.
      </Text>
      <Text tag="p" size="sm">
        As soon as you made a deposit, the button will update and say "Manage
        Contribution", so you always know about your current status.
      </Text>
    </div>
  );
};

export default TasteTestInteractiveGuideStep5;
