import { AssetAmount } from "@sundaeswap/asset";
import { SundaeSDK } from "@sundaeswap/core";
import { type MouseEventParams, type Time } from "lightweight-charts";
import { Dispatch } from "react";
import { FetchProtocolsQuery } from "../../gql/generated/bramble.sdk";
import { IAssetMetaData } from "../../types/Asset.types";
import { TPool } from "../../types/Pool.types";

interface ITradeOnChartParams {
  params: MouseEventParams<Time>;
  price: number;
}

export enum EAppActions {
  /* -------------------------------------------------------------------------------------------------
   * App
   * -----------------------------------------------------------------------------------------------*/
  SET_DISPLAYED_ORDER_ROUTE_INDEX = "SET_DISPLAYED_ORDER_ROUTE_INDEX",
  SET_SDK = "SET_SDK",
  SET_CONNECTED_WALLET = "SET_CONNECTED_WALLET",
  SET_ERROR_REPORTING = "SET_ERROR_REPORTING",
  SET_OFFCANVAS_OPEN = "SET_OFFCANVAS_OPEN",
  SET_POOLS_SORTING_DIRECTION = "SET_POOLS_SORTING_DIRECTION",
  SET_POOLS_SORT_BY = "SET_POOLS_SORT_BY",
  SET_ACTIVE_ZAP_ASSET = "SET_ACTIVE_ZAP_ASSET",
  SET_ACTIVE_ZAP_POOL = "SET_ACTIVE_ZAP_POOL",
  SET_EXCHANGE_WIDGET_NAVIGATION_FROM = "SET_EXCHANGE_WIDGET_NAVIGATION_FROM",
  SET_LATEST_TX_HASH = "SET_LATEST_TX_HASH",
  SET_DIALOG_OPEN = "SET_DIALOG_OPEN",
  SET_COLLAPSIBLE_OPEN = "SET_COLLAPSIBLE_OPEN",
  SET_TASTE_TEST_ACTIVE_SCROLL_INDEX = "SET_TASTE_TEST_ACTIVE_SCROLL_INDEX",
  SET_V3_SCOOPER_FEE = "SET_V3_SCOOPER_FEE",
  SET_TRADE_ON_CHART_HOVER_PARAMS = "SET_TRADE_ON_CHART_HOVER_PARAMS",
  SET_TRADE_ON_CHART_DIALOG_PARAMS = "SET_TRADE_ON_CHART_DIALOG_PARAMS",
}

export interface IAppAction {
  type: string;
  // Payload types are inforced in ./actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

interface IOpenState {
  isOpen: boolean;
}

interface IDialogState extends IOpenState {
  context?: "given" | "taken" | undefined;
}

export enum ETasteTestScrollIndex {
  JUMBOTRON = 0,
  BENEFITS = 1,
  UPCOMING = 2,
  ALL = 3,
  FAQ = 4,
}

export enum EPoolTableSortingOptions {
  apy = "apy",
  change24 = "change24",
  fees24 = "fees24",
  price = "price",
  totalValueLocked = "totalValueLocked",
  volume24 = "volume24",
  poolName = "poolName",
}

export enum ESortingDirection {
  asc = "asc",
  desc = "desc",
}

export interface IAppState {
  displayedOrderRouteIndex: number;
  sdk: SundaeSDK | null;
  V3MaxScooperFee?: bigint;
  collapsibles: {
    orderFeeBreakdown: IOpenState;
  };
  dialogs: {
    assetSelect: IDialogState;
    liquidity: {
      createPool: IDialogState;
      deposit: IDialogState;
      migrate: IDialogState;
      withdraw: IDialogState;
      unlock: IDialogState;
      zap: IDialogState;
      zapPoolSelect: IDialogState;
    };
    pickAvatar: IDialogState;
    pickHandle: IDialogState;
    swap: IDialogState;
    walletConnect: IDialogState;
    wrongNetwork: IDialogState;
    cancellation: IDialogState;
  };
  offCanvasOpen: boolean;
  sortPoolsSortingDirection?: ESortingDirection;
  sortPoolsBy?: EPoolTableSortingOptions;
  activeZapAsset?: AssetAmount<IAssetMetaData>;
  activeZapPool?: TPool;
  latestTxHash?: string;
  protocols: FetchProtocolsQuery["protocols"];
  tasteTestActiveScrollIndex: number;
  tradeOnChartHoverCardParams?: ITradeOnChartParams;
  tradeOnChartDialogParams?: ITradeOnChartParams;
}

export interface IAppContext {
  state: IAppState;
  dispatch: Dispatch<IAppAction>;
}

// Type for the keys of all dialog keys excluding the nested objects
export type TRootDialogKeys = Exclude<
  keyof IAppState["dialogs"],
  "liquidity" | "tasteTest"
>;
// Types for the keys of the nested objects
type TLiquidityKeys = keyof IAppState["dialogs"]["liquidity"];

export type TActionFunc<T> = (value: T, dispatch: Dispatch<IAppAction>) => void;
export type TDialogActionPayload = {
  context?: string;
  dialog:
    | `${Extract<"liquidity", keyof IAppState["dialogs"]>}.${TLiquidityKeys}`
    | TRootDialogKeys;
  isOpen: boolean;
};
export type TCollapsibleActionPayload = {
  collapsible: keyof IAppState["collapsibles"];
  isOpen: boolean;
};
