import { IOperation } from "../../types/Operation.types";
import { TPool } from "../../types/Pool.types";
import { calculatePoolRatio, getIsExoticPair } from "../../utils/pool.utils";
import { unixToSlot } from "../../utils/slots.utils";
import {
  AssetBrambleFragmentFragment,
  DepositDetailsFragmentFragment,
  OrderBrambleFragmentFragment,
  PoolBrambleFragmentFragment,
  SwapDetailsFragmentFragment,
  WithdrawDetailsFragmentFragment,
} from "../generated/bramble.sdk";
import {
  AssetFragmentFragment,
  Resolution,
  TokenMetadataSource,
} from "../generated/stats2.sdk";

export const transformBrambleAsset = (
  data: AssetBrambleFragmentFragment,
): AssetFragmentFragment => {
  return {
    assetId: data.id,
    dateListed: data.dateListed?.format ?? "",
    decimals: data.decimals,
    policyId: data.policyId,
    poolId: "",
    slotAdded: data.dateListed?.format
      ? unixToSlot(new Date(data.dateListed.format).getTime() / 1000)
      : 0,
    assetName: data.assetName,
    logo: data.logo,
    ticker: data.ticker ?? data.name,
    sources: (data.metadata?.map(({ __typename }) => __typename) ??
      []) as TokenMetadataSource[],
    // @ts-expect-error: AssetFragmentFragment doesn't have description field
    description: data.description,
  };
};

export const transformBramblePool = (
  data: PoolBrambleFragmentFragment,
  adaUsdPrice = "0",
): TPool => {
  const assetA = transformBrambleAsset(data.assetA);
  const assetB = transformBrambleAsset(data.assetB);

  const isExoticPair = getIsExoticPair({ assetA, assetB });

  const poolRatio = calculatePoolRatio({
    assetA,
    assetB,
    quantityA: data.current?.quantityA.quantity,
    quantityB: data.current?.quantityB.quantity,
  });

  const priceToday = String(Number(adaUsdPrice) * (poolRatio?.toNumber() ?? 0));

  return {
    assetA: { ...assetA, ...(!isExoticPair && { priceToday }) },
    assetB: { ...assetB, ...(!isExoticPair && { priceToday }) },
    assetID: data.id,
    assetLP: transformBrambleAsset(data.assetLP),
    askFee: data.askFee,
    bidFee: data.bidFee,
    ident: data.id,
    marketOpen: data.marketOpen,
    name: `${data.assetA.ticker}-${data.assetB.ticker}`,
    quantityA: data.current?.quantityA.quantity,
    quantityB: data.current?.quantityB.quantity,
    quantityLP: data.current?.quantityLP.quantity,
    rewards: [],
    version: data.version,
  };
};

export const getOrderOpFromBramble = (
  data: OrderBrambleFragmentFragment,
): IOperation["op"] => {
  const { details, pool } = data;
  const { __typename } = details;

  switch (__typename) {
    case "Deposit": {
      const { offerDeposit, receivedDeposit } =
        data.details as DepositDetailsFragmentFragment;
      const depositMixedQuantityA = offerDeposit.find(
        ({ asset }) => asset.id === pool?.assetA.id,
      )?.quantity;
      const depositMixedQuantityB = offerDeposit.find(
        ({ asset }) => asset.id === pool?.assetB.id,
      )?.quantity;

      return {
        __typename: "OpDepositMixed",
        depositMixedLq: receivedDeposit?.quantity,
        depositMixedQuantityA,
        depositMixedQuantityB,
      };
    }
    case "Withdraw": {
      const { amountWithdraw, receivedWithdraw } =
        data.details as WithdrawDetailsFragmentFragment;
      return {
        __typename: "OpWithdraw",
        withdrawLq: amountWithdraw.quantity,
        withdrawQuantityA: receivedWithdraw.find(
          ({ asset }) => asset.id === pool?.assetA?.id,
        )?.quantity,
        withdrawQuantityB: receivedWithdraw.find(
          ({ asset }) => asset.id === pool?.assetB?.id,
        )?.quantity,
      };
    }
    default:
    case "Swap":
      const { offerSwap, receivedSwap, minimumSwap } =
        data.details as SwapDetailsFragmentFragment;
      const swapQuantityA =
        offerSwap.asset.id === pool?.assetA.id ? offerSwap.quantity : null;
      const swapQuantityB =
        offerSwap.asset.id === pool?.assetB.id ? offerSwap.quantity : null;

      return {
        __typename: "OpSwap",
        swapGot: receivedSwap?.quantity,
        swapQuantityA,
        swapQuantityB,
        swapWant: minimumSwap.quantity,
      };
  }
};

export const getOutcomeFromBramble = (data: OrderBrambleFragmentFragment) => {
  const { details, pool } = data;

  let quantityA: string | null = null;
  let quantityB: string | null = null;
  let quantityLP: string | null = null;

  switch (details.__typename) {
    case "Deposit":
      const { receivedDeposit } = details as DepositDetailsFragmentFragment;
      quantityLP = receivedDeposit?.quantity as string;
      break;
    case "Withdraw":
      const { receivedWithdraw } = details as WithdrawDetailsFragmentFragment;
      quantityA = receivedWithdraw.find(
        ({ asset }) => asset.id === pool?.assetA.id,
      )?.quantity;
      quantityB = receivedWithdraw.find(
        ({ asset }) => asset.id === pool?.assetB.id,
      )?.quantity;
      break;
    default:
    case "Swap":
      const { receivedSwap } = details as SwapDetailsFragmentFragment;

      quantityA =
        receivedSwap?.asset.id === pool?.assetA.id
          ? receivedSwap?.quantity
          : null;
      quantityB =
        receivedSwap?.asset.id === pool?.assetB.id
          ? receivedSwap?.quantity
          : null;
  }

  return {
    feeA: null,
    feeB: null,
    quantityA,
    quantityB,
    quantityLP,
    price: {
      unit: "",
      value: "",
    },
  };
};

export const getSpentReasonFromBramble = (
  outcome: OrderBrambleFragmentFragment["outcome"],
) => {
  if (outcome === "Executed") return Resolution.Scooped;
  return Resolution.Canceled;
};
