import { ENewsActions, INewsAction, IPersistedNewsState } from "./types";

export const NewsReducer = (
  prevState: IPersistedNewsState,
  action: INewsAction,
): IPersistedNewsState => {
  let newState: IPersistedNewsState;
  switch (action.type) {
    case ENewsActions.SET_READ_NEWS_ITEM: {
      const newsRead = [...prevState.newsRead, action.payload.newsRead];
      newState = {
        ...prevState,
        newsRead: [...new Set(newsRead)],
      };
      break;
    }

    case ENewsActions.SET_UNREAD_NEWS_ITEM: {
      const newsRead = prevState.newsRead.filter(
        (newsId) => newsId !== action.payload.newsRead,
      );
      newState = {
        ...prevState,
        newsRead: [...new Set(newsRead)],
      };
      break;
    }

    case ENewsActions.SET_READ_ALL_NEWS_ITEMS: {
      const newsRead = [...prevState.newsRead, ...action.payload.newsRead];
      newState = {
        ...prevState,
        newsRead: [...new Set(newsRead)],
      };
      break;
    }

    case ENewsActions.SET_FORCE_OPENED_NEWS_ID: {
      const forceOpened = [
        ...prevState.forceOpened,
        action.payload.forceOpened,
      ];
      newState = {
        ...prevState,
        forceOpened: [...new Set(forceOpened)],
      };
      break;
    }

    default:
      return prevState;
  }

  return newState;
};
