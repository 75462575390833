import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, useMemo } from "react";
import { getControlCenterContentHeaderMotion } from "../../../motions/control-center.motions";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import Motion from "../../Motion";

interface IControlCenterContentTitleProps {
  title: string;
  onBack?: () => void;
  description: string;
}

const ControlCenterContentTitle: FC<IControlCenterContentTitleProps> = ({
  onBack,
  title,
  description,
}) => {
  const {
    state: {
      localState: { withAnimationDelay },
    },
  } = useControlCenterContext();

  const memoizedMotion = useMemo(
    () => getControlCenterContentHeaderMotion(withAnimationDelay),
    [withAnimationDelay],
  );

  return (
    <Motion
      animationKey="ControlCenterContentTitle"
      variants={memoizedMotion}
      className={cx(
        "grid gap-2",
        "border-b border-b-gray-300 dark:border-b-gray-600",
        toolkitCx.layout.spacing.horizontal.md,
        toolkitCx.layout.spacing.bottom.md,
      )}
    >
      {onBack && (
        <div
          className={cx(
            "w-fit cursor-pointer hover:-translate-x-1",
            toolkitCx.layout.transition.transform,
          )}
          role="button"
          onClick={onBack}
        >
          <ArrowLeftIcon
            className={cx("h-5 w-5", toolkitCx.layout.text.default)}
          />
        </div>
      )}

      <Text tag="h1" size="2xl" weight="medium">
        {title}
      </Text>
      <Text size="sm" tag="p" variant="muted">
        {description}
      </Text>
    </Motion>
  );
};

export default ControlCenterContentTitle;
