export const lazyInit = <T>(
  initializer: () => Promise<T>,
): (() => Promise<T>) => {
  let value: T;
  return async () => {
    if (value === undefined) {
      value = await initializer();
    }
    return value;
  };
};
