import { AssetAmount } from "@sundaeswap/asset";
import { TUTXO } from "@sundaeswap/core";
import { TableV2Types } from "@sundaeswap/ui-toolkit";
import { Dispatch, ReactNode } from "react";
import { FreezerItem } from "../../gql/generated/stats2.sdk";
import { IPendingOrder } from "../../gql/hooks/operations.query";
import { IAssetMetaData } from "../../types/Asset.types";
import { EOpsStatus, IOperation } from "../../types/Operation.types";
import { TPool } from "../../types/Pool.types";

export enum EPortfolioActions {
  SET_ACTIVE_TAB_TITLE = "SET_ACTIVE_TAB_TITLE",
  SET_ALL_ORDERS = "SET_ALL_ORDERS",
  SET_FARM_POSITIONS = "SET_FARM_POSITIONS",
  SET_FARMS_HISTORY = "SET_FARMS_HISTORY",
  SET_FORMATTED_ORDERS = "SET_FORMATTED_ORDERS",
  SET_IS_FETCHED_FARMS = "SET_IS_FETCHED_FARMS",
  SET_IS_FETCHING_FARMS = "SET_IS_FETCHING_FARMS",
  SET_IS_FETCHED_OPS = "SET_IS_FETCHED_OPS",
  SET_IS_FETCHING_OPS = "SET_IS_FETCHING_OPS",
  SET_IS_LOADING_PORTFOLIO_BALANCE_VALUES = "SET_IS_LOADING_PORTFOLIO_BALANCE_VALUES",
  SET_IS_REFETCHING_OPS = "SET_IS_REFETCHING_OPS",
  SET_IS_SIDEBAR_COLLAPSED = "SET_IS_SIDEBAR_COLLAPSED",
  SET_OPS_HISTORY = "SET_OPS_HISTORY",
  SET_OPS_OPEN = "SET_OPS_OPEN",
  SET_ORDERS_SEARCH_VALUE = "SET_ORDERS_SEARCH_VALUE",
  SET_ORDER_STATUS_FILTER_ITEMS = "SET_ORDER_STATUS_FILTER_ITEMS",
  SET_ORDER_STATUS_FILTER_VALUE = "SET_ORDER_STATUS_FILTER_VALUE",
  SET_SELECTED_ASSET = "SET_SELECTED_ASSET",
  SET_PORTFOLIO_BALANCE_VALUES = "SET_PORTFOLIO_BALANCE_VALUES",
  SET_TOTAL_PORTFOLIO_BALANCE_IN_ADA = "SET_TOTAL_PORTFOLIO_BALANCE_IN_ADA",
  SET_ORDERS_TABLE_COLUMN_WIDTHS = "SET_ORDERS_TABLE_COLUMN_WIDTHS",
  SET_ORDERS_TABLE_HEADERS = "SET_ORDERS_TABLE_HEADERS",
}

export enum FreezerItemStatus {
  FREEZER_LOCK = "freezerLock",
  FREEZER_UNLOCK = "freezerUnlock",
}

export type TPortfolioBalanceKey = "assets" | "liquidity" | "fees" | "earnings";

export interface IPortfolioAction {
  type: string;
  // Payload types are enforced in ./actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  // Optional dispatch key to make tracing easier.
  key?: string;
}

export interface IOrdersFilterByOpsStatusItem {
  id: string;
  value: EOpsStatus;
  label: string;
}

export interface IFormattedFreezerItem {
  data: FreezerItem & { txHash?: string };
  date: Date;
  source: FreezerItemStatus;
}

export interface IFormattedOrder {
  address?: string;
  orderData?: IOperation;
  date: Date;
  expandableContent?: ReactNode;
  getRowState?: (index: number) => { isExpanded: boolean };
  id?: string;
  input: ReactNode;
  isChainedOrder?: boolean;
  isExpanded?: boolean;
  isHighlighted?: boolean;
  isLimitOrder?: boolean;
  isUpdatable?: boolean;
  order: string;
  output: ReactNode;
  pool?: TPool | null;
  privacyEnabled?: boolean;
  spentTxHash?: string;
  status: EOpsStatus;
  swapQuantityA?: string | null;
  swapQuantityB?: string | null;
  swapWant?: string;
  type?: ReactNode;
  utxo?: TUTXO;
}

export interface IPortfolioBalanceValue {
  id: TPortfolioBalanceKey;
  joyrideSelector: string;
  title: string;
  value: AssetAmount<IAssetMetaData>;
  usdValue: number | undefined;
  tooltipLabel: string;
  percentage: number;
}

export interface IPortfolioState {
  derived?: {
    activeTabTitle?: string;
    isLoadingPortfolioBalanceValues?: boolean;
    portfolioBalanceValues?: IPortfolioBalanceValue[];
    isSidebarCollapsed?: boolean;
    totalPortfolioBalanceInAda?: AssetAmount<IAssetMetaData>;
  };
  farms?: {
    isFetchingFarms?: boolean;
    isFetchedFarms?: boolean;
    history?: FreezerItem[];
    positions?: FreezerItem[];
  };
  orders?: {
    allOrders?: (IOperation | IPendingOrder | FreezerItem)[];
    filters?: {
      orderStatus?: {
        items?: IOrdersFilterByOpsStatusItem[];
        value?: EOpsStatus;
      };
    }; // TODO: Move to Profile App Context in the future?
    formattedOrders?: IFormattedOrder[];
    isFetchedOps?: boolean;
    isFetchingOps?: boolean;
    isRefetchingOps?: boolean;
    opsHistory?: IOperation[];
    opsOpen?: IOperation[];
    ordersTableColumnWidths?: string;
    ordersTableHeaders?: TableV2Types.THeaders[];
    search?: {
      value?: string;
    };
  };
  tabs?: {
    assets?: {
      selectedAsset?: IAssetMetaData;
    };
  };
}

export interface IPortfolioContext {
  state: IPortfolioState;
  dispatch: Dispatch<IPortfolioAction>;
}
