import Step1 from "./steps/Step1";
import Step1Title from "./titles/Step1Title";

export const ExchangeModeSteps = {
  Step1,
};

export const ExchangeModeTitles = {
  Step1Title,
};
