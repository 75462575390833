import { useI18N } from "@sundaeswap/react-hooks";
import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import {
  EGuideChapter,
  IChapter,
} from "../../../../stores/ControlCenterContext";
import { useExchangeGuideJoyride } from "../guides/useExchangeGuideJoyride";

export const useTradeJoyrideChapter = (): IChapter => {
  const { t } = useI18N("joyride");
  const exchangeJoyride = useExchangeGuideJoyride();
  return useMemo(() => {
    return {
      id: EGuideChapter.EXCHANGE,
      title: t("exchange.title"),
      description: t("exchange.description"),
      guides: sortBy([exchangeJoyride], "rank", "asc"),
    };
  }, [exchangeJoyride, t]);
};
