import { ArrowUturnRightIcon } from "@heroicons/react/24/outline";
import { EventType } from "@sundaeswap/react-hooks";
import { Button, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { useEventTracking } from "../../../hooks/useEventTracking";
import { joyrideTooltipComponentMotion } from "../../../motions/joyride.motions";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import { getEnv } from "../../../utils/network.utils";
import Motion from "../../Motion";

interface IJoyrideTooltipCloseNodeProps {
  currentIndex: number;
  guideSize: number;
}

const JoyrideTooltipCloseNode: FC<IJoyrideTooltipCloseNodeProps> = ({
  currentIndex,
  guideSize,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleEventTracking = useEventTracking();
  const {
    actionHandler,
    state: {
      localState: {
        guides: { chapters },
      },
      persistentState: {
        guides: { currentGuideId, guideInfo },
      },
    },
  } = useControlCenterContext();
  const currentPersistentGuide = guideInfo[currentGuideId ?? ""];

  const handleConfirmClose = useCallback(() => {
    const chapter = chapters.find((chapter) =>
      chapter.guides.some((guide) => guide.joyride.id === currentGuideId),
    );
    const guideId = currentGuideId ?? "";
    const completed =
      currentPersistentGuide.completed || // If the guide has already been completed, do not reset the guide status
      currentIndex === guideSize;
    const stepIndex = !!currentPersistentGuide.completed ? 0 : currentIndex;

    handleEventTracking({
      eventType: EventType.Click,
      source: "JoyrideTooltipCloseNode",
      value: `guide_${currentGuideId}_confirm_close`,
      network: getEnv(),
    });
    actionHandler.guides.close(guideId, completed, stepIndex, chapter);

    /* -------------------------------------------------------------------------------------------------
     * If the user started the guide using a Magic Link, we automatically set a `guide` query parameter.
     * If the user closes the guide, we remove the `guide` query parameter from the URL to prevent the
     * guide from automatically starting again when the user navigates to a different page.
     * -----------------------------------------------------------------------------------------------*/
    if (searchParams.has("guide")) {
      searchParams.delete("guide");
      setSearchParams(searchParams);
    }
  }, [
    actionHandler,
    chapters,
    currentGuideId,
    currentPersistentGuide.completed,
    currentIndex,
    guideSize,
    searchParams,
    setSearchParams,
  ]);

  const handleContinue = useCallback(() => {
    handleEventTracking({
      eventType: EventType.Click,
      source: "JoyrideTooltipCloseNode",
      value: `guide_${currentGuideId}_continue`,
      network: getEnv(),
    });
    actionHandler.guides.continue();
  }, [actionHandler.guides, currentGuideId, handleEventTracking]);

  return (
    <Motion
      key="JoyrideTooltipCloseNode--Container"
      className={cx(
        "absolute z-10 my-auto flex h-fit w-full flex-col gap-8 text-center",
        toolkitCx.recipes.center.both(),
      )}
      variants={joyrideTooltipComponentMotion}
    >
      <div
        key="JoyrideTooltipCloseNode--Title"
        className="motion-safe:animate-fadeIn"
      >
        <Text tag="h1" size="2xl" weight="semibold">
          Are you sure you want to close this Guide?
        </Text>
      </div>
      <div
        key="JoyrideTooltipCloseNode--Buttons"
        className="flex items-center justify-center gap-4 motion-safe:animate-fadeIn"
      >
        <div
          key="JoyrideTooltipCloseNode--close"
          className="flex items-center justify-center gap-4"
        >
          <Button
            className="flex-1"
            onClick={handleConfirmClose}
            size="sm"
            variant="neutral"
          >
            Yes, close
          </Button>
        </div>

        <div
          key="JoyrideTooltipCloseNode--continue"
          className="flex items-center justify-center gap-4"
        >
          <Button onClick={handleContinue} className="flex-1" size="sm">
            <ArrowUturnRightIcon className="h-4 w-4" />
            No, continue
          </Button>
        </div>
      </div>
    </Motion>
  );
};

export default JoyrideTooltipCloseNode;
