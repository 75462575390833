import cx from "classnames";
import { motion, MotionProps } from "framer-motion";
import {
  ElementType,
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  useMemo,
} from "react";
import {
  DEFAULT_MOTION_PROPS,
  slideInAndScaleFromBottomMotion,
} from "../../motions/default.motions";
import { useProfileAppContext } from "../../stores";

interface IMotion extends MotionProps {
  animationKey?: string;
  className?: string;
  onClick?: () => void;
  tag?: ElementType;
  withDefaultMotionProps?: boolean;
}

const Motion: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<IMotion>
> = (
  {
    animationKey,
    children,
    className,
    onClick,
    tag = "div",
    withDefaultMotionProps = true,
    variants = slideInAndScaleFromBottomMotion,
    ...motionProps
  },
  forwardedRef,
) => {
  const {
    state: { prefersReducedMotion },
  } = useProfileAppContext();

  const MemoizedMotionNode = useMemo(() => motion(tag), [tag]);

  return (
    <MemoizedMotionNode
      {...(withDefaultMotionProps && DEFAULT_MOTION_PROPS)}
      {...motionProps}
      key={animationKey}
      className={cx("will-change-transform", className)}
      ref={forwardedRef}
      onClick={onClick}
      {...(!prefersReducedMotion && { variants })}
    >
      {children}
    </MemoizedMotionNode>
  );
};

export default forwardRef(Motion);
