import { closestTo } from "date-fns/closestTo";

export const closerToDate = (dates: string[]) => {
  if (!dates.length) return undefined;
  const dateToCompare = new Date();
  const result = closestTo(
    dateToCompare,
    dates.map((date) => new Date(date)),
  );
  return result;
};

export const newDateWithoutTime = (date: Date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

/**
 * Transforms a date string to a format that is compatible with Safari and other browsers.
 *
 * This utility specifically addresses an issue where Safari (and potentially other browsers)
 * might throw an "Invalid Date Value" error when attempting to parse date strings that include
 * " UTC" to denote Coordinated Universal Time. Unlike some other browsers that can interpret
 * this format correctly, Safari may fail to parse it, leading to inconsistencies in date handling
 * across different environments.
 *
 * By replacing " UTC" with "Z" in the date string, we align with the ISO 8601 standard for
 * date-time representation. The "Z" character is a time zone designator for the zero UTC offset,
 * also known as "Zulu" time. ISO 8601 specifies that "Z" is the indicator for time values in
 * UTC. Thus, this transformation ensures the date string is universally recognized and parsed
 * correctly by JavaScript's Date constructor across all major browsers, including Safari.
 *
 * @param {string} [date] - The original date string that may contain " UTC" as the timezone
 * indicator. If the date string is undefined, the function will return undefined.
 * @returns {string | undefined} The transformed date string with " UTC" replaced by "Z", or
 * undefined if the input was undefined. This makes the date string compatible with Safari's
 * parsing requirements while maintaining the intended time zone as UTC.
 */
export const safariSafeDate = (date?: string) => date?.replace(" UTC", "Z");
