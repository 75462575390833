import ReactDOM from "react-dom/client";

import { App } from "./App";
import "./styles/tailwind.scss";

const target = document.querySelector("#app");
if (target) {
  const root = ReactDOM.createRoot(target as Element);
  root.render(<App />);
}
