import { lazyInit } from "../../utils/lazyInit";
import * as bramble from "../generated/bramble.sdk";

export const getBrambleGraphQLClient = lazyInit(() =>
  import("graphql-request").then(
    ({ GraphQLClient }) =>
      new GraphQLClient(window.__APP_CONFIG.apiUrls.bramble),
  ),
);

export const getBrambleSdk = lazyInit(() =>
  getBrambleGraphQLClient().then(bramble.getSdk),
);
