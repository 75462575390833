import { AssetAmount } from "@sundaeswap/asset";
import { useEffect, useMemo } from "react";

import { useAssetMetadata } from "../../../gql/hooks/assets.query";
import { usePoolsByIdent } from "../../../gql/hooks/pools.query";
import useAppSearch from "../../../hooks/router/useAppSearch";
import { IAssetMetaData } from "../../../types/Asset.types";
import { getAssetId } from "../../../utils/assets.utils";
import { OrderActions } from "../actions";
import { useOrderContext } from "../context";

export const useSearchSeed = () => {
  const { state, dispatch } = useOrderContext();
  const { given, taken, routeIdent } = useAppSearch();
  const { data, isSuccess } = useAssetMetadata();
  const idents = routeIdent?.split("-");
  const { data: poolsByIdentData } = usePoolsByIdent(idents);

  const { givenMetadata, takenMetadata } = useMemo(() => {
    if (routeIdent && poolsByIdentData) {
      const pools = poolsByIdentData.stats;

      const givenPool = pools[0];
      const takenPool = pools.length === 1 ? pools[0] : pools[1];

      const givenMetadata =
        givenPool.assetA.assetId === given
          ? givenPool.assetA
          : givenPool.assetB;
      const takenMetadata =
        takenPool.assetA.assetId === taken
          ? takenPool.assetA
          : takenPool.assetB;

      return { givenMetadata, takenMetadata };
    }

    if (!isSuccess) return {};

    return {
      givenMetadata: data?.stats.find(
        (metadata) => getAssetId(metadata) === given,
      ),
      takenMetadata: data?.stats.find(
        (metadata) => getAssetId(metadata) === taken,
      ),
    };
  }, [
    poolsByIdentData?.stats,
    data?.stats,
    given,
    taken,
    routeIdent,
    isSuccess,
  ]);

  /**
   * Updates the Order Context based on search parameters if this particular
   * context supports that feature.
   */
  useEffect(() => {
    if (!state.flowData.syncSearch) {
      return;
    }

    if (
      givenMetadata &&
      givenMetadata?.assetId !== state.assets?.given?.metadata?.assetId
    ) {
      OrderActions.setGivenAmount(
        new AssetAmount<IAssetMetaData>(0n, givenMetadata),
        dispatch,
        "OrderContext.useSearchSeed.given.first",
      );
    }

    if (!given && !routeIdent) {
      OrderActions.setGivenAmount(
        undefined,
        dispatch,
        "OrderContext.useSearchSeed.given.second",
      );
    }

    if (
      takenMetadata &&
      takenMetadata?.assetId !== state.assets?.taken?.metadata?.assetId
    ) {
      OrderActions.setTakenAmount(
        new AssetAmount<IAssetMetaData>(0n, takenMetadata),
        dispatch,
        "OrderContext.useSearchSeed.taken.first",
      );
    }

    if (!taken && !routeIdent) {
      OrderActions.setTakenAmount(
        undefined,
        dispatch,
        "OrderContext.useSearchSeed.taken.second",
      );
    }
  }, [
    given,
    taken,
    givenMetadata,
    routeIdent,
    state.assets?.given?.metadata?.assetId,
    state.assets?.taken?.metadata?.assetId,
  ]);
};
