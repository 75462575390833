import { useSearchParams } from "react-router-dom";

import { TSearchParams } from "../../pages/routes";
import { decodeSearchParams } from "../../utils/routes.utils";

const useAppSearch = () => {
  const [searchParams] = useSearchParams();

  const search: TSearchParams = decodeSearchParams(searchParams);

  return search;
};

export default useAppSearch;
