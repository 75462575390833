import { ExternalLink } from "@sundaeswap/ui-toolkit";
import { IExternalLinkProps } from "@sundaeswap/ui-toolkit/dist/types/components/atoms/ExternalLink/ExternalLink";
import { FC, memo } from "react";
import { Trans } from "react-i18next";
import { externalRoutes } from "../../../../pages/routes";

const TransWithCardanoRegistryLink: FC<{
  context?: string;
  i18nFields?: { [i18nField: string]: unknown };
  i18nKey: string;
  linkProps?: Omit<IExternalLinkProps, "children">;
  ns: string;
}> = memo(({ i18nKey, i18nFields, ns, linkProps, context }) => {
  return (
    <Trans
      {...i18nFields}
      {...(context && { context })}
      i18nKey={i18nKey}
      ns={ns}
      components={{
        1: (
          <ExternalLink
            href={externalRoutes.cardanoTokenRegistryHelp}
            variant="primary"
            size="xs"
            {...linkProps}
          />
        ),
      }}
    />
  );
});

export default TransWithCardanoRegistryLink;
