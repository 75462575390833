import { IHeaderNavigationMap } from "@sundaeswap/ui-toolkit/dist/types/components/organisms/Header/Header";
import { TFunction } from "i18next";
import { ReactNode } from "react";
import { createSearchParams } from "react-router-dom";
import Link from "../components/Link";
import { TSearchParams, paths } from "../pages/routes";

const isActiveLink = (pathname: string, path: string) => {
  // Ignore index route for dynamic routes to avoid false positives
  if (path === "/") {
    return pathname === path;
  }

  const regex = new RegExp(`^${path}(/|$)`);
  return regex.test(pathname);
};

export const getMenuItems = ({
  isWalletConnected,
  pathname,
  search,
  t,
}: {
  isWalletConnected: boolean;
  pathname: string;
  search: TSearchParams;
  t: TFunction;
}): IHeaderNavigationMap[] => {
  const renderLink = ({
    children,
    path,
    search,
  }: {
    children: ReactNode;
    path: string;
    search?: TSearchParams;
  }) => {
    const isActive = isActiveLink(pathname, path);
    return (
      <Link
        className="block lg:px-3 lg:py-2"
        hoverable={false}
        to={`${path}${search ? `?${createSearchParams(search)}` : ""}`}
        size="sm"
        variant={isActive ? "info" : "normal"}
      >
        {children}
      </Link>
    );
  };

  return [
    {
      isActive: pathname === paths.root,
      renderLink: (children) => renderLink({ children, path: paths.root }),
      title: isWalletConnected
        ? t("paths.root.connected")
        : t("paths.root.disconnected"),
    },
    {
      isActive: pathname === paths.exchange,
      renderLink: (children) =>
        renderLink({
          children,
          path: paths.exchange,
          search,
        }),
      title: t("paths.exchange.root"),
    },
    {
      isActive: pathname === paths.liquidity.root,
      renderLink: (children) =>
        renderLink({ children, path: paths.liquidity.root }),
      title: t("paths.liquidity.root"),
    },
    {
      isActive: pathname === paths.yieldFarming,
      renderLink: (children) =>
        renderLink({ children, path: paths.yieldFarming }),
      title: t("paths.yieldFarming"),
    },
    {
      isActive: pathname.includes(paths.tasteTest.root),
      renderLink: (children: ReactNode) =>
        renderLink({ children, path: paths.tasteTest.root }),
      title: t("paths.tasteTest"),
    },
  ];
};
