import { AssetAmount } from "@sundaeswap/asset";
import { EContractVersion } from "@sundaeswap/core";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { Fraction } from "@sundaeswap/fraction";
import isNil from "lodash/isNil";

import { ADA_METADATA } from "../constants/cardano.constants";
import { AssetHistory } from "../gql/generated/stats2.sdk";
import { IAssetMetaData } from "../types/Asset.types";
import { ILiquidity } from "../types/Liquidity.types";
import { TPool } from "../types/Pool.types";
import { calculateAssetPriceInADA } from "./assets.utils";
import { featureIsEnabled } from "./features.utils";
import { stringToBigint } from "./number-format";
import { getIsExoticPair } from "./pool.utils";

/**
 * Calculates the amount of unlocked tokens in a liquidity pool.
 *
 * This function subtracts the total quantity of farms from the total quantity of liquidity pool tokens.
 * If the result is negative, it returns 0, indicating that there are no unlocked tokens.
 *
 * The function will return 0 if either `lpTokensQuantity` is null, undefined, 0 or if `totalFarmsQuantity` is null or undefined.
 * However, a value of 0 is allowed for `totalFarmsQuantity`.
 *
 * @param {number} lpTokensQuantity - The total quantity of liquidity pool tokens.
 * @param {number} totalFarmsQuantity - The total quantity of farms.
 *
 * @returns {number} The amount of unlocked tokens. If the calculated amount is negative, the function returns 0.
 * If either `lpTokensQuantity` or `totalFarmsQuantity` is null or undefined, the function returns 0.
 */
export const getUnlockedTokensAmount = (
  lpTokensQuantity?: number,
  totalFarmsQuantity?: number,
) => {
  if (
    !lpTokensQuantity ||
    // allow `0` as a valid value for `totalFarmsQuantity`
    isNil(totalFarmsQuantity)
  ) {
    return 0;
  }

  return Math.max(lpTokensQuantity - totalFarmsQuantity, 0);
};

/**
 * Calculates the share of unlocked tokens in relation to the total quantity of a specific asset in the liquidity pool.
 *
 * This function divides the quantity of unlocked tokens by the total quantity of a specific asset.
 * The result represents the share of unlocked tokens as a part of the total asset quantity.
 *
 * The function will return 0 if either `unlockedTokens` or `assetQuantity` is null, undefined or 0.
 *
 * @param {number} unlockedTokens - The quantity of unlocked tokens.
 * @param {number} assetQuantity - The total quantity of a specific asset.
 *
 * @returns {number} The share of unlocked tokens in relation to the total quantity of the asset.
 * If either `unlockedTokens` or `assetQuantity` is null, undefined or 0, the function returns 0.
 */
export const getUnlockedTokensPoolShare = (
  unlockedTokens?: number | null,
  assetQuantity?: number | null,
) => {
  if (!unlockedTokens || !assetQuantity) return 0;
  return unlockedTokens / assetQuantity;
};

/**
 * Calculates the balances of two assets in a liquidity pool based on the share of unlocked tokens.
 *
 * This function uses the share of unlocked tokens to determine the balance of two specific assets in a liquidity pool.
 * It multiplies the share of unlocked tokens with the total quantity of each asset to derive the balances.
 *
 * The function will return an object containing `assetA` and `assetB` with values of `0n`
 * if either `unlockedTokensPoolShare`, `quantityA` or `quantityB` is null, undefined or 0.
 *
 * @param {number} unlockedTokensPoolShare - The share of unlocked tokens in the liquidity pool.
 * @param {number} quantityA - The total quantity of asset A.
 * @param {number} quantityB - The total quantity of asset B.
 *
 * @returns {Object} An object containing the balances of asset A and asset B.
 * If either `unlockedTokensPoolShare`, `quantityA` or `quantityB` is null, undefined or 0, the function returns
 * an object with both `assetA` and `assetB` as `0n`.
 */
export const getLPAssetBalances = (
  unlockedTokensPoolShare?: number | null,
  quantityA?: number | null,
  quantityB?: number | null,
) => {
  if (!unlockedTokensPoolShare || !quantityA || !quantityB)
    return { assetA: 0n, assetB: 0n };
  return {
    assetA: stringToBigint(unlockedTokensPoolShare * quantityA),
    assetB: stringToBigint(unlockedTokensPoolShare * quantityB),
  };
};

/**
 * Calculates the amount of LP tokens that will be burned based on a given quantity of LP tokens and a percentage amount.
 *
 * This function takes a quantity of LP tokens and a percentage amount and computes the number of tokens that will be burned.
 * The percentage amount is given as a number array with the percentage value at index 0. The function first converts the LP token quantity to a fraction,
 * then multiplies this fraction by the percentage value, and finally divides by 100 (since percentage is a proportion of 100).
 * The resulting quotient is the amount of LP tokens to be burned.
 *
 * If `lpTokensQuantity` is null or 0, the function returns `0n`.
 *
 * @param {bigint} lpTokensQuantity - The total quantity of LP tokens.
 * @param {number[]} amount - The percentage of LP tokens to be burned, specified as a number array with the percentage value at index 0.
 *
 * @returns {bigint} The amount of LP tokens to be burned. If `lpTokensQuantity` is null or 0, the function returns `0n`.
 */
export const getLPTokensBurnedAmount = (
  lpTokensQuantity: bigint | null,
  amount: number[],
) => {
  if (!lpTokensQuantity) return 0n;
  const quantityAsFraction = Fraction.asFraction(lpTokensQuantity);
  return quantityAsFraction.multiply(amount[0]).divide(Fraction.HUNDRED)
    .quotient;
};

/**
 * Calculates the asset balance for a given liquidity provider's pool share and asset quantity in the pool.
 *
 * @param {number} poolShare - The liquidity provider's share of the pool, represented as a number between 0 and 1.
 * @param {number} assetQuantity - The total quantity of the asset in the liquidity pool.
 * @returns {number} The calculated asset balance for the liquidity provider.
 */
export const calculateAssetBalanceForLP = (
  poolShare?: number,
  assetQuantity?: number,
) => {
  if (!poolShare || !assetQuantity) return 0;
  return poolShare * assetQuantity;
};

/**
 * Calculates the value of a liquidity position in ADA.
 *
 * @param {ILiquidity} position - The liqu#idity position containing pool share and pool information.
 * @param {number} adaInUsd - The price of ADA.
 * @returns {BigInt} The calculated value of the liquidity position in ADA.
 */
export const calculatePositionValueInADA = (
  position?: ILiquidity,
  adaInUsd?: number,
) => {
  if (!position || !adaInUsd) return 0n;

  if (!position?.pool) return 0n;
  const { poolShare, pool } = position;

  const assetBalances = {
    assetA: calculateAssetBalanceForLP(poolShare, Number(pool.quantityA)),
    assetB: calculateAssetBalanceForLP(poolShare, Number(pool.quantityB)),
  };

  if (getIsExoticPair(pool)) {
    const assetAValueInADA =
      assetBalances.assetA *
      (calculateAssetPriceInADA(
        Number(pool.assetA.priceToday),
        adaInUsd,
        ADA_METADATA.decimals,
      ) || 0);
    const assetBValueInADA =
      assetBalances.assetB *
      (calculateAssetPriceInADA(
        Number(pool.assetB.priceToday),
        adaInUsd,
        ADA_METADATA.decimals,
      ) || 0);

    return stringToBigint(assetAValueInADA) + stringToBigint(assetBValueInADA);
  } else {
    return (
      (SundaeUtils.isAdaAsset(pool.assetA)
        ? stringToBigint(assetBalances.assetA)
        : stringToBigint(assetBalances.assetB)) * 2n
    );
  }
};

/**
 * Calculates the total value of all liquidity positions in ADA.
 *
 * @param {ILiquidity[]} positions - An array of liquidity position containing pool share and pool information.
 * @param {number} adaInUsd - The price of ADA.
 * @returns {AssetAmount<IAssetMetaData>} The total value of the liquidity positions in ADA, represented as an AssetAmount.
 */
export const getLiquidityPositionsValueInADA = (
  positions: ILiquidity[] | undefined,
  adaInUsd: number,
) => {
  if (!positions?.length)
    return new AssetAmount<IAssetMetaData>(0n, ADA_METADATA);

  const totalPositionsInAda = positions.reduce((valueInADA, position) => {
    return valueInADA + calculatePositionValueInADA(position, adaInUsd);
  }, 0n);

  return new AssetAmount<IAssetMetaData>(totalPositionsInAda, ADA_METADATA);
};

/**
 * Calculate the total earned fees for a list of liquidity pool positions.
 * If a position is an exotic pool (neither asset is ADA), the earned fees for both assets are converted into ADA equivalent using the respective asset's `priceToday` field.
 *
 * @param {Object[]} positions - An array of objects representing the liquidity pool positions.
 * @param {AssetHistory} positions[].feesA - An object representing the earned fees for asset A.
 * @param {AssetHistory} positions[].feesB - An object representing the earned fees for asset B.
 * @param {TPool} positions[].pool - An object representing the liquidity pool.
 * @param {number} adaInUsd - The current price of ADA.
 *
 * @return {bigint | null} The total earned fees in ADA equivalent (expressed in lovelaces), or null if the positions array is empty or if adaData.usd is not provided.
 */
export const calculateEarnedFeesForPositions = (
  positions:
    | {
        feesA: AssetHistory;
        feesB: AssetHistory;
        pool: TPool;
      }[]
    | null,
  adaInUsd?: number | null, // current price in ADA
) => {
  if (!positions || !adaInUsd) return null;

  const totalEarnedFeesInLovelaces = positions.reduce(
    (
      acc: {
        earnedFeesInLovelaces: bigint;
        feesA: AssetHistory | null;
        feesB: AssetHistory | null;
      },
      { feesA, feesB, pool },
    ) => {
      // Check if it's an exotic position
      const isExoticPool =
        !SundaeUtils.isAdaAsset(pool.assetA) &&
        !SundaeUtils.isAdaAsset(pool.assetB);
      if (isExoticPool) {
        // If it's an exotic position, convert the fees to ADA equivalent
        const assetPriceInADA_A =
          calculateAssetPriceInADA(Number(pool.assetA.priceToday), adaInUsd) ||
          0;
        const assetPriceInADA_B =
          calculateAssetPriceInADA(Number(pool.assetB.priceToday), adaInUsd) ||
          0;
        const feesInLovelace_A = BigInt(
          Math.floor(Number(feesA.quantity) * assetPriceInADA_A),
        );
        const feesInLovelace_B = BigInt(
          Math.floor(Number(feesB.quantity) * assetPriceInADA_B),
        );
        return {
          earnedFeesInLovelaces:
            acc.earnedFeesInLovelaces + feesInLovelace_A + feesInLovelace_B,
          feesA,
          feesB,
        };
      } else {
        // For non-exotic positions, just calculate the total fees earned in lovelaces
        return {
          earnedFeesInLovelaces:
            acc.earnedFeesInLovelaces +
            BigInt(
              SundaeUtils.isAdaAsset(pool.assetA)
                ? feesA.quantity
                : feesB.quantity,
            ) *
              2n,
          feesA: SundaeUtils.isAdaAsset(feesA.asset) ? feesA : feesB,
          feesB: SundaeUtils.isAdaAsset(feesA.asset) ? feesB : feesA,
        };
      }
    },
    { earnedFeesInLovelaces: 0n, feesA: null, feesB: null },
  );

  return totalEarnedFeesInLovelaces;
};

/**
 * Checks if a liquidity position is migrateable.
 *
 * @param {ILiquidity[]} positions - An array of liquidity position containing pool share and pool information.
 * @param {pool} TPool - The pool object to compare against.
 * @returns {boolean} True if the position is migrateable, false otherwise.
 */
export const checkHasMigrateableLiquidity = (
  positions: ILiquidity[] | undefined,
  pool: TPool,
) => {
  if (!positions?.length || !featureIsEnabled("migrateLiquidity")) return false;

  return positions?.some(
    ({ pool: poolFromPosition }) =>
      poolFromPosition?.ident === pool.ident &&
      poolFromPosition?.version === EContractVersion.V1,
  );
};
