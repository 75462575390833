import { AssetAmount } from "@sundaeswap/asset";
import {
  ADA_METADATA,
  EContractVersion,
  IComposedTx,
  ITxBuilderSign,
} from "@sundaeswap/core";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { useDebounce, usePrevious } from "@sundaeswap/react-hooks";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import { Tx, TxComplete } from "lucid-cardano";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCustomCompareMemo } from "use-custom-compare";

import { INPUT_DEBOUNCE_IN_MS } from "../../../constants/page.constants";
import {
  ADA_TX_FEE,
  DEPOSIT,
  SCOOPER_FEE,
} from "../../../constants/SundaeSwap.constants";
import { useRawPoolsByAssetIds } from "../../../gql/hooks/pools.query";
import { useWalletPositions } from "../../../gql/hooks/positions.query";
import { useAppContext } from "../../../stores";
import { TPool } from "../../../types/Pool.types";
import { ETransactionState } from "../../../types/Transaction.types";
import { normalizeAdaAssetIdsForGql } from "../../../utils/assets.utils";
import {
  EMigrationTransactionBuilderState,
  IMigrateLiquidityContext,
  IMigrationError,
  TSelectedPool,
} from "./types";

const defaultMigrateLiquidityState: IMigrateLiquidityContext = {
  derived: {
    fees: undefined,
    isLoadingV3Pools: true,
    selectedPools: [],
    v3PoolsByAssetId: [],
  },
  error: undefined,
  flowState: {
    dialogState: ETransactionState.initial,
    selectionChanged: false,
  },
  handlers: {
    handleSetError: () => {},
    handleCheckedChange: () => {},
    resetContext: () => {},
    setDialogState: () => {},
    setTransactionBuilderState: () => {},
    setBuiltTx: () => {},
    setCompleteTx: () => {},
    setIsLoadingV3Pools: () => {},
    setSelectedPools: () => {},
  },
  transaction: {
    builderState: undefined,
    builtTx: undefined,
    completeTx: undefined,
  },
};

const MigrateLiquidityContext = createContext(defaultMigrateLiquidityState);

export const useMigrateLiquidityContext = () =>
  useContext(MigrateLiquidityContext);

export const MigrateLiquidityContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    state: {
      dialogs: { liquidity: liquidityDialogs },
    },
  } = useAppContext();
  const [dialogState, setDialogState] = useState(ETransactionState.initial);
  const [transactionBuilderState, setTransactionBuilderState] =
    useState<EMigrationTransactionBuilderState>();
  const [builtTx, setBuiltTx] = useState<IComposedTx<Tx, TxComplete>>();
  const [completeTx, setCompleteTx] = useState<ITxBuilderSign<TxComplete>>();
  const [error, setError] = useState<IMigrationError>();
  const [isLoadingV3Pools, setIsLoadingV3Pools] = useState<boolean>();
  const [selectedPools, setSelectedPools] = useState<TSelectedPool[]>([]);
  const amountPoolsMigrating = selectedPools.length;
  const { positions } = useWalletPositions({
    page: 0,
    pageSize: 20,
  });

  // /**
  //  * Get all asset ids from positions to query for v3 pools.
  //  */
  const assetIds = useCustomCompareMemo(
    () => {
      return (
        positions.stats.reduce((acc: string[], { pool }) => {
          if (
            !SundaeUtils.isAdaAsset(pool.assetA) &&
            !acc.includes(pool.assetA.assetId)
          ) {
            acc.push(pool.assetA.assetId);
          }
          if (
            !SundaeUtils.isAdaAsset(pool.assetB) &&
            !acc.includes(pool.assetB.assetId)
          ) {
            acc.push(pool.assetB.assetId);
          }
          return acc;
        }, []) ?? []
      );
    },
    [positions ?? []],
    isEqual,
  );

  // /**
  //  * Query for v3 pools by asset ids.
  //  */
  const { data: poolsByAssetIds, isLoading } = useRawPoolsByAssetIds({
    assetIds: normalizeAdaAssetIdsForGql(assetIds),
    enabled: liquidityDialogs.migrate.isOpen,
  });

  // /**
  //  * Get all v3 pools from poolsByAssetIds.
  //  */
  const v3PoolsByAssetId = useCustomCompareMemo(
    () => {
      if (poolsByAssetIds?.stats) {
        return Object.values(poolsByAssetIds.stats).reduce((acc, pool) => {
          if (pool.version === EContractVersion.V3) return [...acc, pool];
          return acc;
        }, []);
      }
    },
    [poolsByAssetIds?.stats ?? {}],
    isEqual,
  );

  const [debouncedSelectedPools] = useDebounce(
    selectedPools,
    INPUT_DEBOUNCE_IN_MS,
  );
  const previousDevbouncedSelectedPools = usePrevious(debouncedSelectedPools);
  const debouncedSelectionChanged = useMemo(() => {
    return (
      debouncedSelectedPools.length !== previousDevbouncedSelectedPools?.length
    );
  }, [previousDevbouncedSelectedPools, debouncedSelectedPools]);
  const memoizedTotalAdaTxFee = useMemo(() => {
    if (builtTx) {
      return builtTx.fees.cardanoTxFee || new AssetAmount(0, ADA_METADATA);
    }
    return AssetAmount.fromValue(
      ADA_TX_FEE.value.multiply(amountPoolsMigrating),
      ADA_METADATA,
    );
  }, [amountPoolsMigrating, builtTx]);
  const memoizedTotalScooperFee = useMemo(() => {
    if (builtTx) {
      return builtTx.fees.scooperFee;
    }
    return AssetAmount.fromValue(
      SCOOPER_FEE.value.multiply(amountPoolsMigrating),
      ADA_METADATA,
    );
  }, [amountPoolsMigrating, builtTx]);
  const memoizedTotalDeposit = useMemo(() => {
    if (builtTx) {
      return builtTx.fees.deposit;
    }
    return AssetAmount.fromValue(
      DEPOSIT.value.multiply(amountPoolsMigrating),
      ADA_METADATA,
    );
  }, [amountPoolsMigrating, builtTx]);
  const memoizedTotalFees = useMemo(() => {
    return memoizedTotalAdaTxFee.add(memoizedTotalScooperFee);
  }, [memoizedTotalAdaTxFee, memoizedTotalScooperFee]);

  const handleSetError = useCallback(
    (data: IMigrationError) => {
      setError(data);
      setDialogState(ETransactionState.error);
    },
    [setError, setDialogState],
  );

  const onCheckedChange = useCallback(
    (checked: boolean | string, v1Pool: TPool, v3Pool: TPool) => {
      setTransactionBuilderState?.(EMigrationTransactionBuilderState.BUILD);
      setSelectedPools((prev) => {
        if (checked) {
          return uniqWith(
            [
              ...prev,
              { [EContractVersion.V1]: v1Pool, [EContractVersion.V3]: v3Pool },
            ],
            isEqual,
          );
        }
        return uniqWith(
          prev.filter(
            (versionKey) =>
              versionKey[EContractVersion.V1].ident !== v1Pool.ident,
          ),
          isEqual,
        );
      });
    },
    [setSelectedPools],
  );

  const resetContext = useCallback(() => {
    setDialogState(ETransactionState.initial);
    setTransactionBuilderState(undefined);
    setBuiltTx(undefined);
    setCompleteTx(undefined);
    setError(undefined);
    setSelectedPools([]);
  }, []);

  /**
   * Set loading state for v3 pools in context to have it available for other components.
   */
  useEffect(() => {
    setIsLoadingV3Pools(isLoading);
  }, [isLoading]);

  return (
    <MigrateLiquidityContext.Provider
      value={{
        derived: {
          fees: {
            adaTxFee: memoizedTotalAdaTxFee,
            deposit: memoizedTotalDeposit,
            scooperFee: memoizedTotalScooperFee,
            totalFees: memoizedTotalFees,
          },
          isLoadingV3Pools,
          selectedPools,
          v3PoolsByAssetId,
        },
        error,
        flowState: {
          dialogState,
          selectionChanged: debouncedSelectionChanged,
        },
        handlers: {
          handleCheckedChange: onCheckedChange,
          handleSetError,
          resetContext,
          setDialogState,
          setTransactionBuilderState,
          setBuiltTx,
          setCompleteTx,
          setIsLoadingV3Pools,
          setSelectedPools,
        },
        transaction: {
          builderState: transactionBuilderState,
          builtTx,
          completeTx,
        },
      }}
    >
      {children}
    </MigrateLiquidityContext.Provider>
  );
};
