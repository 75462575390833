import { Image, Text } from "@sundaeswap/ui-toolkit";
import { FC, memo } from "react";

const TasteTestExplanationGuideStep6: FC = () => {
  return (
    <div className="grid gap-4">
      <Text tag="p" size="sm">
        These two factors combined create an envelope pushing the initial price
        of CARAMEL towards what the market thinks the fair value is, and reduces
        the risk of volatility.
      </Text>
      <Image
        width={394}
        height={221}
        src="/static/images/guides/taste-test/slide_3.png"
      />
    </div>
  );
};

export default memo(TasteTestExplanationGuideStep6);
