import { Context, createContext, useContext } from "react";

import { IPortfolioContext, IPortfolioState } from "./types";

export const defaultPortfolioState: IPortfolioState = {
  derived: { activeTabTitle: undefined },
  farms: {
    isFetchingFarms: false,
    isFetchedFarms: false,
    history: undefined,
    positions: undefined,
  },
  orders: {
    allOrders: undefined,
    formattedOrders: undefined,
    isFetchedOps: false,
    isFetchingOps: false,
    isRefetchingOps: false,
    opsHistory: undefined,
    opsOpen: undefined,
    filters: { orderStatus: {} },
    search: { value: undefined },
    ordersTableColumnWidths: undefined,
    ordersTableHeaders: undefined,
  },
  tabs: {
    assets: {
      selectedAsset: undefined,
    },
  },
};

export const PortfolioContext: Context<IPortfolioContext> = createContext({
  state: defaultPortfolioState,
  dispatch: (_val) => {},
});

// Utility hook.
export const usePortfolioContext = (): IPortfolioContext =>
  useContext(PortfolioContext);
