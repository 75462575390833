import { Button, Text } from "@sundaeswap/ui-toolkit";
import {
  TMetadataResolverFunc,
  WalletObserverProvider,
} from "@sundaeswap/wallet-lite";
import { FC, lazy, Suspense, useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./i18n";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorFallback from "./components/ErrorFallback";
import LoadingComponent from "./components/LoadingComponent";
import { APP_NAME } from "./constants/page.constants";
import type {
  FetchAssetsByIdQuery,
  FetchPoolByAssetsQuery,
} from "./gql/generated/bramble.sdk";
import type { GetAdaPriceQuery } from "./gql/generated/stats2.sdk";
import { transformBrambleAsset } from "./gql/utils/transformers";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { paths } from "./pages/routes";
import Providers from "./Providers";
import { IAssetMetaData } from "./types/Asset.types";
import {
  getAssetPriceToday,
  getAssetsPool,
  getAssetWarning,
} from "./utils/assets.utils";

const LazyYieldFarming = lazy(() => import("./pages/YieldFarming"));
const LazyTasteTestDetailContextProvider = lazy(() =>
  import("./pages/TasteTestDetail/components").then(
    ({ TasteTestDetailContextProvider }) => ({
      default: TasteTestDetailContextProvider,
    }),
  ),
);
const LazyTasteTestDetail = lazy(() => import("./pages/TasteTestDetail"));
const LazyDialogs = lazy(() => import("./Dialogs"));
const LazyExchange = lazy(() => import("./pages/Exchange"));
const LazyLiquidity = lazy(() => import("./pages/Liquidity"));
const LazyTasteTest = lazy(() => import("./pages/TasteTest"));

const FallbackComponent: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div
      className="flex h-screen flex-col items-center justify-center gap-2 p-2"
      role="alert"
    >
      <Text tag="div" size="lg">
        Something went wrong:
      </Text>
      <Text tag="div">{error.message}</Text>
      <Button onClick={resetErrorBoundary} className="w-fit">
        Try again
      </Button>
    </div>
  );
};

const cache: { [key: string]: string } = {};

const metadataResolver: TMetadataResolverFunc<IAssetMetaData> = async ({
  assetIds,
  normalizeAssetId,
}) => {
  try {
    const metadataMap = new Map<string, IAssetMetaData>();

    const cacheKey = assetIds.sort().join(",");
    if (cache[cacheKey])
      return new Map<string, IAssetMetaData>(JSON.parse(cache[cacheKey]));

    const { default: request } = await import("graphql-request");
    const { FetchAssetsByIdDocument, FetchPoolByAssetsDocument } = await import(
      "./gql/generated/bramble.sdk"
    );
    const { GetAdaPriceDocument } = await import("./gql/generated/stats2.sdk");
    const [assetsById, poolsByAssets, { adaPrice }] = await Promise.all([
      request<FetchAssetsByIdQuery>(
        window.__APP_CONFIG.apiUrls.bramble,
        FetchAssetsByIdDocument,
        {
          ids: assetIds,
        },
      ),
      request<FetchPoolByAssetsQuery>(
        window.__APP_CONFIG.apiUrls.bramble,
        FetchPoolByAssetsDocument,
        {
          assets: assetIds,
        },
      ),
      request<GetAdaPriceQuery>(
        window.__APP_CONFIG.apiUrls.stats2,
        GetAdaPriceDocument,
      ),
    ]);

    const assetsPool = getAssetsPool(
      assetsById.assets.byIds,
      poolsByAssets.pools.byAssets,
    );

    assetsById.assets.byIds.forEach((asset) => {
      const pool = assetsPool[asset.id];
      const priceToday = getAssetPriceToday(adaPrice, pool);
      const metadata: IAssetMetaData = {
        ...transformBrambleAsset(asset),
        priceToday,
      };
      metadata.warning = getAssetWarning(metadata);

      metadataMap.set(normalizeAssetId(asset.id), metadata);
    });

    cache[cacheKey] = JSON.stringify([...metadataMap]);

    return metadataMap;
  } catch (error) {
    throw new Error(`Failed to fetch asset metadata: ${error.message}`);
  }
};

const queryClient = new QueryClient();

export const App: FC = () => {
  /**
   * Effect that handles the cardano dApp bridge connection.
   */
  useEffect(() => {
    import("./utils/network.utils").then(({ checkIsEternlEnv }) => {
      if (checkIsEternlEnv()) {
        import("./vendor/cardano-dapp-connector-bridge").then(
          ({ initCardanoDAppConnectorBridge }) =>
            initCardanoDAppConnectorBridge(),
        );
      }
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <WalletObserverProvider
        options={{
          observerOptions: {
            debug: window.__APP_CONFIG.envName === "local",
            peerConnectArgs: {
              dAppInfo: {
                name: APP_NAME,
                url: window.location.hostname,
              },
            },
            metadataResolver,
            persistence: true,
          },
        }}
      >
        <BrowserRouter
          future={{ v7_relativeSplatPath: true, v7_startTransition: true }}
        >
          <Providers>
            <ErrorBoundary
              FallbackComponent={(props) => (
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                  <ErrorFallback {...props} />
                </ErrorBoundary>
              )}
            >
              <Routes>
                <Route path={paths.root} element={<MainLayout />}>
                  <Route index element={<Home />} />
                  <Route
                    path={paths.exchange}
                    element={
                      <Suspense
                        fallback={<LoadingComponent className="min-h-[50vh]" />}
                      >
                        <LazyExchange />
                      </Suspense>
                    }
                  />
                  <Route
                    path={paths.liquidity.root}
                    element={
                      <Suspense
                        fallback={<LoadingComponent className="min-h-[50vh]" />}
                      >
                        <LazyLiquidity />
                      </Suspense>
                    }
                  />
                  <Route
                    path={paths.yieldFarming}
                    element={
                      <Suspense
                        fallback={<LoadingComponent className="min-h-[50vh]" />}
                      >
                        <LazyYieldFarming />
                      </Suspense>
                    }
                  />
                  <Route path="/taste-test">
                    <Route
                      index
                      element={
                        <Suspense
                          fallback={
                            <LoadingComponent className="min-h-[50vh]" />
                          }
                        >
                          <LazyTasteTest />
                        </Suspense>
                      }
                    />
                    <Route
                      index={false}
                      path=":slug"
                      element={
                        <Suspense
                          fallback={
                            <LoadingComponent className="min-h-[50vh]" />
                          }
                        >
                          <LazyTasteTestDetailContextProvider>
                            <LazyTasteTestDetail />
                          </LazyTasteTestDetailContextProvider>
                        </Suspense>
                      }
                    />
                  </Route>

                  {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
                  <Route
                    path="*"
                    element={
                      <NotFound className="lg:min-h-[calc(100vh-254px)]" />
                    }
                  />
                </Route>
              </Routes>
              <LazyDialogs />
            </ErrorBoundary>
          </Providers>
        </BrowserRouter>
      </WalletObserverProvider>
    </QueryClientProvider>
  );
};
