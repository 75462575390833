import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/**
 * scalar BigInt # := String
 * scalar DateTime # := String
 */
export type Asset = {
  __typename?: "Asset";
  /** hex encoded policyId + hex encoded asset name */
  assetId: Scalars["String"];
  /** hex encoded asset name (ledger: asset_name) */
  assetName?: Maybe<Scalars["String"]>;
  /**
   * the project's website
   *  websiteUrl: String
   * listed date for token (ISO8601: yyyy-mm-dd)
   */
  dateListed: Scalars["String"];
  /** token decimal places (defaults to 0) */
  decimals: Scalars["Int"];
  /** description of asset */
  description?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  /** market cap of token */
  marketCap?: Maybe<Scalars["String"]>;
  /** hex encoded policyId, 56 chars length (ledger: policy_id) */
  policyId: Scalars["String"];
  poolId: Scalars["String"];
  priceDiff24H?: Maybe<Scalars["String"]>;
  priceToday?: Maybe<Scalars["String"]>;
  priceYesterday?: Maybe<Scalars["String"]>;
  slotAdded: Scalars["Int"];
  /** sources define where the token metadata is sourced from */
  sources?: Maybe<Array<TokenMetadataSource>>;
  /** ticker (max 6-8 characters ?) */
  ticker?: Maybe<Scalars["String"]>;
  /** total supply of tokens */
  totalSupply?: Maybe<Scalars["String"]>;
  tvl?: Maybe<Scalars["String"]>;
  tvlDiff24H?: Maybe<Scalars["String"]>;
  tvlToday?: Maybe<Scalars["String"]>;
  tvlTodayADA?: Maybe<Scalars["String"]>;
  tvlYesterday?: Maybe<Scalars["String"]>;
  volume24H?: Maybe<Scalars["String"]>;
};

/**
 * scalar BigInt # := String
 * scalar DateTime # := String
 */
export type AssetTvlArgs = {
  by?: InputMaybe<Rank>;
};

export type AssetHistory = {
  __typename?: "AssetHistory";
  asset: Asset;
  /** change back to int? */
  quantity: Scalars["String"];
};

export type AssetStat = {
  __typename?: "AssetStat";
  asset: Asset;
  /** currency identifier (ex: USD) */
  currency: Scalars["String"];
  /** date of interval stat (ISO8601: yyyy-mm-dd) */
  date: Scalars["String"];
  /** value not changing hands within period (in currency) */
  lockedValue: Scalars["String"];
  /** TODO: end of day price? which timezones? */
  price: Scalars["String"];
  /** value changing hands (in currency) */
  tradingValue: Scalars["String"];
  /** how asset amount shifted within period */
  tradingVolume: Scalars["Int"];
};

export type BalanceHistoryPoint = {
  __typename?: "BalanceHistoryPoint";
  date?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

export type BalanceHistoryPointPriceArgs = {
  currency: Scalars["String"];
};

export type Build = {
  __typename?: "Build";
  /** built at */
  built: Scalars["String"];
  /** version number */
  version: Scalars["String"];
};

export enum Coin {
  CoinA = "CoinA",
  CoinB = "CoinB",
}

export type DateTime = {
  __typename?: "DateTime";
  /** canonical date is 2006-01-02 15:04:05 MST */
  format: Scalars["String"];
};

export type DateTimeFormatArgs = {
  layout?: InputMaybe<Scalars["String"]>;
};

/**
 * todo move this to an admin interface
 * type Mutation {
 *  registerAsset(assetId: String!, ticker: String!, base64Logo: String, decimals: Int = 6): Query
 * }
 */
export enum Direction {
  Buy = "Buy",
  Sell = "Sell",
}

export type Farm = {
  __typename?: "Farm";
  /** assetA of first participating pool (nonnil if rewards program only has pools of one pair) */
  assetA?: Maybe<Asset>;
  /** assetB of first participating pool (nonnil if rewards program only has pools of one pair) */
  assetB?: Maybe<Asset>;
  participatingLPTokenAssets: Array<Maybe<Asset>>;
  participatingPools: Array<Maybe<Pool>>;
  /** asset being rewarded by farm program */
  rewardAsset?: Maybe<Asset>;
  /** these are from rewards api REST schema https://github.com/SundaeSwap-finance/sundae-yield/blob/04544ef5312bacb9c47c4e95cb724ca67df403de/rest/endpoint.go#L632 */
  rewardConfig?: Maybe<RewardConfig>;
  /**
   * these are from https://github.com/SundaeSwap-finance/dex-v2/commit/c61a2775ff8e5ca19c24d784eb19e13a9aa6a824?diff=unified
   * and https://discord.com/channels/918902314000080957/963845964987260968/1017093249904685156
   * number of LP tokens staked in rewards program
   */
  totalStaked: Scalars["String"];
};

export type FarmsPage = {
  __typename?: "FarmsPage";
  farms?: Maybe<FreezerItems>;
  farmsHistory?: Maybe<FreezerItems>;
};

export type FarmsPageFarmsArgs = {
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

export type FarmsPageFarmsHistoryArgs = {
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

export type FeeQuery = {
  amount: Scalars["String"];
  lpAsset: Scalars["String"];
  txHash: Scalars["String"];
  txIndex: Scalars["Int"];
};

export type FreezerItem = {
  __typename?: "FreezerItem";
  /** assetID of the liquidity token */
  assetID: Scalars["String"];
  /**
   * quantity of SUNDAE earned by Staking
   * todo - remove earned once ui refactors to use rewards
   */
  earned?: Maybe<Scalars["String"]>;
  /** the farm programs the freezer item participates in, also listed in each FreezerReward */
  farmPrograms: Array<Farm>;
  /** when the next reward will be */
  nextRewardAt?: Maybe<DateTime>;
  /** pool tokens belong to */
  pool?: Maybe<Pool>;
  /** quantity of liquidity tokens staked */
  quantity: Scalars["String"];
  /** All rewards, regardless of maturity */
  rewards: Array<FreezerReward>;
  /** rewardsMatured is the rewards that have matured */
  rewardsMatured: Array<FreezerReward>;
  /** when was the freezer spent; null if still utxo */
  spentAt?: Maybe<DateTime>;
  /** reason the tx was spent */
  spentReason?: Maybe<Resolution>;
  /** which tx spent it into */
  spentTxHash?: Maybe<Scalars["String"]>;
  spentTxIn?: Maybe<TxIn>;
  /** When things were locked up */
  startDate?: Maybe<DateTime>;
  /** The current status of the freezer item */
  status: FreezerStatus;
  /** length of the yield term */
  term: YieldTerm;
  /** utxo holding stake */
  utxo?: Maybe<Utxo>;
};

export type FreezerItems = {
  __typename?: "FreezerItems";
  items: Array<FreezerItem>;
  /** continuation/page token */
  token?: Maybe<Scalars["String"]>;
};

/** FreezerReward received */
export type FreezerReward = {
  __typename?: "FreezerReward";
  /** asset issued as reward */
  asset?: Maybe<Asset>;
  /** assetID of reward */
  assetID: Scalars["String"];
  farmProgram: Farm;
  /** quantity of reward */
  quantity: Scalars["String"];
  /** The reward config associated with this : */
  rewardConfig: RewardConfig;
};

export enum FreezerStatus {
  /** No rewards have matured yet */
  Allocating = "Allocating",
  /** Rewards have matured and can be harvested */
  Harvested = "Harvested",
  Immature = "Immature",
  /** Rewards are mature, but still being allocated, so are unable to be harvested */
  Mature = "Mature",
}

export type GlobalStat = {
  __typename?: "GlobalStat";
  /** currency identifier (ex: USD) */
  currency: Scalars["String"];
  /** date of interval stat (ISO8601: yyyy-mm-dd) */
  date: Scalars["String"];
  /** value not changing hands within period (in currency) */
  lockedValue: Scalars["String"];
  /** TODO: end of day price? which timezones? */
  price: Scalars["String"];
  /** value changing hands (in currency) */
  tradingValue: Scalars["String"];
  /** how asset amount shifted within period */
  tradingVolume: Scalars["Int"];
};

export type Liquidity = {
  __typename?: "Liquidity";
  /** fees: [BalanceHistoryPoint] */
  feesA: AssetHistory;
  feesB: AssetHistory;
  lpTokens: AssetHistory;
  pool: Pool;
  poolShare: Scalars["Float"];
};

export type LiquidityPage = {
  __typename?: "LiquidityPage";
  liquidity: Array<Liquidity>;
};

export type LiquidityPageLiquidityArgs = {
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Queue up a job to generate tax reports for a given year */
  generate_tax_report: TaxReport;
};

export type MutationGenerate_Tax_ReportArgs = {
  wallet: Scalars["String"];
  year: Scalars["Int"];
};

export type Op = OpDepositMixed | OpDepositSingle | OpSwap | OpWithdraw;

export type OpDepositMixed = {
  __typename?: "OpDepositMixed";
  lq?: Maybe<Scalars["String"]>;
  quantityA?: Maybe<Scalars["String"]>;
  quantityB?: Maybe<Scalars["String"]>;
  type: OpType;
};

export type OpDepositSingle = {
  __typename?: "OpDepositSingle";
  lq?: Maybe<Scalars["String"]>;
  quantityA?: Maybe<Scalars["String"]>;
  quantityB?: Maybe<Scalars["String"]>;
  type: OpType;
};

export type OpItems = {
  __typename?: "OpItems";
  ops: Array<Operation>;
  token?: Maybe<Scalars["String"]>;
};

export type OpSwap = {
  __typename?: "OpSwap";
  fee?: Maybe<Scalars["String"]>;
  got?: Maybe<Scalars["String"]>;
  quantityA?: Maybe<Scalars["String"]>;
  quantityB?: Maybe<Scalars["String"]>;
  type: OpType;
  want: Scalars["String"];
};

export enum OpType {
  CreatePool = "CreatePool",
  DepositMixed = "DepositMixed",
  DepositSingle = "DepositSingle",
  Swap = "Swap",
  Withdraw = "Withdraw",
}

export type OpWithdraw = {
  __typename?: "OpWithdraw";
  lq?: Maybe<Scalars["String"]>;
  quantityA?: Maybe<Scalars["String"]>;
  quantityB?: Maybe<Scalars["String"]>;
  type: OpType;
};

export type Operation = {
  __typename?: "Operation";
  /** Address of the user who funds will be paid back to */
  address: Scalars["String"];
  /** An auxiliary signer, if needed */
  auxiliary?: Maybe<Scalars["String"]>;
  /** when was the operation created */
  createdAt: DateTime;
  /** Details about the operation represented */
  op: Op;
  /** outcome of the tx if available */
  outcome?: Maybe<Outcome>;
  /** The pool the escrow is acting against */
  pool: Pool;
  /** when was the escrow spent; null if still utxo */
  spentAt?: Maybe<DateTime>;
  /** reason the tx was spent */
  spentReason?: Maybe<Resolution>;
  /** which tx spent it into */
  spentTxHash?: Maybe<Scalars["String"]>;
  spentTxIn?: Maybe<TxIn>;
  /** utxo reference */
  txIn: TxIn;
};

export type Outcome = {
  __typename?: "Outcome";
  /** feeA paid in token A */
  feeA?: Maybe<Scalars["String"]>;
  /** feeB paid in token B */
  feeB?: Maybe<Scalars["String"]>;
  /** price of asset measured by assetID */
  price?: Maybe<Price>;
  /** quantity of a tokens received */
  quantityA?: Maybe<Scalars["String"]>;
  /** quantity of b tokens received */
  quantityB?: Maybe<Scalars["String"]>;
  /** quantity of lp tokens received */
  quantityLP?: Maybe<Scalars["String"]>;
};

export type OutcomePriceArgs = {
  coin?: InputMaybe<Coin>;
};

export type Pool = {
  __typename?: "Pool";
  /** apr for yield farming if available; returns % yield e.g. 1.0 == 100% */
  apr?: Maybe<Scalars["Float"]>;
  /** first asset for pool pair (no amount, just the identifier) */
  assetA: Asset;
  /** second asset in pool pair (no amount, just the identifier) */
  assetB: Asset;
  /** assetID that identifies the pool NFT */
  assetID: Scalars["String"];
  /** liquidity pool token asset */
  assetLP: Asset;
  /** liquidity provider fee percentage (e.g. 1.0 is 1%) */
  fee: Scalars["String"];
  /** estimated yearly return percentage, from fees, from a hypothetical stake */
  feeApr?: Maybe<Scalars["Float"]>;
  /** fees earned in the last 24h (volume * fee percentage) */
  fees24H?: Maybe<Scalars["String"]>;
  /** ident contains hex encoded pool ident */
  ident: Scalars["String"];
  /** human friendly name of pool */
  name: Scalars["String"];
  /** price in usd (if available) */
  priceUSD?: Maybe<Scalars["String"]>;
  /** number of asset A */
  quantityA?: Maybe<Scalars["String"]>;
  /** number of asset B */
  quantityB?: Maybe<Scalars["String"]>;
  /** number of outstanding lp tokens */
  quantityLP?: Maybe<Scalars["String"]>;
  /** rewards issued via yield farming rewards by token */
  rewards: Array<PoolReward>;
  /** stats list to get current amounts, past value, etc. */
  stats: Array<PoolStat>;
  /** tvl in usd if available */
  tvl?: Maybe<Scalars["String"]>;
  /** The version of the protocol this pool is for */
  version: Scalars["String"];
  /** pool trading volume in the last 24h */
  volume24H?: Maybe<Scalars["String"]>;
};

export type PoolStatsArgs = {
  currency?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  interval?: InputMaybe<VolumeInterval>;
};

/** PoolReward identifies the reward for a */
export type PoolReward = {
  __typename?: "PoolReward";
  /** apr for this reward */
  apr?: Maybe<Scalars["Float"]>;
  /** asset issued as reward */
  asset?: Maybe<Asset>;
  /** assetID issued reward in case you don't */
  assetID: Scalars["String"];
  farmProgram: Farm;
};

export type PoolStat = {
  __typename?: "PoolStat";
  /** currency identifier (ex: USD) */
  currency: Scalars["String"];
  /** date of interval stat (ISO8601: yyyy-mm-dd) */
  date: Scalars["String"];
  /** rewards fee */
  fees: Scalars["String"];
  /** value not changing hands within period (in currency) */
  lockedValue: Scalars["String"];
  /**
   * virtual liquidity pool price edges. TODO: end of day price? which timezones?
   * price evaluated: A > B
   */
  priceA: Scalars["String"];
  /** price evaluated: B > A */
  priceB: Scalars["String"];
  /** value changing hands (in currency) */
  tradingValue: Scalars["String"];
  /** how asset amount shifted within period */
  tradingVolume: Scalars["Int"];
  /**
   * total amount of asset a
   * Priority 3
   */
  valueA: Scalars["String"];
  /**
   * total amount of asset b
   * Priority 4
   */
  valueB: Scalars["String"];
  /**
   * total amount of liquidity pool tokens
   * Priority 5
   */
  valueLP: Scalars["String"];
};

export type Position = {
  __typename?: "Position";
  allocationPercentage?: Maybe<Scalars["Float"]>;
  asset: Asset;
  history?: Maybe<Array<Maybe<BalanceHistoryPoint>>>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

export type PositionPriceArgs = {
  currency: Scalars["String"];
};

/** Price by asset id */
export type Price = {
  __typename?: "Price";
  /** asset id of the numerator token */
  unit: Coin;
  /** price amount */
  value: Scalars["String"];
};

/** info queries */
export type Query = {
  __typename?: "Query";
  adaPrice: Scalars["String"];
  /**
   * assets fetch
   * Priority 1
   */
  assets: Array<Asset>;
  assetsPopular: Array<Asset>;
  /** build info */
  build: Build;
  /** factory returns the current utxo holding the factory */
  factory?: Maybe<Utxo>;
  /** mirror of sundae-yield, query open rewards programs */
  farms: Array<Farm>;
  /** lookup the pending fee earnings on a specific utxo */
  fees: LiquidityPage;
  /** closed freezer items */
  freezerHistory?: Maybe<FreezerItems>;
  /** the open freezer items */
  freezerOpen?: Maybe<FreezerItems>;
  now: DateTime;
  ok: Scalars["String"];
  /** a specific operation by tx in */
  op: Operation;
  /**
   * operations initiated by address
   * dateFrom, dateTo: 2006-01-02
   */
  opHistory?: Maybe<OpItems>;
  opOpen?: Maybe<OpItems>;
  /**
   * All open orders against a pool
   * By default, returns 50 buy and 50 sell orders, centered around the mid price
   */
  openOrders?: Maybe<OpItems>;
  /** fetch a specific pool by identifier */
  poolByIdent?: Maybe<Pool>;
  /**
   * fetch pools containing any of the assets, and matching any of the search terms
   * query is a partial asset name that the returned pools will be filtered by
   */
  pools: Array<Maybe<Pool>>;
  poolsByIdent: Array<Maybe<Pool>>;
  /** find any pools for a specific pair of tokens */
  poolsByPair: Array<Maybe<Pool>>;
  /** pools returned by highest magnitude changes */
  poolsPopular: Array<Pool>;
  /**
   * V2 API
   * assets(sortBy: SortType, pageSize: Int = 100, paginationToken: String = ""): TokenPage!
   */
  positions: Wallet;
  /**
   * queue depth returns the approximate number of processable orders in queue
   * this is an expensive operation and should not be called from the home page
   */
  queueDepth: Array<QueueDepth>;
  /**
   * attempt to discover the current position for a given utxo
   * updates every 5 minutes
   */
  queuePosition?: Maybe<Scalars["Int"]>;
  /** average median queue time */
  queueStats?: Maybe<QueueStats>;
  /** Check the progress of tax report exports */
  tax_reports: Array<TaxReport>;
  /** lookup a particular tx */
  tx?: Maybe<Tx>;
  /** lookup a specific utxo */
  utxo?: Maybe<Utxo>;
};

/** info queries */
export type QueryAssetsArgs = {
  ids: Array<Scalars["String"]>;
};

/** info queries */
export type QueryAssetsPopularArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sortBy: SortType;
};

/** info queries */
export type QueryFeesArgs = {
  queries: Array<FeeQuery>;
};

/** info queries */
export type QueryFreezerHistoryArgs = {
  address: Scalars["String"];
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

/** info queries */
export type QueryFreezerOpenArgs = {
  address: Scalars["String"];
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

/** info queries */
export type QueryOpArgs = {
  index: Scalars["Int"];
  txHash: Scalars["String"];
};

/** info queries */
export type QueryOpHistoryArgs = {
  address: Scalars["String"];
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  opTypes?: InputMaybe<Array<OpType>>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

/** info queries */
export type QueryOpOpenArgs = {
  address: Scalars["String"];
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
};

/** info queries */
export type QueryOpenOrdersArgs = {
  direction?: InputMaybe<Direction>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  poolIdent: Scalars["String"];
  token?: InputMaybe<Scalars["String"]>;
};

/** info queries */
export type QueryPoolByIdentArgs = {
  ident: Scalars["String"];
};

/** info queries */
export type QueryPoolsArgs = {
  assetIds?: InputMaybe<Array<Scalars["String"]>>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

/** info queries */
export type QueryPoolsByIdentArgs = {
  idents: Array<Scalars["String"]>;
};

/** info queries */
export type QueryPoolsByPairArgs = {
  coinA: Scalars["String"];
  coinB: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

/** info queries */
export type QueryPoolsPopularArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

/** info queries */
export type QueryPositionsArgs = {
  wallet: Scalars["String"];
};

/** info queries */
export type QueryQueueDepthArgs = {
  poolIdents?: InputMaybe<Array<Scalars["String"]>>;
};

/** info queries */
export type QueryQueuePositionArgs = {
  index: Scalars["Int"];
  poolIdent: Scalars["String"];
  txHash: Scalars["String"];
};

/** info queries */
export type QueryTax_ReportsArgs = {
  wallet: Scalars["String"];
};

/** info queries */
export type QueryTxArgs = {
  txHash: Scalars["String"];
};

/** info queries */
export type QueryUtxoArgs = {
  index: Scalars["Int"];
  txHash: Scalars["String"];
};

export type QueueDepth = {
  __typename?: "QueueDepth";
  depth: Scalars["Int"];
  pool?: Maybe<Pool>;
  poolIdent: Scalars["String"];
};

export type QueueStats = {
  __typename?: "QueueStats";
  time: Scalars["Float"];
};

export enum Rank {
  RankTvl = "RankTVL",
}

export enum Resolution {
  Canceled = "Canceled",
  Harvested = "Harvested",
  Scooped = "Scooped",
}

export type RewardConfig = {
  __typename?: "RewardConfig";
  AssetID: Scalars["String"];
  DailyEmission: Scalars["Int"];
  DateEnd: Scalars["String"];
  DateStart: Scalars["String"];
  EarlyUnlock: Scalars["Boolean"];
  Id: Scalars["String"];
  LPTokens: Array<Scalars["String"]>;
  Pools: Array<Scalars["String"]>;
};

export enum SortType {
  Gainers = "Gainers",
  Name = "Name",
  Recent = "Recent",
  Tvl = "TVL",
  Trending = "Trending",
  Volume = "Volume",
}

/** A given generated tax report */
export type TaxReport = {
  __typename?: "TaxReport";
  download_url: Scalars["String"];
  error: Scalars["String"];
  status: Scalars["String"];
  wallet: Scalars["String"];
  year: Scalars["Int"];
};

export enum TokenMetadataSource {
  CardanoTokenRegistry = "CardanoTokenRegistry",
  OnChainLabel20 = "OnChainLabel20",
  OnChainLabel721 = "OnChainLabel721",
}

export type Tx = {
  __typename?: "Tx";
  inputs: Array<TxIn>;
  outputs: Array<Utxo>;
};

export type TxIn = {
  __typename?: "TxIn";
  index: Scalars["Int"];
  txHash: Scalars["String"];
};

export type Utxo = {
  __typename?: "Utxo";
  address: Scalars["String"];
  assets: Array<UtxoAsset>;
  coins: Scalars["String"];
  datum?: Maybe<Scalars["String"]>;
  datumHash?: Maybe<Scalars["String"]>;
  index: Scalars["Int"];
  txHash: Scalars["String"];
};

export type UtxoAsset = {
  __typename?: "UtxoAsset";
  assetID: Scalars["String"];
  quantity: Scalars["String"];
};

export enum VolumeInterval {
  Daily = "Daily",
  Monthly = "Monthly",
  Weekly = "Weekly",
}

export type Wallet = {
  __typename?: "Wallet";
  /** positions(pageSize: Int, paginationToken: String): PositionPage! */
  farms: FarmsPage;
  liquidity: LiquidityPage;
};

export enum YieldTerm {
  Term30 = "Term30",
}

export type _Service = {
  __typename?: "_Service";
  sdl: Scalars["String"];
};

export type AssetFragmentFragment = {
  __typename?: "Asset";
  assetId: string;
  policyId: string;
  assetName?: string | null;
  decimals: number;
  logo?: string | null;
  ticker?: string | null;
  dateListed: string;
  marketCap?: string | null;
  sources?: Array<TokenMetadataSource> | null;
  priceToday?: string | null;
  priceYesterday?: string | null;
  priceDiff24H?: string | null;
  poolId: string;
  slotAdded: number;
  totalSupply?: string | null;
  tvl?: string | null;
  tvlDiff24H?: string | null;
  tvlToday?: string | null;
  tvlYesterday?: string | null;
  volume24H?: string | null;
};

export type UtxoFragmentFragment = {
  __typename?: "Utxo";
  address: string;
  txHash: string;
  index: number;
  coins: string;
  datum?: string | null;
  datumHash?: string | null;
  assets: Array<{
    __typename?: "UtxoAsset";
    quantity: string;
    assetID: string;
  }>;
};

export type PoolFragmentFragment = {
  __typename?: "Pool";
  apr?: number | null;
  name: string;
  fees24H?: string | null;
  quantityA?: string | null;
  quantityB?: string | null;
  quantityLP?: string | null;
  ident: string;
  assetID: string;
  version: string;
  rewards: Array<{
    __typename?: "PoolReward";
    apr?: number | null;
    assetID: string;
    farmProgram: {
      __typename?: "Farm";
      totalStaked: string;
      rewardConfig?: {
        __typename?: "RewardConfig";
        DateEnd: string;
        DateStart: string;
        Id: string;
      } | null;
    };
    asset?: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    } | null;
  }>;
  assetA: {
    __typename?: "Asset";
    assetId: string;
    policyId: string;
    assetName?: string | null;
    decimals: number;
    logo?: string | null;
    ticker?: string | null;
    dateListed: string;
    marketCap?: string | null;
    sources?: Array<TokenMetadataSource> | null;
    priceToday?: string | null;
    priceYesterday?: string | null;
    priceDiff24H?: string | null;
    poolId: string;
    slotAdded: number;
    totalSupply?: string | null;
    tvl?: string | null;
    tvlDiff24H?: string | null;
    tvlToday?: string | null;
    tvlYesterday?: string | null;
    volume24H?: string | null;
  };
  assetB: {
    __typename?: "Asset";
    assetId: string;
    policyId: string;
    assetName?: string | null;
    decimals: number;
    logo?: string | null;
    ticker?: string | null;
    dateListed: string;
    marketCap?: string | null;
    sources?: Array<TokenMetadataSource> | null;
    priceToday?: string | null;
    priceYesterday?: string | null;
    priceDiff24H?: string | null;
    poolId: string;
    slotAdded: number;
    totalSupply?: string | null;
    tvl?: string | null;
    tvlDiff24H?: string | null;
    tvlToday?: string | null;
    tvlYesterday?: string | null;
    volume24H?: string | null;
  };
  assetLP: {
    __typename?: "Asset";
    assetId: string;
    policyId: string;
    assetName?: string | null;
    decimals: number;
    logo?: string | null;
    ticker?: string | null;
    dateListed: string;
    marketCap?: string | null;
    sources?: Array<TokenMetadataSource> | null;
    priceToday?: string | null;
    priceYesterday?: string | null;
    priceDiff24H?: string | null;
    poolId: string;
    slotAdded: number;
    totalSupply?: string | null;
    tvl?: string | null;
    tvlDiff24H?: string | null;
    tvlToday?: string | null;
    tvlYesterday?: string | null;
    volume24H?: string | null;
  };
};

type OpFragment_OpDepositMixed_Fragment = {
  __typename: "OpDepositMixed";
  depositMixedLq?: string | null;
  depositMixedQuantityA?: string | null;
  depositMixedQuantityB?: string | null;
};

type OpFragment_OpDepositSingle_Fragment = {
  __typename: "OpDepositSingle";
  depositSingleLq?: string | null;
  depositSingleQuantityA?: string | null;
  depositSingleQuantityB?: string | null;
};

type OpFragment_OpSwap_Fragment = {
  __typename: "OpSwap";
  swapGot?: string | null;
  swapQuantityA?: string | null;
  swapQuantityB?: string | null;
  swapWant: string;
};

type OpFragment_OpWithdraw_Fragment = {
  __typename: "OpWithdraw";
  withdrawLq?: string | null;
  withdrawQuantityA?: string | null;
  withdrawQuantityB?: string | null;
};

export type OpFragmentFragment =
  | OpFragment_OpDepositMixed_Fragment
  | OpFragment_OpDepositSingle_Fragment
  | OpFragment_OpSwap_Fragment
  | OpFragment_OpWithdraw_Fragment;

export type OperationFragmentFragment = {
  __typename?: "Operation";
  address: string;
  spentReason?: Resolution | null;
  createdAt: { __typename?: "DateTime"; format: string };
  op:
    | {
        __typename: "OpDepositMixed";
        depositMixedLq?: string | null;
        depositMixedQuantityA?: string | null;
        depositMixedQuantityB?: string | null;
      }
    | {
        __typename: "OpDepositSingle";
        depositSingleLq?: string | null;
        depositSingleQuantityA?: string | null;
        depositSingleQuantityB?: string | null;
      }
    | {
        __typename: "OpSwap";
        swapGot?: string | null;
        swapQuantityA?: string | null;
        swapQuantityB?: string | null;
        swapWant: string;
      }
    | {
        __typename: "OpWithdraw";
        withdrawLq?: string | null;
        withdrawQuantityA?: string | null;
        withdrawQuantityB?: string | null;
      };
  outcome?: {
    __typename?: "Outcome";
    feeA?: string | null;
    feeB?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    price?: { __typename?: "Price"; unit: Coin; value: string } | null;
  } | null;
  pool: {
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  };
  spentAt?: { __typename?: "DateTime"; format: string } | null;
  spentTxIn?: { __typename?: "TxIn"; index: number; txHash: string } | null;
  txIn: { __typename?: "TxIn"; index: number; txHash: string };
};

export type PoolInfoFragmentFragment = {
  __typename?: "Pool";
  tvl?: string | null;
  name: string;
  priceUSD?: string | null;
};

export type ExtendPoolFragmentFragment = {
  __typename?: "Pool";
  apr?: number | null;
  name: string;
  fees24H?: string | null;
  quantityA?: string | null;
  quantityB?: string | null;
  quantityLP?: string | null;
  ident: string;
  assetID: string;
  version: string;
  tvl?: string | null;
  priceUSD?: string | null;
  rewards: Array<{
    __typename?: "PoolReward";
    apr?: number | null;
    assetID: string;
    farmProgram: {
      __typename?: "Farm";
      totalStaked: string;
      rewardConfig?: {
        __typename?: "RewardConfig";
        DateEnd: string;
        DateStart: string;
        Id: string;
      } | null;
    };
    asset?: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    } | null;
  }>;
  assetA: {
    __typename?: "Asset";
    assetId: string;
    policyId: string;
    assetName?: string | null;
    decimals: number;
    logo?: string | null;
    ticker?: string | null;
    dateListed: string;
    marketCap?: string | null;
    sources?: Array<TokenMetadataSource> | null;
    priceToday?: string | null;
    priceYesterday?: string | null;
    priceDiff24H?: string | null;
    poolId: string;
    slotAdded: number;
    totalSupply?: string | null;
    tvl?: string | null;
    tvlDiff24H?: string | null;
    tvlToday?: string | null;
    tvlYesterday?: string | null;
    volume24H?: string | null;
  };
  assetB: {
    __typename?: "Asset";
    assetId: string;
    policyId: string;
    assetName?: string | null;
    decimals: number;
    logo?: string | null;
    ticker?: string | null;
    dateListed: string;
    marketCap?: string | null;
    sources?: Array<TokenMetadataSource> | null;
    priceToday?: string | null;
    priceYesterday?: string | null;
    priceDiff24H?: string | null;
    poolId: string;
    slotAdded: number;
    totalSupply?: string | null;
    tvl?: string | null;
    tvlDiff24H?: string | null;
    tvlToday?: string | null;
    tvlYesterday?: string | null;
    volume24H?: string | null;
  };
  assetLP: {
    __typename?: "Asset";
    assetId: string;
    policyId: string;
    assetName?: string | null;
    decimals: number;
    logo?: string | null;
    ticker?: string | null;
    dateListed: string;
    marketCap?: string | null;
    sources?: Array<TokenMetadataSource> | null;
    priceToday?: string | null;
    priceYesterday?: string | null;
    priceDiff24H?: string | null;
    poolId: string;
    slotAdded: number;
    totalSupply?: string | null;
    tvl?: string | null;
    tvlDiff24H?: string | null;
    tvlToday?: string | null;
    tvlYesterday?: string | null;
    volume24H?: string | null;
  };
};

export type FreezerItemFragmentFragment = {
  __typename: "FreezerItem";
  assetID: string;
  earned?: string | null;
  quantity: string;
  term: YieldTerm;
  spentTxHash?: string | null;
  rewards: Array<{
    __typename?: "FreezerReward";
    quantity: string;
    asset?: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    } | null;
  }>;
  rewardsMatured: Array<{
    __typename?: "FreezerReward";
    quantity: string;
    asset?: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    } | null;
  }>;
  startDate?: { __typename?: "DateTime"; format: string } | null;
  spentAt?: { __typename?: "DateTime"; format: string } | null;
  nextRewardAt?: { __typename?: "DateTime"; format: string } | null;
  utxo?: {
    __typename?: "Utxo";
    address: string;
    txHash: string;
    index: number;
    coins: string;
    datum?: string | null;
    datumHash?: string | null;
    assets: Array<{
      __typename?: "UtxoAsset";
      quantity: string;
      assetID: string;
    }>;
  } | null;
  pool?: {
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  } | null;
};

export type LiquidityFragmentFragment = {
  __typename?: "Liquidity";
  poolShare: number;
  pool: {
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  };
  lpTokens: {
    __typename?: "AssetHistory";
    quantity: string;
    asset: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  };
  feesA: {
    __typename?: "AssetHistory";
    quantity: string;
    asset: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  };
  feesB: {
    __typename?: "AssetHistory";
    quantity: string;
    asset: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  };
};

export type LiquidityFeesFragmentFragment = {
  __typename?: "Liquidity";
  pool: {
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  };
  feesA: {
    __typename?: "AssetHistory";
    quantity: string;
    asset: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  };
  feesB: {
    __typename?: "AssetHistory";
    quantity: string;
    asset: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  };
};

export type GetOperationsOpenQueryVariables = Exact<{
  address: Scalars["String"];
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
}>;

export type GetOperationsOpenQuery = {
  __typename?: "Query";
  opOpen?: {
    __typename?: "OpItems";
    token?: string | null;
    ops: Array<{
      __typename?: "Operation";
      address: string;
      spentReason?: Resolution | null;
      createdAt: { __typename?: "DateTime"; format: string };
      op:
        | {
            __typename: "OpDepositMixed";
            depositMixedLq?: string | null;
            depositMixedQuantityA?: string | null;
            depositMixedQuantityB?: string | null;
          }
        | {
            __typename: "OpDepositSingle";
            depositSingleLq?: string | null;
            depositSingleQuantityA?: string | null;
            depositSingleQuantityB?: string | null;
          }
        | {
            __typename: "OpSwap";
            swapGot?: string | null;
            swapQuantityA?: string | null;
            swapQuantityB?: string | null;
            swapWant: string;
          }
        | {
            __typename: "OpWithdraw";
            withdrawLq?: string | null;
            withdrawQuantityA?: string | null;
            withdrawQuantityB?: string | null;
          };
      outcome?: {
        __typename?: "Outcome";
        feeA?: string | null;
        feeB?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        price?: { __typename?: "Price"; unit: Coin; value: string } | null;
      } | null;
      pool: {
        __typename?: "Pool";
        apr?: number | null;
        name: string;
        fees24H?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        ident: string;
        assetID: string;
        version: string;
        rewards: Array<{
          __typename?: "PoolReward";
          apr?: number | null;
          assetID: string;
          farmProgram: {
            __typename?: "Farm";
            totalStaked: string;
            rewardConfig?: {
              __typename?: "RewardConfig";
              DateEnd: string;
              DateStart: string;
              Id: string;
            } | null;
          };
          asset?: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          } | null;
        }>;
        assetA: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetB: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetLP: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
      };
      spentAt?: { __typename?: "DateTime"; format: string } | null;
      spentTxIn?: { __typename?: "TxIn"; index: number; txHash: string } | null;
      txIn: { __typename?: "TxIn"; index: number; txHash: string };
    }>;
  } | null;
};

export type GetOperationsHistoryQueryVariables = Exact<{
  address: Scalars["String"];
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
}>;

export type GetOperationsHistoryQuery = {
  __typename?: "Query";
  opHistory?: {
    __typename?: "OpItems";
    token?: string | null;
    ops: Array<{
      __typename?: "Operation";
      address: string;
      spentReason?: Resolution | null;
      createdAt: { __typename?: "DateTime"; format: string };
      op:
        | {
            __typename: "OpDepositMixed";
            depositMixedLq?: string | null;
            depositMixedQuantityA?: string | null;
            depositMixedQuantityB?: string | null;
          }
        | {
            __typename: "OpDepositSingle";
            depositSingleLq?: string | null;
            depositSingleQuantityA?: string | null;
            depositSingleQuantityB?: string | null;
          }
        | {
            __typename: "OpSwap";
            swapGot?: string | null;
            swapQuantityA?: string | null;
            swapQuantityB?: string | null;
            swapWant: string;
          }
        | {
            __typename: "OpWithdraw";
            withdrawLq?: string | null;
            withdrawQuantityA?: string | null;
            withdrawQuantityB?: string | null;
          };
      outcome?: {
        __typename?: "Outcome";
        feeA?: string | null;
        feeB?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        price?: { __typename?: "Price"; unit: Coin; value: string } | null;
      } | null;
      pool: {
        __typename?: "Pool";
        apr?: number | null;
        name: string;
        fees24H?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        ident: string;
        assetID: string;
        version: string;
        rewards: Array<{
          __typename?: "PoolReward";
          apr?: number | null;
          assetID: string;
          farmProgram: {
            __typename?: "Farm";
            totalStaked: string;
            rewardConfig?: {
              __typename?: "RewardConfig";
              DateEnd: string;
              DateStart: string;
              Id: string;
            } | null;
          };
          asset?: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          } | null;
        }>;
        assetA: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetB: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetLP: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
      };
      spentAt?: { __typename?: "DateTime"; format: string } | null;
      spentTxIn?: { __typename?: "TxIn"; index: number; txHash: string } | null;
      txIn: { __typename?: "TxIn"; index: number; txHash: string };
    }>;
  } | null;
};

export type GetOpenOrdersQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars["Int"]>;
  poolIdent: Scalars["String"];
}>;

export type GetOpenOrdersQuery = {
  __typename?: "Query";
  openOrders?: {
    __typename?: "OpItems";
    ops: Array<{
      __typename?: "Operation";
      address: string;
      spentReason?: Resolution | null;
      createdAt: { __typename?: "DateTime"; format: string };
      op:
        | {
            __typename: "OpDepositMixed";
            depositMixedLq?: string | null;
            depositMixedQuantityA?: string | null;
            depositMixedQuantityB?: string | null;
          }
        | {
            __typename: "OpDepositSingle";
            depositSingleLq?: string | null;
            depositSingleQuantityA?: string | null;
            depositSingleQuantityB?: string | null;
          }
        | {
            __typename: "OpSwap";
            swapGot?: string | null;
            swapQuantityA?: string | null;
            swapQuantityB?: string | null;
            swapWant: string;
          }
        | {
            __typename: "OpWithdraw";
            withdrawLq?: string | null;
            withdrawQuantityA?: string | null;
            withdrawQuantityB?: string | null;
          };
      outcome?: {
        __typename?: "Outcome";
        feeA?: string | null;
        feeB?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        price?: { __typename?: "Price"; unit: Coin; value: string } | null;
      } | null;
      pool: {
        __typename?: "Pool";
        apr?: number | null;
        name: string;
        fees24H?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        ident: string;
        assetID: string;
        version: string;
        rewards: Array<{
          __typename?: "PoolReward";
          apr?: number | null;
          assetID: string;
          farmProgram: {
            __typename?: "Farm";
            totalStaked: string;
            rewardConfig?: {
              __typename?: "RewardConfig";
              DateEnd: string;
              DateStart: string;
              Id: string;
            } | null;
          };
          asset?: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          } | null;
        }>;
        assetA: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetB: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetLP: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
      };
      spentAt?: { __typename?: "DateTime"; format: string } | null;
      spentTxIn?: { __typename?: "TxIn"; index: number; txHash: string } | null;
      txIn: { __typename?: "TxIn"; index: number; txHash: string };
    }>;
  } | null;
};

export type GetPopularPoolsQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars["Int"]>;
}>;

export type GetPopularPoolsQuery = {
  __typename?: "Query";
  poolsPopular: Array<{
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    tvl?: string | null;
    priceUSD?: string | null;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  }>;
};

export type GetPoolByIdentQueryVariables = Exact<{
  ident: Scalars["String"];
}>;

export type GetPoolByIdentQuery = {
  __typename?: "Query";
  poolByIdent?: {
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    tvl?: string | null;
    priceUSD?: string | null;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  } | null;
};

export type SearchPoolsQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type SearchPoolsQuery = {
  __typename?: "Query";
  pools: Array<{
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  } | null>;
};

export type SearchPoolsWithStatsQueryVariables = Exact<{
  query: Scalars["String"];
  dateFrom: Scalars["String"];
  dateTo: Scalars["String"];
  interval: VolumeInterval;
  currency: Scalars["String"];
}>;

export type SearchPoolsWithStatsQuery = {
  __typename?: "Query";
  pools: Array<{
    __typename?: "Pool";
    fees24H?: string | null;
    feeApr?: number | null;
    apr?: number | null;
    name: string;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    stats: Array<{
      __typename?: "PoolStat";
      lockedValue: string;
      tradingValue: string;
      tradingVolume: number;
    }>;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  } | null>;
};

export type GetAssetsByIdsQueryVariables = Exact<{
  ids: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetAssetsByIdsQuery = {
  __typename?: "Query";
  assets: Array<{
    __typename?: "Asset";
    assetId: string;
    policyId: string;
    assetName?: string | null;
    decimals: number;
    logo?: string | null;
    ticker?: string | null;
    dateListed: string;
    marketCap?: string | null;
    sources?: Array<TokenMetadataSource> | null;
    priceToday?: string | null;
    priceYesterday?: string | null;
    priceDiff24H?: string | null;
    poolId: string;
    slotAdded: number;
    totalSupply?: string | null;
    tvl?: string | null;
    tvlDiff24H?: string | null;
    tvlToday?: string | null;
    tvlYesterday?: string | null;
    volume24H?: string | null;
  }>;
};

export type GetPoolsByAssetIdsQueryVariables = Exact<{
  assetIds: Array<Scalars["String"]> | Scalars["String"];
  pageSize?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
}>;

export type GetPoolsByAssetIdsQuery = {
  __typename?: "Query";
  pools: Array<{
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  } | null>;
};

export type GetPositionsQueryVariables = Exact<{
  wallet: Scalars["String"];
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
}>;

export type GetPositionsQuery = {
  __typename?: "Query";
  positions: {
    __typename?: "Wallet";
    liquidity: {
      __typename?: "LiquidityPage";
      liquidity: Array<{
        __typename?: "Liquidity";
        poolShare: number;
        pool: {
          __typename?: "Pool";
          apr?: number | null;
          name: string;
          fees24H?: string | null;
          quantityA?: string | null;
          quantityB?: string | null;
          quantityLP?: string | null;
          ident: string;
          assetID: string;
          version: string;
          rewards: Array<{
            __typename?: "PoolReward";
            apr?: number | null;
            assetID: string;
            farmProgram: {
              __typename?: "Farm";
              totalStaked: string;
              rewardConfig?: {
                __typename?: "RewardConfig";
                DateEnd: string;
                DateStart: string;
                Id: string;
              } | null;
            };
            asset?: {
              __typename?: "Asset";
              assetId: string;
              policyId: string;
              assetName?: string | null;
              decimals: number;
              logo?: string | null;
              ticker?: string | null;
              dateListed: string;
              marketCap?: string | null;
              sources?: Array<TokenMetadataSource> | null;
              priceToday?: string | null;
              priceYesterday?: string | null;
              priceDiff24H?: string | null;
              poolId: string;
              slotAdded: number;
              totalSupply?: string | null;
              tvl?: string | null;
              tvlDiff24H?: string | null;
              tvlToday?: string | null;
              tvlYesterday?: string | null;
              volume24H?: string | null;
            } | null;
          }>;
          assetA: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          };
          assetB: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          };
          assetLP: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          };
        };
        lpTokens: {
          __typename?: "AssetHistory";
          quantity: string;
          asset: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          };
        };
        feesA: {
          __typename?: "AssetHistory";
          quantity: string;
          asset: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          };
        };
        feesB: {
          __typename?: "AssetHistory";
          quantity: string;
          asset: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          };
        };
      }>;
    };
  };
};

export type FreezerOpenQueryVariables = Exact<{
  address: Scalars["String"];
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
}>;

export type FreezerOpenQuery = {
  __typename?: "Query";
  freezerOpen?: {
    __typename?: "FreezerItems";
    token?: string | null;
    items: Array<{
      __typename: "FreezerItem";
      assetID: string;
      earned?: string | null;
      quantity: string;
      term: YieldTerm;
      spentTxHash?: string | null;
      rewards: Array<{
        __typename?: "FreezerReward";
        quantity: string;
        asset?: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        } | null;
      }>;
      rewardsMatured: Array<{
        __typename?: "FreezerReward";
        quantity: string;
        asset?: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        } | null;
      }>;
      startDate?: { __typename?: "DateTime"; format: string } | null;
      spentAt?: { __typename?: "DateTime"; format: string } | null;
      nextRewardAt?: { __typename?: "DateTime"; format: string } | null;
      utxo?: {
        __typename?: "Utxo";
        address: string;
        txHash: string;
        index: number;
        coins: string;
        datum?: string | null;
        datumHash?: string | null;
        assets: Array<{
          __typename?: "UtxoAsset";
          quantity: string;
          assetID: string;
        }>;
      } | null;
      pool?: {
        __typename?: "Pool";
        apr?: number | null;
        name: string;
        fees24H?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        ident: string;
        assetID: string;
        version: string;
        rewards: Array<{
          __typename?: "PoolReward";
          apr?: number | null;
          assetID: string;
          farmProgram: {
            __typename?: "Farm";
            totalStaked: string;
            rewardConfig?: {
              __typename?: "RewardConfig";
              DateEnd: string;
              DateStart: string;
              Id: string;
            } | null;
          };
          asset?: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          } | null;
        }>;
        assetA: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetB: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetLP: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
      } | null;
    }>;
  } | null;
  now: { __typename?: "DateTime"; format: string };
};

export type FreezerHistoryQueryVariables = Exact<{
  address: Scalars["String"];
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  token?: InputMaybe<Scalars["String"]>;
}>;

export type FreezerHistoryQuery = {
  __typename?: "Query";
  freezerHistory?: {
    __typename?: "FreezerItems";
    token?: string | null;
    items: Array<{
      __typename: "FreezerItem";
      assetID: string;
      earned?: string | null;
      quantity: string;
      term: YieldTerm;
      spentTxHash?: string | null;
      rewards: Array<{
        __typename?: "FreezerReward";
        quantity: string;
        asset?: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        } | null;
      }>;
      rewardsMatured: Array<{
        __typename?: "FreezerReward";
        quantity: string;
        asset?: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        } | null;
      }>;
      startDate?: { __typename?: "DateTime"; format: string } | null;
      spentAt?: { __typename?: "DateTime"; format: string } | null;
      nextRewardAt?: { __typename?: "DateTime"; format: string } | null;
      utxo?: {
        __typename?: "Utxo";
        address: string;
        txHash: string;
        index: number;
        coins: string;
        datum?: string | null;
        datumHash?: string | null;
        assets: Array<{
          __typename?: "UtxoAsset";
          quantity: string;
          assetID: string;
        }>;
      } | null;
      pool?: {
        __typename?: "Pool";
        apr?: number | null;
        name: string;
        fees24H?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        ident: string;
        assetID: string;
        version: string;
        rewards: Array<{
          __typename?: "PoolReward";
          apr?: number | null;
          assetID: string;
          farmProgram: {
            __typename?: "Farm";
            totalStaked: string;
            rewardConfig?: {
              __typename?: "RewardConfig";
              DateEnd: string;
              DateStart: string;
              Id: string;
            } | null;
          };
          asset?: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          } | null;
        }>;
        assetA: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetB: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetLP: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
      } | null;
    }>;
  } | null;
};

export type GetFactoryDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetFactoryDataQuery = {
  __typename?: "Query";
  factory?: {
    __typename?: "Utxo";
    txHash: string;
    index: number;
    datum?: string | null;
    datumHash?: string | null;
    address: string;
    coins: string;
    assets: Array<{
      __typename?: "UtxoAsset";
      assetID: string;
      quantity: string;
    }>;
  } | null;
};

export type GetPoolsByPairQueryVariables = Exact<{
  coinA: Scalars["String"];
  coinB: Scalars["String"];
}>;

export type GetPoolsByPairQuery = {
  __typename?: "Query";
  poolsByPair: Array<{
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  } | null>;
};

export type GetTaxReportsQueryVariables = Exact<{
  wallet: Scalars["String"];
}>;

export type GetTaxReportsQuery = {
  __typename?: "Query";
  tax_reports: Array<{
    __typename?: "TaxReport";
    wallet: string;
    year: number;
    status: string;
    error: string;
    download_url: string;
  }>;
};

export type GenerateTaxReportMutationVariables = Exact<{
  wallet: Scalars["String"];
  year: Scalars["Int"];
}>;

export type GenerateTaxReportMutation = {
  __typename?: "Mutation";
  generate_tax_report: {
    __typename?: "TaxReport";
    wallet: string;
    year: number;
    status: string;
    error: string;
    download_url: string;
  };
};

export type GetFeesQueryVariables = Exact<{
  queries: Array<FeeQuery> | FeeQuery;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
}>;

export type GetFeesQuery = {
  __typename?: "Query";
  fees: {
    __typename?: "LiquidityPage";
    liquidity: Array<{
      __typename?: "Liquidity";
      pool: {
        __typename?: "Pool";
        apr?: number | null;
        name: string;
        fees24H?: string | null;
        quantityA?: string | null;
        quantityB?: string | null;
        quantityLP?: string | null;
        ident: string;
        assetID: string;
        version: string;
        rewards: Array<{
          __typename?: "PoolReward";
          apr?: number | null;
          assetID: string;
          farmProgram: {
            __typename?: "Farm";
            totalStaked: string;
            rewardConfig?: {
              __typename?: "RewardConfig";
              DateEnd: string;
              DateStart: string;
              Id: string;
            } | null;
          };
          asset?: {
            __typename?: "Asset";
            assetId: string;
            policyId: string;
            assetName?: string | null;
            decimals: number;
            logo?: string | null;
            ticker?: string | null;
            dateListed: string;
            marketCap?: string | null;
            sources?: Array<TokenMetadataSource> | null;
            priceToday?: string | null;
            priceYesterday?: string | null;
            priceDiff24H?: string | null;
            poolId: string;
            slotAdded: number;
            totalSupply?: string | null;
            tvl?: string | null;
            tvlDiff24H?: string | null;
            tvlToday?: string | null;
            tvlYesterday?: string | null;
            volume24H?: string | null;
          } | null;
        }>;
        assetA: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetB: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
        assetLP: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
      };
      feesA: {
        __typename?: "AssetHistory";
        quantity: string;
        asset: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
      };
      feesB: {
        __typename?: "AssetHistory";
        quantity: string;
        asset: {
          __typename?: "Asset";
          assetId: string;
          policyId: string;
          assetName?: string | null;
          decimals: number;
          logo?: string | null;
          ticker?: string | null;
          dateListed: string;
          marketCap?: string | null;
          sources?: Array<TokenMetadataSource> | null;
          priceToday?: string | null;
          priceYesterday?: string | null;
          priceDiff24H?: string | null;
          poolId: string;
          slotAdded: number;
          totalSupply?: string | null;
          tvl?: string | null;
          tvlDiff24H?: string | null;
          tvlToday?: string | null;
          tvlYesterday?: string | null;
          volume24H?: string | null;
        };
      };
    }>;
  };
};

export type GetPoolsByIdentQueryVariables = Exact<{
  idents: Array<Scalars["String"]> | Scalars["String"];
}>;

export type GetPoolsByIdentQuery = {
  __typename?: "Query";
  poolsByIdent: Array<{
    __typename?: "Pool";
    apr?: number | null;
    name: string;
    fees24H?: string | null;
    quantityA?: string | null;
    quantityB?: string | null;
    quantityLP?: string | null;
    ident: string;
    assetID: string;
    version: string;
    tvl?: string | null;
    priceUSD?: string | null;
    rewards: Array<{
      __typename?: "PoolReward";
      apr?: number | null;
      assetID: string;
      farmProgram: {
        __typename?: "Farm";
        totalStaked: string;
        rewardConfig?: {
          __typename?: "RewardConfig";
          DateEnd: string;
          DateStart: string;
          Id: string;
        } | null;
      };
      asset?: {
        __typename?: "Asset";
        assetId: string;
        policyId: string;
        assetName?: string | null;
        decimals: number;
        logo?: string | null;
        ticker?: string | null;
        dateListed: string;
        marketCap?: string | null;
        sources?: Array<TokenMetadataSource> | null;
        priceToday?: string | null;
        priceYesterday?: string | null;
        priceDiff24H?: string | null;
        poolId: string;
        slotAdded: number;
        totalSupply?: string | null;
        tvl?: string | null;
        tvlDiff24H?: string | null;
        tvlToday?: string | null;
        tvlYesterday?: string | null;
        volume24H?: string | null;
      } | null;
    }>;
    assetA: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetB: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
    assetLP: {
      __typename?: "Asset";
      assetId: string;
      policyId: string;
      assetName?: string | null;
      decimals: number;
      logo?: string | null;
      ticker?: string | null;
      dateListed: string;
      marketCap?: string | null;
      sources?: Array<TokenMetadataSource> | null;
      priceToday?: string | null;
      priceYesterday?: string | null;
      priceDiff24H?: string | null;
      poolId: string;
      slotAdded: number;
      totalSupply?: string | null;
      tvl?: string | null;
      tvlDiff24H?: string | null;
      tvlToday?: string | null;
      tvlYesterday?: string | null;
      volume24H?: string | null;
    };
  } | null>;
};

export type GetAdaPriceQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdaPriceQuery = { __typename?: "Query"; adaPrice: string };

export const OpFragmentFragmentDoc = gql`
  fragment OpFragment on Op {
    __typename
    ... on OpSwap {
      swapGot: got
      swapQuantityA: quantityA
      swapQuantityB: quantityB
      swapWant: want
    }
    ... on OpDepositMixed {
      depositMixedLq: lq
      depositMixedQuantityA: quantityA
      depositMixedQuantityB: quantityB
    }
    ... on OpDepositSingle {
      depositSingleLq: lq
      depositSingleQuantityA: quantityA
      depositSingleQuantityB: quantityB
    }
    ... on OpWithdraw {
      withdrawLq: lq
      withdrawQuantityA: quantityA
      withdrawQuantityB: quantityB
    }
  }
`;
export const AssetFragmentFragmentDoc = gql`
  fragment AssetFragment on Asset {
    assetId
    policyId
    assetName
    decimals
    logo
    ticker
    dateListed
    marketCap
    sources
    priceToday
    priceYesterday
    priceDiff24H
    poolId
    slotAdded
    totalSupply
    tvl
    tvlDiff24H
    tvlToday
    tvlYesterday
    volume24H
  }
`;
export const PoolFragmentFragmentDoc = gql`
  fragment PoolFragment on Pool {
    apr
    rewards {
      farmProgram {
        totalStaked
        rewardConfig {
          DateEnd
          DateStart
          Id
        }
      }
      apr
      assetID
      asset {
        ...AssetFragment
      }
    }
    assetA {
      ...AssetFragment
    }
    assetB {
      ...AssetFragment
    }
    assetLP {
      ...AssetFragment
    }
    name
    fees24H
    quantityA
    quantityB
    quantityLP
    ident
    assetID
    version
  }
`;
export const OperationFragmentFragmentDoc = gql`
  fragment OperationFragment on Operation {
    createdAt {
      format(layout: "2006-01-02T15:04:05-0700")
    }
    address
    op {
      __typename
      ...OpFragment
    }
    outcome {
      feeA
      feeB
      quantityA
      quantityB
      quantityLP
      price {
        unit
        value
      }
    }
    pool {
      ...PoolFragment
    }
    spentAt {
      format(layout: "2006-01-02T15:04:05-0700")
    }
    spentReason
    spentTxIn {
      index
      txHash
    }
    txIn {
      index
      txHash
    }
  }
`;
export const PoolInfoFragmentFragmentDoc = gql`
  fragment PoolInfoFragment on Pool {
    tvl
    name
    priceUSD
  }
`;
export const ExtendPoolFragmentFragmentDoc = gql`
  fragment ExtendPoolFragment on Pool {
    ...PoolFragment
    ...PoolInfoFragment
  }
`;
export const UtxoFragmentFragmentDoc = gql`
  fragment UtxoFragment on Utxo {
    address
    txHash
    index
    coins
    datum
    datumHash
    assets {
      quantity
      assetID
    }
  }
`;
export const FreezerItemFragmentFragmentDoc = gql`
  fragment FreezerItemFragment on FreezerItem {
    __typename
    assetID
    earned
    rewards {
      asset {
        ...AssetFragment
      }
      quantity
    }
    rewardsMatured {
      asset {
        ...AssetFragment
      }
      quantity
    }
    startDate {
      format(layout: "2006-01-02T15:04:05Z")
    }
    spentAt {
      format(layout: "2006-01-02T15:04:05Z")
    }
    nextRewardAt {
      format(layout: "2006-01-02T15:04:05Z")
    }
    quantity
    term
    utxo {
      ...UtxoFragment
    }
    pool {
      ...PoolFragment
    }
    spentTxHash
  }
`;
export const LiquidityFragmentFragmentDoc = gql`
  fragment LiquidityFragment on Liquidity {
    pool {
      ...PoolFragment
    }
    poolShare
    lpTokens {
      asset {
        ...AssetFragment
      }
      quantity
    }
    feesA {
      asset {
        ...AssetFragment
      }
      quantity
    }
    feesB {
      asset {
        ...AssetFragment
      }
      quantity
    }
  }
`;
export const LiquidityFeesFragmentFragmentDoc = gql`
  fragment LiquidityFeesFragment on Liquidity {
    pool {
      ...PoolFragment
    }
    feesA {
      asset {
        ...AssetFragment
      }
      quantity
    }
    feesB {
      asset {
        ...AssetFragment
      }
      quantity
    }
  }
`;
export const GetOperationsOpenDocument = gql`
  query getOperationsOpen($address: String!, $pageSize: Int, $token: String) {
    opOpen(address: $address, pageSize: $pageSize, token: $token) {
      ops {
        ...OperationFragment
      }
      token
    }
  }
  ${OperationFragmentFragmentDoc}
  ${OpFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetOperationsHistoryDocument = gql`
  query getOperationsHistory(
    $address: String!
    $pageSize: Int
    $token: String
  ) {
    opHistory(address: $address, pageSize: $pageSize, token: $token) {
      ops {
        ...OperationFragment
      }
      token
    }
  }
  ${OperationFragmentFragmentDoc}
  ${OpFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetOpenOrdersDocument = gql`
  query getOpenOrders($pageSize: Int, $poolIdent: String!) {
    openOrders(pageSize: $pageSize, poolIdent: $poolIdent) {
      ops {
        ...OperationFragment
      }
    }
  }
  ${OperationFragmentFragmentDoc}
  ${OpFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetPopularPoolsDocument = gql`
  query getPopularPools($pageSize: Int) {
    poolsPopular(pageSize: $pageSize) {
      ...ExtendPoolFragment
    }
  }
  ${ExtendPoolFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${PoolInfoFragmentFragmentDoc}
`;
export const GetPoolByIdentDocument = gql`
  query getPoolByIdent($ident: String!) {
    poolByIdent(ident: $ident) {
      ...ExtendPoolFragment
    }
  }
  ${ExtendPoolFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${PoolInfoFragmentFragmentDoc}
`;
export const SearchPoolsDocument = gql`
  query searchPools($query: String!) {
    pools(query: $query) {
      ...PoolFragment
    }
  }
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const SearchPoolsWithStatsDocument = gql`
  query searchPoolsWithStats(
    $query: String!
    $dateFrom: String!
    $dateTo: String!
    $interval: VolumeInterval!
    $currency: String!
  ) {
    pools(query: $query) {
      ...PoolFragment
      fees24H
      feeApr
      stats(
        dateFrom: $dateFrom
        dateTo: $dateTo
        interval: $interval
        currency: $currency
      ) {
        lockedValue
        tradingValue
        tradingVolume
      }
    }
  }
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetAssetsByIdsDocument = gql`
  query getAssetsByIds($ids: [String!]!) {
    assets(ids: $ids) {
      ...AssetFragment
    }
  }
  ${AssetFragmentFragmentDoc}
`;
export const GetPoolsByAssetIdsDocument = gql`
  query getPoolsByAssetIds($assetIds: [String!]!, $pageSize: Int, $page: Int) {
    pools(assetIds: $assetIds, pageSize: $pageSize, page: $page) {
      ...PoolFragment
    }
  }
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetPositionsDocument = gql`
  query getPositions($wallet: String!, $page: Int!, $pageSize: Int!) {
    positions(wallet: $wallet) {
      liquidity {
        liquidity(page: $page, pageSize: $pageSize) {
          ...LiquidityFragment
        }
      }
    }
  }
  ${LiquidityFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const FreezerOpenDocument = gql`
  query freezerOpen($address: String!, $pageSize: Int, $token: String) {
    freezerOpen(address: $address, pageSize: $pageSize, token: $token) {
      token
      items {
        ...FreezerItemFragment
      }
    }
    now {
      format(layout: "2006-01-02T15:04:05Z")
    }
  }
  ${FreezerItemFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${UtxoFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
`;
export const FreezerHistoryDocument = gql`
  query freezerHistory(
    $address: String!
    $dateFrom: String
    $dateTo: String
    $pageSize: Int
    $token: String
  ) {
    freezerHistory(
      address: $address
      pageSize: $pageSize
      token: $token
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      token
      items {
        ...FreezerItemFragment
      }
    }
  }
  ${FreezerItemFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${UtxoFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
`;
export const GetFactoryDataDocument = gql`
  query getFactoryData {
    factory {
      txHash
      index
      datum
      datumHash
      address
      coins
      assets {
        assetID
        quantity
      }
    }
  }
`;
export const GetPoolsByPairDocument = gql`
  query getPoolsByPair($coinA: String!, $coinB: String!) {
    poolsByPair(coinA: $coinA, coinB: $coinB) {
      ...PoolFragment
    }
  }
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetTaxReportsDocument = gql`
  query getTaxReports($wallet: String!) {
    tax_reports(wallet: $wallet) {
      wallet
      year
      status
      error
      download_url
    }
  }
`;
export const GenerateTaxReportDocument = gql`
  mutation generateTaxReport($wallet: String!, $year: Int!) {
    generate_tax_report(wallet: $wallet, year: $year) {
      wallet
      year
      status
      error
      download_url
    }
  }
`;
export const GetFeesDocument = gql`
  query getFees($queries: [FeeQuery!]!, $page: Int!, $pageSize: Int!) {
    fees(queries: $queries) {
      liquidity(page: $page, pageSize: $pageSize) {
        ...LiquidityFeesFragment
      }
    }
  }
  ${LiquidityFeesFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetPoolsByIdentDocument = gql`
  query getPoolsByIdent($idents: [String!]!) {
    poolsByIdent(idents: $idents) {
      ...ExtendPoolFragment
    }
  }
  ${ExtendPoolFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${PoolInfoFragmentFragmentDoc}
`;
export const GetAdaPriceDocument = gql`
  query getAdaPrice {
    adaPrice
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    getOperationsOpen(
      variables: GetOperationsOpenQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetOperationsOpenQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetOperationsOpenQuery>(
            GetOperationsOpenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getOperationsOpen",
        "query",
      );
    },
    getOperationsHistory(
      variables: GetOperationsHistoryQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetOperationsHistoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetOperationsHistoryQuery>(
            GetOperationsHistoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getOperationsHistory",
        "query",
      );
    },
    getOpenOrders(
      variables: GetOpenOrdersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetOpenOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetOpenOrdersQuery>(GetOpenOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getOpenOrders",
        "query",
      );
    },
    getPopularPools(
      variables?: GetPopularPoolsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetPopularPoolsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPopularPoolsQuery>(
            GetPopularPoolsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getPopularPools",
        "query",
      );
    },
    getPoolByIdent(
      variables: GetPoolByIdentQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetPoolByIdentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPoolByIdentQuery>(
            GetPoolByIdentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getPoolByIdent",
        "query",
      );
    },
    searchPools(
      variables: SearchPoolsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<SearchPoolsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchPoolsQuery>(SearchPoolsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "searchPools",
        "query",
      );
    },
    searchPoolsWithStats(
      variables: SearchPoolsWithStatsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<SearchPoolsWithStatsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchPoolsWithStatsQuery>(
            SearchPoolsWithStatsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "searchPoolsWithStats",
        "query",
      );
    },
    getAssetsByIds(
      variables: GetAssetsByIdsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetAssetsByIdsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAssetsByIdsQuery>(
            GetAssetsByIdsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getAssetsByIds",
        "query",
      );
    },
    getPoolsByAssetIds(
      variables: GetPoolsByAssetIdsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetPoolsByAssetIdsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPoolsByAssetIdsQuery>(
            GetPoolsByAssetIdsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getPoolsByAssetIds",
        "query",
      );
    },
    getPositions(
      variables: GetPositionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetPositionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPositionsQuery>(GetPositionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getPositions",
        "query",
      );
    },
    freezerOpen(
      variables: FreezerOpenQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FreezerOpenQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FreezerOpenQuery>(FreezerOpenDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "freezerOpen",
        "query",
      );
    },
    freezerHistory(
      variables: FreezerHistoryQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FreezerHistoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FreezerHistoryQuery>(
            FreezerHistoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "freezerHistory",
        "query",
      );
    },
    getFactoryData(
      variables?: GetFactoryDataQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetFactoryDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetFactoryDataQuery>(
            GetFactoryDataDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getFactoryData",
        "query",
      );
    },
    getPoolsByPair(
      variables: GetPoolsByPairQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetPoolsByPairQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPoolsByPairQuery>(
            GetPoolsByPairDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getPoolsByPair",
        "query",
      );
    },
    getTaxReports(
      variables: GetTaxReportsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetTaxReportsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTaxReportsQuery>(GetTaxReportsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getTaxReports",
        "query",
      );
    },
    generateTaxReport(
      variables: GenerateTaxReportMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GenerateTaxReportMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GenerateTaxReportMutation>(
            GenerateTaxReportDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "generateTaxReport",
        "mutation",
      );
    },
    getFees(
      variables: GetFeesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetFeesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetFeesQuery>(GetFeesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getFees",
        "query",
      );
    },
    getPoolsByIdent(
      variables: GetPoolsByIdentQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetPoolsByIdentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPoolsByIdentQuery>(
            GetPoolsByIdentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getPoolsByIdent",
        "query",
      );
    },
    getAdaPrice(
      variables?: GetAdaPriceQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<GetAdaPriceQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAdaPriceQuery>(GetAdaPriceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getAdaPrice",
        "query",
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
