import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import { Guides } from "../../../../../components/JoyrideStepComponents";
import {
  joyrideIds,
  joyrideSelectors,
} from "../../../../../constants/joyride.constants";
import { PortfolioTab, paths } from "../../../../../pages/routes";
import {
  EGuideChapter,
  IGuide,
} from "../../../../../stores/ControlCenterContext";

export const usePortfolioLiquidityGuideJoyride = (): IGuide => {
  const { t } = useI18N("joyride");

  return useMemo(
    () => ({
      rank: 3,
      description: t("portfolio.guides.liquidity.description"),
      title: t("portfolio.guides.liquidity.title"),
      chapter: EGuideChapter.PORTFOLIO,
      joyride: {
        id: joyrideIds.portfolio.liquidity,
        steps: [
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.liquidity,
            },
            title: <Guides.Portfolio.Liquidity.Titles.LiquidityStep1Title />,
            content: <Guides.Portfolio.Liquidity.Steps.LiquidityStep1 />,
            placement: "right",
            target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.liquidity.doughnutChart}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.liquidity,
            },
            title: <Guides.Portfolio.Liquidity.Titles.LiquidityStep2Title />,
            content: <Guides.Portfolio.Liquidity.Steps.LiquidityStep2 />,
            placement: "left",
            target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.liquidity.stackedBarChart}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.liquidity,
            },
            title: t("portfolio.guides.liquidity.steps.step3.title"),
            content: t("portfolio.guides.liquidity.steps.step3.description"),
            placement: "top",
            target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.liquidity.table}"]`,
          },
          {
            entryUrl: paths.root,
            entrySearchParams: {
              tab: PortfolioTab.liquidity,
            },
            title: t("portfolio.guides.liquidity.steps.step4.title"),
            content: t("portfolio.guides.liquidity.steps.step4.description"),
            placement: "left",
            target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.liquidity.actionsButton}"]`,
          },
        ],
      },
    }),
    [t],
  );
};
