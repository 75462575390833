import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Button, Confetti, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { motion } from "framer-motion";
import { FC, useEffect, useState } from "react";
import { getControlCenterContentElementsMotion } from "../../../motions/control-center.motions";
import { useProfileAppContext } from "../../../stores";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import Motion from "../../Motion";

const ControlCenterGuidesSuccessMessage: FC = () => {
  const [fireConfetti, setFireConfetti] = useState(false);
  const {
    state: { prefersReducedMotion },
  } = useProfileAppContext();
  const {
    actionHandler,
    state: {
      localState: {
        guides: { showSuccessAnimation },
        withAnimationDelay,
      },
    },
  } = useControlCenterContext();

  useEffect(() => {
    if (showSuccessAnimation) {
      setTimeout(() => {
        setFireConfetti(true);
      }, 750);
    }
  }, [showSuccessAnimation]);

  return (
    <>
      <Motion className="relative z-10 flex h-full w-full items-center justify-center">
        <motion.div
          key="JoyrideGuides--success"
          className={cx(
            "grid place-items-center gap-4",
            toolkitCx.layout.spacing.both.lg,
          )}
          variants={getControlCenterContentElementsMotion(withAnimationDelay)}
        >
          <CheckCircleIcon className="h-24 w-24 text-success" />

          <Text tag="h1" size="2xl" weight="semibold">
            Guide Completed!
          </Text>

          <Button
            onClick={actionHandler.guides.handleSuccessMessage}
            variant="neutral"
          >
            <ArrowLeftIcon className="h-4 w-4" />
            Back to all guides
          </Button>
        </motion.div>
      </Motion>
      {!prefersReducedMotion && (
        <Confetti className="!absolute bottom-0 left-0" fire={fireConfetti} />
      )}
    </>
  );
};

export default ControlCenterGuidesSuccessMessage;
