import { XMarkIcon } from "@heroicons/react/24/outline";
import { EventType } from "@sundaeswap/react-hooks";
import { SundaeSwapLogo, Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, useCallback } from "react";

import { joyrideSelectors } from "../../../constants/joyride.constants";
import { useEventTracking } from "../../../hooks/useEventTracking";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import { useNewsContext } from "../../../stores/NewsContext";
import { getEnv } from "../../../utils/network.utils";

const ControlCenterAvatar: FC = () => {
  const handleEventTracking = useEventTracking();
  const {
    actionHandler,
    state: {
      localState: { showToolbar },
      persistentState: {
        guides: { isEveryChapterCompleted },
      },
    },
  } = useControlCenterContext();
  const {
    state: { numberOfUnreadNewsItems },
  } = useNewsContext();

  const handleAvatarClick = useCallback(() => {
    handleEventTracking({
      eventType: EventType.Click,
      source: "ControlCenterAvatar",
      value: showToolbar ? "close" : "open",
      network: getEnv(),
    });
    actionHandler?.controlCenter?.toggleOpen();
  }, [actionHandler?.controlCenter, handleEventTracking, showToolbar]);

  return (
    <div
      className={cx(
        "relative z-20 flex h-14 w-14 cursor-pointer select-none items-center justify-center rounded-full",
        toolkitCx.layout.border.weight.semibold,
        { "hover:opacity-75": !showToolbar },
        {
          [`${toolkitCx.layout.background.primary} border-transparent`]:
            !showToolbar,
          "border-primary bg-gray-900": showToolbar,
        },
        toolkitCx.layout.transition.default,
        toolkitCx.recipes.scaleWhenActive,
      )}
      data-guide-selector={joyrideSelectors.avatar}
      onClick={handleAvatarClick}
      aria-expanded={showToolbar}
      role="button"
      aria-haspopup="true"
    >
      <SundaeSwapLogo
        className={cx(
          "h-3/5 w-3/5",
          {
            "opacity-25": showToolbar,
          },
          toolkitCx.layout.transition.opacity,
        )}
      />

      <Text
        tag="div"
        className={cx(
          "absolute flex h-6 w-6 items-center justify-center opacity-0",
          {
            "opacity-100": showToolbar,
          },
          toolkitCx.recipes.center.both(),
          toolkitCx.layout.transition.opacity,
        )}
        variant="forceLight"
      >
        <XMarkIcon
          className={cx(
            "h-6 w-6",
            {
              "rotate-180": showToolbar,
            },
            toolkitCx.layout.transition.transform,
          )}
        />
      </Text>

      {!isEveryChapterCompleted ||
      (numberOfUnreadNewsItems && numberOfUnreadNewsItems > 0) ? (
        <div
          className={cx(
            "absolute right-0 top-0 h-3 w-3 rounded-full border-2 border-gray-300 bg-current dark:border-gray-600",
            toolkitCx.layout.text.error,
          )}
        />
      ) : null}
    </div>
  );
};

export default ControlCenterAvatar;
