import { ExternalLink, Image, Text } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC } from "react";
import { Trans } from "react-i18next";

interface INoNewsPlaceholder {
  containerClassName?: string;
}

const NoNewsPlaceholder: FC<INoNewsPlaceholder> = ({ containerClassName }) => {
  return (
    <div
      className={cx(
        "relative flex w-full flex-col items-center gap-4 p-4 pt-0",
        containerClassName,
      )}
    >
      <Image
        src="/static/images/no-data/empty_table.png"
        style={{ maxWidth: `${128}px` }}
        width={128}
      />
      <Text className="text-center" size="xs" tag="p">
        <Trans ns="news" i18nKey="noNews">
          No news at the moment. We will update you here as soon as we have
          something new for you. Check out our{" "}
          <ExternalLink
            arrow="top-right"
            rel="noopener noreferrer"
            href="https://forum.sundaeswap.finance/"
            size="xs"
            variant="primary"
          >
            Community
          </ExternalLink>{" "}
          in the meantime.
        </Trans>
      </Text>
    </div>
  );
};

export default NoNewsPlaceholder;
