import { AssetAmount, AssetRatio } from "@sundaeswap/asset";
import { IPoolData } from "@sundaeswap/core";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { getSwapOutput } from "@sundaeswap/cpp";
import { Fraction, TFractionLike, TIntegerLike } from "@sundaeswap/fraction";

import { ADA_METADATA } from "../constants/cardano.constants";
import { FreezerItem } from "../gql/generated/stats2.sdk";
import useIsLPAsset from "../hooks/useIsLPAsset";
import { IAssetMetaData } from "../types/Asset.types";
import { TPool } from "../types/Pool.types";
import {
  calculateAssetPriceInADA,
  calculateAssetValueInUSD,
  getAssetName,
  getQAsset,
  isSameAsset,
} from "./assets.utils";
import {
  parseScientific,
  percentageI18n,
  stringToBigint,
} from "./number-format";
import { getUnlockedTokensPoolShare } from "./positions.utils";

/**
 * The key is an AssetID for the pools LP token
 */
export type TAssetIdPoolMap = Map<string, TPool>;

/**
 * Calculates and returns the price ratio of two assets in a pool.
 *
 * The function expects an object with properties `assetAId`, `assetAmountA`, and `assetAmountB`.
 * - `assetAId` should be a string representing the ID of the first asset.
 * - `assetAmountA` and `assetAmountB` are instances of `AssetAmount` representing the amount of each asset in the pool.
 *
 * The price ratio is calculated as the value of `assetAmountA` divided by `assetAmountB` if the `assetAId` is equivalent to 'Ada',
 * otherwise it's calculated as the value of `assetAmountB` divided by `assetAmountA`.
 *
 * The function returns an instance of `AssetAmount` containing the calculated price ratio.
 *
 * @param {Object} params - An object containing `assetAId`, `assetAmountA`, and `assetAmountB`.
 * @param {AssetAmount} params.assetAmountA - The amount of the first asset in the pool.
 * @param {AssetAmount} params.assetAmountB - The amount of the second asset in the pool.
 *
 * @returns {AssetAmount} The price ratio of the two assets in the pool.
 */
export const getPoolDisplayPrice = ({
  assetAmountA,
  assetAmountB,
}: {
  assetAmountA: AssetAmount;
  assetAmountB: AssetAmount;
}) => {
  const price = SundaeUtils.isAdaAsset(assetAmountA?.metadata)
    ? AssetAmount.fromValue(
        assetAmountA.value.div(assetAmountB.value),
        assetAmountA.decimals,
      )
    : AssetAmount.fromValue(
        assetAmountB.value.div(assetAmountA.value),
        assetAmountA.decimals,
      );
  return price;
};

/**
 * Generates a name for a pool consisting of two assets.
 *
 * The function expects an object with properties `assetA` and `assetB`.
 * - `assetA` and `assetB` are instances of `IAssetMetaData` representing the metadata of each asset.
 *
 * The pool name is generated by concatenating the names of `assetA` and `assetB`, separated by a hyphen.
 * The name of each asset is retrieved using the `getAssetName` function.
 *
 * @param {Object} params - An object containing `assetA` and `assetB`.
 * @param {IAssetMetaData} params.assetA - The metadata of the first asset.
 * @param {IAssetMetaData} params.assetB - The metadata of the second asset.
 *
 * @returns {string} The generated pool name.
 */
export const getPoolName = ({
  assetA,
  assetB,
}: {
  assetA: IAssetMetaData;
  assetB: IAssetMetaData;
}) => {
  const assetAQAsset = getQAsset(assetA?.assetId ?? "");
  const assetBQAsset = getQAsset(assetB?.assetId ?? "");
  const assetAName = getAssetName(assetAQAsset ?? assetA);
  const assetBName = getAssetName(assetBQAsset ?? assetB);
  return `${assetAName}-${assetBName}`;
};

/**
 * Generates a pool pair ID from an array of asset IDs.
 *
 * The function expects an array of strings representing asset IDs.
 *
 * The pool pair ID is generated by first sorting the asset IDs lexicographically, and then
 * joining them into a single string separated by an underscore.
 *
 * @param {string[]} assetIds - An array of asset IDs.
 *
 * @returns {string} The generated pool pair ID.
 */
export const getPoolPairId = (assetIds: string[]) => assetIds.sort().join("_");

/**
 * Retrieves the unique start and end asset IDs from a list of pool asset connections.
 * If there is only one pool in the route, it returns the asset IDs of that pool.
 * Otherwise, it identifies and returns the asset IDs that appear only once across all pools,
 * typically representing the endpoints in a sequence of connected assets.
 *
 * @param {TPool[]} orderRoute - An array of pool objects, each containing two assets.
 * @returns {string[]} An array containing the start and end asset IDs. For a single pool, it returns the IDs of both assets in the pool.
 */
export const getPoolPairAssetIds = (orderRoute: TPool[]) => {
  if (orderRoute.length === 1) {
    const pool = orderRoute[0];
    return [pool.assetA.assetId, pool.assetB.assetId];
  }

  const assetMap = new Map();

  // Count the occurrence of each asset
  orderRoute.forEach(({ assetA, assetB }) => {
    assetMap.set(assetA.assetId, (assetMap.get(assetA.assetId) || 0) + 1);
    assetMap.set(assetB.assetId, (assetMap.get(assetB.assetId) || 0) + 1);
  });

  const pairAssetIds: string[] = [];

  // The pairAssetIds are the assets that appear only once
  assetMap.forEach((count, assetId) => {
    if (count === 1) {
      pairAssetIds.push(assetId);
    }
  });

  return pairAssetIds;
};

/**
 * Calculates and returns the ratio of two assets in a pool.
 *
 * The function expects a `TPool` object representing a pool, which should include
 * `quantityA`, `quantityB`, `assetA`, and `assetB` properties.
 *
 * The ratio is calculated as the quantity of `assetA` divided by the quantity of `assetB` if the
 * `assetId` of `assetA` is equivalent to 'Ada', otherwise it's calculated as the quantity of
 * `assetB` divided by the quantity of `assetA`.
 *
 * @param {TPool} pool - The pool to calculate the ratio for.
 *
 * @returns {number} The ratio of the two assets in the pool.
 */
export const calculatePoolRatio = ({
  assetA,
  assetB,
  quantityA,
  quantityB,
}: Pick<TPool, "assetA" | "assetB" | "quantityA" | "quantityB">) => {
  const assetAmountA = new AssetAmount(quantityA ?? 0n, assetA.decimals);
  const assetAmountB = new AssetAmount(quantityB ?? 0n, assetB.decimals);
  return SundaeUtils.isAdaAsset(assetA)
    ? assetAmountA.value.divide(assetAmountB.value)
    : assetAmountB.value.divide(assetAmountA.value);
};

export const calculatePercentageOfEarnedFees = (
  feesEarned: TIntegerLike | null,
  percentage: number | null,
) => {
  if (!feesEarned || percentage === 0) return 0n;
  if (!percentage) return feesEarned;
  if (percentage === 1) return feesEarned;
  return stringToBigint(Fraction.asFraction(feesEarned).multiply(percentage));
};

/**
 * This function takes the active farms and returns the accumulated rewards in ADA.
 * @param {FreezerItem[]} farms - Active farms of the wallet.
 * @param {number} adaInUsd - Price of ADA in USD.
 * @returns {AssetAmount<IAssetMetaData>} - Accumulated rewards in ADA.
 */
export const getEarnedFarmRewardsInADA = (
  farms?: FreezerItem[],
  adaInUsd?: number,
) => {
  if (!farms?.length || !adaInUsd)
    return new AssetAmount<IAssetMetaData>(0n, ADA_METADATA);

  return farms.reduce(
    (valueInADA, farm) => {
      const { rewards } = farm;

      const rewardsInAda = rewards.reduce(
        (farmRewards, { asset, quantity }) => {
          if (asset) {
            const { priceToday } = asset;

            if (SundaeUtils.isAdaAsset(asset)) {
              const adaRewards = new AssetAmount<IAssetMetaData>(
                Number(quantity),
                ADA_METADATA,
              );
              return farmRewards.add(adaRewards);
            }

            const assetPriceInADA = calculateAssetPriceInADA(
              Number(priceToday),
              adaInUsd,
              asset.decimals,
            );

            if (!assetPriceInADA) return farmRewards;

            const assetPriceInAda = AssetAmount.fromValue<IAssetMetaData>(
              assetPriceInADA,
              ADA_METADATA,
            );
            const actualQuantity = new AssetAmount<IAssetMetaData>(
              quantity,
              asset,
            );
            const rewardsInAda = AssetAmount.fromValue<IAssetMetaData>(
              actualQuantity.value.multiply(assetPriceInAda.value).toNumber(),
              ADA_METADATA.decimals,
            );

            return rewardsInAda?.add(farmRewards);
          }
          return farmRewards;
        },
        new AssetAmount<IAssetMetaData>(0n, ADA_METADATA),
      );

      return valueInADA.add(rewardsInAda);
    },
    new AssetAmount<IAssetMetaData>(0n, ADA_METADATA),
  );
};

/**
 * Determines if a pool represents an exotic pair.
 *
 * An exotic pair is defined as a pool where neither of the assets is ADA. This function checks the
 * given pool's assets and returns `true` if both assets in the pool are not ADA, indicating it is an
 * exotic pair. Otherwise, it returns `false`.
 *
 * @param {TPool} pool - The pool object containing the assets to be checked.
 * @param {IAssetMetaData} pool.assetA - Metadata for the first asset in the pool.
 * @param {IAssetMetaData} pool.assetB - Metadata for the second asset in the pool.
 *
 * @returns {boolean} `true` if the pool is an exotic pair (neither asset is ADA), otherwise `false`.
 */
export const getIsExoticPair = ({ assetA, assetB }: Partial<TPool>) =>
  !SundaeUtils.isAdaAsset(assetA) && !SundaeUtils.isAdaAsset(assetB);

/**
 * Calculates the TVL of a liquidity pool in ADA.
 *
 * @param {TPool} pool - The liquidity pool containing information about asset quantities and prices.
 * @param {Fraction} adaInUsd - The price of ADA.
 * @returns {AssetAmount} The calculated TVL of the liquidity pool in ADA.
 */
export const calculateTVLOfPoolInADA = (
  pool?: TPool | null,
  adaInUsd?: number,
) => {
  if (!pool || !adaInUsd) return new AssetAmount(0n, ADA_METADATA.decimals);

  const isExoticPair = getIsExoticPair(pool);

  if (isExoticPair) {
    const assetAPriceInADA = calculateAssetPriceInADA(
      Number(pool.assetA.priceToday),
      adaInUsd,
      ADA_METADATA.decimals,
    );
    const assetBPriceInADA = calculateAssetPriceInADA(
      Number(pool.assetB.priceToday),
      adaInUsd,
      ADA_METADATA.decimals,
    );
    const assetAValueInADA = assetAPriceInADA
      ? Number(pool.quantityA) * assetAPriceInADA
      : 0;
    const assetBValueInADA = assetBPriceInADA
      ? Number(pool.quantityB) * assetBPriceInADA
      : 0;

    const tvlInADA =
      stringToBigint(assetAValueInADA) + stringToBigint(assetBValueInADA);

    return new AssetAmount(tvlInADA, ADA_METADATA.decimals);
  }

  const tvlInADA =
    (SundaeUtils.isAdaAsset(pool.assetA)
      ? stringToBigint(pool.quantityA || "0")
      : stringToBigint(pool.quantityB || "0")) * 2n;

  return new AssetAmount(tvlInADA, ADA_METADATA.decimals);
};

/**
 * Calculates the applicable fee for a swap transaction based on the given asset ID and the pool data.
 *
 * The function determines whether the given asset ID matches the asset ID of `assetA` in the pool.
 * If it matches, the bid fee is applicable; otherwise, the ask fee is applicable.
 *
 * @param {Object} params - An object containing the given asset ID and the pool data.
 * @param {string} params.givenAssetId - The asset ID of the asset being given in the swap.
 * @param {TPool} params.pool - The pool data containing information about the assets and their associated fees.
 * @returns {Fraction} - The applicable fee as a Fraction instance. Returns the bid fee if the given asset ID matches `assetA`, otherwise returns the ask fee.
 */
export const getApplicableFee = ({
  givenAssetId,
  pool,
}: {
  givenAssetId?: string;
  pool: TPool;
}) => {
  const askFee = new Fraction(...pool.askFee);
  const bidFee = new Fraction(...pool.bidFee);

  if (pool.assetA.assetId === givenAssetId) {
    return bidFee;
  }

  return askFee;
};

/**
 * Calculates the 24-hour volume of a liquidity pool in ADA.
 *
 * @param {TPool} pool - The liquidity pool containing information about asset quantities and fees.
 * @returns {AssetAmount} - The calculated 24-hour volume of the liquidity pool in ADA.
 *                          If pool data is not provided, returns 0 ADA.
 */
export const calculateVolume24hOfPoolInADA = (pool?: TPool) => {
  if (!pool || !pool?.fees24H || Number(pool?.fees24H) === 0)
    return new AssetAmount(0n, ADA_METADATA.decimals);

  return new AssetAmount(
    stringToBigint(
      Number(pool.fees24H) / new Fraction(...pool.bidFee).toNumber(),
    ),
    ADA_METADATA.decimals,
  );
};

/**
 * Calculates the relative price change of a pool's asset between today and yesterday.
 *
 * @param {string} [priceToday] - The price of the asset today, as a string (optional).
 * @param {string} [priceYesterday] - The price of the asset yesterday, as a string (optional).
 * @returns {number|null} The relative price change as a decimal (e.g., 0.05 for a 5% increase),
 *                        null if there's no price today but there was yesterday,
 *                        null if there's a price today but there wasn't yesterday,
 *                        null if there are no prices for both today and yesterday,
 *                        or null if either price is invalid.
 */
export const getPoolDeltaPriceChange = (
  priceToday?: string | null,
  priceYesterday?: string | null,
) => {
  if (!priceToday || !priceYesterday) return null;

  return (
    (parseScientific(priceToday).num - parseScientific(priceYesterday).num) /
    parseScientific(priceYesterday).num
  );
};

/**
 * Gets the reserve amount of a specific asset in a given liquidity pool.
 *
 * @param {AssetAmount<IAssetMetaData>} asset - The asset for which to get the reserve.
 * @param {TPool} pool - The liquidity pool from which to get the reserve.
 * @returns {bigint} The reserve amount of the asset in the pool. If the asset matches pool's assetA, it returns the quantity of assetA; otherwise, it returns the quantity of assetB.
 *
 * @example
 *
 * const assetReserve = getAssetReserve(assetAmount, liquidityPool);
 */
export const getAssetReserve = (
  asset: AssetAmount<IAssetMetaData>,
  pool: TPool,
): bigint => {
  return isSameAsset(asset?.metadata, pool.assetA)
    ? pool?.quantityA
      ? BigInt(pool.quantityA)
      : 0n
    : pool?.quantityB
      ? BigInt(pool.quantityB)
      : 0n;
};

/**
 * Returns a map of LP asset IDs to pools. Each entry in the map links an LP asset ID
 * to its corresponding pool. This function helps map assetIDs to their corresponding
 * pool data from a given pools object. It can be used when we have asset IDs that we want
 * to map to the corresponding pool data.
 *
 * @param {Object} pools - An object where keys are asset IDs and values are arrays of pools.
 * @param {string[]} assetIds - An array of asset IDs that we want to map to their corresponding pools.
 *
 * @returns {Map<string, TPool>} - A map where keys are asset IDs and values are pools.
 */
export const getPoolMapByLPAssetId = (
  pools: TPool[],
  assetIds: string[],
): TAssetIdPoolMap => {
  // Initialize an empty Map to hold the assetId to pool mapping
  const poolMap: TAssetIdPoolMap = new Map();

  pools.forEach((pool) => {
    if (assetIds.includes(pool.assetLP.assetId)) {
      poolMap.set(pool.assetLP.assetId, pool);
    }
  });

  // Return the map of asset IDs to pools
  return poolMap;
};

/**
 * Returns a map of asset IDs to pools. Each entry in the map links an asset ID
 * to its corresponding pool. This function helps map assetIDs to their corresponding
 * pool data from a given pools object. It can be used when we have asset IDs that we want
 * to map to the corresponding pool data.
 *
 * @param {Object} pools - An object where keys are asset IDs and values are arrays of pools.
 * @param {string[]} assetIds - An array of asset IDs that we want to map to their corresponding pools.
 *
 * @returns {Map<string, TPool>} - A map where keys are asset IDs and values are pools.
 */
export const getPoolMapByPoolIdent = (
  pools: TPool[],
  poolIdents: string[],
): TAssetIdPoolMap | undefined => {
  // If pools or assetIds is not provided, return undefined
  if (!pools || !poolIdents) return undefined;

  // Initialize an empty Map to hold the assetId to pool mapping
  const poolMap: TAssetIdPoolMap = new Map();

  pools.forEach((pool) => {
    if (poolIdents.includes(pool.ident)) {
      poolMap.set(pool.ident, pool);
    }
  });

  // Return the map of asset IDs to pools
  return poolMap;
};

/**
 * Calculates the total value of a liquidity pool token (LP) asset in both ADA and USD.
 *
 * The function calculates the share of the LP asset that the user owns in relation to the total quantity of LP assets in the pool.
 * Based on this share, it calculates the amount of both assets (assetA and assetB) in the pool that the user owns.
 * The function then calculates the value of these amounts in ADA, taking into account whether the assets are ADA or another asset.
 * The function also calculates the total value of the owned amounts of assetA and assetB in USD.
 *
 * The function requires the LP asset, the pool data, and the current price of ADA in USD as inputs.
 *
 * @param {AssetAmount<IAssetMetaData & IAsset>} lpAsset - The LP asset that the user owns, including its quantity and metadata.
 * @param {TPool} pool - The data of the pool that contains the LP asset, including the quantities and prices of assetA and assetB.
 * @param {number} adaInUsd - The current price of ADA in USD.
 *
 * @returns {Object} An object that includes the total value of the LP asset in both ADA and USD.
 * The returned object has two properties: `totalAmountInADA` and `totalAmountInUSD`.
 * `totalAmountInADA` is an AssetAmount object that represents the total value of the LP asset in ADA.
 * `totalAmountInUSD` is a number that represents the total value of the LP asset in USD.
 */
export const calculateTotalLPAssetValues = (
  lpAsset: AssetAmount<IAssetMetaData>,
  pool: TPool,
  adaInUsd: number,
) => {
  const { assetA, assetB } = pool;
  const share = getUnlockedTokensPoolShare(
    lpAsset.value.toNumber(),
    Number(pool.quantityLP),
  );
  const assetAmountA = new AssetAmount(
    Math.round(Number(pool.quantityA) * share),
    pool.assetA.decimals,
  );
  const assetAmountB = new AssetAmount(
    Math.round(Number(pool.quantityB) * share),
    pool.assetB.decimals,
  );

  if (
    !share ||
    (!assetAmountA.value.toNumber() && !assetAmountB.value.toNumber())
  ) {
    return {
      totalAmountInADA: new AssetAmount<IAssetMetaData>(0n, ADA_METADATA),
      totalAmountInUSD: 0,
    };
  }

  const isAssetAAda = SundaeUtils.isAdaAsset(assetA);
  const aPricedInAda = isAssetAAda
    ? adaInUsd
    : calculateAssetPriceInADA(
        Number(assetA.priceToday ?? "0"),
        adaInUsd,
        ADA_METADATA.decimals,
      ) ?? 0;
  const bPricedInAda =
    calculateAssetPriceInADA(
      Number(assetB.priceToday ?? "0"),
      adaInUsd,
      ADA_METADATA.decimals,
    ) ?? 0;

  const adaAmountA = isAssetAAda
    ? assetAmountA
    : AssetAmount.fromValue(
        assetAmountA.value.multiply(aPricedInAda).toNumber(),
        ADA_METADATA,
      );
  const adaAmountB = AssetAmount.fromValue(
    assetAmountB.value.multiply(bPricedInAda).toNumber(),
    ADA_METADATA,
  );

  const aPricedInUSD = adaAmountA.value.toNumber() * adaInUsd;
  const bPricedInUSD = adaAmountB.value.toNumber() * adaInUsd;

  return {
    totalAmountInADA: adaAmountA.add(adaAmountB),
    totalAmountInUSD: aPricedInUSD + bPricedInUSD,
  };
};

/**
 * Calculates the total value of a given asset in both ADA and USD.
 *
 * The function checks if the given asset is a liquidity pool (LP) asset. If it is, the function calls
 * `calculateTotalLPAssetValues` to calculate the total value of the LP asset in both ADA and USD.
 *
 * If the given asset is not an LP asset, the function calculates the total value of the asset in ADA,
 * taking into account whether the asset is ADA or another asset. The function then calculates the total
 * value of the asset in USD.
 *
 * The function requires the asset, the pool data (if the asset is an LP asset), and the current price
 * of ADA in USD as inputs.
 *
 * @param {AssetAmount<IAssetMetaData>} asset - The asset that the user owns, including its quantity and metadata.
 * @param {TPool} pool - The data of the pool that contains the LP asset (if the asset is an LP asset), including the quantities and prices of assetA and assetB.
 * @param {number} [adaInUsd] - The current price of ADA in USD.
 *
 * @returns {Object} An object that includes the total value of the asset in both ADA and USD.
 * The returned object has two properties: `totalAmountInADA` and `totalAmountInUSD`.
 * `totalAmountInADA` is an AssetAmount object (or the asset quantity if the asset is ADA) that represents the total value of the asset in ADA.
 * `totalAmountInUSD` is a number that represents the total value of the asset in USD.
 *
 */
export const calculateAssetValueInADAAndUSD = (
  isLPAsset: ReturnType<typeof useIsLPAsset>["isLPAsset"],
  asset?: AssetAmount<IAssetMetaData>,
  pool?: TPool,
  adaInUsd?: number,
) => {
  if (!asset || !adaInUsd || !pool)
    return { totalAmountInADA: undefined, totalAmountInUSD: undefined };
  if (isLPAsset(asset.metadata.policyId) && pool) {
    return calculateTotalLPAssetValues(asset, pool, adaInUsd);
  }

  const totalAmountInADA = SundaeUtils.isAdaAsset(asset.metadata)
    ? (asset as AssetAmount<IAssetMetaData>)
    : new AssetAmount<IAssetMetaData>(
        stringToBigint(
          asset.value
            .mul(
              calculateAssetPriceInADA(
                Number(asset.metadata.priceToday),
                adaInUsd,
                ADA_METADATA.decimals,
              ) || 0,
            )
            .toString(),
          ADA_METADATA.decimals,
        ),
        ADA_METADATA,
      );

  return {
    totalAmountInADA,
    totalAmountInUSD: calculateAssetValueInUSD(asset, adaInUsd),
  };
};

/**
 * Removes repeated pools from an array of pool objects.
 *
 * @param {TPool[]} pools - The array of pool objects to process.
 * @returns {TPool[]} - An array containing unique pool objects.
 */
export const getUniqueListOfPools = (pools: TPool[]) => {
  const uniqueValues = new Set();
  const result = [];

  for (const item of pools) {
    if (!uniqueValues.has(item.ident)) {
      uniqueValues.add(item.ident);
      result.push(item);
    }
  }

  return result;
};

/**
 * Filters pools that have no quantities for assetA, assetB, or assetLP.
 *
 * @param {TPool} pool - The pool object to process.
 * @returns {boolean} - `true` if the pool has non-zero quantities for assetA, assetB, and assetLP; otherwise, `false`.
 */
export const filterZeroQuantityPools = (pool: TPool) =>
  !!Number(pool.quantityA) &&
  !!Number(pool.quantityB) &&
  !!Number(pool.quantityLP);

/**
 * Selects the best pool based on the given swap outcome.
 *
 * This function takes an array of pools, an optional 'given' asset amount, and an optional 'taken' asset amount.
 * It first converts the pools to a conforming structure expected by the utility function `SundaeUtils.getBestPoolBySwapOutcome`.
 * Then, it determines the best pool based on the swap outcome. If a best pool is found, it returns that pool.
 * Otherwise, it defaults to the first pool in the array.
 *
 * @param {TPool[]} pools - An array of pool objects to evaluate.
 * @param {AssetAmount<IAssetMetaData>} [given] - The asset amount being given in the swap (optional).
 * @param {AssetAmount<IAssetMetaData>} [taken] - The asset amount being taken in the swap (optional).
 * @returns {TPool} - The selected best pool or the first pool if no best pool is determined.
 */
export const selectBestPoolByOutcome = (
  pools: TPool[],
  given?: AssetAmount<IAssetMetaData>,
  taken?: AssetAmount<IAssetMetaData>,
) => {
  const conformedPools: IPoolData[] = pools
    .filter(filterZeroQuantityPools)
    .map((pool) => ({
      assetA: pool.assetA,
      assetB: pool.assetB,
      assetLP: pool.assetLP,
      currentFee: getApplicableFee({
        pool,
        givenAssetId: given?.metadata.assetId,
      }).toNumber(),
      ident: pool.ident,
      liquidity: {
        aReserve: BigInt(pool.quantityA ?? "0"),
        bReserve: BigInt(pool.quantityB ?? "0"),
        lpTotal: BigInt(pool.quantityLP ?? "0"),
      },
      version: pool.version,
    }));

  const bestPool = SundaeUtils.getBestPoolBySwapOutcome({
    availablePools: conformedPools,
    given,
    taken,
  });

  const chosenPool =
    pools.find(({ ident }) => ident === bestPool?.ident) ?? pools[0];

  return chosenPool;
};

/**
 * Calculates the outcome of a swap transaction in a given pool.
 * @param {bigint} amount - The amount of the input asset.
 * @param {IAssetMetaData} inputAsset - Metadata of the input asset.
 * @param {IAssetMetaData} outputAsset - Metadata of the output asset.
 * @param {TPool} pool - The pool fragment where the swap occurs.
 * @param {TFractionLike} feePercentage - The fee percentage for the swap.
 * @returns {Object} The output amount after the swap.
 */
export const calculateSwapOutcome = (
  amount: bigint,
  inputAsset: IAssetMetaData,
  outputAsset: IAssetMetaData,
  pool: TPool,
  feePercentage: TFractionLike,
) => {
  const inputReserve = getAssetReserve(new AssetAmount(1, inputAsset), pool);
  const outputReserve = getAssetReserve(new AssetAmount(1, outputAsset), pool);
  return getSwapOutput(amount, inputReserve, outputReserve, feePercentage);
};

export const getOrderRouteOutput = ({
  givenAssetId,
  inputAmount,
  orderRoute,
  takenAssetId,
}: {
  givenAssetId: string;
  inputAmount?: bigint;
  orderRoute: TPool[];
  takenAssetId: string;
}) => {
  const firstPool = orderRoute[0];
  const { firstInputAsset, firstOutputAsset } =
    givenAssetId === firstPool.assetA.assetId
      ? {
          firstInputAsset: firstPool.assetA,
          firstOutputAsset: firstPool.assetB,
        }
      : {
          firstInputAsset: firstPool.assetB,
          firstOutputAsset: firstPool.assetA,
        };

  const input = inputAmount || BigInt(10 ** firstInputAsset.decimals);

  const firstInputReserve = getAssetReserve(
    new AssetAmount(1, firstInputAsset),
    firstPool,
  );
  const firstOutputReserve = getAssetReserve(
    new AssetAmount(1, firstOutputAsset),
    firstPool,
  );

  const firstSwapOutcome = getSwapOutput(
    input,
    firstInputReserve,
    firstOutputReserve,
    getApplicableFee({ pool: firstPool, givenAssetId }).toNumber(),
  );

  if (firstSwapOutcome.output === 0n) return 0n;

  const firstSwapOutput = firstSwapOutcome.output;

  const secondPool = orderRoute[1];

  if (!secondPool) return firstSwapOutput;

  const { secondInputAsset, secondOutputAsset } =
    takenAssetId === secondPool.assetA.assetId
      ? {
          secondOutputAsset: secondPool.assetA,
          secondInputAsset: secondPool.assetB,
        }
      : {
          secondOutputAsset: secondPool.assetB,
          secondInputAsset: secondPool.assetA,
        };

  const secondInputReserve = getAssetReserve(
    new AssetAmount(1, secondInputAsset),
    secondPool,
  );
  const secondOutputReserve = getAssetReserve(
    new AssetAmount(1, secondOutputAsset),
    secondPool,
  );

  const secondSwapOutcome = getSwapOutput(
    firstSwapOutput,
    secondInputReserve,
    secondOutputReserve,
    getApplicableFee({
      pool: secondPool,
      givenAssetId: secondInputAsset.assetId,
    }).toNumber(),
  );

  return secondSwapOutcome.output;
};

/**
 * Determines the best swap candidate from a list of pools based on given and taken asset IDs.
 * @param {TPool[][]} matchingPairs - The array of all matching pairs of pools.
 * @param {string} given - The asset ID of the given asset.
 * @param {string} taken - The asset ID of the taken asset.
 * @param {bigint} [inputAmount] - The amount of the given asset to swap, if not provided a default value is calculated based on the asset's decimals.
 * @returns {Object} The best swap candidate and the maximum output from that swap. The result includes the output amount and the candidate pair.
 */
export const getBestCandidate = (
  matchingPairs: TPool[][],
  given: string,
  taken: string,
  inputAmount?: bigint,
) => {
  const bestCandidate = matchingPairs.reduce(
    (
      best: {
        givenFromTakenOutput: bigint;
        takenFromGivenOutput: bigint;
        orderRoute: TPool[] | undefined;
      },
      orderRoute,
    ) => {
      const takenFromGivenOutput = getOrderRouteOutput({
        givenAssetId: given,
        inputAmount,
        orderRoute,
        takenAssetId: taken,
      });

      const givenFromTakenOutput = getOrderRouteOutput({
        givenAssetId: taken,
        inputAmount: takenFromGivenOutput,
        orderRoute:
          orderRoute.length === 1 ? orderRoute : [orderRoute[1], orderRoute[0]],
        takenAssetId: given,
      });

      if (
        givenFromTakenOutput >= best.givenFromTakenOutput &&
        takenFromGivenOutput >= best.takenFromGivenOutput
      ) {
        return {
          givenFromTakenOutput,
          takenFromGivenOutput,
          orderRoute,
        };
      }

      return best;
    },
    {
      givenFromTakenOutput: 0n,
      takenFromGivenOutput: 0n,
      orderRoute: undefined,
    },
  );
  return bestCandidate.orderRoute;
};

export const calculateRouteRatio = ({
  given,
  inputAmount,
  orderRoute,
  taken,
}: {
  given?: string;
  inputAmount?: bigint;
  orderRoute?: TPool[];
  taken?: string;
}) => {
  if (!orderRoute || !given || !taken) return;

  if (orderRoute.length === 1) {
    const pool = orderRoute[0];
    return typeof pool?.quantityA === "string" &&
      typeof pool?.quantityB === "string"
      ? new AssetRatio<IAssetMetaData>(
          new AssetAmount<IAssetMetaData>(pool.quantityA, pool.assetA),
          new AssetAmount<IAssetMetaData>(pool.quantityB, pool.assetB),
          pool,
        )
      : undefined;
  }

  const givenPool = orderRoute.find(
    (pool) => pool.assetA.assetId === given || pool.assetB.assetId === given,
  );
  const takenPool = orderRoute.find(
    (pool) => pool.assetA.assetId === taken || pool.assetB.assetId === taken,
  );

  if (!givenPool || !takenPool) {
    throw new Error("Assets not found in the provided liquidity pools.");
  }

  const givenAsset =
    given === givenPool.assetA.assetId ? givenPool.assetA : givenPool.assetB;
  const takenAsset =
    taken === takenPool.assetA.assetId ? takenPool.assetA : takenPool.assetB;

  const input = inputAmount || BigInt(10 ** givenAsset.decimals);

  const output = getOrderRouteOutput({
    givenAssetId: given,
    inputAmount,
    orderRoute,
    takenAssetId: taken,
  });

  const ratio = new AssetRatio<IAssetMetaData>(
    new AssetAmount<IAssetMetaData>(output, takenAsset),
    new AssetAmount<IAssetMetaData>(input, givenAsset),
  );

  return ratio;
};

/**
 * Retrieves and formats the fees associated with a specific liquidity pool.
 * This function checks both the ask fee and the bid fee in the pool, and returns
 * them formatted as a percentage string based on the current locale setting.
 *
 * If both fees are equal, it returns a single percentage string. If they differ,
 * it returns a string that separates the bid and ask fees with a slash.
 *
 * @param {TPool} pool - The pool object containing the fee information.
 * @param {string} [currentLocale] - Optional locale string to format the percentage according to local preferences.
 * @returns {string} The formatted fees as a percentage string. If fees are different for bid and ask,
 *                   returns them in the format "bid% / ask%".
 */
export const getPoolFees = (pool: TPool, currentLocale?: string) => {
  const askFee = new Fraction(...pool.askFee);
  const bidFee = new Fraction(...pool.bidFee);

  if (askFee.equals(bidFee)) {
    return percentageI18n(askFee.toNumber(), currentLocale);
  }

  return `${percentageI18n(bidFee.toNumber(), currentLocale)} / ${percentageI18n(
    askFee.toNumber(),
    currentLocale,
  )}`;
};
