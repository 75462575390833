import { useI18N } from "@sundaeswap/react-hooks";
import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import {
  EGuideChapter,
  IChapter,
} from "../../../../stores/ControlCenterContext";
import { useYieldFarmingGuideJoyride } from "../guides/useYieldFarmingGuideJoyride";

export const useYieldFarmingJoyrideChapter = (): IChapter => {
  const { t } = useI18N("joyride");
  const yieldFarmingJoyride = useYieldFarmingGuideJoyride();

  return useMemo(() => {
    return {
      id: EGuideChapter.YIELD_FARMING,
      title: t("yieldFarming.title"),
      description: t("yieldFarming.description"),
      guides: sortBy([yieldFarmingJoyride], "rank", "asc"),
    };
  }, [t, yieldFarmingJoyride]);
};
