import { PrismicDocument } from "@prismicio/client";
import { useI18N } from "@sundaeswap/react-hooks";
import {
  DateI18N,
  ExternalLink,
  OpenInNewWindowIcon,
  Text,
  toolkitCx,
} from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, useMemo } from "react";

import {
  INewsField,
  NewsActions,
  useNewsContext,
} from "../../stores/NewsContext";
import { prismicLinkResolver } from "../../utils/prismic.utils";

const NewsItem: FC<PrismicDocument<INewsField>> = ({
  data,
  id,
  first_publication_date,
}) => {
  const { t } = useI18N("news");
  const {
    backlink,
    desc,
    popover_backlink,
    read_more_label,
    title,
    type = "inside_popover",
  } = data;
  const {
    dispatch,
    state: { newsRead, popoverNewsItems, setActiveNewsItem },
  } = useNewsContext();

  const isNewsRead = useMemo(() => newsRead.includes(id), [newsRead, id]);
  const link = backlink ?? popover_backlink;

  return (
    <div className="relative">
      {/* Render a dot if the news is not read to indicate urgency. */}
      <div
        className={cx(
          "absolute left-3 top-1/2 h-2 w-2 -translate-y-1/2 rounded-full bg-primary transition-opacity duration-500",
          { "opacity-100": !isNewsRead, "opacity-0": isNewsRead },
        )}
      />

      <div
        key={id}
        onClick={() => {
          if (type === "dialog") {
            const activeNewsItem = popoverNewsItems?.find(
              (newsItem) => newsItem.id === id,
            );
            setActiveNewsItem(activeNewsItem);
            NewsActions.setReadNewsItem(id, dispatch);
          }
        }}
        className={cx(
          "grid gap-1 border-t border-t-gray-300 motion-safe:animate-fadeIn dark:border-t-gray-600",
          toolkitCx.layout.spacing.right.md,
          toolkitCx.layout.spacing.vertical.md,
          toolkitCx.layout.spacing.left.xl,
          toolkitCx.layout.transition.colors,
          toolkitCx.layout.background.hoverable,
          {
            // [`${toolkitCx.layout.background.default}`]: isNewsRead, // read
            [`${toolkitCx.layout.background.loading}`]: !isNewsRead, // unread
            "cursor-pointer": type === "dialog",
          },
        )}
      >
        <Text className="flex justify-between" tag="p" size="sm">
          <Text className="w-4/5" tag="span" size="sm" weight="medium">
            {title}
          </Text>

          <DateI18N
            className="mt-[3px]"
            size="xs"
            value={new Date(first_publication_date)}
            variant="silent"
          />
        </Text>

        <Text tag="p" size="xs">
          {desc}
        </Text>

        <div
          className={cx("flex items-center gap-4", {
            "mt-2": type === "dialog" || link?.link_type !== "Any",
          })}
        >
          {type === "inside_popover" && link?.link_type !== "Any" && (
            <ExternalLink
              arrow="top-right"
              href={link && prismicLinkResolver(link)}
              hoverable
              onClick={() => NewsActions.setReadNewsItem(id, dispatch)}
              size="xs"
              variant="primary"
            >
              {read_more_label ?? t("link", { context: "inside_popover" })}
            </ExternalLink>
          )}

          {type === "dialog" && (
            <Text
              className="inline-flex cursor-pointer gap-[2px]"
              hoverable
              size="xs"
              tag="p"
              variant="primary"
            >
              {t("link", { context: "dialog" })} <OpenInNewWindowIcon />
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsItem;
