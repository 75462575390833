import { Text, toolkitCx, Tooltip } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { motion, MotionProps } from "framer-motion";
import { cloneElement, FC, ReactElement } from "react";
import { controlCenterToolbarElementMotion } from "../../../motions/control-center.motions";

interface IControlCenterToolbarElementProps extends MotionProps {
  animationKey?: string;
  className?: string;
  icon: ReactElement;
  isActive?: boolean;
  notificationCount?: number;
  onClick?: () => void;
  tooltipLabel?: string;
}

const ControlCenterToolbarElement: FC<IControlCenterToolbarElementProps> = ({
  animationKey,
  className,
  onClick,
  icon,
  isActive,
  notificationCount,
  tooltipLabel,
  ...rest
}) => {
  return (
    <motion.div
      {...rest}
      key={animationKey}
      className={cx(
        "absolute bottom-2",
        "cursor-pointer select-none rounded-full",
        "h-10 w-10",
        toolkitCx.layout.background.default,
        toolkitCx.layout.text.default,
        toolkitCx.layout.shadow.default,
        className,
      )}
      onClick={onClick}
      variants={controlCenterToolbarElementMotion}
    >
      <Tooltip
        trigger={
          <div
            className={cx(
              "relative h-full w-full rounded-full",
              "flex items-center justify-center",
              toolkitCx.layout.transition.colors,
              {
                "bg-primary !text-white motion-safe:animate-shadow-pulse":
                  isActive,
                "bg-primary/20 hover:border-primary hover:bg-primary/40":
                  !isActive,
              },
            )}
          >
            {cloneElement(icon, {
              className: cx(icon.props.className, "h-5 w-5"),
            })}

            {!!notificationCount && (
              <div
                className={cx(
                  "absolute -right-1 -top-1 motion-safe:animate-scaleIn",
                  "flex items-center justify-center",
                  "h-4 w-4 rounded-full",
                  "bg-error dark:bg-error",
                )}
              >
                <Text size="2xs" tag="span" variant="forceLight">
                  {notificationCount >= 9 ? "9+" : notificationCount}
                </Text>
              </div>
            )}
          </div>
        }
        tooltipLabel={tooltipLabel || ""}
      />
    </motion.div>
  );
};

export default ControlCenterToolbarElement;
