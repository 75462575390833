import useAppLocation from "../../hooks/router/useAppLocation";
import useAppNavigate from "../../hooks/router/useAppNavigate";
import { TSearchParams } from "../../pages/routes";
import { isOnJoyrideEntryUrl } from "../../utils/joyride.utils";
import {
  LocalControlCenterActions,
  PersistentControlCenterActions,
} from "./actions";
import {
  IActionHandlers,
  IChapter,
  IGuide,
  ILocalControlCenterState,
  TLocalDispatch,
  TPersistentDispatch,
} from "./types";

interface ICreateActionHandlerParams {
  localControlCenterState: ILocalControlCenterState;
  localDispatch: TLocalDispatch;
  location: ReturnType<typeof useAppLocation>;
  navigate: ReturnType<typeof useAppNavigate>;
  persistentDispatch: TPersistentDispatch;
  search: TSearchParams;
}

export const createActionHandler = (
  params: ICreateActionHandlerParams,
): IActionHandlers => {
  const {
    localControlCenterState,
    localDispatch,
    location,
    navigate,
    persistentDispatch,
    search,
  } = params;

  return {
    controlCenter: {
      close: () => {
        LocalControlCenterActions.setWithAnimationDelay(true, localDispatch);
        LocalControlCenterActions.showToolbar(false, localDispatch);
        LocalControlCenterActions.showHelpCenter(false, localDispatch);
        LocalControlCenterActions.showInbox(false, localDispatch);
        LocalControlCenterActions.showGuides(false, localDispatch);
        LocalControlCenterActions.guides.showSuccessAnimation(
          false,
          localDispatch,
        );
        LocalControlCenterActions.guides.setActiveChapter(
          undefined,
          localDispatch,
        );
      },
      openGuide: () => {
        if (!localControlCenterState.showGuides) {
          LocalControlCenterActions.showHelpCenter(false, localDispatch);
          LocalControlCenterActions.showInbox(false, localDispatch);
          LocalControlCenterActions.setWithAnimationDelay(false, localDispatch);
          LocalControlCenterActions.showGuides(true, localDispatch);
        }
      },
      openHelpCenter: () => {
        if (!localControlCenterState.showHelpCenter) {
          LocalControlCenterActions.showGuides(false, localDispatch);
          LocalControlCenterActions.showInbox(false, localDispatch);
          LocalControlCenterActions.setWithAnimationDelay(false, localDispatch);
          LocalControlCenterActions.showHelpCenter(true, localDispatch);
        }
      },
      openInbox: () => {
        if (!localControlCenterState.showInbox) {
          LocalControlCenterActions.showGuides(false, localDispatch);
          LocalControlCenterActions.showHelpCenter(false, localDispatch);
          LocalControlCenterActions.setWithAnimationDelay(false, localDispatch);
          LocalControlCenterActions.showInbox(true, localDispatch);
        }
      },
      toggleOpen: () => {
        LocalControlCenterActions.showToolbar(
          !localControlCenterState.showToolbar,
          localDispatch,
        );
        LocalControlCenterActions.showHelpCenter(false, localDispatch);
        LocalControlCenterActions.showInbox(false, localDispatch);
        LocalControlCenterActions.guides.setActiveChapter(
          undefined,
          localDispatch,
        );
        LocalControlCenterActions.guides.showSuccessAnimation(
          false,
          localDispatch,
        );

        if (localControlCenterState.showToolbar) {
          // Closing the Avatar will reset the animation delay.
          LocalControlCenterActions.setWithAnimationDelay(true, localDispatch);
        }
      },
    },
    guides: {
      close: (
        guideId: string,
        completed: boolean,
        stepIndex: number,
        chapter?: IChapter,
      ) => {
        const isSingleChapter = chapter?.guides.length === 1;
        LocalControlCenterActions.showToolbar(true, localDispatch);
        LocalControlCenterActions.showGuides(true, localDispatch);
        LocalControlCenterActions.guides.setActiveGuideId(
          undefined,
          localDispatch,
        );
        LocalControlCenterActions.guides.setLastClosedGuideItemId(
          isSingleChapter ? chapter.id : guideId,
          localDispatch,
        );
        LocalControlCenterActions.guides.showGuideCloseUI(false, localDispatch); // Need to set our running state to false, so we can restart if we click start again.
        LocalControlCenterActions.guides.runGuide(false, localDispatch); // Need to set our running state to false, so we can restart if we click start again.
        PersistentControlCenterActions.guides.closeGuide(
          guideId,
          !!completed,
          stepIndex,
          persistentDispatch,
        );

        // For single chapter guides, we need to close the chapter as well and return to the chapter list.
        if (isSingleChapter) {
          LocalControlCenterActions.guides.setActiveChapter(
            undefined,
            localDispatch,
          );
        }
      },
      closeChapter: () => {
        LocalControlCenterActions.guides.setActiveChapter(
          undefined,
          localDispatch,
        );
      },
      continue: () => {
        LocalControlCenterActions.guides.showGuideCloseUI(false, localDispatch);
      },
      exit: (guideId: string, completed: boolean, stepIndex: number) => {
        LocalControlCenterActions.guides.setActiveGuideId(
          undefined,
          localDispatch,
        );
        LocalControlCenterActions.guides.showGuideCloseUI(false, localDispatch); // Need to set our running state to false, so we can restart if we click start again.
        LocalControlCenterActions.guides.runGuide(false, localDispatch); // Need to set our running state to false, so we can restart if we click start again.
        PersistentControlCenterActions.guides.closeGuide(
          guideId,
          !!completed,
          stepIndex,
          persistentDispatch,
        );
      },
      finish: (guideId: string, chapter?: IChapter) => {
        const isSingleChapter = chapter?.guides.length === 1;
        LocalControlCenterActions.showToolbar(true, localDispatch);
        LocalControlCenterActions.showGuides(true, localDispatch);
        LocalControlCenterActions.guides.runGuide(false, localDispatch); // Need to set our running state to false, so we can restart if we click start again.
        LocalControlCenterActions.guides.showSuccessAnimation(
          true,
          localDispatch,
        );
        PersistentControlCenterActions.guides.completeGuide(
          guideId,
          true,
          persistentDispatch,
        );

        // For single chapter guides, we need to close the chapter as well and return to the chapter list.
        if (isSingleChapter) {
          LocalControlCenterActions.guides.setActiveChapter(
            undefined,
            localDispatch,
          );
        }
      },
      goToStep: (guideId: string, nextStepIndex: number) => {
        PersistentControlCenterActions.guides.goToStep(
          guideId,
          nextStepIndex,
          persistentDispatch,
        );
      },
      handleSuccessMessage: () => {
        LocalControlCenterActions.setWithAnimationDelay(false, localDispatch);
        LocalControlCenterActions.guides.showSuccessAnimation(
          false,
          localDispatch,
        );
      },
      openChapter: (chapter: IChapter) => {
        LocalControlCenterActions.guides.setActiveChapter(
          chapter,
          localDispatch,
        );
      },
      start: async (guide: IGuide, stepIndex?: number) => {
        const entryUrl = guide.joyride.steps[stepIndex ?? 0].entryUrl;
        const entrySearchParams =
          guide.joyride.steps[stepIndex ?? 0].entrySearchParams ?? {};
        const isOnEntryUrl = isOnJoyrideEntryUrl(
          entryUrl ?? "",
          entrySearchParams,
          location,
        );

        if (!isOnEntryUrl) {
          LocalControlCenterActions.guides.setActiveGuideId(
            guide.joyride.id,
            localDispatch,
          );
          LocalControlCenterActions.guides.showGuidesLoadingAnimation(
            true,
            localDispatch,
          );
          navigate({
            to: entryUrl,
            search: {
              ...search,
              ...entrySearchParams,
            },
          });
          await new Promise((resolve) => setTimeout(resolve, 50)); // Timeout to allow the URL to update.
        } else {
          LocalControlCenterActions.guides.runGuide(true, localDispatch);
        }

        LocalControlCenterActions.setWithAnimationDelay(true, localDispatch);
        LocalControlCenterActions.showToolbar(false, localDispatch);
        LocalControlCenterActions.showGuides(false, localDispatch);
        PersistentControlCenterActions.guides.startGuide(
          guide.joyride.id,
          persistentDispatch,
        );
      },
    },
  };
};
