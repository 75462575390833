import { useCallback, useEffect } from "react";

import { joyrideIds } from "../../constants/joyride.constants";
import { CONTROL_CENTER_AVATAR_ANIMATION_DELAY } from "../../motions/control-center.motions";
import { EGuideSearchParamEntries } from "../../pages/routes";
import {
  IGuide,
  useControlCenterContext,
} from "../../stores/ControlCenterContext";
import useAppSearch from "../router/useAppSearch";

export const useJoyrideAutorun = () => {
  const {
    actionHandler,
    state: {
      localState: {
        guides: { chapters },
      },
    },
  } = useControlCenterContext();
  const search = useAppSearch();

  const mapSearchParamToJoyrideId = useCallback(() => {
    if (search.guide === EGuideSearchParamEntries.tasteTestExplanationGuide) {
      return joyrideIds.tasteTest.explanationGuide;
    }
    if (search.guide === EGuideSearchParamEntries.tasteTestInteractiveGuide) {
      return joyrideIds.tasteTest.interactiveGuide;
    }
    if (search.guide === EGuideSearchParamEntries.v3) {
      return joyrideIds.v3.comparison;
    }
  }, [search.guide]);

  useEffect(() => {
    const joyrideId = mapSearchParamToJoyrideId();

    if (joyrideId && chapters.length) {
      setTimeout(() => {
        // We need to find the matching chapter and guide for the given joyride id from the search params.
        const matchingChapterByJoyrideId = chapters.find(
          (chapter) =>
            chapter.guides.find((guide) => guide.joyride.id === joyrideId) !==
            undefined,
        );
        const matchingGuideByJoyrideId =
          matchingChapterByJoyrideId?.guides.find(
            (guide) => guide.joyride.id === joyrideId,
          ) ?? ({} as IGuide);

        // If we start the guide via a magic link (automatically enforcing the `guide` search param), we want to start at the first step.
        const entryStepIndex = 0;

        // In order to start the guide, we need to set an active chapter first.
        actionHandler.guides.openChapter(matchingChapterByJoyrideId);
        // Once the chapter is open, we "reset" the step index to the first step.
        actionHandler.guides.goToStep(
          matchingGuideByJoyrideId.joyride.id,
          entryStepIndex,
        );
        // Finally, we start the guide.
        actionHandler.guides.start(matchingGuideByJoyrideId, 0);
      }, CONTROL_CENTER_AVATAR_ANIMATION_DELAY * 1000);
    }
  }, [chapters, mapSearchParamToJoyrideId]);
};
