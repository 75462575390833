import { THooks } from "@sundaeswap/react-hooks";
import deepmerge from "deepmerge";
import hasIn from "lodash/hasIn";
import omitBy from "lodash/omitBy";
import {
  Context,
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import { LOCAL_STORAGE_CONTROL_CENTER } from "../../constants/localStorage.constants";
import { useSortedJoyridesByChapter } from "../../hooks/joyride/useSortedJoyridesByChapter";
import useAppLocation from "../../hooks/router/useAppLocation";
import useAppNavigate from "../../hooks/router/useAppNavigate";
import useAppSearch from "../../hooks/router/useAppSearch";
import {
  getChapterProgress,
  getIsEveryGuideInChapterCompleted,
} from "../../utils/control-center.utils";
import {
  LocalControlCenterActions,
  PersistentControlCenterActions,
} from "./actions";
import { createActionHandler } from "./actions.handler";
import {
  LocalControlCenterStateReducer,
  PersistentControlCenterStateReducer,
} from "./reducer";
import {
  IActionHandlers,
  IControlCenterContext,
  ILocalControlCenterState,
  IPersistentControlCenterState,
  IPersistentControlCenterStateAction,
} from "./types";

export const defaultLocalControlCenterState: ILocalControlCenterState = {
  guides: {
    activeChapter: undefined,
    activeGuideId: undefined,
    chapters: [],
    isNextButtonDisabled: false,
    lastClosedGuideItemId: undefined,
    runGuide: false,
    showGuideCloseUI: false,
    showLoadingAnimation: false,
    showSuccessAnimation: false,
  },
  news: {
    lastClosedNewsId: undefined,
  },
  showGuides: false,
  showHelpCenter: false,
  showInbox: false,
  showToolbar: false,
  withAnimationDelay: true,
};

export const defaultPersistentControlCenterState: IPersistentControlCenterState =
  {
    guides: {
      currentGuideId: null,
      isEveryChapterCompleted: false,
      openGuidesOnMount: false,
      guideInfo: {},
    },
  };

const ControlCenterContext: Context<IControlCenterContext> = createContext({
  actionHandler: {} as IActionHandlers,
  state: {
    localState: defaultLocalControlCenterState,
    persistentState: defaultPersistentControlCenterState,
  },
  dispatchers: {
    localDispatch: (_val) => {},
    persistentDispatch: (_val) => {},
  },
});

export const ControlCenterContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useAppNavigate();
  const location = useAppLocation();
  const search = useAppSearch();
  const sortedChapters = useSortedJoyridesByChapter();

  /* -------------------------------------------------------------------------------------------------
   * Local Control Center State.
   * This is the state that is used to control the Control Center interface component, i.e. `<ControlCenterAvatar />` and other components that don't depend on persistent state.
   * It is not persistent in local storage since it can be reset after a page refresh.
   * -----------------------------------------------------------------------------------------------*/
  const [localControlCenterState, localControlCenterStateDispatch] = useReducer(
    LocalControlCenterStateReducer,
    defaultLocalControlCenterState,
  );
  const memoizedLocalControlCenterStateDispatch = useMemo(
    () => localControlCenterStateDispatch,
    [],
  );

  /* -------------------------------------------------------------------------------------------------
   * Persistent Control Center State.
   * This is the state that is used to control the persistent Control Center State.
   * It is persistent in local storage since it should be kept after a page refresh.
   * -----------------------------------------------------------------------------------------------*/
  const [persistentState, savePersistentControlCenterState] =
    THooks.useLocalStorage<IPersistentControlCenterState>(
      LOCAL_STORAGE_CONTROL_CENTER,
      defaultPersistentControlCenterState,
    );

  const persistentReducer = useCallback(
    (
      persistentState: IPersistentControlCenterState,
      action: IPersistentControlCenterStateAction,
    ): IPersistentControlCenterState => {
      const newState = PersistentControlCenterStateReducer(
        persistentState,
        action,
      );
      savePersistentControlCenterState(newState);

      return newState;
    },
    [savePersistentControlCenterState],
  );

  const hydratedState = useMemo(() => {
    const hydrated = deepmerge(
      defaultPersistentControlCenterState,
      omitBy(persistentState, (_value, key) => {
        if (!hasIn(defaultPersistentControlCenterState, key)) {
          return true;
        }

        return false;
      }),
    );

    return {
      ...defaultPersistentControlCenterState,
      ...hydrated,
    };
  }, [persistentState, defaultPersistentControlCenterState]);

  const [persistentControlCenterState, persistentControlCenterStateDispatch] =
    useReducer(persistentReducer, hydratedState);

  const memoizedPersistentControlCenterStateDispatch = useMemo(
    () => persistentControlCenterStateDispatch,
    [],
  );

  /* -------------------------------------------------------------------------------------------------
   * Action Handler.
   * This is the action handler that is used to dispatch actions from the components that use the Control Center Context.
   * -----------------------------------------------------------------------------------------------*/
  const actionHandler = useMemo(
    (): IActionHandlers =>
      createActionHandler({
        localControlCenterState,
        localDispatch: memoizedLocalControlCenterStateDispatch,
        location,
        navigate,
        persistentDispatch: memoizedPersistentControlCenterStateDispatch,
        search,
      }),
    [
      memoizedLocalControlCenterStateDispatch,
      memoizedPersistentControlCenterStateDispatch,
      localControlCenterState,
      location.pathname,
      navigate,
    ],
  );

  /* -------------------------------------------------------------------------------------------------
   * "Fetch" Joyrides / Chapters.
   * We're currently just importing them our local joyride hooks directory and sort them alphabetically.
   * -----------------------------------------------------------------------------------------------*/
  useEffect(() => {
    LocalControlCenterActions.guides.loadChapters(
      sortedChapters,
      localControlCenterStateDispatch,
    );
  }, []);

  /**
   * This effect is used to set the `isEveryGuideCompleted` state in the persistent state.
   * It is used to determine whether or not to show the indicator on the avatar.
   */
  useEffect(() => {
    const chapters = localControlCenterState.guides.chapters;
    const guideInfo = persistentControlCenterState.guides.guideInfo;
    const isEveryChapterCompleted = chapters.every((chapter) => {
      const chapterProgress = getChapterProgress(chapter, guideInfo);
      const isChapterCompleted =
        getIsEveryGuideInChapterCompleted(chapterProgress);
      return isChapterCompleted;
    });

    PersistentControlCenterActions.guides.setIsEveryChapterCompleted(
      isEveryChapterCompleted,
      memoizedPersistentControlCenterStateDispatch,
    );
  }, [
    persistentControlCenterState.guides.guideInfo,
    localControlCenterState.guides.chapters,
    memoizedPersistentControlCenterStateDispatch,
  ]);

  return (
    <ControlCenterContext.Provider
      value={{
        actionHandler,
        state: {
          localState: localControlCenterState,
          persistentState: persistentControlCenterState,
        },
        dispatchers: {
          localDispatch: memoizedLocalControlCenterStateDispatch,
          persistentDispatch: memoizedPersistentControlCenterStateDispatch,
        },
      }}
    >
      {children}
    </ControlCenterContext.Provider>
  );
};

// Utility hook.
export const useControlCenterContext = (): IControlCenterContext =>
  useContext(ControlCenterContext);
