import { Notice, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { motion } from "framer-motion";
import divide from "lodash/divide";
import isEmpty from "lodash/isEmpty";
import { FC, useCallback, useRef } from "react";
import { slideInAndScaleFromBottomMotion } from "../../../motions/default.motions";
import {
  IChapter,
  useControlCenterContext,
} from "../../../stores/ControlCenterContext";
import {
  getAmountOfCompletedGuidesByChapter,
  getChapterProgress,
  getIsEveryGuideInChapterCompleted,
} from "../../../utils/control-center.utils";
import ControlCenterContentFooter from "../ControlCenterContentFooter";
import ControlCenterContentNode from "../ControlCenterContentNode";
import ControlCenterContentTitle from "../ControlCenterContentTitle";
import ControlCenterGuideItem from "../ControlCenterGuideItem";

const ControlCenterGuideChapters: FC = () => {
  const {
    actionHandler,
    state: {
      localState: {
        guides: { chapters },
      },
      persistentState: {
        guides: { guideInfo, isEveryChapterCompleted },
      },
    },
  } = useControlCenterContext();
  const chapterListRef = useRef<HTMLElement>(null);

  const handleOpenChapter = useCallback(
    (chapter: IChapter) => {
      actionHandler.guides.openChapter(chapter);
    },
    [actionHandler.guides],
  );

  const handleStartGuide = useCallback(
    (chapter: IChapter) => {
      const guide = chapter.guides[0];
      const persistentGuideInfo = guideInfo[guide.joyride.id] || {};
      const stepIndex = persistentGuideInfo?.completed
        ? 0
        : persistentGuideInfo?.stepIndex ?? 0;
      if (persistentGuideInfo?.completed) {
        actionHandler.guides.goToStep(guide.joyride.id, stepIndex);
      }
      actionHandler.guides.start(guide, stepIndex);
    },
    [actionHandler, guideInfo],
  );

  return (
    <>
      <ControlCenterContentTitle
        title="Interactive Guides"
        description="Learn how to use SundaeSwap step by step with our interactive guides."
      />

      {!isEmpty(chapters) && (
        <ControlCenterContentNode
          tag="ul"
          className={cx("grid gap-4", toolkitCx.layout.spacing.both.md)}
          ref={chapterListRef}
        >
          {isEveryChapterCompleted && (
            <motion.li
              variants={slideInAndScaleFromBottomMotion}
              key="ControlCenterGuideList--success"
            >
              <Notice type="success" size="xs" title="You're all set">
                You have completed all guides in every chapter! You're awesome!
              </Notice>
            </motion.li>
          )}

          {chapters?.map((chapter, index) => {
            const isSingleGuideChapter = chapter.guides.length === 1;
            const chapterProgress = getChapterProgress(chapter, guideInfo);
            const isChapterCompleted =
              getIsEveryGuideInChapterCompleted(chapterProgress);
            const amountOfCompletedGuides =
              getAmountOfCompletedGuidesByChapter(chapterProgress);
            const isSingleChapterCompleted =
              isSingleGuideChapter && isChapterCompleted;
            const progressByChapter = isSingleGuideChapter
              ? divide(chapterProgress[0].percentage, 100)
              : amountOfCompletedGuides / chapter.guides.length;
            const progressPercentage = isSingleChapterCompleted
              ? 1
              : progressByChapter;

            const handleOnClick = () => {
              handleOpenChapter(chapter);
              if (isSingleGuideChapter) {
                handleStartGuide(chapter);
              }
            };

            return (
              <ControlCenterGuideItem
                chapterId={chapter.id}
                description={chapter.description}
                isCompleted={isChapterCompleted}
                inViewRoot={chapterListRef}
                key={chapter.id}
                onClick={handleOnClick}
                percentage={progressPercentage}
                progressBarTranslateX={-100 + progressPercentage * 100}
                rank={index + 1}
                title={chapter.title}
                type="chapter"
              />
            );
          })}
        </ControlCenterContentNode>
      )}
      <ControlCenterContentFooter title="Guided Tours by Sundae Labs." />
    </>
  );
};

export default ControlCenterGuideChapters;
