import { lazyInit } from "../../utils/lazyInit";
import * as stats2 from "../generated/stats2.sdk";

export const getStats2GraphqlClient = lazyInit(() =>
  import("graphql-request").then(
    ({ GraphQLClient }) =>
      new GraphQLClient(window.__APP_CONFIG.apiUrls.stats2),
  ),
);

export const getStats2Sdk = lazyInit(() =>
  getStats2GraphqlClient().then(stats2.getSdk),
);
