import { useTheme } from "@sundaeswap/react-hooks";
import { colors } from "@sundaeswap/tailwind-config";
import merge from "lodash/merge";
import { FC, lazy, Suspense, useCallback, useMemo } from "react";
import type { Props as JoyrideProps, Step, StylesOptions } from "react-joyride";

import { useControlCenterContext } from "../../../stores/ControlCenterContext";

const LazyJoyride = lazy(() => import("react-joyride"));

import { useJoyrideAutorun } from "../../../hooks/joyride/useJoyrideAutorun";
import { useJoyrideCallbackHandler } from "../../../hooks/joyride/useJoyrideCallbackHandler";
import JoyrideTooltipComponent from "../JoyrideTooltipComponent";
const defaultPrimaryColor = colors.primary["DEFAULT"];

const JoyrideWrapper: FC = () => {
  const { theme } = useTheme();
  const isDark = theme === "dark";
  const {
    state: {
      localState: {
        guides: { activeChapter, runGuide },
      },
      persistentState: {
        guides: { currentGuideId, guideInfo },
      },
    },
  } = useControlCenterContext();
  const callbackHandler = useJoyrideCallbackHandler(currentGuideId ?? "");

  const defaultStyles: Partial<StylesOptions> = useMemo(
    () => ({
      arrowColor: isDark ? colors.gray[600] : colors.gray[300],
      backgroundColor: isDark ? colors.gray[800] : colors.gray["DEFAULT"],
      overlayColor: `${colors.black}B3`, // `B3` --> 70% opacity
      primaryColor: defaultPrimaryColor,
      textColor: isDark ? colors.gray[200] : colors.black,
      width: 375,
      zIndex: 1000,
    }),
    [isDark],
  );

  const defaultProps: Partial<JoyrideProps> = useMemo(
    () => ({
      continuous: true,
      hideCloseButton: true,
      scrollToFirstStep: true,
      styles: {
        options: defaultStyles,
        tooltip: {
          pointerEvents: "auto",
        },
        tooltipContainer: {
          pointerEvents: "auto",
        },
        spotlight: {
          borderRadius: 8,
        },
      },
    }),
    [],
  );

  const currentGuide = activeChapter?.guides.find(
    (tour) => tour.joyride.id === currentGuideId,
  );
  const currentPersistentGuide = guideInfo[currentGuideId ?? ""];
  const joyrideProps: Partial<JoyrideProps> = useMemo(
    () => merge(defaultProps, currentGuide?.joyride.config),
    [currentGuide?.joyride.config],
  );
  const enhanceStepsWithDefaultStepProps = useMemo(() => {
    if (!currentGuide?.joyride.steps.length) return [];

    return currentGuide.joyride.steps.map((step, i) =>
      merge({}, step, {
        disableBeacon: true,
        locale: {
          last: "Finish Guide",
          next: i === 0 ? "Start Tour" : "Next",
        },
        floaterProps: {
          styles: {
            floaterOpening: {
              pointerEvents: "auto", // Note: This is required to make the floater clickable while a modal is open.
            },
          },
        },
      } as Step),
    );
  }, [currentGuide?.joyride.steps]);

  /**
   * This hook is used to run the guide automatically when the user lands on the page with a magic link
   */
  useJoyrideAutorun();

  const renderMemoizedNode = useCallback(() => {
    if (!currentGuide || !currentPersistentGuide) return null;

    return (
      <Suspense>
        <LazyJoyride
          {...joyrideProps}
          disableCloseOnEsc
          disableOverlayClose
          scrollToFirstStep
          callback={callbackHandler}
          stepIndex={currentPersistentGuide.stepIndex}
          tooltipComponent={JoyrideTooltipComponent}
          run={runGuide}
          steps={enhanceStepsWithDefaultStepProps}
        />
      </Suspense>
    );
  }, [
    joyrideProps,
    joyrideProps.callback,
    currentPersistentGuide?.stepIndex,
    runGuide,
    currentGuide?.joyride.steps,
  ]);

  return renderMemoizedNode();
};

export default JoyrideWrapper;
