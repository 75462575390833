import { Text } from "@sundaeswap/ui-toolkit";
import { FC, useCallback } from "react";
import { useIsWalletConnected } from "../../../../../../hooks/useIsWalletConnected";
import { DisconnectedWallet } from "../../../../DisconnectedWallet";

const ExchangeModeStep1: FC = () => {
  const isWalletConnected = useIsWalletConnected();

  const renderMemoizedNode = useCallback(() => {
    if (!isWalletConnected) {
      return <DisconnectedWallet.Content />;
    }

    return (
      <Text tag="p" size="sm">
        Master the art of trading with our comprehensive guide. Start buying,
        selling, and swapping like a pro on SundaeSwap.
      </Text>
    );
  }, [isWalletConnected]);

  return renderMemoizedNode();
};

export default ExchangeModeStep1;
