import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Text } from "@sundaeswap/ui-toolkit";

const DisconnectWalletTitle = () => {
  return (
    <div className="grid gap-4 text-center">
      <ArrowPathIcon className="mx-auto h-12 w-12 text-primary opacity-20 motion-safe:animate-spin-slow" />

      <Text className="text-center" tag="h1" size="3xl" weight="semibold">
        This Guide requires a Wallet Connection
      </Text>
    </div>
  );
};

export default DisconnectWalletTitle;
