import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInteger: any;
  Fraction: any;
  HexBytes: any;
  JSON: any;
  TickCompact: any;
};

/**
 *  An asset
 *
 *  An asset on the Cardano network
 *
 *  An asset
 */
export type Asset = {
  __typename?: "Asset";
  /**  The assetName portion of the AssetID, as a UTF-8 string  */
  assetName: Scalars["String"];
  /**  The assetName portion of the AssetID, as a hex string  */
  assetNameHex: Scalars["String"];
  /**  The first time we saw metadata for this token  */
  dateListed?: Maybe<DateTime>;
  /**  The number of decimals to use when displaying the token; defaults to 0  */
  decimals: Scalars["Int"];
  /**  A short description of the token and its purpose  */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /**  A URL for the token logo  */
  logo?: Maybe<Scalars["String"]>;
  /**  The original component pieces of metadata, if aggregated from multiple places  */
  metadata: Array<TokenMetadata>;
  /**  The human readable name derived from the token metadata  */
  name: Scalars["String"];
  /**  The policy ID portion of the AssetID  */
  policyId: Scalars["String"];
  /**  A short ticker for the asset, derived from the token metadata  */
  ticker?: Maybe<Scalars["String"]>;
};

/**  A quantity and a unit  */
export type AssetAmount = {
  __typename?: "AssetAmount";
  asset: Asset;
  quantity: Scalars["BigInteger"];
};

/**  A ratio between two assets  */
export type AssetRatio = {
  __typename?: "AssetRatio";
  denominator: AssetAmount;
  numerator: AssetAmount;
  ratio: Scalars["Float"];
};

export type AssetsQuery = {
  __typename?: "AssetsQuery";
  /**  Get a single asset by its ID  */
  byId: Asset;
  /**  Get multiple assets by their IDs  */
  byIds: Array<Asset>;
  /**  Search for assets by name, ticker, or AssetID  */
  search: Array<Asset>;
};

export type AssetsQueryByIdArgs = {
  id: Scalars["ID"];
};

export type AssetsQueryByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type AssetsQuerySearchArgs = {
  term: Scalars["String"];
};

/**  A blueprint for a specific protocol, as a collection of validators  */
export type Blueprint = {
  __typename?: "Blueprint";
  /**  The validators that make up this version of the protocol  */
  validators: Array<Validator>;
};

/**  Token metadata sourced from the Cardano Token Registry  */
export type CardanoTokenRegistry = TokenMetadata & {
  __typename?: "CardanoTokenRegistry";
  /**  The number of decimals to use when displaying the token  */
  decimals: Scalars["Int"];
  /**  A short description of the token and its purpose  */
  description: Scalars["String"];
  /**  The first time we saw metadata for this token  */
  listedAt: DateTime;
  /**  A URL for the token logo  */
  logo: Scalars["String"];
  /**  The human readable name  */
  name: Scalars["String"];
  /**  The token policy script  */
  policy: Scalars["String"];
  /**  A short ticker for this token  */
  ticker: Scalars["String"];
  /**  The last time we saw metadata for this token  */
  updatedAt: DateTime;
  /**  A URL for the project behind this token  */
  url: Scalars["String"];
};

/**  A specific moment in time  */
export type DateTime = {
  __typename?: "DateTime";
  format: Scalars["String"];
  slot: Scalars["Int"];
};

/**  A specific moment in time  */
export type DateTimeFormatArgs = {
  layout?: InputMaybe<Scalars["String"]>;
};

/**  A delta between two snapshots  */
export type Delta = {
  __typename?: "Delta";
  /**  The absolute differences between the two snapshots  */
  difference: Stats;
  /**  The comparand of the delta  */
  end: DateTime;
  /**  The percent change between the two snapshots  */
  percent: StatsPercent;
  /**  The baseline of the delta  */
  start: DateTime;
};

/** A request to deposit assets into the pool, in return for some LP tokens */
export type Deposit = {
  __typename?: "Deposit";
  /** The estimated number of LP tokens, given the current pool state */
  estimated: AssetAmount;
  /** The amount of assets being deposited */
  offer: Array<AssetAmount>;
  /** The actual amount of LP tokens received as a result of the deposit, if its executed */
  received?: Maybe<AssetAmount>;
};

export type Destination = {
  __typename?: "Destination";
  address: Scalars["String"];
  datumHash: Scalars["String"];
};

export type Fees = {
  __typename?: "Fees";
  /** The amount of fees (of asset A in the pool) that this position has collectively earned since deposit */
  assetA: AssetAmount;
  /** The amount of fees (of asset B in the pool) that this position has collectively earned since deposit */
  assetB: AssetAmount;
};

/**  The interval of tick to query; Currently only Hourly is supported  */
export enum Interval {
  All = "All",
  Daily = "Daily",
  Hourly = "Hourly",
  Minute = "Minute",
  Monthly = "Monthly",
  Raw = "Raw",
}

export type Liquidity = {
  __typename?: "Liquidity";
  /** The fees (in either asset) that have been collectively earned since these LP tokens were minted */
  fees: Fees;
  /** The pool that this liquidity is associated with */
  pool: Pool;
  /** The quantity of LP tokens that this liquidity position consists of */
  quantity: AssetAmount;
};

/**  Token metadata published on-chain under the metadata label "20"  */
export type OnChainLabel20 = TokenMetadata & {
  __typename?: "OnChainLabel20";
  /**  The number of decimals to use when displaying the token  */
  decimals: Scalars["Int"];
  /**  A short description of the token and its purpose  */
  desc: Scalars["String"];
  /**  A URL for the token logo  */
  icon: Scalars["String"];
  /**  The first time we saw metadata for this token  */
  listedAt: DateTime;
  /**  The raw JSON from label 20  */
  properties: Scalars["JSON"];
  /**  A short ticker for this token  */
  ticker: Scalars["String"];
  /**  The last time we saw metadata for this token  */
  updatedAt: DateTime;
  /**  A URL for the project behind this token  */
  url: Scalars["String"];
  /**  The version of the CIP-20 standard to use  */
  version: Scalars["String"];
};

/**  Token metadata published on-chain under the metadata label "721"  */
export type OnChainLabel721 = TokenMetadata & {
  __typename?: "OnChainLabel721";
  /**  A short description of the token and its purpose  */
  description: Scalars["String"];
  /**  A URL for the token logo  */
  image: Scalars["String"];
  /**  The first time we saw metadata for this token  */
  listedAt: DateTime;
  /**  The type of media attached to this token, such as image or video  */
  mediaType: Scalars["String"];
  /**  The human readable name  */
  name: Scalars["String"];
  /**  The raw JSON from label 721  */
  properties: Scalars["JSON"];
  /**  The last time we saw metadata for this token  */
  updatedAt: DateTime;
};

/** An order placed on the DEX; Either a swap, deposit, or withdrawal */
export type Order = {
  __typename?: "Order";
  /** When the order was initially listed on-chain */
  createdAt: DateTime;
  /** Details specific to this order type */
  details: OrderDetails;
  /** The order ID; in particular, `txHash#outputIndex` */
  id: Scalars["ID"];
  /** The outcome of the order: whether it was processed or cancelled */
  outcome?: Maybe<Outcome>;
  /** Optionally, the pool identifier that this order *must* execute against */
  pool?: Maybe<Pool>;
  /** The time at which this order was spent, if it's been spent */
  spentAt?: Maybe<DateTime>;
  /** The transaction hash of the transaction that spent this order */
  spentTxHash?: Maybe<Scalars["String"]>;
  /** The sundae protocol version this order was placed and executed against */
  version: Version;
};

export type OrderDetails = Deposit | Swap | Withdraw;

export type Orders = {
  __typename?: "Orders";
  open: Array<Order>;
  recent: Array<Order>;
};

export enum Outcome {
  Cancelled = "Cancelled",
  Executed = "Executed",
}

/**  A SundaeSwap liquidity pool  */
export type Pool = {
  __typename?: "Pool";
  /**  The portion of the trade, expressed as a fraction, that is charged when swapping from assetB to assetA  */
  askFee: Scalars["Fraction"];
  /**  The first asset of the pool  */
  assetA: Asset;
  /**  The second asset of the pool  */
  assetB: Asset;
  /**  The Asset representing the LP token the pool issues for deposits  */
  assetLP: Asset;
  /**  The portion of the trade, expressed as a fraction, that is charged when swapping from assetA to assetB  */
  bidFee: Scalars["Fraction"];
  /**  The current statistics of the pool  */
  current: Stats;
  /**
   *  The time that fees reach their final value
   * @deprecated fees no longer decay
   */
  feesFinalized: DateTime;
  /**
   *  The fee; if bid and ask fees are different, it's the higher of the two; used to represent a deprecated fature
   * @deprecated fees no longer decay
   */
  finalFee: Scalars["Fraction"];
  /**  The pool identifier  */
  id: Scalars["ID"];
  /**  The time that trading against the pool should be allowed  */
  marketOpen: DateTime;
  /**
   *  The fee, as a fraction, at the moment the pool opens for trading (if bid and ask are different, it's the higher of the two)
   * @deprecated fees no longer decay, and bid/ask fees are split
   */
  openingFee: Scalars["Fraction"];
  orders: Orders;
  /**  Candlestick data for the pool  */
  ticks?: Maybe<Ticks>;
  /**  The protocol version this pool is tied to  */
  version: Version;
};

/**  A SundaeSwap liquidity pool  */
export type PoolTicksArgs = {
  end: Scalars["String"];
  interval: Interval;
  start: Scalars["String"];
};

/**  Different ways to query for different pools  */
export type PoolsQuery = {
  __typename?: "PoolsQuery";
  /**  Find all pools that trade a specific asset by Asset ID  */
  byAsset: Array<Pool>;
  /**  Find all pools that trade any one of a number of assets  */
  byAssets: Array<Pool>;
  /**  Fetch a single pool by its Identifier  */
  byId: Pool;
  /**  Fetch many pools by their IDs  */
  byIds: Array<Pool>;
  /**  Fetch the pool associated with an LP token; Will return null if the asset isn't an LP token  */
  byLPToken?: Maybe<Pool>;
  /**  Fetch the pools associated with any LP tokens in the list; If one of the assetIDs isn't an LP token, it will be filtered  */
  byLPTokens: Array<Pool>;
  /**  Find all pools that trade a specific pair of assets  */
  byPair: Array<Pool>;
  /**  Find a list of routes that can be used to trade between two assets, up to a maximum depth  */
  byRoute: Array<Array<Pool>>;
  /**  Find the 50 most "popular" pools, by TVL  */
  popular: Array<Pool>;
  /**  Search for pools using fuzzy string matching  */
  search: Array<Pool>;
};

/**  Different ways to query for different pools  */
export type PoolsQueryByAssetArgs = {
  asset: Scalars["ID"];
};

/**  Different ways to query for different pools  */
export type PoolsQueryByAssetsArgs = {
  assets: Array<Scalars["ID"]>;
};

/**  Different ways to query for different pools  */
export type PoolsQueryByIdArgs = {
  id: Scalars["ID"];
};

/**  Different ways to query for different pools  */
export type PoolsQueryByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

/**  Different ways to query for different pools  */
export type PoolsQueryByLpTokenArgs = {
  assetId: Scalars["ID"];
};

/**  Different ways to query for different pools  */
export type PoolsQueryByLpTokensArgs = {
  assetIds: Array<Scalars["ID"]>;
};

/**  Different ways to query for different pools  */
export type PoolsQueryByPairArgs = {
  assetA: Scalars["ID"];
  assetB: Scalars["ID"];
};

/**  Different ways to query for different pools  */
export type PoolsQueryByRouteArgs = {
  depth: Scalars["Int"];
  givenAsset: Scalars["ID"];
  takenAsset: Scalars["ID"];
};

/**  Different ways to query for different pools  */
export type PoolsQuerySearchArgs = {
  term: Scalars["String"];
};

export type Portfolio = {
  __typename?: "Portfolio";
  /** The address of the portfolio whose data is surfaced */
  id: Scalars["ID"];
  /** Any liquidity owned by this portfolio, either in the wallet directly or in some other indexed contract like yield farming */
  liquidity: Array<Liquidity>;
  /** A history of orders placed by the user */
  orders: Array<Order>;
};

/**  A specific instance or version of the Sundae protocol  */
export type Protocol = {
  __typename?: "Protocol";
  /**  The blueprint, describing all scripts that make up this version of the protocol  */
  blueprint: Blueprint;
  /**  The environment this protocol should be deployed to, for avoidance of doubt  */
  environment: Scalars["String"];
  /**  References to the above scripts published on-chain  */
  references: Array<ScriptReference>;
  /**  The version label of the protocol, such as V1, V3, V3-Stable, etc.  */
  version: Version;
};

/**  A temporary type for global protocol stats  */
export type ProtocolStats = {
  __typename?: "ProtocolStats";
  /**  The number of orders filled in the last 24 hours  */
  filledOrders: Scalars["Int"];
  /**  The number of orders opened in the last 24 hours  */
  openedOrders: Scalars["Int"];
  /**  The number of pools in the protocol  */
  poolCount: Scalars["Int"];
  /**  The total protocol revenue collected  */
  protocolRevenue: AssetAmount;
  /**  The timestamp as of when the stats are valid  */
  timestamp: DateTime;
  /**  The total value locked in the protocol  */
  tvl: AssetAmount;
  /**  The total liquidity fees collected by the protocol in the last 24 hours  */
  volume: AssetAmount;
};

export type Query = {
  __typename?: "Query";
  /**  Queries for specific assets  */
  assets: AssetsQuery;
  /**  Recently opened / filled orders  */
  orders: Array<Order>;
  /**  Fetch information about any pools in this environment  */
  pools: PoolsQuery;
  /**  The portfolio details for a specific wallet address  */
  portfolio: Portfolio;
  /**  Fetch information about each protocol deployed in this environment  */
  protocols: Array<Protocol>;
  stats: ProtocolStats;
  /**  Portfolio-specific details about a given UTXO; Note: we currently don't support querying *arbitrary* transaction outputs, only those with LP tokens on them  */
  transactionOutput: Array<TxOut>;
};

export type QueryOrdersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryPortfolioArgs = {
  address: Scalars["String"];
};

export type QueryTransactionOutputArgs = {
  ids: Array<Scalars["ID"]>;
};

/**  A reference to a specific on-chain script  */
export type ScriptReference = {
  __typename?: "ScriptReference";
  /**  The title of the validator being referenced  */
  key: Scalars["String"];
  /**  The reference to the output that has this script as a script ref  */
  txIn: TxIn;
};

/**  Time-dependent snapshot statistics  */
export type Stats = {
  __typename?: "Stats";
  /**  The time the snapshot is applicable to  */
  asOf: DateTime;
  /**  The price of the pool calculated in the given unit  */
  price: AssetRatio;
  /**  The total protocol fees currently collected and not paid out by this pool  */
  protocolFees: AssetAmount;
  /**  The reserves of assetA in the pool  */
  quantityA: AssetAmount;
  /**  The reserves of assetB in the pool  */
  quantityB: AssetAmount;
  /**  The total circulating supply of LP tokens  */
  quantityLP: AssetAmount;
  /**  The total value locked in the pool, calculated in the given unit  */
  tvl: AssetAmount;
};

/**  Time-dependent snapshot statistics  */
export type StatsPriceArgs = {
  unit?: InputMaybe<Unit>;
};

/**  Time-dependent snapshot statistics  */
export type StatsTvlArgs = {
  unit?: InputMaybe<Unit>;
};

/**  Time-dependent snapshot statistics, as a percentage of some value, such as a percentage change  */
export type StatsPercent = {
  __typename?: "StatsPercent";
  /**  The percent change of the price, calculated in the given unit  */
  price: Scalars["Float"];
  /**  The percentage change of the reserves in assetA in the pool  */
  quantityA: Scalars["Float"];
  /**  The percentage change of the reserves in assetB in the pool  */
  quantityB: Scalars["Float"];
  /**  The percentage change in the circulating supply of LP tokens  */
  quantityLP: Scalars["Float"];
  /**  The percent change of the total value locked in the pool, calculated in the given unit  */
  tvl: Scalars["Float"];
};

/**  Time-dependent snapshot statistics, as a percentage of some value, such as a percentage change  */
export type StatsPercentPriceArgs = {
  unit?: InputMaybe<Unit>;
};

/**  Time-dependent snapshot statistics, as a percentage of some value, such as a percentage change  */
export type StatsPercentTvlArgs = {
  unit?: InputMaybe<Unit>;
};

/** A request to swap one asset for another */
export type Swap = {
  __typename?: "Swap";
  /** The estimated outcome of the swap, given the current pool state */
  estimated: AssetAmount;
  /** The minimum amount of tokens that must be received */
  minimum: AssetAmount;
  /** The portion of the assets on the UTXO being offered for the swap */
  offer: AssetAmount;
  /** The amount of tokens actually received from the swap */
  received?: Maybe<AssetAmount>;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type Tick = {
  __typename?: "Tick";
  /**  The price as of the end window  */
  close: AssetRatio;
  /**  The total value of assets deposited, in the specified unit; The sum of depositA and depositB  */
  deposit: AssetAmount;
  /**  The end of the tick interval  */
  end: DateTime;
  /**  The highest price traded in the interval  */
  high: AssetRatio;
  /**  The lowest price traded in the interval  */
  low: AssetRatio;
  /**  The total LP fees collected by liquidity providers in this interval  */
  lpFees: AssetAmount;
  /**  The price as of the start window  */
  open: AssetRatio;
  /**  The number of orders closed (executed or cancelled) in this interval; currently not working  */
  ordersClosed: Scalars["Int"];
  /**  The number of orders created in this interval; currently not working  */
  ordersOpened: Scalars["Int"];
  /**  The protocol fees collected from each scoop in this interval  */
  protocolFees: AssetAmount;
  /**  The start of the tick interval  */
  start: DateTime;
  /**  The cardano transaction fees paid in order to execute the scoops; currently not working  */
  txFees: AssetAmount;
  /**  The total volume traded, in the specified unit; The sum of volumeA and volumeB  */
  volume: AssetAmount;
  /**  The total value of assets withdrawn, in the specified unit; The sum of withdrawA and withdrawB  */
  withdraw: AssetAmount;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type TickDepositArgs = {
  unit: Unit;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type TickLpFeesArgs = {
  unit: Unit;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type TickVolumeArgs = {
  unit: Unit;
};

/**  A rich tick object, mostly useful for very small intervals, or for learning the shape of the data  */
export type TickWithdrawArgs = {
  unit: Unit;
};

/**  The candlestick data associated with a pool  */
export type Ticks = {
  __typename?: "Ticks";
  /**  The candlestick data in a compact format, useful for most purposes  */
  compact: Array<Scalars["TickCompact"]>;
  /**  A rich description of candlestick data, useful for very small intervals, or for learning the shape of the data  */
  rich: Array<Tick>;
};

/**  Token metadata, providing additional human-consumable information about a specific asset  */
export type TokenMetadata = {
  listedAt: DateTime;
  updatedAt: DateTime;
};

/**  A reference to a specific on-chain transaction output  */
export type TxIn = {
  __typename?: "TxIn";
  /**  The transaction hash  */
  hash: Scalars["HexBytes"];
  /**  The index in the outputs array for the tx output  */
  index: Scalars["Int"];
};

/**  Details about a specific transaction output  */
export type TxOut = {
  __typename?: "TxOut";
  /** The fees earned by this UTXO specifically, if it holds LP tokens */
  fees: Array<Liquidity>;
  /** The TxIn ("txHash#idx") of this UTXO */
  id: Scalars["ID"];
  /** The transaction this UTXO was spent in */
  spentTxHash?: Maybe<Scalars["String"]>;
};

/**  The unit to return an asset amount in; Natural chooses an opinionated 'best' option  */
export enum Unit {
  AssetA = "AssetA",
  AssetB = "AssetB",
  Lovelace = "Lovelace",
  Natural = "Natural",
  Usd = "USD",
}

/**  A specific validator contract on Cardano  */
export type Validator = {
  __typename?: "Validator";
  /**  The compiled code  */
  compiledCode: Scalars["HexBytes"];
  /**  The hash of the script, useful for constructing the payment key  */
  hash: Scalars["HexBytes"];
  /**  The title of the validator  */
  title: Scalars["String"];
};

/**  The protocol version tag we're working with  */
export enum Version {
  V1 = "V1",
  V3 = "V3",
}

/** A request to burn LP tokens, in return for a share of the pool assets */
export type Withdraw = {
  __typename?: "Withdraw";
  /** The amount of LP tokens being withdrawn */
  amount: AssetAmount;
  /** The estimated amount of tokens that will be received, given the current pool state */
  estimated: Array<AssetAmount>;
  /** The actual amount of tokens received, if the withdrawal has executed */
  received: Array<AssetAmount>;
};

export type AssetBrambleFragmentFragment = {
  __typename?: "Asset";
  id: string;
  policyId: string;
  description?: string | null;
  decimals: number;
  ticker?: string | null;
  name: string;
  logo?: string | null;
  assetName: string;
  dateListed?: { __typename?: "DateTime"; format: string } | null;
  metadata: Array<
    | { __typename: "CardanoTokenRegistry" }
    | { __typename: "OnChainLabel20" }
    | { __typename: "OnChainLabel721" }
  >;
};

export type PoolBrambleFragmentFragment = {
  __typename?: "Pool";
  id: string;
  askFee: any;
  bidFee: any;
  openingFee: any;
  finalFee: any;
  version: Version;
  assetA: {
    __typename?: "Asset";
    id: string;
    policyId: string;
    description?: string | null;
    decimals: number;
    ticker?: string | null;
    name: string;
    logo?: string | null;
    assetName: string;
    dateListed?: { __typename?: "DateTime"; format: string } | null;
    metadata: Array<
      | { __typename: "CardanoTokenRegistry" }
      | { __typename: "OnChainLabel20" }
      | { __typename: "OnChainLabel721" }
    >;
  };
  assetB: {
    __typename?: "Asset";
    id: string;
    policyId: string;
    description?: string | null;
    decimals: number;
    ticker?: string | null;
    name: string;
    logo?: string | null;
    assetName: string;
    dateListed?: { __typename?: "DateTime"; format: string } | null;
    metadata: Array<
      | { __typename: "CardanoTokenRegistry" }
      | { __typename: "OnChainLabel20" }
      | { __typename: "OnChainLabel721" }
    >;
  };
  assetLP: {
    __typename?: "Asset";
    id: string;
    policyId: string;
    description?: string | null;
    decimals: number;
    ticker?: string | null;
    name: string;
    logo?: string | null;
    assetName: string;
    dateListed?: { __typename?: "DateTime"; format: string } | null;
    metadata: Array<
      | { __typename: "CardanoTokenRegistry" }
      | { __typename: "OnChainLabel20" }
      | { __typename: "OnChainLabel721" }
    >;
  };
  feesFinalized: { __typename?: "DateTime"; slot: number };
  marketOpen: { __typename?: "DateTime"; slot: number };
  current: {
    __typename?: "Stats";
    quantityA: { __typename?: "AssetAmount"; quantity: any };
    quantityB: { __typename?: "AssetAmount"; quantity: any };
    quantityLP: { __typename?: "AssetAmount"; quantity: any };
    tvl: { __typename?: "AssetAmount"; quantity: any };
  };
};

export type OrderBrambleAssetFragmentFragment = {
  __typename?: "Asset";
  id: string;
  policyId: string;
  decimals: number;
  ticker?: string | null;
  logo?: string | null;
  assetName: string;
};

export type DetailsAmountFragmentFragment = {
  __typename?: "AssetAmount";
  quantity: any;
  asset: {
    __typename?: "Asset";
    id: string;
    policyId: string;
    decimals: number;
    ticker?: string | null;
    logo?: string | null;
    assetName: string;
  };
};

export type SwapDetailsFragmentFragment = {
  __typename: "Swap";
  minimumSwap: {
    __typename?: "AssetAmount";
    quantity: any;
    asset: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      decimals: number;
      ticker?: string | null;
      logo?: string | null;
      assetName: string;
    };
  };
  offerSwap: {
    __typename?: "AssetAmount";
    quantity: any;
    asset: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      decimals: number;
      ticker?: string | null;
      logo?: string | null;
      assetName: string;
    };
  };
  receivedSwap?: {
    __typename?: "AssetAmount";
    quantity: any;
    asset: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      decimals: number;
      ticker?: string | null;
      logo?: string | null;
      assetName: string;
    };
  } | null;
};

export type DepositDetailsFragmentFragment = {
  __typename: "Deposit";
  offerDeposit: Array<{
    __typename?: "AssetAmount";
    quantity: any;
    asset: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      decimals: number;
      ticker?: string | null;
      logo?: string | null;
      assetName: string;
    };
  }>;
  receivedDeposit?: {
    __typename?: "AssetAmount";
    quantity: any;
    asset: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      decimals: number;
      ticker?: string | null;
      logo?: string | null;
      assetName: string;
    };
  } | null;
};

export type WithdrawDetailsFragmentFragment = {
  __typename: "Withdraw";
  amountWithdraw: {
    __typename?: "AssetAmount";
    quantity: any;
    asset: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      decimals: number;
      ticker?: string | null;
      logo?: string | null;
      assetName: string;
    };
  };
  receivedWithdraw: Array<{
    __typename?: "AssetAmount";
    quantity: any;
    asset: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      decimals: number;
      ticker?: string | null;
      logo?: string | null;
      assetName: string;
    };
  }>;
};

export type OrderBrambleFragmentFragment = {
  __typename?: "Order";
  outcome?: Outcome | null;
  id: string;
  spentTxHash?: string | null;
  version: Version;
  createdAt: { __typename?: "DateTime"; format: string };
  pool?: {
    __typename?: "Pool";
    id: string;
    askFee: any;
    bidFee: any;
    openingFee: any;
    finalFee: any;
    version: Version;
    assetA: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    };
    assetB: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    };
    assetLP: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    };
    feesFinalized: { __typename?: "DateTime"; slot: number };
    marketOpen: { __typename?: "DateTime"; slot: number };
    current: {
      __typename?: "Stats";
      quantityA: { __typename?: "AssetAmount"; quantity: any };
      quantityB: { __typename?: "AssetAmount"; quantity: any };
      quantityLP: { __typename?: "AssetAmount"; quantity: any };
      tvl: { __typename?: "AssetAmount"; quantity: any };
    };
  } | null;
  details:
    | {
        __typename: "Deposit";
        offerDeposit: Array<{
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            decimals: number;
            ticker?: string | null;
            logo?: string | null;
            assetName: string;
          };
        }>;
        receivedDeposit?: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            decimals: number;
            ticker?: string | null;
            logo?: string | null;
            assetName: string;
          };
        } | null;
      }
    | {
        __typename: "Swap";
        minimumSwap: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            decimals: number;
            ticker?: string | null;
            logo?: string | null;
            assetName: string;
          };
        };
        offerSwap: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            decimals: number;
            ticker?: string | null;
            logo?: string | null;
            assetName: string;
          };
        };
        receivedSwap?: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            decimals: number;
            ticker?: string | null;
            logo?: string | null;
            assetName: string;
          };
        } | null;
      }
    | {
        __typename: "Withdraw";
        amountWithdraw: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            decimals: number;
            ticker?: string | null;
            logo?: string | null;
            assetName: string;
          };
        };
        receivedWithdraw: Array<{
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            decimals: number;
            ticker?: string | null;
            logo?: string | null;
            assetName: string;
          };
        }>;
      };
  spentAt?: { __typename?: "DateTime"; format: string } | null;
};

export type LiquidityBrambleFragmentFragment = {
  __typename?: "Liquidity";
  fees: {
    __typename?: "Fees";
    assetA: {
      __typename?: "AssetAmount";
      quantity: any;
      asset: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
    };
    assetB: {
      __typename?: "AssetAmount";
      quantity: any;
      asset: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
    };
  };
  pool: {
    __typename?: "Pool";
    id: string;
    askFee: any;
    bidFee: any;
    openingFee: any;
    finalFee: any;
    version: Version;
    assetA: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    };
    assetB: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    };
    assetLP: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    };
    feesFinalized: { __typename?: "DateTime"; slot: number };
    marketOpen: { __typename?: "DateTime"; slot: number };
    current: {
      __typename?: "Stats";
      quantityA: { __typename?: "AssetAmount"; quantity: any };
      quantityB: { __typename?: "AssetAmount"; quantity: any };
      quantityLP: { __typename?: "AssetAmount"; quantity: any };
      tvl: { __typename?: "AssetAmount"; quantity: any };
    };
  };
  quantity: {
    __typename?: "AssetAmount";
    quantity: any;
    asset: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    };
  };
};

export type TransactionOutputFragmentFragment = {
  __typename?: "TxOut";
  id: string;
  spentTxHash?: string | null;
  fees: Array<{
    __typename?: "Liquidity";
    fees: {
      __typename?: "Fees";
      assetA: {
        __typename?: "AssetAmount";
        quantity: any;
        asset: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
      };
      assetB: {
        __typename?: "AssetAmount";
        quantity: any;
        asset: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
      };
    };
    quantity: {
      __typename?: "AssetAmount";
      quantity: any;
      asset: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
    };
    pool: {
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    };
  }>;
};

export type FetchPoolsByPairQueryVariables = Exact<{
  assetA: Scalars["ID"];
  assetB: Scalars["ID"];
}>;

export type FetchPoolsByPairQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byPair: Array<{
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    }>;
  };
};

export type FetchPopularPoolsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchPopularPoolsQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    popular: Array<{
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    }>;
  };
};

export type FetchPoolsBySearchQueryVariables = Exact<{
  searchValue: Scalars["String"];
}>;

export type FetchPoolsBySearchQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    search: Array<{
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    }>;
  };
};

export type FetchPoolByAssetsQueryVariables = Exact<{
  assets: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type FetchPoolByAssetsQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byAssets: Array<{
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    }>;
  };
};

export type FetchPoolByLpTokensQueryVariables = Exact<{
  assetIds: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type FetchPoolByLpTokensQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byLPTokens: Array<{
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    }>;
  };
};

export type FetchTtPoolByLpTokenQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type FetchTtPoolByLpTokenQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byLPToken?: {
      __typename?: "Pool";
      current: {
        __typename?: "Stats";
        quantityA: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            logo?: string | null;
            id: string;
            decimals: number;
            ticker?: string | null;
            assetName: string;
          };
        };
        quantityB: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            logo?: string | null;
            id: string;
            decimals: number;
            ticker?: string | null;
            assetName: string;
          };
        };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
      };
    } | null;
  };
};

export type FetchPoolByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchPoolByIdQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byId: {
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    };
  };
};

export type FetchPoolsByIdQueryVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type FetchPoolsByIdQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byIds: Array<{
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    }>;
  };
};

export type FetchPoolTicksCompactQueryVariables = Exact<{
  id: Scalars["ID"];
  interval: Interval;
  start: Scalars["String"];
  end: Scalars["String"];
}>;

export type FetchPoolTicksCompactQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byIds: Array<{
      __typename?: "Pool";
      ticks?: { __typename?: "Ticks"; compact: Array<any> } | null;
    }>;
  };
};

export type FetchAssetsByIdQueryVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type FetchAssetsByIdQuery = {
  __typename?: "Query";
  assets: {
    __typename?: "AssetsQuery";
    byIds: Array<{
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    }>;
  };
};

export type FetchPoolOpenOrdersQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchPoolOpenOrdersQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byId: {
      __typename?: "Pool";
      id: string;
      askFee: any;
      bidFee: any;
      openingFee: any;
      finalFee: any;
      version: Version;
      orders: {
        __typename?: "Orders";
        open: Array<{
          __typename?: "Order";
          outcome?: Outcome | null;
          id: string;
          spentTxHash?: string | null;
          version: Version;
          createdAt: { __typename?: "DateTime"; format: string };
          pool?: {
            __typename?: "Pool";
            id: string;
            askFee: any;
            bidFee: any;
            openingFee: any;
            finalFee: any;
            version: Version;
            assetA: {
              __typename?: "Asset";
              id: string;
              policyId: string;
              description?: string | null;
              decimals: number;
              ticker?: string | null;
              name: string;
              logo?: string | null;
              assetName: string;
              dateListed?: { __typename?: "DateTime"; format: string } | null;
              metadata: Array<
                | { __typename: "CardanoTokenRegistry" }
                | { __typename: "OnChainLabel20" }
                | { __typename: "OnChainLabel721" }
              >;
            };
            assetB: {
              __typename?: "Asset";
              id: string;
              policyId: string;
              description?: string | null;
              decimals: number;
              ticker?: string | null;
              name: string;
              logo?: string | null;
              assetName: string;
              dateListed?: { __typename?: "DateTime"; format: string } | null;
              metadata: Array<
                | { __typename: "CardanoTokenRegistry" }
                | { __typename: "OnChainLabel20" }
                | { __typename: "OnChainLabel721" }
              >;
            };
            assetLP: {
              __typename?: "Asset";
              id: string;
              policyId: string;
              description?: string | null;
              decimals: number;
              ticker?: string | null;
              name: string;
              logo?: string | null;
              assetName: string;
              dateListed?: { __typename?: "DateTime"; format: string } | null;
              metadata: Array<
                | { __typename: "CardanoTokenRegistry" }
                | { __typename: "OnChainLabel20" }
                | { __typename: "OnChainLabel721" }
              >;
            };
            feesFinalized: { __typename?: "DateTime"; slot: number };
            marketOpen: { __typename?: "DateTime"; slot: number };
            current: {
              __typename?: "Stats";
              quantityA: { __typename?: "AssetAmount"; quantity: any };
              quantityB: { __typename?: "AssetAmount"; quantity: any };
              quantityLP: { __typename?: "AssetAmount"; quantity: any };
              tvl: { __typename?: "AssetAmount"; quantity: any };
            };
          } | null;
          details:
            | {
                __typename: "Deposit";
                offerDeposit: Array<{
                  __typename?: "AssetAmount";
                  quantity: any;
                  asset: {
                    __typename?: "Asset";
                    id: string;
                    policyId: string;
                    decimals: number;
                    ticker?: string | null;
                    logo?: string | null;
                    assetName: string;
                  };
                }>;
                receivedDeposit?: {
                  __typename?: "AssetAmount";
                  quantity: any;
                  asset: {
                    __typename?: "Asset";
                    id: string;
                    policyId: string;
                    decimals: number;
                    ticker?: string | null;
                    logo?: string | null;
                    assetName: string;
                  };
                } | null;
              }
            | {
                __typename: "Swap";
                minimumSwap: {
                  __typename?: "AssetAmount";
                  quantity: any;
                  asset: {
                    __typename?: "Asset";
                    id: string;
                    policyId: string;
                    decimals: number;
                    ticker?: string | null;
                    logo?: string | null;
                    assetName: string;
                  };
                };
                offerSwap: {
                  __typename?: "AssetAmount";
                  quantity: any;
                  asset: {
                    __typename?: "Asset";
                    id: string;
                    policyId: string;
                    decimals: number;
                    ticker?: string | null;
                    logo?: string | null;
                    assetName: string;
                  };
                };
                receivedSwap?: {
                  __typename?: "AssetAmount";
                  quantity: any;
                  asset: {
                    __typename?: "Asset";
                    id: string;
                    policyId: string;
                    decimals: number;
                    ticker?: string | null;
                    logo?: string | null;
                    assetName: string;
                  };
                } | null;
              }
            | {
                __typename: "Withdraw";
                amountWithdraw: {
                  __typename?: "AssetAmount";
                  quantity: any;
                  asset: {
                    __typename?: "Asset";
                    id: string;
                    policyId: string;
                    decimals: number;
                    ticker?: string | null;
                    logo?: string | null;
                    assetName: string;
                  };
                };
                receivedWithdraw: Array<{
                  __typename?: "AssetAmount";
                  quantity: any;
                  asset: {
                    __typename?: "Asset";
                    id: string;
                    policyId: string;
                    decimals: number;
                    ticker?: string | null;
                    logo?: string | null;
                    assetName: string;
                  };
                }>;
              };
          spentAt?: { __typename?: "DateTime"; format: string } | null;
        }>;
      };
      assetA: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetB: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      assetLP: {
        __typename?: "Asset";
        id: string;
        policyId: string;
        description?: string | null;
        decimals: number;
        ticker?: string | null;
        name: string;
        logo?: string | null;
        assetName: string;
        dateListed?: { __typename?: "DateTime"; format: string } | null;
        metadata: Array<
          | { __typename: "CardanoTokenRegistry" }
          | { __typename: "OnChainLabel20" }
          | { __typename: "OnChainLabel721" }
        >;
      };
      feesFinalized: { __typename?: "DateTime"; slot: number };
      marketOpen: { __typename?: "DateTime"; slot: number };
      current: {
        __typename?: "Stats";
        quantityA: { __typename?: "AssetAmount"; quantity: any };
        quantityB: { __typename?: "AssetAmount"; quantity: any };
        quantityLP: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    };
  };
};

export type FetchPoolStatsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchPoolStatsQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byId: {
      __typename?: "Pool";
      current: {
        __typename?: "Stats";
        protocolFees: { __typename?: "AssetAmount"; quantity: any };
        tvl: { __typename?: "AssetAmount"; quantity: any };
      };
    };
  };
};

export type FetchProtocolOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProtocolOrdersQuery = {
  __typename?: "Query";
  orders: Array<{
    __typename?: "Order";
    id: string;
    version: Version;
    outcome?: Outcome | null;
    spentTxHash?: string | null;
    createdAt: { __typename?: "DateTime"; format: string };
    spentAt?: { __typename?: "DateTime"; format: string } | null;
    pool?: {
      __typename?: "Pool";
      assetA: {
        __typename?: "Asset";
        logo?: string | null;
        ticker?: string | null;
        name: string;
      };
      assetB: {
        __typename?: "Asset";
        logo?: string | null;
        ticker?: string | null;
        name: string;
      };
    } | null;
  }>;
};

export type FetchOrdersQueryVariables = Exact<{
  address: Scalars["String"];
}>;

export type FetchOrdersQuery = {
  __typename?: "Query";
  portfolio: {
    __typename?: "Portfolio";
    orders: Array<{
      __typename?: "Order";
      outcome?: Outcome | null;
      id: string;
      spentTxHash?: string | null;
      version: Version;
      createdAt: { __typename?: "DateTime"; format: string };
      pool?: {
        __typename?: "Pool";
        id: string;
        askFee: any;
        bidFee: any;
        openingFee: any;
        finalFee: any;
        version: Version;
        assetA: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        assetB: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        assetLP: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        feesFinalized: { __typename?: "DateTime"; slot: number };
        marketOpen: { __typename?: "DateTime"; slot: number };
        current: {
          __typename?: "Stats";
          quantityA: { __typename?: "AssetAmount"; quantity: any };
          quantityB: { __typename?: "AssetAmount"; quantity: any };
          quantityLP: { __typename?: "AssetAmount"; quantity: any };
          tvl: { __typename?: "AssetAmount"; quantity: any };
        };
      } | null;
      details:
        | {
            __typename: "Deposit";
            offerDeposit: Array<{
              __typename?: "AssetAmount";
              quantity: any;
              asset: {
                __typename?: "Asset";
                id: string;
                policyId: string;
                decimals: number;
                ticker?: string | null;
                logo?: string | null;
                assetName: string;
              };
            }>;
            receivedDeposit?: {
              __typename?: "AssetAmount";
              quantity: any;
              asset: {
                __typename?: "Asset";
                id: string;
                policyId: string;
                decimals: number;
                ticker?: string | null;
                logo?: string | null;
                assetName: string;
              };
            } | null;
          }
        | {
            __typename: "Swap";
            minimumSwap: {
              __typename?: "AssetAmount";
              quantity: any;
              asset: {
                __typename?: "Asset";
                id: string;
                policyId: string;
                decimals: number;
                ticker?: string | null;
                logo?: string | null;
                assetName: string;
              };
            };
            offerSwap: {
              __typename?: "AssetAmount";
              quantity: any;
              asset: {
                __typename?: "Asset";
                id: string;
                policyId: string;
                decimals: number;
                ticker?: string | null;
                logo?: string | null;
                assetName: string;
              };
            };
            receivedSwap?: {
              __typename?: "AssetAmount";
              quantity: any;
              asset: {
                __typename?: "Asset";
                id: string;
                policyId: string;
                decimals: number;
                ticker?: string | null;
                logo?: string | null;
                assetName: string;
              };
            } | null;
          }
        | {
            __typename: "Withdraw";
            amountWithdraw: {
              __typename?: "AssetAmount";
              quantity: any;
              asset: {
                __typename?: "Asset";
                id: string;
                policyId: string;
                decimals: number;
                ticker?: string | null;
                logo?: string | null;
                assetName: string;
              };
            };
            receivedWithdraw: Array<{
              __typename?: "AssetAmount";
              quantity: any;
              asset: {
                __typename?: "Asset";
                id: string;
                policyId: string;
                decimals: number;
                ticker?: string | null;
                logo?: string | null;
                assetName: string;
              };
            }>;
          };
      spentAt?: { __typename?: "DateTime"; format: string } | null;
    }>;
  };
};

export type FetchProtocolStatsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProtocolStatsQuery = {
  __typename?: "Query";
  stats: {
    __typename?: "ProtocolStats";
    poolCount: number;
    openedOrders: number;
    filledOrders: number;
    tvl: { __typename?: "AssetAmount"; quantity: any };
    volume: { __typename?: "AssetAmount"; quantity: any };
    protocolRevenue: { __typename?: "AssetAmount"; quantity: any };
  };
};

export type FetchProtocolsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchProtocolsQuery = {
  __typename?: "Query";
  protocols: Array<{
    __typename?: "Protocol";
    version: Version;
    blueprint: {
      __typename?: "Blueprint";
      validators: Array<{ __typename?: "Validator"; hash: any; title: string }>;
    };
  }>;
};

export type FetchAssetByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchAssetByIdQuery = {
  __typename?: "Query";
  assets: {
    __typename?: "AssetsQuery";
    byId: {
      __typename?: "Asset";
      id: string;
      policyId: string;
      description?: string | null;
      decimals: number;
      ticker?: string | null;
      name: string;
      logo?: string | null;
      assetName: string;
      dateListed?: { __typename?: "DateTime"; format: string } | null;
      metadata: Array<
        | { __typename: "CardanoTokenRegistry" }
        | { __typename: "OnChainLabel20" }
        | { __typename: "OnChainLabel721" }
      >;
    };
  };
};

export type FetchPositionsQueryVariables = Exact<{
  address: Scalars["String"];
}>;

export type FetchPositionsQuery = {
  __typename?: "Query";
  portfolio: {
    __typename?: "Portfolio";
    liquidity: Array<{
      __typename?: "Liquidity";
      fees: {
        __typename?: "Fees";
        assetA: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            description?: string | null;
            decimals: number;
            ticker?: string | null;
            name: string;
            logo?: string | null;
            assetName: string;
            dateListed?: { __typename?: "DateTime"; format: string } | null;
            metadata: Array<
              | { __typename: "CardanoTokenRegistry" }
              | { __typename: "OnChainLabel20" }
              | { __typename: "OnChainLabel721" }
            >;
          };
        };
        assetB: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            description?: string | null;
            decimals: number;
            ticker?: string | null;
            name: string;
            logo?: string | null;
            assetName: string;
            dateListed?: { __typename?: "DateTime"; format: string } | null;
            metadata: Array<
              | { __typename: "CardanoTokenRegistry" }
              | { __typename: "OnChainLabel20" }
              | { __typename: "OnChainLabel721" }
            >;
          };
        };
      };
      pool: {
        __typename?: "Pool";
        id: string;
        askFee: any;
        bidFee: any;
        openingFee: any;
        finalFee: any;
        version: Version;
        assetA: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        assetB: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        assetLP: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        feesFinalized: { __typename?: "DateTime"; slot: number };
        marketOpen: { __typename?: "DateTime"; slot: number };
        current: {
          __typename?: "Stats";
          quantityA: { __typename?: "AssetAmount"; quantity: any };
          quantityB: { __typename?: "AssetAmount"; quantity: any };
          quantityLP: { __typename?: "AssetAmount"; quantity: any };
          tvl: { __typename?: "AssetAmount"; quantity: any };
        };
      };
      quantity: {
        __typename?: "AssetAmount";
        quantity: any;
        asset: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
      };
    }>;
  };
};

export type FetchFeesQueryVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type FetchFeesQuery = {
  __typename?: "Query";
  transactionOutput: Array<{
    __typename?: "TxOut";
    id: string;
    spentTxHash?: string | null;
    fees: Array<{
      __typename?: "Liquidity";
      fees: {
        __typename?: "Fees";
        assetA: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            description?: string | null;
            decimals: number;
            ticker?: string | null;
            name: string;
            logo?: string | null;
            assetName: string;
            dateListed?: { __typename?: "DateTime"; format: string } | null;
            metadata: Array<
              | { __typename: "CardanoTokenRegistry" }
              | { __typename: "OnChainLabel20" }
              | { __typename: "OnChainLabel721" }
            >;
          };
        };
        assetB: {
          __typename?: "AssetAmount";
          quantity: any;
          asset: {
            __typename?: "Asset";
            id: string;
            policyId: string;
            description?: string | null;
            decimals: number;
            ticker?: string | null;
            name: string;
            logo?: string | null;
            assetName: string;
            dateListed?: { __typename?: "DateTime"; format: string } | null;
            metadata: Array<
              | { __typename: "CardanoTokenRegistry" }
              | { __typename: "OnChainLabel20" }
              | { __typename: "OnChainLabel721" }
            >;
          };
        };
      };
      quantity: {
        __typename?: "AssetAmount";
        quantity: any;
        asset: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
      };
      pool: {
        __typename?: "Pool";
        id: string;
        askFee: any;
        bidFee: any;
        openingFee: any;
        finalFee: any;
        version: Version;
        assetA: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        assetB: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        assetLP: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        feesFinalized: { __typename?: "DateTime"; slot: number };
        marketOpen: { __typename?: "DateTime"; slot: number };
        current: {
          __typename?: "Stats";
          quantityA: { __typename?: "AssetAmount"; quantity: any };
          quantityB: { __typename?: "AssetAmount"; quantity: any };
          quantityLP: { __typename?: "AssetAmount"; quantity: any };
          tvl: { __typename?: "AssetAmount"; quantity: any };
        };
      };
    }>;
  }>;
};

export type FetchPoolByRouteQueryVariables = Exact<{
  depth: Scalars["Int"];
  givenAsset: Scalars["ID"];
  takenAsset: Scalars["ID"];
}>;

export type FetchPoolByRouteQuery = {
  __typename?: "Query";
  pools: {
    __typename?: "PoolsQuery";
    byRoute: Array<
      Array<{
        __typename?: "Pool";
        id: string;
        askFee: any;
        bidFee: any;
        openingFee: any;
        finalFee: any;
        version: Version;
        assetA: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        assetB: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        assetLP: {
          __typename?: "Asset";
          id: string;
          policyId: string;
          description?: string | null;
          decimals: number;
          ticker?: string | null;
          name: string;
          logo?: string | null;
          assetName: string;
          dateListed?: { __typename?: "DateTime"; format: string } | null;
          metadata: Array<
            | { __typename: "CardanoTokenRegistry" }
            | { __typename: "OnChainLabel20" }
            | { __typename: "OnChainLabel721" }
          >;
        };
        feesFinalized: { __typename?: "DateTime"; slot: number };
        marketOpen: { __typename?: "DateTime"; slot: number };
        current: {
          __typename?: "Stats";
          quantityA: { __typename?: "AssetAmount"; quantity: any };
          quantityB: { __typename?: "AssetAmount"; quantity: any };
          quantityLP: { __typename?: "AssetAmount"; quantity: any };
          tvl: { __typename?: "AssetAmount"; quantity: any };
        };
      }>
    >;
  };
};

export const AssetBrambleFragmentFragmentDoc = gql`
  fragment AssetBrambleFragment on Asset {
    id
    policyId
    description
    dateListed {
      format
    }
    decimals
    ticker
    name
    logo
    assetName
    metadata {
      ... on OnChainLabel20 {
        __typename
      }
      ... on OnChainLabel721 {
        __typename
      }
      ... on CardanoTokenRegistry {
        __typename
      }
    }
  }
`;
export const PoolBrambleFragmentFragmentDoc = gql`
  fragment PoolBrambleFragment on Pool {
    id
    assetA {
      ...AssetBrambleFragment
    }
    assetB {
      ...AssetBrambleFragment
    }
    assetLP {
      ...AssetBrambleFragment
    }
    feesFinalized {
      slot
    }
    marketOpen {
      slot
    }
    askFee
    bidFee
    openingFee
    finalFee
    current {
      quantityA {
        quantity
      }
      quantityB {
        quantity
      }
      quantityLP {
        quantity
      }
      tvl {
        quantity
      }
    }
    version
  }
`;
export const OrderBrambleAssetFragmentFragmentDoc = gql`
  fragment OrderBrambleAssetFragment on Asset {
    id
    policyId
    decimals
    ticker
    logo
    assetName: name
  }
`;
export const DetailsAmountFragmentFragmentDoc = gql`
  fragment DetailsAmountFragment on AssetAmount {
    quantity
    asset {
      ...OrderBrambleAssetFragment
    }
  }
`;
export const SwapDetailsFragmentFragmentDoc = gql`
  fragment SwapDetailsFragment on Swap {
    __typename
    minimumSwap: minimum {
      ...DetailsAmountFragment
    }
    offerSwap: offer {
      ...DetailsAmountFragment
    }
    receivedSwap: received {
      ...DetailsAmountFragment
    }
  }
`;
export const DepositDetailsFragmentFragmentDoc = gql`
  fragment DepositDetailsFragment on Deposit {
    __typename
    offerDeposit: offer {
      ...DetailsAmountFragment
    }
    receivedDeposit: received {
      ...DetailsAmountFragment
    }
  }
`;
export const WithdrawDetailsFragmentFragmentDoc = gql`
  fragment WithdrawDetailsFragment on Withdraw {
    __typename
    amountWithdraw: amount {
      ...DetailsAmountFragment
    }
    receivedWithdraw: received {
      ...DetailsAmountFragment
    }
  }
`;
export const OrderBrambleFragmentFragmentDoc = gql`
  fragment OrderBrambleFragment on Order {
    outcome
    createdAt {
      format
    }
    pool {
      ...PoolBrambleFragment
    }
    details {
      ...SwapDetailsFragment
      ...DepositDetailsFragment
      ...WithdrawDetailsFragment
    }
    id
    spentTxHash
    spentAt {
      format
    }
    version
  }
`;
export const LiquidityBrambleFragmentFragmentDoc = gql`
  fragment LiquidityBrambleFragment on Liquidity {
    fees {
      assetA {
        asset {
          ...AssetBrambleFragment
        }
        quantity
      }
      assetB {
        asset {
          ...AssetBrambleFragment
        }
        quantity
      }
    }
    pool {
      ...PoolBrambleFragment
    }
    quantity {
      asset {
        ...AssetBrambleFragment
      }
      quantity
    }
  }
`;
export const TransactionOutputFragmentFragmentDoc = gql`
  fragment TransactionOutputFragment on TxOut {
    fees {
      fees {
        assetA {
          asset {
            ...AssetBrambleFragment
          }
          quantity
        }
        assetB {
          asset {
            ...AssetBrambleFragment
          }
          quantity
        }
      }
      quantity {
        asset {
          ...AssetBrambleFragment
        }
        quantity
      }
      pool {
        ...PoolBrambleFragment
      }
    }
    id
    spentTxHash
  }
`;
export const FetchPoolsByPairDocument = gql`
  query fetchPoolsByPair($assetA: ID!, $assetB: ID!) {
    pools {
      byPair(assetA: $assetA, assetB: $assetB) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPopularPoolsDocument = gql`
  query fetchPopularPools {
    pools {
      popular {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolsBySearchDocument = gql`
  query fetchPoolsBySearch($searchValue: String!) {
    pools {
      search(term: $searchValue) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolByAssetsDocument = gql`
  query fetchPoolByAssets($assets: [ID!]!) {
    pools {
      byAssets(assets: $assets) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolByLpTokensDocument = gql`
  query fetchPoolByLPTokens($assetIds: [ID!]!) {
    pools {
      byLPTokens(assetIds: $assetIds) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchTtPoolByLpTokenDocument = gql`
  query fetchTTPoolByLpToken($assetId: ID!) {
    pools {
      byLPToken(assetId: $assetId) {
        current {
          quantityA {
            quantity
            asset {
              logo
              id
              decimals
              ticker
              assetName
            }
          }
          quantityB {
            quantity
            asset {
              logo
              id
              decimals
              ticker
              assetName
            }
          }
          quantityLP {
            quantity
          }
        }
      }
    }
  }
`;
export const FetchPoolByIdDocument = gql`
  query fetchPoolById($id: ID!) {
    pools {
      byId(id: $id) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolsByIdDocument = gql`
  query fetchPoolsById($ids: [ID!]!) {
    pools {
      byIds(ids: $ids) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolTicksCompactDocument = gql`
  query fetchPoolTicksCompact(
    $id: ID!
    $interval: Interval!
    $start: String!
    $end: String!
  ) {
    pools {
      byIds(ids: [$id]) {
        ticks(interval: $interval, start: $start, end: $end) {
          compact
        }
      }
    }
  }
`;
export const FetchAssetsByIdDocument = gql`
  query fetchAssetsById($ids: [ID!]!) {
    assets {
      byIds(ids: $ids) {
        ...AssetBrambleFragment
      }
    }
  }
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolOpenOrdersDocument = gql`
  query fetchPoolOpenOrders($id: ID!) {
    pools {
      byId(id: $id) {
        ...PoolBrambleFragment
        orders {
          open {
            ...OrderBrambleFragment
          }
        }
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
  ${OrderBrambleFragmentFragmentDoc}
  ${SwapDetailsFragmentFragmentDoc}
  ${DetailsAmountFragmentFragmentDoc}
  ${OrderBrambleAssetFragmentFragmentDoc}
  ${DepositDetailsFragmentFragmentDoc}
  ${WithdrawDetailsFragmentFragmentDoc}
`;
export const FetchPoolStatsDocument = gql`
  query fetchPoolStats($id: ID!) {
    pools {
      byId(id: $id) {
        current {
          protocolFees {
            quantity
          }
          tvl {
            quantity
          }
        }
      }
    }
  }
`;
export const FetchProtocolOrdersDocument = gql`
  query fetchProtocolOrders {
    orders {
      id
      version
      createdAt {
        format
      }
      spentAt {
        format
      }
      outcome
      spentTxHash
      pool {
        assetA {
          logo
          ticker
          name
        }
        assetB {
          logo
          ticker
          name
        }
      }
    }
  }
`;
export const FetchOrdersDocument = gql`
  query fetchOrders($address: String!) {
    portfolio(address: $address) {
      orders {
        ...OrderBrambleFragment
      }
    }
  }
  ${OrderBrambleFragmentFragmentDoc}
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
  ${SwapDetailsFragmentFragmentDoc}
  ${DetailsAmountFragmentFragmentDoc}
  ${OrderBrambleAssetFragmentFragmentDoc}
  ${DepositDetailsFragmentFragmentDoc}
  ${WithdrawDetailsFragmentFragmentDoc}
`;
export const FetchProtocolStatsDocument = gql`
  query fetchProtocolStats {
    stats {
      poolCount
      openedOrders
      filledOrders
      tvl {
        quantity
      }
      volume {
        quantity
      }
      protocolRevenue {
        quantity
      }
    }
  }
`;
export const FetchProtocolsDocument = gql`
  query fetchProtocols {
    protocols {
      blueprint {
        validators {
          hash
          title
        }
      }
      version
    }
  }
`;
export const FetchAssetByIdDocument = gql`
  query fetchAssetById($id: ID!) {
    assets {
      byId(id: $id) {
        ...AssetBrambleFragment
      }
    }
  }
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPositionsDocument = gql`
  query fetchPositions($address: String!) {
    portfolio(address: $address) {
      liquidity {
        ...LiquidityBrambleFragment
      }
    }
  }
  ${LiquidityBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
  ${PoolBrambleFragmentFragmentDoc}
`;
export const FetchFeesDocument = gql`
  query fetchFees($ids: [ID!]!) {
    transactionOutput(ids: $ids) {
      ...TransactionOutputFragment
    }
  }
  ${TransactionOutputFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
  ${PoolBrambleFragmentFragmentDoc}
`;
export const FetchPoolByRouteDocument = gql`
  query fetchPoolByRoute($depth: Int!, $givenAsset: ID!, $takenAsset: ID!) {
    pools {
      byRoute(depth: $depth, givenAsset: $givenAsset, takenAsset: $takenAsset) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    fetchPoolsByPair(
      variables: FetchPoolsByPairQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolsByPairQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolsByPairQuery>(
            FetchPoolsByPairDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolsByPair",
        "query",
      );
    },
    fetchPopularPools(
      variables?: FetchPopularPoolsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPopularPoolsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPopularPoolsQuery>(
            FetchPopularPoolsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPopularPools",
        "query",
      );
    },
    fetchPoolsBySearch(
      variables: FetchPoolsBySearchQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolsBySearchQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolsBySearchQuery>(
            FetchPoolsBySearchDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolsBySearch",
        "query",
      );
    },
    fetchPoolByAssets(
      variables: FetchPoolByAssetsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolByAssetsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolByAssetsQuery>(
            FetchPoolByAssetsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolByAssets",
        "query",
      );
    },
    fetchPoolByLPTokens(
      variables: FetchPoolByLpTokensQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolByLpTokensQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolByLpTokensQuery>(
            FetchPoolByLpTokensDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolByLPTokens",
        "query",
      );
    },
    fetchTTPoolByLpToken(
      variables: FetchTtPoolByLpTokenQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchTtPoolByLpTokenQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchTtPoolByLpTokenQuery>(
            FetchTtPoolByLpTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchTTPoolByLpToken",
        "query",
      );
    },
    fetchPoolById(
      variables: FetchPoolByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolByIdQuery>(FetchPoolByIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "fetchPoolById",
        "query",
      );
    },
    fetchPoolsById(
      variables: FetchPoolsByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolsByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolsByIdQuery>(
            FetchPoolsByIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolsById",
        "query",
      );
    },
    fetchPoolTicksCompact(
      variables: FetchPoolTicksCompactQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolTicksCompactQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolTicksCompactQuery>(
            FetchPoolTicksCompactDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolTicksCompact",
        "query",
      );
    },
    fetchAssetsById(
      variables: FetchAssetsByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchAssetsByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchAssetsByIdQuery>(
            FetchAssetsByIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchAssetsById",
        "query",
      );
    },
    fetchPoolOpenOrders(
      variables: FetchPoolOpenOrdersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolOpenOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolOpenOrdersQuery>(
            FetchPoolOpenOrdersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolOpenOrders",
        "query",
      );
    },
    fetchPoolStats(
      variables: FetchPoolStatsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolStatsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolStatsQuery>(
            FetchPoolStatsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolStats",
        "query",
      );
    },
    fetchProtocolOrders(
      variables?: FetchProtocolOrdersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchProtocolOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchProtocolOrdersQuery>(
            FetchProtocolOrdersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchProtocolOrders",
        "query",
      );
    },
    fetchOrders(
      variables: FetchOrdersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchOrdersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchOrdersQuery>(FetchOrdersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "fetchOrders",
        "query",
      );
    },
    fetchProtocolStats(
      variables?: FetchProtocolStatsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchProtocolStatsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchProtocolStatsQuery>(
            FetchProtocolStatsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchProtocolStats",
        "query",
      );
    },
    fetchProtocols(
      variables?: FetchProtocolsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchProtocolsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchProtocolsQuery>(
            FetchProtocolsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchProtocols",
        "query",
      );
    },
    fetchAssetById(
      variables: FetchAssetByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchAssetByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchAssetByIdQuery>(
            FetchAssetByIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchAssetById",
        "query",
      );
    },
    fetchPositions(
      variables: FetchPositionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPositionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPositionsQuery>(
            FetchPositionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPositions",
        "query",
      );
    },
    fetchFees(
      variables: FetchFeesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchFeesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchFeesQuery>(FetchFeesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "fetchFees",
        "query",
      );
    },
    fetchPoolByRoute(
      variables: FetchPoolByRouteQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<FetchPoolByRouteQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FetchPoolByRouteQuery>(
            FetchPoolByRouteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "fetchPoolByRoute",
        "query",
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
