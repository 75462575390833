import { ExternalLink, Text } from "@sundaeswap/ui-toolkit";
import { FC } from "react";
import { Trans } from "react-i18next";
import { externalRoutes } from "../../../../../../pages/routes";

const V3ComparisonStep10: FC = () => {
  return (
    <>
      <div
        className="absolute left-0 top-0 z-[-1] h-full w-full bg-cover bg-no-repeat opacity-10"
        style={{
          backgroundImage: `url("/static/images/v3/and_much_more.png")`,
        }}
      />
      <Text tag="p" size="sm">
        <Trans
          i18nKey="v3.guides.comparison.steps.step13.description"
          ns="joyride"
        >
          Follow us on{" "}
          <ExternalLink href={externalRoutes.twitter} size="sm" variant="info">
            X
          </ExternalLink>
          , or join our{" "}
          <ExternalLink href={externalRoutes.discord} size="sm" variant="info">
            Discord
          </ExternalLink>{" "}
          to stay up to date on other exciting developments regarding the Sundae
          protocol.
        </Trans>
      </Text>
    </>
  );
};

export default V3ComparisonStep10;
