import { Asset } from "../gql/generated/stats2.sdk";
import { ECardanoExplorer, ECardanoExplorerType } from "../stores";

export const SUNDAE_POOL_IDENT = "08";
export const SUNDAE_POOL_IDENT_V3 =
  "2f36866691fa75a9aab66dec99f7cc2d297ca09e34d9ce68cde04773";

export const ADA_METADATA: Asset = {
  __typename: "Asset",
  description: "",
  assetId: "ada.lovelace",
  policyId: "ada",
  assetName: "Cardano",
  decimals: 6,
  logo: "/static/images/cardano.png",
  ticker: "ADA",
  dateListed: "2023-07-24 20:56:47.60474487 +0000 UTC m=+3459.540384892",
  marketCap: null,
  sources: [],
  priceToday: null,
  priceYesterday: null,
  priceDiff24H: null,
  poolId: "",
  slotAdded: 0,
  totalSupply: null,
  tvl: null,
  tvlDiff24H: null,
  tvlToday: null,
  tvlYesterday: null,
  volume24H: null,
};

export const SNEK_METADATA: Asset = {
  assetId: "279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f.534e454b",
  policyId: "279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f",
  assetName: "SNEK",
  decimals: 0,
  logo: "https://cdn.sundaeswap.finance/logos/568/5689165335886365140.png",
  ticker: "SNEK",
  dateListed: "2023-07-24 20:54:14.208892176 +0000 UTC",
  marketCap: "23690855.98",
  priceToday: "0.0003088129339",
  priceYesterday: "0.0003626157417",
  priceDiff24H: "-0.1483741648",
  poolId: "0029cb7c88c7567b63d1a512c0ed626aa169688ec980730c0473b913.70201f04",
  slotAdded: 91330451,
  totalSupply: "76715880000",
  tvl: "4.238973994e+11",
  tvlDiff24H: "-0.08393363482",
  tvlToday: "4.238973994e+11",
  tvlYesterday: "4.62736561e+11",
  volume24H: "428647420",
};

export const SUNDAE_METADATA: Asset = {
  assetId:
    "9a9693a9a37912a5097918f97918d15240c92ab729a0b7c4aa144d77.53554e444145",
  policyId: "9a9693a9a37912a5097918f97918d15240c92ab729a0b7c4aa144d77",
  assetName: "SUNDAE",
  decimals: 6,
  logo: "https://cdn.sundaeswap.finance/logos/983/9830390119461449821.png",
  ticker: "SUNDAE",
  dateListed: "2023-07-24 20:24:26.13619674 +0000 UTC",
  marketCap: "32401348.17",
  priceToday: "0.0186358653",
  priceYesterday: "0.01802171402",
  priceDiff24H: "-0.07150710736",
  poolId: "0029cb7c88c7567b63d1a512c0ed626aa169688ec980730c0473b913.702008",
  slotAdded: 51075469,
  totalSupply: "2000000000",
  tvl: "3224902.797",
  tvlDiff24H: "-0.04265673978",
  tvlToday: "3224902.797",
  tvlYesterday: "3368596.126",
  volume24H: "5568194028370",
};

export const ADA_SUNDAE_LP_METADATA: Asset = {
  assetId: "4086577ed57c514f8e29b78f42ef4f379363355a3b65b9a032ee30c9.6c702001",
  policyId: "4086577ed57c514f8e29b78f42ef4f379363355a3b65b9a032ee30c9",
  assetName: "6c702001",
  decimals: 0,
  logo: null,
  ticker: "lp \u0001",
  dateListed: "2023-05-29 19:52:17.46511791 +0000 UTC",
  marketCap: null,
  sources: [],
  priceToday: null,
  priceYesterday: null,
  priceDiff24H: null,
  poolId: "",
  slotAdded: 0,
  totalSupply: null,
  tvl: null,
  tvlDiff24H: null,
  tvlToday: null,
  tvlYesterday: null,
  volume24H: null,
};

export const DEFAULT_DECIMALS = 0;

export const CARDANO_EXPLORER_LINK_MAP = {
  [ECardanoExplorerType.policy]: {
    [ECardanoExplorer.cardanoscan]: "tokenPolicy",
    [ECardanoExplorer.cexplorer]: "policy",
    [ECardanoExplorer.cardanoExplorer]: "smart-contract",
  },
  [ECardanoExplorerType.transaction]: {
    [ECardanoExplorer.cardanoscan]: "transaction",
    [ECardanoExplorer.cexplorer]: "tx",
    [ECardanoExplorer.cardanoExplorer]: "transaction",
  },
  [ECardanoExplorerType.asset]: {
    [ECardanoExplorer.cardanoscan]: "token",
    [ECardanoExplorer.cexplorer]: "asset",
    [ECardanoExplorer.cardanoExplorer]: "token",
  },
};

export const readableAdaAssetId = ADA_METADATA.assetId;

export const MOCK_V3_ASSET_B_ID =
  "99b071ce8580d6a3a11b4902145adb8bfd0d2a03935af8cf66403e15.524245525259";
export const MOCK_V3_POOL: unknown = {
  assetA: ADA_METADATA,
  assetB: {
    assetName: "RBERRY",
    assetId: MOCK_V3_ASSET_B_ID,
    decimals: 6,
    ticker: "RBERRY",
    dateListed: "",
    policyId: "99b071ce8580d6a3a11b4902145adb8bfd0d2a03935af8cf66403e15",
    poolId: "",
    slotAdded: 0,
  },
  fee: "0.05",
  quantityA: "1018800000",
  quantityB: "992067448",
  ident: "99b071ce8580d6a3a11b4902145adb8bfd0d2a03935af8cf66403e15",
  version: "V3",
};

export const SUNDAE_CDN = "https://cdn.sundaeswap.finance/";
