import isEqual from "lodash/isEqual";
import { useState } from "react";
import { useCustomCompareEffect } from "use-custom-compare";
import { INITIAL_PAGE_TRANSITION_DURATION } from "../components/InitialPageTransition/InitialPageTransition";
import useAppLocation from "./router/useAppLocation";

export const useIsInitialPageLoad = () => {
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
  const location = useAppLocation();

  // this effect sets `isInitialPageLoad` to false and makes sure that we do not trigger the initial page transition a second time
  useCustomCompareEffect(
    () => {
      setTimeout(
        () => isInitialPageLoad && setIsInitialPageLoad(false),
        1000 * INITIAL_PAGE_TRANSITION_DURATION,
      );
    },
    [isInitialPageLoad, location],
    isEqual,
  );

  return { isInitialPageLoad };
};
