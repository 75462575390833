import { useCallback } from "react";
import type { CallBackProps } from "react-joyride";

import {
  LocalControlCenterActions,
  useControlCenterContext,
} from "../../stores/ControlCenterContext";

export const useDefaultJoyrideCallbackHandler = () => {
  const {
    actionHandler,
    dispatchers: { localDispatch },
    state: {
      localState: {
        guides: { chapters },
      },
      persistentState: {
        guides: { currentGuideId },
      },
    },
  } = useControlCenterContext();

  return useCallback(
    ({ action, index, status, type }: CallBackProps) => {
      import("react-joyride").then(({ ACTIONS, EVENTS, STATUS }) => {
        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
          const chapter = chapters.find((chapter) =>
            chapter.guides.some((guide) => guide.joyride.id === currentGuideId),
          );
          actionHandler.guides.finish(currentGuideId ?? "", chapter);
        } else if (
          ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(
            type,
          )
        ) {
          const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
          if (action === ACTIONS.CLOSE) {
            LocalControlCenterActions.guides.showGuideCloseUI(
              true,
              localDispatch,
            );
          }

          if (([ACTIONS.PREV, ACTIONS.NEXT] as string[]).includes(action)) {
            if (status !== STATUS.PAUSED) {
              actionHandler.guides.goToStep(
                currentGuideId ?? "",
                nextStepIndex,
              );
            }
          }
        }
      });
    },
    [actionHandler, currentGuideId, localDispatch],
  );
};
