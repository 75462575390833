import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { Earning } from "../../../gql/generated/yieldFarmingV2.sdk";
import { IYieldFarmingTotalEarnings } from "../../../types/Farm.types";
import { ETransactionState } from "../../../types/Transaction.types";

export enum EErrorType {
  error = "error",
  info = "info",
  warning = "warning",
}

export type TErrorBoxData = {
  type: EErrorType;
  message: string;
  originalError?: Error;
  details?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

interface IYieldFarmingEarningsContextProviderProps extends PropsWithChildren {
  earnings: Earning[];
  refetchEarnings(): void;
  totalEarnings: IYieldFarmingTotalEarnings[];
}

export interface IYieldFarmingEarningsContext {
  dialogState: ETransactionState;
  earnings: Earning[];
  errorData?: TErrorBoxData;
  handleSetErrorData: (data: TErrorBoxData) => void;
  refetchEarnings(): void;
  selectedPrograms: string[];
  setDialogState: Dispatch<SetStateAction<ETransactionState>>;
  setSelectedPrograms: Dispatch<SetStateAction<string[]>>;
  totalEarnings: IYieldFarmingTotalEarnings[];
}

const defaultYieldFarmingEarningsState: IYieldFarmingEarningsContext = {
  dialogState: ETransactionState.initial,
  earnings: [],
  errorData: undefined,
  handleSetErrorData: () => {},
  refetchEarnings: () => {},
  selectedPrograms: [],
  setDialogState: () => {},
  setSelectedPrograms: () => {},
  totalEarnings: [],
};

const YieldFarmingEarningsContext = createContext(
  defaultYieldFarmingEarningsState,
);

export const useYieldFarmingEarningsContext = () =>
  useContext(YieldFarmingEarningsContext);

export const YieldFarmingEarningsContextProvider: FC<
  IYieldFarmingEarningsContextProviderProps
> = ({ children, earnings, refetchEarnings, totalEarnings }) => {
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>(
    totalEarnings?.reduce(
      (
        arr: string[],
        { program, someEarningsCooldown, someEarningsUnverified },
      ) => {
        if (!someEarningsCooldown && !someEarningsUnverified)
          return [...arr, program.id];
        return arr;
      },
      [],
    ) ?? [],
  );
  const [dialogState, setDialogState] = useState(ETransactionState.initial);
  const [errorData, setErrorData] = useState<TErrorBoxData>();

  const handleSetErrorData = (data: TErrorBoxData) => {
    setErrorData(data);
    setDialogState(ETransactionState.error);
  };

  return (
    <YieldFarmingEarningsContext.Provider
      value={{
        dialogState,
        earnings,
        errorData,
        handleSetErrorData,
        refetchEarnings,
        selectedPrograms,
        setDialogState,
        setSelectedPrograms,
        totalEarnings,
      }}
    >
      {children}
    </YieldFarmingEarningsContext.Provider>
  );
};
