import { useMemo } from "react";

import { SundaeUtils } from "@sundaeswap/core/utilities";
import {
  useWalletLoadingState,
  useWalletObserver,
} from "@sundaeswap/wallet-lite";
import { IAssetMetaData } from "../types/Asset.types";
import { getAssetId } from "../utils/assets.utils";
import useAppSearch from "./router/useAppSearch";
import useIsLPAsset from "./useIsLPAsset";

export const useAssetsWithBalance = () => {
  const { given, taken } = useAppSearch();
  const { balance } = useWalletObserver<IAssetMetaData>();
  const { connectingWallet } = useWalletLoadingState();
  const { isLPAsset } = useIsLPAsset();

  const assetsWithBalance = useMemo(() => {
    if (!balance) return [];

    return [...balance.getFungibleTokens().values()];
  }, [balance]);

  const searchAssetsBalance = useMemo(() => {
    if (assetsWithBalance?.length === 0) {
      return {};
    }

    return {
      givenAssetBalance: assetsWithBalance?.find(
        ({ metadata }) =>
          given && SundaeUtils.isAssetIdsEqual(given, getAssetId(metadata)),
      ),
      takenAssetBalance: assetsWithBalance?.find(
        ({ metadata }) =>
          taken && SundaeUtils.isAssetIdsEqual(taken, getAssetId(metadata)),
      ),
    };
  }, [given, taken, assetsWithBalance]);

  const assetsWithoutLP = useMemo(
    () =>
      assetsWithBalance.filter(({ metadata }) => !isLPAsset(metadata.policyId)),
    [assetsWithBalance, isLPAsset],
  );

  const lpAssets = useMemo(() => {
    return assetsWithBalance.filter(({ metadata }) =>
      isLPAsset(metadata.policyId),
    );
  }, [assetsWithBalance, isLPAsset]);

  /**
   * An array of LP assetIds that the user has in their wallet.
   * Useful for fetching pool data for LPs only.
   */
  const lpAssetIds = useMemo(
    () =>
      assetsWithBalance
        .filter((asset) => isLPAsset(asset.metadata.policyId))
        .map((asset) => asset.metadata.assetId),
    [assetsWithBalance, isLPAsset],
  );

  return {
    searchAssetsBalance,
    assetsWithBalance,
    assetsWithoutLP,
    lpAssets,
    lpAssetIds,
    isLoading: connectingWallet,
    isLoaded: !connectingWallet,
  };
};
