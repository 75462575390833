import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import { Guides } from "../../../../../components/JoyrideStepComponents";
import {
  joyrideIds,
  joyrideSelectors,
} from "../../../../../constants/joyride.constants";
import { paths } from "../../../../../pages/routes";
import {
  EGuideChapter,
  IGuide,
} from "../../../../../stores/ControlCenterContext";
import { getEnv } from "../../../../../utils/network.utils";
import { useIsWalletConnected } from "../../../../useIsWalletConnected";

export const useTasteTestInteractiveGuideJoyride = (): IGuide => {
  const { t } = useI18N("joyride");
  const isWalletConnected = useIsWalletConnected();
  const env = getEnv();
  const isPreview = env === "preview";

  return useMemo(
    () => ({
      rank: 2,
      description: t("tasteTest.interactiveGuide.description"),
      title: t("tasteTest.interactiveGuide.title"),
      chapter: EGuideChapter.TASTE_TEST,
      joyride: {
        entryUrl: paths.tasteTest.root,
        id: joyrideIds.tasteTest.interactiveGuide,
        steps: [
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            locale: {
              next: "Let's get started",
            },
            title: <Guides.TasteTest.Interactive.Titles.Step1Title />,
            content: <Guides.TasteTest.Interactive.Steps.Step1 />,
            placement: "center",
            target: "body",
            disableBeacon: true,
          },
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            placement: "bottom",
            title: "Relevant Information",
            content:
              "Here, you can find all the relevant information about the project. This includes the project's name, description, and the total amount of tokens available.",
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.detailBanner}"]`,
            disableBeacon: true,
          },
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            placement: "bottom",
            title: "Total Raised",
            content:
              "This is the amount of ADA that has been contributed to the Taste Test, so far.",
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.totalRaised}"]`,
            disableBeacon: true,
          },
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            placement: "bottom",
            title: "Team Amount Contributed",
            content:
              "This is the amount of the native token the launching team has contributed to the Taste Test.",
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.contributedAmount}"]`,
            disableBeacon: true,
          },
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            placement: "left",
            title: "Status",
            content:
              "This is where you can find the current status of the Taste Test campaign - it will be either Ongoing or Closing.",
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.status}"]`,
            disableBeacon: true,
          },
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            placement: "left",
            title: "Price",
            content:
              "This reflects the current estimated price of the token, expressed in ADA.",
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.price}"]`,
            disableBeacon: true,
          },
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            placement: "right",
            title: "Target Price Graph / TVL",
            content:
              "Here you can view the current target price of the token and the historical Taste Test performance to-date. Toggling TVL displays the volume being traded.",
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.priceChart}"]`,
            disableBeacon: true,
          },
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            placement: "right",
            title: "Reported Vesting Schedule / Tokenomics",
            content:
              "This chart displays the vesting schedule that the team has committed to. Toggling Tokenomics displays the tokenomics of the project.",
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.tokenomics}"]`,
            disableBeacon: true,
          },
          {
            entryUrl: `${paths.tasteTest.root}/${isPreview ? "preview-test" : "clarity"}`,
            placement: "left",
            title: "Contribute to Taste Test",
            content:
              'Here is where you contribute ADA to the Taste Test. Once you have contributed, you will see your current balance here. As soon as the Taste Test closes, contributions will no longer be accepted. Toggling "Your Contributions" provides an overview of your current Taste Test contribution.',
            spotlightPadding: 20,
            target: `[data-guide-selector="${joyrideSelectors.guides.tasteTest.interactiveGuide.transactionWidget}"]`,
            disableBeacon: true,
          },
        ],
      },
    }),
    [isWalletConnected, t],
  );
};
