import { EContractVersion } from "@sundaeswap/core";
import { Dispatch } from "react";

export enum EFarmsViewMode {
  list = "list",
  tiles = "tiles",
}

export enum EFarmsFilter {
  all = "all",
  user = "user",
  core = "core",
  community = "community",
}
export enum ECardanoExplorer {
  cardanoExplorer = "cardanoExplorer",
  cardanoscan = "cardanoscan",
  cexplorer = "cexplorer",
}
export enum ECardanoExplorerType {
  policy = "tokenPolicy",
  transaction = "transaction",
  asset = "asset",
}

export enum EProfileAppActions {
  /* -------------------------------------------------------------------------------------------------
   * App
   * -----------------------------------------------------------------------------------------------*/
  LOGOUT = "LOGOUT",
  SET_ERROR_REPORTING = "SET_ERROR_REPORTING",
  SET_HOTJAR_ENABLED = "SET_HOTJAR_ENABLED",
  SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE",
  SET_CARDANO_EXPLORER = "SET_CARDANO_EXPLORER",
  SET_PRIVACY_MODE = "SET_PRIVACY_MODE",
  SET_PROFILE_ENABLED = "SET_PROFILE_ENABLED",
  SYNC_DEFAULT_STATE = "SYNC_DEFAULT_STATE",
  SET_PREFERS_REDUCED_MOTION = "SET_PREFERS_REDUCED_MOTION",
  /* -------------------------------------------------------------------------------------------------
   * TX Settings
   * -----------------------------------------------------------------------------------------------*/
  SET_FEES_VIEW_MODE = "SET_FEES_VIEW_MODE",
  SET_IS_SLIPPAGE_TOLERANCE_SET_TO_AUTO = "SET_IS_SLIPPAGE_TOLERANCE_SET_TO_AUTO",
  SET_SLIPPAGE_TOLERANCE = "SET_SLIPPAGE_TOLERANCE",
  /* -------------------------------------------------------------------------------------------------
   * Limit Orders (temporarily used to track tx hashes in order to properly flag limit orders on the orders page - will be provided by the API in the future)
   * -----------------------------------------------------------------------------------------------*/
  ADD_LIMIT_ORDER = "ADD_LIMIT_ORDER",
  /* -------------------------------------------------------------------------------------------------
   * Farms
   * -----------------------------------------------------------------------------------------------*/
  SET_FARMS_FILTER = "SET_FARMS_FILTER",
  SET_FARMS_VIEW_MODE = "SET_FARMS_VIEW_MODE",

  /* -------------------------------------------------------------------------------------------------
   * Pool Filters
   * -----------------------------------------------------------------------------------------------*/
  SET_POOL_FILTERS = "SET_POOL_FILTERS",
  SET_FAVORITE_POOL = "SET_FAVORITE_POOL",
  UNSET_FAVORITE_POOL = "UNSET_FAVORITE_POOL",

  /* -------------------------------------------------------------------------------------------------
   * Pool Toggles
   * -----------------------------------------------------------------------------------------------*/
  SET_TOGGLES = "SET_TOGGLES",

  /* -------------------------------------------------------------------------------------------------
   * Personalization
   * -----------------------------------------------------------------------------------------------*/
  SET_PREFERRED_HANDLE = "SET_PREFERRED_HANDLE",
  SET_PREFERRED_AVATAR = "SET_PREFERRED_AVATAR",
}

export interface IProfileAppAction {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

interface IFilterField<T> {
  label?: string;
  value?: T;
}

export interface IProfileAppState {
  avatar: string | null;
  handle: string | null;
  activeLanguage: string;
  cardanoExplorer: ECardanoExplorer;
  errorReportingEnabled: boolean;
  farmsFilter: EFarmsFilter;
  farmsViewMode: EFarmsViewMode;
  favoritePools: string[];
  isSlippageToleranceSetToAuto: boolean;
  toggles: {
    pools?: {
      favorites: boolean;
    };
    orders?: {
      canceled: boolean;
    };
  };
  filters: {
    pools: {
      contractVersion?: IFilterField<EContractVersion>[];
      lpFee?: IFilterField<string>[];
    };
  };
  hotjarEnabled?: boolean;
  limitOrders: string[];
  prefersReducedMotion: boolean;
  privacyEnabled: boolean;
  slippageTolerance: number;
}

export interface IProfileAppContext {
  state: IProfileAppState;
  dispatch: Dispatch<IProfileAppAction>;
}
