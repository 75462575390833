import { ExternalLink, TARGET_BLANK } from "@sundaeswap/ui-toolkit";
import { t } from "i18next";
import { FC } from "react";
import { Trans } from "react-i18next";
import { externalRoutes } from "../../../../pages/routes";

const ErrorReportingTooltip: FC = () => (
  <Trans ns="error-fallback" i18nKey="message" context="autoSendTooltip">
    Error reporting is enabled inside your profile settings. This setting shares
    error reports that may include non-anonymized information (such as wallet
    address) with SundaeSwap Labs, Inc. Submitted information will be used to
    address the issue and improve related systems. You can find our privacy
    policy{" "}
    <ExternalLink
      href={externalRoutes.privacy}
      size="xs"
      title={t("header.buttons.quickMenu.policyLinkTitle")}
      variant="primary"
      {...TARGET_BLANK}
    >
      here
    </ExternalLink>
    .
  </Trans>
);

export default ErrorReportingTooltip;
