import { Dispatch } from "react";
import { ENewsActions, INewsAction } from "./types";

type TActionFunc<T> = (value: T, dispatch: Dispatch<INewsAction>) => void;

const setReadNewsItem: TActionFunc<string> = (newsRead, dispatch) =>
  dispatch({ type: ENewsActions.SET_READ_NEWS_ITEM, payload: { newsRead } });
const setUnreadNewsItem: TActionFunc<string> = (newsRead, dispatch) =>
  dispatch({ type: ENewsActions.SET_UNREAD_NEWS_ITEM, payload: { newsRead } });
const setReadAllNewsItems: TActionFunc<string[]> = (newsRead, dispatch) =>
  dispatch({
    type: ENewsActions.SET_READ_ALL_NEWS_ITEMS,
    payload: { newsRead },
  });
const setForceOpenedNewsId: TActionFunc<string> = (forceOpened, dispatch) =>
  dispatch({
    type: ENewsActions.SET_FORCE_OPENED_NEWS_ID,
    payload: { forceOpened },
  });

/* -------------------------------------------------------------------------------------------------
 * Export
 * -----------------------------------------------------------------------------------------------*/
export const NewsActions = {
  setReadNewsItem,
  setUnreadNewsItem,
  setReadAllNewsItems,
  setForceOpenedNewsId,
};
