import { colors } from "@sundaeswap/tailwind-config";
import { Text } from "@sundaeswap/ui-toolkit";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { transparentize } from "polished";
import { FC, memo } from "react";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
);

const errorColor = colors.error["DEFAULT"];

const TasteTestExplanationGuideStep3: FC = () => {
  const mockChartData = {
    labels: [
      "2023-01-01",
      "2023-01-02",
      "2023-01-03",
      "2023-01-04",
      "2023-01-05",
      "2023-01-06",
      "2023-01-07",
      "2023-01-08",
      "2023-01-09",
      "2023-01-10",
      "2023-01-11",
      "2023-01-12",
      "2023-01-13",
      "2023-01-14",
      "2023-01-15",
      "2023-01-16",
      "2023-01-17",
      "2023-01-18",
      "2023-01-19",
      "2023-01-20",
      "2023-01-21",
      "2023-01-22",
      "2023-01-23",
      "2023-01-24",
      "2023-01-25",
      "2023-01-26",
      "2023-01-27",
      "2023-01-28",
      "2023-01-29",
      "2023-01-30",
      "2023-01-31",
      "2023-02-01",
      "2023-02-02",
      "2023-02-03",
      "2023-02-04",
      "2023-02-05",
      "2023-02-06",
      "2023-02-07",
      "2023-02-08",
      "2023-02-09",
      "2023-02-10",
    ],
    datasets: [
      {
        label: "Token Price",
        data: [
          10, 100, 200, 300, 400, 500, 600, 700, 800, 300, 125, 145, 130, 160,
          120, 100, 75, 111, 120, 99, 128, 114, 112, 88, 75, 99, 121, 150, 162,
          148, 145, 140, 149, 150, 145, 152, 160, 148, 139, 142, 135,
        ],

        fill: true,
        backgroundColor: transparentize(0.7, errorColor),
        borderColor: errorColor,
      },
    ],
  };

  const mockChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: false },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  return (
    <div className="grid gap-4">
      <div className="grid gap-2">
        <Text tag="p" size="sm">
          This lack of clarity for all parties means
        </Text>
        <Text tag="ul" size="sm">
          <Text tag="li" size="sm">
            - The project may under-price the tokens they sell
          </Text>
          <Text tag="li" size="sm">
            - Users may get caught in a hype cycle
          </Text>
          <Text tag="li" size="sm">
            - Large token holders may be incentivized to sell into that hype
          </Text>
        </Text>
        <Text tag="p" size="sm">
          Overall, volatility will be extremely high and many people may be
          hurt.
        </Text>
        <Chart
          className="my-4"
          type="line"
          options={mockChartOptions}
          data={mockChartData}
        />
      </div>
    </div>
  );
};

export default memo(TasteTestExplanationGuideStep3);
