import { EventType } from "@sundaeswap/react-hooks";
import { toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import { useCallback, useRef } from "react";
import { useEventTracking } from "../../../hooks/useEventTracking";
import {
  IChapter,
  IGuide,
  useControlCenterContext,
} from "../../../stores/ControlCenterContext";
import { getEnv } from "../../../utils/network.utils";
import ControlCenterContentFooter from "../ControlCenterContentFooter";
import ControlCenterContentNode from "../ControlCenterContentNode";
import ControlCenterContentTitle from "../ControlCenterContentTitle";
import ControlCenterGuideItem from "../ControlCenterGuideItem";

const ControlCenterGuideList = () => {
  const guideItemListRef = useRef<HTMLElement>(null);
  const handleEventTracking = useEventTracking();
  const {
    actionHandler,
    state: {
      localState: {
        guides: { activeChapter },
      },
      persistentState: {
        guides: { guideInfo },
      },
    },
  } = useControlCenterContext();

  const handleBackButton = useCallback(() => {
    handleEventTracking({
      eventType: EventType.Click,
      source: "ControlCenterGuideList",
      value: "back",
      network: getEnv(),
    });
    actionHandler.guides.closeChapter();
  }, [actionHandler.guides, handleEventTracking]);
  const chapter = activeChapter ?? ({} as IChapter);
  const guides = activeChapter?.guides;

  const handleStartGuide = useCallback(
    (guide: IGuide) => {
      const persistentGuideInfo = guideInfo[guide.joyride.id] || {};
      const stepIndex = persistentGuideInfo?.completed
        ? 0
        : persistentGuideInfo?.stepIndex ?? 0;
      if (persistentGuideInfo?.completed) {
        actionHandler.guides.goToStep(guide.joyride.id, stepIndex);
      }
      actionHandler.guides.start(guide, stepIndex);
    },
    [actionHandler, guideInfo],
  );

  return (
    <>
      <ControlCenterContentTitle
        onBack={handleBackButton}
        title={chapter.title}
        description={chapter.description}
      />

      {!isEmpty(guides) && (
        <ControlCenterContentNode
          tag="ul"
          className={cx("grid gap-4", toolkitCx.layout.spacing.both.md)}
          ref={guideItemListRef}
        >
          {guides?.map((guide) => {
            const currentGuide = guideInfo[guide.joyride.id] || {};
            const isGuideCompleted = !!currentGuide?.completed;
            const stepIndex = currentGuide?.stepIndex ?? 0;
            const progressPercentage = isGuideCompleted
              ? 1
              : stepIndex / guide.joyride.steps.length;

            return (
              <ControlCenterGuideItem
                description={guide.description}
                guideId={guide.joyride.id}
                isCompleted={isGuideCompleted}
                key={guide.joyride.id}
                inViewRoot={guideItemListRef}
                onClick={() => handleStartGuide(guide)}
                percentage={progressPercentage}
                progressBarTranslateX={-100 + progressPercentage * 100}
                rank={guide.rank}
                title={guide.title}
                type="guide"
              />
            );
          })}
        </ControlCenterContentNode>
      )}

      <ControlCenterContentFooter title="Guided Tours by Sundae Labs." />
    </>
  );
};

export default ControlCenterGuideList;
