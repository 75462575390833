import { Text } from "@sundaeswap/ui-toolkit";
import { FC, memo } from "react";
import { Trans } from "react-i18next";

interface IErrorDetailsMessage {
  toggleCollapsible(): void;
}

const ErrorDetailsMessage: FC<IErrorDetailsMessage> = memo(
  ({ toggleCollapsible }) => {
    return (
      <Text tag="div">
        <Trans i18nKey="detailsToggle" ns="error-fallback">
          To see what data this error report contains,
          <Text
            className="cursor-pointer"
            onClick={toggleCollapsible}
            tag="span"
            variant="primary"
          >
            click here
          </Text>
          .
        </Trans>
      </Text>
    );
  },
);

export default ErrorDetailsMessage;
