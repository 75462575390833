import { Image, Text } from "@sundaeswap/ui-toolkit";
import { FC, memo } from "react";

const TasteTestExplanationGuideStep5: FC = () => {
  return (
    <div className="grid gap-4">
      <Text tag="p" size="sm">
        If the taste test price is higher than the true market value, then it is
        better to buy at equilibrium.
      </Text>
      <Image
        width={394}
        height={221}
        src="/static/images/guides/taste-test/slide_2.png"
      />
      <Text tag="p" size="sm">
        This deters participants from participating in the taste test, and may
        cause some to withdraw.
      </Text>
    </div>
  );
};

export default memo(TasteTestExplanationGuideStep5);
