import { joyrideIds } from "../../constants/joyride.constants";
import { useDefaultJoyrideCallbackHandler } from "./useDefaultJoyrideCallbackHandler";
import { useV3ComparisonGuideCallbackhandler } from "./v3/guides/useV3ComparisonGuideCallbackhandler";

export const useJoyrideCallbackHandler = (currentGuideId: string) => {
  const defaultCallbackHandler = useDefaultJoyrideCallbackHandler();
  const { v3ComparisonCallbackHandler } = useV3ComparisonGuideCallbackhandler();

  switch (currentGuideId) {
    case joyrideIds.v3.comparison: {
      return v3ComparisonCallbackHandler;
    }
    default: {
      return defaultCallbackHandler;
    }
  }
};
