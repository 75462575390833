import { FC } from "react";
import { joyrideSelectors } from "../../../../../../constants/joyride.constants";
import JoyrideInputStep from "../../../../../JoyrideComponents/JoyrideInputStep";

const TasteTestInteractiveGuideStep10: FC = () => {
  return (
    <JoyrideInputStep
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      guideSelectorId={
        joyrideSelectors.guides.tasteTest.interactiveGuide.contributedAmount
      }
      maxValue={100}
      title="Contribute up to 100 ADA"
    />
  );
};

export default TasteTestInteractiveGuideStep10;
