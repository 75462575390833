import { PrismicDocument } from "@prismicio/client";
import { useI18N } from "@sundaeswap/react-hooks";
import { Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, memo, useMemo } from "react";

import {
  INewsField,
  NewsActions,
  useNewsContext,
} from "../../stores/NewsContext";
import NoDataIndicator from "../NoDataIndicator";
import NewsItem from "./NewsItem";
import NoNewsPlaceholder from "./NoNewsPlaceholder";

interface INewsListProps {
  numberOfUnreadNews: number;
}

const LoadingSkeleton: FC = memo(() => {
  return (
    <div className="w-full">
      <div
        className={cx(
          "grid gap-1 border-t border-t-gray-300 motion-safe:animate-pulse dark:border-t-gray-600",
          toolkitCx.layout.spacing.right.md,
          toolkitCx.layout.spacing.vertical.md,
          toolkitCx.layout.spacing.left.xl,
          toolkitCx.layout.transition.colors,
          toolkitCx.layout.background.hoverable,
          toolkitCx.layout.background.default,
        )}
      >
        <div
          className={cx(
            "h-5 w-2/3 rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
        <div
          className={cx(
            "h-3 w-full rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
        <div
          className={cx(
            "h-3 w-full rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
        <div
          className={cx(
            "h-3 w-2/3 rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
        <div
          className={cx(
            "mt-2 h-3 w-1/3 rounded-lg",
            toolkitCx.layout.background.loading,
          )}
        />
      </div>
    </div>
  );
});

const NewsList: FC<INewsListProps> = ({ numberOfUnreadNews }) => {
  const { t } = useI18N("news");
  const {
    dispatch,
    state: {
      error,
      isNewsFetched,
      isFetchingNews,
      isRefetchingNews,
      popoverNewsItems,
    },
  } = useNewsContext();

  // List of all news ids to mark all as read at once.
  const allNewsIds = useMemo(
    () => popoverNewsItems?.map((newsItem) => newsItem.id),
    [popoverNewsItems],
  );

  return (
    <div className="grid w-full gap-4">
      <div
        className={cx(
          "flex items-center justify-between",
          toolkitCx.layout.spacing.horizontal.md,
          toolkitCx.layout.spacing.top.md,
        )}
      >
        <Text tag="h6" size="lg" weight="bold">
          {t("title")} ({popoverNewsItems?.length})
        </Text>

        {Boolean(popoverNewsItems?.length) &&
          !isFetchingNews &&
          !isRefetchingNews && (
            <>
              {numberOfUnreadNews > 0 ? (
                <Text
                  className="cursor-pointer motion-safe:animate-fadeIn"
                  hoverable
                  onClick={() =>
                    NewsActions.setReadAllNewsItems(allNewsIds, dispatch)
                  }
                  role="button"
                  size="xs"
                  tag="span"
                  variant="primary"
                >
                  {t("markAllAsRead")}
                </Text>
              ) : (
                <Text
                  className="motion-safe:animate-fadeIn"
                  tag="span"
                  size="xs"
                >
                  {t("allRead")}
                </Text>
              )}
            </>
          )}
      </div>

      <div className="w-full">
        {
          /* -------------------------------------------------------------------------------------------------
           * Error Case
           * -----------------------------------------------------------------------------------------------*/
          error ? <NoDataIndicator context="news" size="xs" /> : null
        }

        {
          /* -------------------------------------------------------------------------------------------------
           * Loading.
           * -----------------------------------------------------------------------------------------------*/
          (isFetchingNews || isRefetchingNews) &&
            Array.from({ length: popoverNewsItems?.length ?? 2 }).map(
              (_, index) => <LoadingSkeleton key={index} />,
            )
        }

        {
          /* -------------------------------------------------------------------------------------------------
           * Not loading.
           * No News available.
           * -----------------------------------------------------------------------------------------------*/
          isNewsFetched &&
            !isRefetchingNews &&
            popoverNewsItems.length === 0 && <NoNewsPlaceholder />
        }

        {
          /* -------------------------------------------------------------------------------------------------
           * Not loading.
           * News available.
           * -----------------------------------------------------------------------------------------------*/
          isNewsFetched &&
            !isRefetchingNews &&
            popoverNewsItems?.map((newsItem) => (
              <NewsItem
                {...(newsItem as PrismicDocument<INewsField>)}
                key={newsItem.id}
              />
            ))
        }
      </div>
    </div>
  );
};

export default NewsList;
