import { Dispatch } from "react";
import { Props as JoyrideProps, Step } from "react-joyride";
import { TSearchParams } from "../../pages/routes";

export enum ELocalControlCenterStateActions {
  LOAD_CHAPTERS = "LOAD_CHAPTERS",
  RUN_GUIDE = "RUN_GUIDE",
  SET_ACTIVE_CHAPTER = "SET_ACTIVE_CHAPTER",
  SET_ACTIVE_GUIDE = "SET_ACTIVE_GUIDE",
  SET_IS_NEXT_BUTTON_DISABLED = "SET_IS_NEXT_BUTTON_DISABLED",
  SET_LAST_CLOSED_GUIDE_ID = "SET_LAST_CLOSED_GUIDE_ID",
  SET_LAST_CLOSED_NEWS_ID = "SET_LAST_CLOSED_NEWS_ID",
  SET_WITH_ANIMATION_DELAY = "SET_WITH_ANIMATION_DELAY",
  SHOW_GUIDE_CLOSE_UI = "SHOW_GUIDE_CLOSE_UI",
  SHOW_GUIDES = "SHOW_GUIDES",
  SHOW_HELP_CENTER = "SHOW_HELP_CENTER",
  SHOW_INBOX = "SHOW_INBOX",
  SHOW_LOADING_ANIMATION_GUIDES = "SHOW_LOADING_ANIMATION_GUIDES",
  SHOW_SUCCESS_ANIMATION = "SHOW_SUCCESS_ANIMATION",
  SHOW_TOOLBAR = "SHOW_TOOLBAR",
}

export enum EPersistentControlCenterStateActions {
  CLOSE_GUIDE = "CLOSE_GUIDE",
  COMPLETE_GUIDE = "COMPLETE_GUIDE",
  HANDLE_STEP = "HANDLE_STEP",
  OPEN_GUIDES_ON_MOUNT = "OPEN_GUIDES_ON_MOUNT",
  SET_IS_EVERY_CHAPTER_COMPLETED = "SET_IS_EVERY_CHAPTER_COMPLETED",
  START_GUIDE = "START_GUIDE",
}

// Index based on the order of the chapters in the sidebar.
export enum EGuideChapter {
  EXCHANGE = "2",
  LIQUIDITY = "3",
  PORTFOLIO = "1",
  TASTE_TEST = "4",
  YIELD_FARMING = "5",
  V3 = "0",
}

export interface ILocalControlCenterStateAction {
  type: ELocalControlCenterStateActions;
  // Payload types are enforced in ./actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  // Optional dispatch key to make tracing easier.
  key?: string;
}

export interface IPersistentControlCenterStateAction {
  type: EPersistentControlCenterStateActions;
  // Payload types are enforced in ./actions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  // Optional dispatch key to make tracing easier.
  key?: string;
}

export interface IStepWithEntry extends Step {
  entryUrl?: string;
  entrySearchParams?: TSearchParams;
}

export interface IGuideMetadata {
  config?: Partial<Omit<JoyrideProps, "callback">>;
  id: string;
  steps: IStepWithEntry[];
}

export interface IGuide {
  rank: number;
  description?: string;
  chapter: EGuideChapter;
  joyride: IGuideMetadata;
  title: string;
}

export interface IChapter {
  description: string;
  guides: IGuide[];
  id: EGuideChapter;
  title: string;
}

export interface IPersistentGuide {
  completed: boolean;
  stepIndex: number;
}

export interface IPersistentControlCenterState {
  guides: {
    guideInfo: Record<
      string, // Joyride Guide ID
      IPersistentGuide
    >;
    currentGuideId: string | null;
    isEveryChapterCompleted: boolean;
    openGuidesOnMount: boolean;
  };
}

export interface ILocalControlCenterState {
  guides: {
    activeChapter: IChapter | undefined;
    activeGuideId: string | undefined;
    chapters: IChapter[];
    isNextButtonDisabled: boolean;
    lastClosedGuideItemId: string | undefined; // Used for scrolling to the last closed guide (chapter - if single chapter) if it's not in view.
    runGuide: boolean;
    showGuideCloseUI: boolean;
    showLoadingAnimation: boolean;
    showSuccessAnimation: boolean;
  };
  news: {
    lastClosedNewsId: string | undefined;
  };
  showGuides: boolean;
  showHelpCenter: boolean;
  showInbox: boolean;
  showToolbar: boolean;
  withAnimationDelay: boolean;
}

export interface IActionHandlers {
  controlCenter: {
    close: () => void;
    openGuide: () => void;
    openHelpCenter: () => void;
    openInbox: () => void;
    toggleOpen: () => void;
  };
  guides: {
    close: (
      guideId: string,
      completed: boolean,
      stepIndex: number,
      chapter?: IChapter,
    ) => void;
    closeChapter: () => void;
    continue: () => void;
    exit: (guideId: string, completed: boolean, stepIndex: number) => void;
    finish: (guideId: string, chapter?: IChapter) => void;
    goToStep: (guideId: string, nextStepIndex: number) => void;
    handleSuccessMessage: () => void;
    openChapter: (chapter: IChapter | undefined) => void;
    start: (guide: IGuide, stepIndex?: number) => void;
  };
}

export type TLocalDispatch = Dispatch<ILocalControlCenterStateAction>;
export type TPersistentDispatch = Dispatch<IPersistentControlCenterStateAction>;

export interface IControlCenterContext {
  actionHandler: IActionHandlers;
  state: {
    localState: ILocalControlCenterState;
    persistentState: IPersistentControlCenterState;
  };
  dispatchers: {
    localDispatch: Dispatch<ILocalControlCenterStateAction>;
    persistentDispatch: Dispatch<IPersistentControlCenterStateAction>;
  };
}
