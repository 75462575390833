import { AssetAmount } from "@sundaeswap/asset";
import { useI18N } from "@sundaeswap/react-hooks";
import { useWalletObserver } from "@sundaeswap/wallet-lite";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo } from "react";
import { useCustomCompareMemo } from "use-custom-compare";

import { getEarningsInADA } from "../../components/PortfolioComponents/PortfolioYieldFarmingCharts/EarningsStackedBarChart/utils";
import { ADA_METADATA } from "../../constants/cardano.constants";
import { joyrideSelectors } from "../../constants/joyride.constants";
import { useAssetMetadata } from "../../gql/hooks/assets.query";
import {
  useYieldFarmingEarningsQuery,
  useYieldFarmingProgramsQuery,
} from "../../gql/hooks/farms.query";
import { useWalletPositions } from "../../gql/hooks/positions.query";
import { PortfolioActions } from "../../stores/PortfolioContext/actions";
import { usePortfolioContext } from "../../stores/PortfolioContext/context";
import { IPortfolioBalanceValue } from "../../stores/PortfolioContext/types";
import { IAssetMetaData } from "../../types/Asset.types";
import { getEarnedFarmRewardsInADA } from "../../utils/pool.utils";
import {
  calculateEarnedFeesForPositions,
  getLiquidityPositionsValueInADA,
} from "../../utils/positions.utils";
import { calculateWalletBalanceInADA } from "../../utils/wallet.utils";
import { useAdaBalance } from "../useAdaBalance";
import { useAdaData } from "../useAdaData";
import { useAssetsWithBalance } from "../useAssetsWithBalance";

export const usePortfolioBalanceValues = () => {
  const { t } = useI18N("portfolio");
  const { mainAddress, stakeAddress } = useWalletObserver();
  const { data: adaData, isLoading: isLoadingAdaData } = useAdaData();
  const { adaBalanceFromWallet, loaded } = useAdaBalance();
  const { assetsWithoutLP, isLoaded: balanceLoaded } = useAssetsWithBalance();
  const {
    dispatch,
    state: { farms },
  } = usePortfolioContext();

  /* -------------------------------------------------------------------------------------------------
   * Fetch Liquidity Positions
   * -----------------------------------------------------------------------------------------------*/
  const { positions, isLoading: isLoadingWalletPositions } = useWalletPositions(
    {
      page: 0,
      pageSize: 20,
    },
  );

  /* -------------------------------------------------------------------------------------------------
   * Fetch Fees
   * -----------------------------------------------------------------------------------------------*/
  const allFarms = farms?.positions ?? [];

  /* -------------------------------------------------------------------------------------------------
   * Fetch Yield Farming v2 Data
   * -----------------------------------------------------------------------------------------------*/
  const { data: programs, isLoading: isLoadingYieldFarmingV2Programs } =
    useYieldFarmingProgramsQuery();
  const programEmittedAssetIds =
    programs.map((program) => program.emittedAsset) ?? [];
  const { data: emittedAssets, isLoading: isLoadingAssetsMetadata } =
    useAssetMetadata(programEmittedAssetIds.sort());
  const {
    data: yieldFarmingEarningsHistory,
    isLoading: isLoadingYieldFarmingV2Earnings,
  } = useYieldFarmingEarningsQuery([mainAddress, stakeAddress]);

  const earnedPositionsFees = useMemo(
    () =>
      new AssetAmount<IAssetMetaData>(
        calculateEarnedFeesForPositions(positions.stats, adaData?.usd ?? 0)
          ?.earnedFeesInLovelaces ?? 0n,
        ADA_METADATA,
      ),
    [adaData?.usd, positions],
  );
  const earnedFarmRewards = useMemo(
    () =>
      getEarnedFarmRewardsInADA(allFarms, adaData?.usd ?? 0) ??
      new AssetAmount<IAssetMetaData>(0n, ADA_METADATA),
    [allFarms, adaData?.usd],
  );
  const totalPositionsInAda = useMemo(
    () =>
      (
        (adaData &&
          getLiquidityPositionsValueInADA(positions.stats, adaData.usd)) ??
        new AssetAmount<IAssetMetaData>(0n, ADA_METADATA)
      ).minus(earnedPositionsFees),
    [positions, adaData?.usd, earnedPositionsFees],
  );
  const claimableEarningsInAda = useMemo(
    () =>
      getEarningsInADA({
        adaData,
        emittedAssets: emittedAssets?.stats,
        onlyClaimable: true,
        programs,
        yieldFarmingEarningsHistory,
      }),
    [emittedAssets, programs, yieldFarmingEarningsHistory, adaData],
  );

  const totalValueAssetsInAda = useMemo(
    () => calculateWalletBalanceInADA(assetsWithoutLP, adaData?.usd ?? 0),
    [assetsWithoutLP, adaData?.usd],
  );
  const totalAda = useMemo(() => {
    if (!adaBalanceFromWallet)
      return new AssetAmount<IAssetMetaData>(0n, ADA_METADATA);
    return adaBalanceFromWallet
      .add(totalValueAssetsInAda)
      .add(earnedPositionsFees)
      .add(totalPositionsInAda)
      .add(earnedFarmRewards)
      .add(claimableEarningsInAda);
  }, [
    adaBalanceFromWallet,
    claimableEarningsInAda,
    earnedFarmRewards,
    earnedPositionsFees,
    totalPositionsInAda,
    totalValueAssetsInAda,
  ]);

  const portfolioBalanceValues: IPortfolioBalanceValue[] = useCustomCompareMemo(
    () => [
      {
        id: "assets",
        joyrideSelector: joyrideSelectors.guides.portfolio.balanceValues.assets,
        title: t("portfolioBalanceValues.assets"),
        value: totalValueAssetsInAda.add(
          adaBalanceFromWallet ??
            new AssetAmount<IAssetMetaData>(0n, ADA_METADATA),
        ),
        usdValue:
          adaData &&
          adaBalanceFromWallet &&
          adaData.usd *
            totalValueAssetsInAda.add(adaBalanceFromWallet).value.toNumber(),
        tooltipLabel: t("portfolioBalanceValues.assets_tooltip"),
        percentage: totalValueAssetsInAda
          .add(
            adaBalanceFromWallet ??
              new AssetAmount<IAssetMetaData>(0n, ADA_METADATA),
          )
          .value.divide(totalAda.value)
          .toNumber(),
      },
      {
        id: "liquidity",
        joyrideSelector:
          joyrideSelectors.guides.portfolio.balanceValues.liquidity,
        title: t("portfolioBalanceValues.liquidity"),
        value: totalPositionsInAda,
        usdValue: (adaData?.usd ?? 0) * totalPositionsInAda.value.toNumber(),
        tooltipLabel: t("portfolioBalanceValues.liquidity_tooltip"),
        percentage: totalPositionsInAda.value.divide(totalAda.value).toNumber(),
      },

      {
        id: "fees",
        joyrideSelector: joyrideSelectors.guides.portfolio.balanceValues.fees,
        title: t("portfolioBalanceValues.earnings", { context: "fees" }),
        value: earnedPositionsFees,
        usdValue: (adaData?.usd ?? 0) * earnedPositionsFees.value.toNumber(),
        tooltipLabel: t("portfolioBalanceValues.earnings_feesTooltip"),
        percentage: earnedPositionsFees.value.divide(totalAda.value).toNumber(),
      },
      {
        id: "earnings",
        joyrideSelector: joyrideSelectors.guides.portfolio.balanceValues.yf,
        title: t("portfolioBalanceValues.earnings", { context: "farming" }),
        value: earnedFarmRewards.add(claimableEarningsInAda),
        usdValue:
          (adaData?.usd ?? 0) *
          earnedFarmRewards.add(claimableEarningsInAda).value.toNumber(),
        tooltipLabel: t("portfolioBalanceValues.earnings_farmingTooltip"),
        percentage: earnedFarmRewards
          .add(claimableEarningsInAda)
          .value.divide(totalAda.value)
          .toNumber(),
      },
    ],
    [
      adaBalanceFromWallet,
      adaData?.usd,
      claimableEarningsInAda,
      earnedFarmRewards,
      earnedPositionsFees,
      totalPositionsInAda,
      totalValueAssetsInAda,
    ],
    isEqual,
  );

  const isLoading = useMemo(
    () =>
      isLoadingAdaData ||
      isLoadingAssetsMetadata ||
      farms?.isFetchingFarms ||
      isLoadingWalletPositions ||
      isLoadingYieldFarmingV2Earnings ||
      isLoadingYieldFarmingV2Programs ||
      !loaded ||
      !balanceLoaded,
    [
      isLoadingAdaData,
      isLoadingAssetsMetadata,
      farms?.isFetchingFarms,
      isLoadingWalletPositions,
      isLoadingYieldFarmingV2Earnings,
      isLoadingYieldFarmingV2Programs,
      loaded,
      balanceLoaded,
    ],
  );

  useEffect(() => {
    PortfolioActions.derived.setIsLoadingPortfolioBalanceValues(
      isLoading,
      dispatch,
    );

    if (!isLoading) {
      PortfolioActions.derived.setPortfolioBalanceValues(
        portfolioBalanceValues,
        dispatch,
      );
      PortfolioActions.derived.setTotalPortfolioBalanceInAda(
        totalAda,
        dispatch,
      );
    }
  }, [dispatch, isLoading]);

  return {
    isLoading,
    portfolioBalanceValues,
    totalAda,
  };
};
