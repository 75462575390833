import Step1 from "./steps/Step1";
import Step1Title from "./titles/Step1Title";

export const ProvideLiquidityGuideSteps = {
  Step1,
};

export const ProvideLiquidityGuideTitles = {
  Step1Title,
};
