import { useI18N, useResponsive } from "@sundaeswap/react-hooks";
import {
  Loader,
  NumberI18N,
  Separator,
  Text,
  toolkitCx,
} from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, memo } from "react";
import { useAdaData } from "../../hooks/useAdaData";

const AdaPrice: FC = () => {
  const { t } = useI18N();
  const responsive = useResponsive();
  const { data: adaData, isLoading } = useAdaData();
  const { currentLocale } = useI18N("portfolio");

  if (!responsive.xl) {
    return null;
  }

  return (
    <>
      <div className="flex items-center gap-1">
        <Text tag="span" variant="muted" size="sm">
          ADA {t("price")}:
        </Text>

        {isLoading ? (
          <Loader className="ml-3 inline-flex" size="xsmall" />
        ) : (
          <NumberI18N
            locale={currentLocale}
            formatOpts={{ style: "currency", currency: "USD" }}
            size="sm"
            weight="bold"
            value={adaData?.usd ?? 0}
          />
        )}
      </div>

      <Separator
        orientation="vertical"
        className={cx("h-4 w-[1px]", toolkitCx.layout.background.separator)}
      />
    </>
  );
};

export default memo(AdaPrice);
