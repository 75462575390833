import { Image, Text } from "@sundaeswap/ui-toolkit";
import { FC, memo } from "react";

const TasteTestExplanationGuideStep7: FC = () => {
  return (
    <div className="grid gap-4">
      <Text tag="p" size="sm">
        To discourage last minute manipulation via withdrawals, people who
        withdraw in the last 24 hours forfeit 25% of their deposit.
      </Text>
      <Image
        width={394}
        height={221}
        src="/static/images/guides/taste-test/slide_4.png"
      />
    </div>
  );
};

export default memo(TasteTestExplanationGuideStep7);
