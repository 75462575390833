import { Text, toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import { FC, useCallback } from "react";

import { useWalletPositions } from "../../../../../../gql/hooks/positions.query";

const PortfolioLiquidityGuideStep2Title: FC = () => {
  const { positions, isLoading } = useWalletPositions({
    page: 0,
    pageSize: 50,
  });

  const renderMemoizedTextNode = useCallback(() => {
    if (isLoading) {
      return (
        <div
          className={cx(
            "h-14 w-full rounded-lg motion-safe:animate-pulse",
            toolkitCx.layout.background.loading,
          )}
        />
      );
    }

    if (!isLoading && isEmpty(positions)) {
      return (
        <Text className="text-center" tag="h1" size="2xl" weight="medium">
          No Positions found
        </Text>
      );
    }

    return (
      <Text className="text-center" tag="h1" size="2xl" weight="medium">
        Liquidity Overview
      </Text>
    );
  }, [positions, isLoading]);

  const renderMemoizedNode = useCallback(() => {
    return <div className="grid gap-4">{renderMemoizedTextNode()}</div>;
  }, [renderMemoizedTextNode]);

  return renderMemoizedNode();
};

export default PortfolioLiquidityGuideStep2Title;
