import { AssetAmount } from "@sundaeswap/asset";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { useEffect } from "react";
import { MIN_ADA_WALLET_BALANCE } from "../../../constants/SundaeSwap.constants";
import { ADA_METADATA } from "../../../constants/cardano.constants";
import { useAssetsWithBalance } from "../../../hooks/useAssetsWithBalance";
import { OrderActions } from "../actions";
import { useOrderContext } from "../context";
import { useGivenBalance } from "./useGivenBalance";

/**
 * Update derived state with data that is
 * dependant on balance information.
 */
export const useGivenExceedsBalance = () => {
  const { state, dispatch } = useOrderContext();
  const givenBalance = useGivenBalance();
  const { isLoaded: balanceLoaded } = useAssetsWithBalance();

  useEffect(() => {
    if (!balanceLoaded || !state.assets?.given?.metadata) {
      return;
    }

    if (!givenBalance || givenBalance?.amount === 0n) {
      OrderActions.setGivenExceedsBalance(
        true,
        dispatch,
        "OrderContext.useGivenExceedsBalance.first",
      );
      return;
    }

    const subtractedAmount = SundaeUtils.isAssetIdsEqual(
      state.assets.given.metadata.assetId,
      ADA_METADATA.assetId,
    )
      ? MIN_ADA_WALLET_BALANCE
      : new AssetAmount(0n, state.assets.given.metadata);

    const balanceIsLessThanGiven =
      givenBalance.subtract(subtractedAmount).amount <
      state.assets.given.amount;

    OrderActions.setGivenExceedsBalance(
      balanceIsLessThanGiven,
      dispatch,
      "OrderContext.useGivenExceedsBalance.second",
    );
  }, [balanceLoaded, givenBalance?.amount, state.assets?.given?.amount]);
};
