import isEqual from "lodash/isEqual";
import { useEffect, useMemo } from "react";
import {
  useCustomCompareEffect,
  useCustomCompareMemo,
} from "use-custom-compare";
import { usePoolsByRoute } from "../../../gql/hooks/pools.query";
import useAppSearch from "../../../hooks/router/useAppSearch";
import { getBestCandidate } from "../../../utils/pool.utils";
import { OrderActions } from "../actions";
import { useOrderContext } from "../context";

export const useActivePool = () => {
  const { state, dispatch } = useOrderContext();
  const { routeIdent } = useAppSearch();
  const activeGiven = state.assets?.given?.metadata?.assetId;
  const activeTaken = state.assets?.taken?.metadata?.assetId;

  const { data: poolsByRouteData, isFetching } = usePoolsByRoute({
    given: activeGiven,
    taken: activeTaken,
  });

  const bestOrderRoute = useCustomCompareMemo(
    () => {
      if (!activeGiven || !activeTaken || !poolsByRouteData) {
        return;
      }

      const candidate = getBestCandidate(
        poolsByRouteData.stats,
        activeGiven,
        activeTaken,
        state.assets?.given?.amount,
      );

      return candidate;
    },
    [
      activeGiven,
      activeTaken,
      poolsByRouteData || {},
      state.assets?.given?.amount,
    ],
    isEqual,
  );

  const chosenRoute = useMemo(() => {
    if (!routeIdent) return;
    return poolsByRouteData?.stats.find(
      (route) =>
        route.map((pool) => pool.ident).join("-") === String(routeIdent),
    );
  }, [routeIdent, poolsByRouteData?.stats]);

  const orderRoute = chosenRoute ?? bestOrderRoute;

  useEffect(() => {
    if (!state.flowData.syncPool) {
      return;
    }

    OrderActions.setFetchingPool(
      isFetching,
      dispatch,
      "OrderContext.useActivePool.isLoading",
    );
  }, [isFetching, state.flowData.syncPool]);

  useCustomCompareEffect(
    () => {
      if (isFetching || !state.flowData.syncPool) {
        return;
      }

      if (!orderRoute) {
        OrderActions.unsetOrderRoute(
          dispatch,
          "OrderContext.useActivePool.noOrderRoute",
        );
      } else {
        OrderActions.setOrderRoute(
          orderRoute,
          dispatch,
          "OrderContext.useActivePool.firstOrderRoute",
        );
        OrderActions.setGivenAmount(
          state.assets?.given,
          dispatch,
          "OrderPoolPicker.onClick.amount",
        );
      }
    },
    [
      activeGiven,
      activeTaken,
      dispatch,
      isFetching,
      orderRoute,
      state.flowData.syncPool,
    ],
    isEqual,
  );
};
