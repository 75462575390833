import { useI18N } from "@sundaeswap/react-hooks";
import { Image, Text } from "@sundaeswap/ui-toolkit";
import { FC } from "react";

const V3ComparisonStep6: FC = () => {
  const { t } = useI18N("joyride");

  return (
    <div className="flex flex-col">
      <Image
        className="mx-auto -mt-4 h-44"
        src="/static/images/v3/faster_transactions_v3.png"
      />
      <Text tag="p" size="sm" className="mb-4">
        {t("v3.guides.comparison.steps.step6.description")}
      </Text>
    </div>
  );
};

export default V3ComparisonStep6;
