import { Text } from "@sundaeswap/ui-toolkit";
import { FC, memo, useCallback } from "react";

const TasteTestInteractiveGuideStep1Title: FC = () => {
  const renderMemoizedNode = useCallback(() => {
    return (
      <>
        <div
          className="absolute left-0 top-0 z-[-1] h-full w-full bg-contain bg-no-repeat opacity-10"
          style={{
            backgroundImage: `url("/static/images/discover/taste_test_jumbotron.png")`,
          }}
        />
        <div className="grid gap-4">
          <Text className="text-center" tag="h1" size="3xl" weight="semibold">
            Welcome to the{" "}
            <Text
              tag="span"
              className="inline"
              size="3xl"
              weight="semibold"
              gradient
            >
              Taste Test
            </Text>{" "}
            – A New Frontier in Price Discovery!
          </Text>
        </div>
      </>
    );
  }, []);

  return renderMemoizedNode();
};

export default memo(TasteTestInteractiveGuideStep1Title);
