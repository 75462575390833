import { useEffect, useState } from "react";
import { testIds } from "../constants/testIds.constants";
import { useNewsContext } from "../stores/NewsContext";

export const useNewsBannerHeight = () => {
  const [newsBannerHeight, setNewsBannerHeight] = useState<number>(0);
  const {
    state: { latestBannerNewsItem },
  } = useNewsContext();

  useEffect(() => {
    const height = document
      .getElementById(testIds.newsBanner)
      ?.getBoundingClientRect()?.height;
    if (height) setNewsBannerHeight(height);
  }, [latestBannerNewsItem]);

  return { newsBannerHeight };
};
