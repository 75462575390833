import { XMarkIcon } from "@heroicons/react/24/outline";
import { useI18N } from "@sundaeswap/react-hooks";
import {
  ConditionalWrapper,
  ExternalLink,
  Text,
  Tooltip,
  toolkitCx,
} from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, useMemo } from "react";
import { testIds } from "../../constants/testIds.constants";
import { NewsActions, useNewsContext } from "../../stores/NewsContext";
import { newDateWithoutTime } from "../../utils/dates.utils";
import { prismicLinkResolver } from "../../utils/prismic.utils";

const NewsBanner: FC = () => {
  const { t } = useI18N();
  const {
    dispatch,
    state: { latestBannerNewsItem },
  } = useNewsContext();
  const bannerValidFrom = useMemo(() => {
    if (latestBannerNewsItem?.data.banner_valid_from) {
      return new Date(latestBannerNewsItem.data.banner_valid_from);
    }
  }, [latestBannerNewsItem]);
  const bannerValidUntil = useMemo(() => {
    if (latestBannerNewsItem?.data.banner_valid_until) {
      return new Date(latestBannerNewsItem.data.banner_valid_until);
    }
  }, [latestBannerNewsItem]);
  const today = newDateWithoutTime();
  const isBannerValid = useMemo(() => {
    // If there is a valid banner but no valid from or until date, then it is always valid
    if (latestBannerNewsItem && !bannerValidFrom && !bannerValidUntil) {
      return true;
    }

    // If there is a valid banner and both a valid from and until date, then check if today is between the valid from and until dates
    if (latestBannerNewsItem && bannerValidFrom && bannerValidUntil) {
      return today >= bannerValidFrom && today <= bannerValidUntil;
    }

    // If there is a valid banner and a valid from date, then check if today is after the valid from date
    if (latestBannerNewsItem && bannerValidFrom) {
      return today >= bannerValidFrom;
    }

    // If there is a valid banner and a valid until date, then check if today is before the valid until date
    if (latestBannerNewsItem && bannerValidUntil) {
      return today <= bannerValidUntil;
    }

    return false;
  }, [bannerValidFrom, bannerValidUntil, latestBannerNewsItem]);

  if (isBannerValid) {
    return (
      <div
        className={cx(
          "fixed left-0 top-0 z-50 w-full",
          toolkitCx.layout.background.gradient,
        )}
        id={testIds.newsBanner}
        role="alert"
        data-testid={testIds.newsBanner}
        style={{
          backgroundColor:
            latestBannerNewsItem?.data.banner_bg_color ?? "transparent",
        }}
      >
        <div className="mx-auto flex items-center justify-between gap-3 px-4 py-1 xl:justify-center xl:px-6">
          <ConditionalWrapper
            condition={
              latestBannerNewsItem?.data.banner_backlink?.link_type !== "Any"
            }
            wrapper={(children) => (
              <ExternalLink
                arrow="top-right"
                className="max-w-[90%] flex-shrink-0 transition-opacity duration-500 hover:opacity-70"
                href={
                  latestBannerNewsItem?.data.banner_backlink &&
                  prismicLinkResolver(latestBannerNewsItem.data.banner_backlink)
                }
                size="xs"
                variant="forceLight"
              >
                {children}
              </ExternalLink>
            )}
          >
            <div>
              {latestBannerNewsItem?.data.title && (
                <Text size="xs" tag="span" weight="bold" variant="forceLight">
                  {latestBannerNewsItem.data.title}
                </Text>
              )}{" "}
              {latestBannerNewsItem?.data.desc && (
                <Text size="xs" tag="span" variant="forceLight">
                  {latestBannerNewsItem.data.desc}
                </Text>
              )}
            </div>
          </ConditionalWrapper>

          <Tooltip
            trigger={
              <XMarkIcon
                className="h-3 w-3 flex-shrink-0 cursor-pointer text-white"
                onClick={() =>
                  latestBannerNewsItem &&
                  NewsActions.setReadNewsItem(latestBannerNewsItem.id, dispatch)
                }
              />
            }
            tooltipLabel={t("close")}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default NewsBanner;
