import { AssetAmount } from "@sundaeswap/asset";
import { TableV2Types } from "@sundaeswap/ui-toolkit";
import { Dispatch } from "react";
import { FreezerItem } from "../../gql/generated/stats2.sdk";
import { IPendingOrder } from "../../gql/hooks/operations.query";
import { IAssetMetaData } from "../../types/Asset.types";
import { EOpsStatus, IOperation } from "../../types/Operation.types";
import {
  EPortfolioActions,
  IFormattedOrder,
  IOrdersFilterByOpsStatusItem,
  IPortfolioAction,
  IPortfolioBalanceValue,
} from "./types";

type TActionFunc<T> = (
  value: T,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => void;

/* -------------------------------------------------------------------------------------------------
 * DERIVED
 * -----------------------------------------------------------------------------------------------*/
const setActiveTabTitle: TActionFunc<string> = (
  activeTabTitle,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_ACTIVE_TAB_TITLE,
    payload: { activeTabTitle },
  });
};
const setIsSidebarCollapsed: TActionFunc<boolean> = (
  isSidebarCollapsed,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_IS_SIDEBAR_COLLAPSED,
    payload: { isSidebarCollapsed },
  });
};
const setPortfolioBalanceValues: TActionFunc<IPortfolioBalanceValue[]> = (
  portfolioBalanceValues,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_PORTFOLIO_BALANCE_VALUES,
    payload: { portfolioBalanceValues },
  });
};
const setTotalPortfolioBalanceInAda = (
  totalPortfolioBalanceInAda: AssetAmount<IAssetMetaData>,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_TOTAL_PORTFOLIO_BALANCE_IN_ADA,
    payload: { totalPortfolioBalanceInAda },
  });
};

const setIsLoadingPortfolioBalanceValues = (
  isLoadingPortfolioBalanceValues: boolean,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_IS_LOADING_PORTFOLIO_BALANCE_VALUES,
    payload: { isLoadingPortfolioBalanceValues },
  });
};

/* -------------------------------------------------------------------------------------------------
 * ASSETS
 * -----------------------------------------------------------------------------------------------*/
const setSelectedAsset: TActionFunc<IAssetMetaData> = (
  selectedAsset,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_SELECTED_ASSET,
    payload: { selectedAsset },
  });
};

/* -------------------------------------------------------------------------------------------------
 * FARMS
 * -----------------------------------------------------------------------------------------------*/
const setIsFetchedFarms: TActionFunc<boolean> = (
  isFetchedFarms,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_IS_FETCHED_FARMS,
    payload: { isFetchedFarms },
  });
};

const setIsFetchingFarms: TActionFunc<boolean> = (
  isFetchingFarms,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_IS_FETCHING_FARMS,
    payload: { isFetchingFarms },
  });
};

const setFarmPositions: TActionFunc<FreezerItem[]> = (
  positions,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_FARM_POSITIONS,
    payload: { positions },
  });
};

const setFarmsHistory: TActionFunc<FreezerItem[]> = (
  history,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_FARMS_HISTORY,
    payload: { history },
  });
};

/* -------------------------------------------------------------------------------------------------
 * OPS
 * -----------------------------------------------------------------------------------------------*/
const setAllOrders = (
  allOrders: (IOperation | IPendingOrder | FreezerItem)[],
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_ALL_ORDERS,
    payload: { allOrders },
  });
};

const setFormattedOrders = (
  formattedOrders: IFormattedOrder[],
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_FORMATTED_ORDERS,
    payload: { formattedOrders },
  });
};

const setOpsOpen = (
  opsOpen: IOperation[],
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({ key, type: EPortfolioActions.SET_OPS_OPEN, payload: { opsOpen } });
};

const setOpsHistory = (
  opsHistory: IOperation[],
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_OPS_HISTORY,
    payload: { opsHistory },
  });
};

const setIsFetchedOps = (
  isFetchedOps: boolean,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_IS_FETCHED_OPS,
    payload: { isFetchedOps },
  });
};

const setIsFetchingOps = (
  isFetchingOps: boolean,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_IS_FETCHING_OPS,
    payload: { isFetchingOps },
  });
};

const setIsRefetchingOps = (
  isRefetchingOps: boolean,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_IS_REFETCHING_OPS,
    payload: { isRefetchingOps },
  });
};

const setOrdersSearchValue = (
  searchValue: string,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_ORDERS_SEARCH_VALUE,
    payload: { searchValue },
  });
};

const setOrderStatusFilterItems = (
  items: IOrdersFilterByOpsStatusItem[],
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_ORDER_STATUS_FILTER_ITEMS,
    payload: { items },
  });
};

const setOrderStatusFilterValue = (
  value: EOpsStatus,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_ORDER_STATUS_FILTER_VALUE,
    payload: { value },
  });
};

const setOrdersTableColumnWidths = (
  value: string,
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_ORDERS_TABLE_COLUMN_WIDTHS,
    payload: { value },
  });
};

const setOrdersTableHeaders = (
  value: TableV2Types.THeaders[],
  dispatch: Dispatch<IPortfolioAction>,
  key?: string,
) => {
  dispatch({
    key,
    type: EPortfolioActions.SET_ORDERS_TABLE_HEADERS,
    payload: { value },
  });
};

export const PortfolioActions = {
  derived: {
    setActiveTabTitle,
    setIsSidebarCollapsed,
    setPortfolioBalanceValues,
    setTotalPortfolioBalanceInAda,
    setIsLoadingPortfolioBalanceValues,
  },
  assets: {
    setSelectedAsset,
  },
  farms: {
    setIsFetchedFarms,
    setIsFetchingFarms,
    setFarmPositions,
    setFarmsHistory,
  },
  ops: {
    setAllOrders,
    setFormattedOrders,
    setOpsOpen,
    setOpsHistory,
    setIsFetchedOps,
    setIsFetchingOps,
    setIsRefetchingOps,
    setOrdersSearchValue,
    setOrderStatusFilterItems,
    setOrderStatusFilterValue,
    setOrdersTableColumnWidths,
    setOrdersTableHeaders,
  },
};
