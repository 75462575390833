import { useI18N } from "@sundaeswap/react-hooks";
import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import {
  EGuideChapter,
  IChapter,
} from "../../../../stores/ControlCenterContext";
import { useLiquidityGuideJoyride } from "../guides/useLiquidityGuideJoyride";

export const useLiquidityJoyrideChapter = (): IChapter => {
  const { t } = useI18N("joyride");
  const liquidityJoyride = useLiquidityGuideJoyride();
  return useMemo(() => {
    return {
      id: EGuideChapter.LIQUIDITY,
      title: t("liquidity.title"),
      description: t("liquidity.description"),
      guides: sortBy([liquidityJoyride], "rank", "asc"),
    };
  }, [t]);
};
