import { ExternalLink, Text } from "@sundaeswap/ui-toolkit";
import { FC, memo } from "react";
import { Trans } from "react-i18next";

import { MIN_ADA_WALLET_BALANCE } from "../../../../constants/SundaeSwap.constants";
import { externalRoutes } from "../../../../pages/routes";

const AdaBalanceLessThanMinimumTooltip: FC = () => {
  const minAmount = MIN_ADA_WALLET_BALANCE.value.toString();
  return (
    <Text tag="span" size="xs" variant="muted">
      <Trans ns="wallet" i18nKey="balance.ada" context="lessThanMinimum">
        You currently don't have any available ADA in your wallet. Please add
        more than {{ minAmount }} ADA to your wallet if you want to perform
        trades on SundaeSwap. Read more{" "}
        <ExternalLink
          // prevents Popovers or other elements to open if the link is clicked and the tooltip is rendered inside the component
          onClick={(e) => e.stopPropagation()}
          href={externalRoutes.feesHelpArticle}
          arrow="top-right"
          variant="primary"
          size="xs"
        >
          here
        </ExternalLink>
      </Trans>
    </Text>
  );
};

export default memo(AdaBalanceLessThanMinimumTooltip);
