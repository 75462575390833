import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

export interface ITimeResponse {
  now: string;
}

export const useServerTime = (): UseQueryResult<ITimeResponse> => {
  return useQuery({
    queryKey: ["time"],
    queryFn: async () => {
      const { data } = await axios.get<ITimeResponse>(
        window.__APP_CONFIG.apiUrls.time,
      );
      return data;
    },
    staleTime: 1000 * 60,
    gcTime: 1000 * 60,
  });
};
