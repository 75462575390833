import { AssetAmount, AssetRatio } from "@sundaeswap/asset";
import { Dispatch } from "react";

import { EContractVersion } from "@sundaeswap/core";
import { IAssetMetaData } from "../../types/Asset.types";
import { TPool } from "../../types/Pool.types";
import {
  EOrderActions,
  EOrderFlowState,
  EOrderTransactionBuilderState,
  EOrderType,
  IOrderAction,
  IOrderFlowConsent,
  IOrderStateTransaction,
} from "./types";

type TActionFunc<T> = (
  value: T,
  dispatch: Dispatch<IOrderAction>,
  key?: string,
) => void;

const setAdaAfterSwap: TActionFunc<AssetAmount> = (
  adaAfterSwap,
  dispatch,
  key,
) =>
  dispatch({
    key,
    type: EOrderActions.SET_ADA_AFTER_SWAP,
    payload: { adaAfterSwap },
  });

const setGivenAmount: TActionFunc<AssetAmount<IAssetMetaData> | undefined> = (
  given,
  dispatch,
  key,
) =>
  dispatch({ key, type: EOrderActions.SET_GIVEN_AMOUNT, payload: { given } });

const setGivenUtxoAmount: TActionFunc<
  AssetAmount<IAssetMetaData> | undefined
> = (givenUtxoAmount, dispatch, key) =>
  dispatch({
    key,
    type: EOrderActions.SET_GIVEN_UTXO_AMOUNT,
    payload: { givenUtxoAmount },
  });

const setTakenAmount: TActionFunc<AssetAmount<IAssetMetaData> | undefined> = (
  taken,
  dispatch,
  key,
) =>
  dispatch({ key, type: EOrderActions.SET_TAKEN_AMOUNT, payload: { taken } });

const setRatio: TActionFunc<AssetRatio<IAssetMetaData> | undefined> = (
  ratio,
  dispatch,
  key,
) => dispatch({ key, type: EOrderActions.SET_RATIO, payload: { ratio } });

const setOrderRoute: TActionFunc<TPool[] | undefined> = (
  orderRoute,
  dispatch,
  key,
) => {
  dispatch({
    key,
    type: EOrderActions.SET_ORDER_ROUTE,
    payload: { orderRoute },
  });
};

const unsetOrderRoute = (dispatch: Dispatch<IOrderAction>, key?: string) =>
  dispatch({ key, type: EOrderActions.UNSET_ORDER_ROUTE });

const setGivenExceedsBalance: TActionFunc<boolean> = (
  givenExceedsBalance,
  dispatch,
  key,
) =>
  dispatch({
    key,
    type: EOrderActions.SET_GIVEN_EXCEEDS_BALANCE,
    payload: { givenExceedsBalance },
  });

const setOrderFlowState: TActionFunc<EOrderFlowState> = (
  flowState,
  dispatch,
  key,
) =>
  dispatch({
    key,
    type: EOrderActions.SET_ORDER_FLOW_STATE,
    payload: { flowState },
  });

const setOrderConsent: TActionFunc<Partial<IOrderFlowConsent>> = (
  orderConsent,
  dispatch,
  key,
) =>
  dispatch({
    key,
    type: EOrderActions.SET_ORDER_CONSENT,
    payload: { orderConsent },
  });

const swapOrderDirection = (dispatch: Dispatch<IOrderAction>, key?: string) =>
  dispatch({ key, type: EOrderActions.SWAP_ORDER_DIRECTION });

const setFetchingPool: TActionFunc<Partial<boolean>> = (
  fetchingPool,
  dispatch: Dispatch<IOrderAction>,
  key,
) =>
  dispatch({
    key,
    type: EOrderActions.SET_FETCHING_POOL,
    payload: { fetchingPool },
  });

const setTransaction: TActionFunc<Partial<IOrderStateTransaction>> = (
  transaction,
  dispatch,
  key,
) =>
  dispatch({
    key,
    type: EOrderActions.SET_TRANSACTION,
    payload: { transaction },
  });

const setTransactionState: TActionFunc<EOrderTransactionBuilderState> = (
  transactionState,
  dispatch,
  key,
) =>
  dispatch({
    key,
    type: EOrderActions.SET_TRANSACTION_STATE,
    payload: { transactionState },
  });

const resetOrderFlow = (dispatch: Dispatch<IOrderAction>, key?: string) =>
  dispatch({ key, type: EOrderActions.RESET_ORDER_FLOW });

const setOrderType: TActionFunc<EOrderType> = (orderType, dispatch, key) =>
  dispatch({ key, type: EOrderActions.SET_ORDER_TYPE, payload: { orderType } });

const setContractVersion: TActionFunc<EContractVersion> = (
  contractVersion,
  dispatch,
  key,
) =>
  dispatch({
    key,
    type: EOrderActions.SET_PROTOCOL_VERSION,
    payload: { contractVersion },
  });

export const OrderActions = {
  swapOrderDirection,
  resetOrderFlow,
  setAdaAfterSwap,
  setGivenAmount,
  setGivenUtxoAmount,
  setGivenExceedsBalance,
  setOrderConsent,
  setOrderFlowState,
  setOrderType,
  setRatio,
  setTakenAmount,
  setTransaction,
  setTransactionState,
  setFetchingPool,
  setContractVersion,
  setOrderRoute,
  unsetOrderRoute,
};
