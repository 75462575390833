import { toolkitCx } from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { AnimatePresence } from "framer-motion";
import { FC, PropsWithChildren } from "react";
import { getControlCenterContentContainerMotion } from "../../../motions/control-center.motions";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import Motion from "../../Motion";

const ControlCenterContentContainer: FC<PropsWithChildren> = ({ children }) => {
  const {
    state: {
      localState: { withAnimationDelay },
    },
  } = useControlCenterContext();

  return (
    <Motion
      animationKey="ControlCenterContentContainer"
      variants={getControlCenterContentContainerMotion(withAnimationDelay)}
      className={cx(
        "absolute bottom-[78px] right-0",
        "flex flex-col rounded-lg",
        "overflow-hidden",
        "w-[350px] xl:w-[400px]",
        "h-[550px] xl:h-[600px]",
        "max-h-[75vh]",
        toolkitCx.layout.border.default,
        toolkitCx.layout.border.weight.default,
        toolkitCx.layout.shadow.default,
        toolkitCx.layout.spacing.vertical.md,
        toolkitCx.recipes.blurryCorner.primary,
        toolkitCx.layout.background.default,
      )}
    >
      <AnimatePresence key="ControlCenterContentContainer--children">
        <div className={cx("flex h-full flex-col")}>{children}</div>
      </AnimatePresence>
    </Motion>
  );
};

export default ControlCenterContentContainer;
