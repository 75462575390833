import { InboxIcon } from "@heroicons/react/24/outline";
import { useI18N } from "@sundaeswap/react-hooks";
import {
  HeaderButton,
  Popover,
  ScrollArea,
  Text,
  Tooltip,
} from "@sundaeswap/ui-toolkit";
import cx from "classnames";
import { FC, lazy, useMemo } from "react";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";
import { NewsActions, useNewsContext } from "../../stores/NewsContext";
import NewsList from "./NewsList";

const LazyActiveNewsDialog = lazy(
  () =>
    import(
      /* webpackChunkName: "ActiveNewsDialog" */ "../Dialogs/ActiveNewsDialog"
    ),
);

const NotificationsPopover: FC = () => {
  const { t } = useI18N("news");
  const {
    dispatch,
    state: { isPopoverOpen, newsRead, popoverNewsItems, setIsPopoverOpen },
  } = useNewsContext();
  const { headerHeight } = useHeaderHeight();

  const numberOfUnreadNews = useMemo(() => {
    const totalLength = popoverNewsItems?.filter(
      (newsItem) => !newsRead.includes(newsItem.id),
    )?.length;
    return {
      display: totalLength > 9 ? "9+" : totalLength,
      value: totalLength,
    };
  }, [popoverNewsItems, newsRead]);

  return (
    <>
      <Popover
        className="flex justify-center"
        withCloseIcon={false}
        primitiveProps={{
          trigger: { className: "group", asChild: true },
          content: {
            className: cx(
              "!w-[21rem] rounded-lg !min-w-0 overflow-x-hidden overflow-y-scroll",
            ),
            style: {
              maxHeight: `calc(100vh - ${headerHeight}px)`,
            },
          },
        }}
        onOpenChange={(open) => {
          if (!open) {
            NewsActions.setReadAllNewsItems(
              popoverNewsItems?.map((newsItem) => newsItem.id),
              dispatch,
            );
          }
          setIsPopoverOpen(open);
        }}
        open={isPopoverOpen}
        trigger={
          <div>
            <Tooltip
              trigger={
                <HeaderButton className="relative">
                  <>
                    <InboxIcon className="h-4 w-4" />

                    {numberOfUnreadNews.value > 0 && (
                      <div
                        className={cx(
                          "absolute -right-1 -top-1 motion-safe:animate-scaleIn",
                          "flex items-center justify-center",
                          "h-4 w-4 rounded-full",
                          "bg-error dark:bg-error",
                        )}
                      >
                        <Text size="2xs" tag="span" variant="forceLight">
                          {numberOfUnreadNews.display}
                        </Text>
                      </div>
                    )}
                  </>
                </HeaderButton>
              }
              tooltipLabel={t("title")}
            />
          </div>
        }
      >
        <ScrollArea
          primitiveProps={{ viewport: { className: "max-h-[500px]" } }}
        >
          <NewsList numberOfUnreadNews={numberOfUnreadNews.value} />
        </ScrollArea>
      </Popover>

      <LazyActiveNewsDialog />
    </>
  );
};

export default NotificationsPopover;
