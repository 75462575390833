import LiquidityStep1 from "./liquidity/steps/Step1";
import LiquidityStep2 from "./liquidity/steps/Step2";
import LiquidityStep1Title from "./liquidity/titles/Step1Title";
import LiquidityStep2Title from "./liquidity/titles/Step2Title";

export const Portfolio = {
  Liquidity: {
    Steps: { LiquidityStep1, LiquidityStep2 },
    Titles: { LiquidityStep1Title, LiquidityStep2Title },
  },
};
