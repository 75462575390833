export const unixToSlot = (
  unix: number | string,
  network: number = 0,
): number =>
  Math.trunc(Number(unix)) -
  (network ? 1591566291 : window.__APP_CONFIG.slotOffset);

export const slotToUnix = (
  unix: number | string,
  network: number = 0,
): number =>
  Math.trunc(Number(unix)) +
  (network ? 1591566291 : window.__APP_CONFIG.slotOffset);
