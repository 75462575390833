import { Text } from "@sundaeswap/ui-toolkit";
import { useCallback } from "react";
import { joyrideIds } from "../../../constants/joyride.constants";
import { useControlCenterContext } from "../../../stores/ControlCenterContext";
import ConnectWalletButton from "../../ConnectWalletButton";

const DisconnectWalletContent = () => {
  const { actionHandler } = useControlCenterContext();

  const handleExitGuide = useCallback(
    () =>
      actionHandler.guides.exit(
        joyrideIds.tasteTest.interactiveGuide,
        false,
        0,
      ),
    [],
  );
  return (
    <div className="grid gap-4 text-center">
      <Text tag="p" size="sm">
        This Guide requires a Wallet Connection. Please establish a Wallet
        Connection and then resume to this guide.
      </Text>

      <ConnectWalletButton
        className="mx-auto w-fit"
        onClick={handleExitGuide}
      />
    </div>
  );
};

export default DisconnectWalletContent;
