import { useI18N } from "@sundaeswap/react-hooks";
import { ErrorFallback as ErrorFallbackBase } from "@sundaeswap/ui-toolkit";
import {
  TSupportedWalletExtensions,
  useWalletObserver,
} from "@sundaeswap/wallet-lite";
import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import { ERRORS_API_URL } from "../../constants/api.constants";
import ErrorDetailsMessage from "../../i18n/components/error/ErrorDetailsMessage";
import ErrorFallbackMessage from "../../i18n/components/error/ErrorFallbackMessage";
import ErrorFallbackMessageAutoSend from "../../i18n/components/error/ErrorFallbackMessage/ErrorFallbackMessageAutoSend.trans";
import { useProfileAppContext } from "../../stores";

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useI18N("error-fallback");
  const {
    state: { errorReportingEnabled },
  } = useProfileAppContext();
  const { mainAddress, activeWallet } = useWalletObserver();

  const renderFallbackMessage = () => {
    if (errorReportingEnabled) {
      return <ErrorFallbackMessageAutoSend />;
    }

    return <ErrorFallbackMessage />;
  };

  return (
    <div className="px-6 pb-6 pt-24">
      <ErrorFallbackBase
        renderFallbackMessage={renderFallbackMessage}
        renderCollapsibleTrigger={(toggle) => (
          <ErrorDetailsMessage toggleCollapsible={toggle} />
        )}
        errorReportingEnabled={errorReportingEnabled}
        activeWallet={activeWallet as unknown as TSupportedWalletExtensions}
        walletAddress={mainAddress ?? ""}
        error={error}
        /**
         * @TODO Allow arbitrary values in the report data in UI Toolkit
         */
        // @ts-ignore
        getReportData={async () => {
          return {
            buildInfo: {
              ...window.__BUILD_CONFIG,
              buildAt: new Date(window.__BUILD_CONFIG.builtAt),
            },
            enabledFeatures: window.__APP_CONFIG.features,
          };
        }}
        resetErrorBoundary={resetErrorBoundary}
        errorReportingEndpoint={ERRORS_API_URL}
        i18n={{
          cancelButtonText: t("dontSend", {
            ...(errorReportingEnabled && { context: "autoSend" }),
          }),
          confirmButtonText: t("sentReport", {
            ...(errorReportingEnabled && { context: "autoSend" }),
          }),
          dateHeader: t("date", { ns: "common" }),
          dialogTitle: t("title"),
          errorMessageHeader: t("errorMessage"),
          errorStackHeader: t("errorStack"),
          osHeader: t("os", { ns: "common" }),
          reportIdHeader: t("reportID", { ns: "common" }),
          urlHeader: t("url", { ns: "common" }),
          walletAddressHeader: t("walletAddress"),
          walletInfoHeader: t("wallet", { ns: "glossary" }),
          browserHeader: t("browser", { ns: "common" }),
        }}
      />
    </div>
  );
};

export default ErrorFallback;
