import { SundaeSwapLogo } from "@sundaeswap/ui-toolkit";
import { motion } from "framer-motion";
import { useEffect } from "react";

const DEFAULT_ANIMATION_DURATION = 0.6;
const QUICK_ANIMATION_DURATION = 0.2;

const gradientBg = {
  initial: {
    transform: "scale(1)",
    bottom: 0,
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transform: "scale(4)",
    transition: {
      when: "afterChildren",
      duration: DEFAULT_ANIMATION_DURATION,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const textContainer = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: {
      duration: QUICK_ANIMATION_DURATION,
      when: "afterChildren",
    },
  },
};

const logoName = {
  initial: {
    y: 40,
  },
  animate: {
    y: 85,
    transition: {
      duration: DEFAULT_ANIMATION_DURATION,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const logoSvgContainer = {
  initial: { y: -30, opacity: 0 },
  animate: {
    y: -10,
    opacity: 1,
    transition: {
      duration: DEFAULT_ANIMATION_DURATION,
      delay: QUICK_ANIMATION_DURATION,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const logoSvg = {
  initial: {
    opacity: 0.35,
  },
  animate: {
    opacity: 0,
    transition: {
      delay: 2,
      duration: DEFAULT_ANIMATION_DURATION / 2,
    },
  },
};

export const INITIAL_PAGE_TRANSITION_DURATION =
  gradientBg.animate.transition.duration +
  textContainer.animate.transition.duration +
  logoSvgContainer.animate.transition.delay +
  logoSvg.animate.transition.duration;

const InitialPageTransition = () => {
  // Scroll user to top to avoid showing the footer
  useEffect(() => {
    typeof window !== "undefined" && window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      className="fixed left-0 z-50 flex h-full w-full items-center justify-center bg-gradient-to-t from-white to-gray-300 dark:from-gray-700 dark:to-gray-900"
      initial="initial"
      animate="animate"
      variants={gradientBg}
    >
      <motion.svg variants={textContainer} className="absolute z-50 flex">
        <pattern
          id="pattern"
          patternUnits="userSpaceOnUse"
          width={750}
          height={800}
          className="text-black dark:text-white"
        >
          <rect className="h-full w-full fill-current" />
          <motion.rect
            variants={logoName}
            className="h-full w-full fill-current text-gray-500 dark:text-gray-600"
          />
        </pattern>
        <text
          className="text-4xl font-bold"
          textAnchor="middle"
          x="50%"
          y="50%"
          style={{ fill: "url(#pattern)" }}
        >
          SundaeSwap
        </text>
      </motion.svg>

      <motion.div
        initial="initial"
        animate="animate"
        variants={logoSvgContainer}
      >
        <motion.div variants={logoSvg}>
          <SundaeSwapLogo />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default InitialPageTransition;
