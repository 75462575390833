import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import {
  EGuideChapter,
  IChapter,
} from "../../../../stores/ControlCenterContext";
import { usePortfolioAssetsGuideJoyride } from "../guides/assets/usePortfolioAssetsGuideJoyride";
import { usePortfolioBalanceGuideJoyride } from "../guides/balance/usePortfolioBalanceGuideJoyride";
import { usePortfolioLiquidityGuideJoyride } from "../guides/liquidity/usePortfolioLiquidityGuideJoyride";
import { usePortfolioOrdersGuideJoyride } from "../guides/orders/usePortfolioOrdersGuideJoyride";
import { usePortfolioYieldFarmingGuideJoyride } from "../guides/yield-farming/usePortfolioYieldFarmingGuideJoyride";

export const usePortfolioJoyrideChapter = (): IChapter => {
  const portfolioBalanceGuide = usePortfolioBalanceGuideJoyride();
  const portfolioAssetsGuide = usePortfolioAssetsGuideJoyride();
  const portfolioLiquidityGuide = usePortfolioLiquidityGuideJoyride();
  const portfolioYieldFarmingGuide = usePortfolioYieldFarmingGuideJoyride();
  const portfolioOrdersGuide = usePortfolioOrdersGuideJoyride();

  return useMemo(() => {
    return {
      id: EGuideChapter.PORTFOLIO,
      title: "Manage your Portfolio",
      description:
        "Take the reins of your investment journey with our portfolio management tools. Track your assets, gain insights, and fine-tune your portfolio for optimized performance.",
      guides: sortBy(
        [
          portfolioBalanceGuide,
          portfolioAssetsGuide,
          portfolioLiquidityGuide,
          portfolioYieldFarmingGuide,
          portfolioOrdersGuide,
        ],
        "rank",
        "asc",
      ),
    };
  }, [
    portfolioBalanceGuide,
    portfolioAssetsGuide,
    portfolioLiquidityGuide,
    portfolioYieldFarmingGuide,
    portfolioOrdersGuide,
  ]);
};
